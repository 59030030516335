import { RootStore } from "./RootStore";
import { makeAutoObservable, runInAction } from "mobx";
import { Dictionary, GetApiKeysDto } from "../Types";

export default class CustomerKeyStore {
  rootStore: RootStore;
  loading: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getAllApiKeys = async () => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: Dictionary<GetApiKeysDto[]> } = await this.rootStore.baseApiService.get("customerkey/keys/all");

    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }
}