import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Header, Segment } from "semantic-ui-react";
import { AnimationCheck } from "../../../AnimationViews/AnimationCheck";
import { StateValue } from "../../../Context";

export interface IOCRLoadingProps {
  /**
   * Header translation key, displayed at the top of the page, defaults to OCR_Labs.Automatic_Refresh_Header
   */
  headerMessageKey?: string;
  /**
   * Sub header translation key, displayed at the top of the page, defaults to OCR_Labs.Loading.SubHeader
   */
  subHeaderTranslationKey?: string;
}

export const OCRLoading = (props: IOCRLoadingProps) => {
  const { headerMessageKey, subHeaderTranslationKey } = props;
  console.log(props);

  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  return (
    <div className="ocr space-between ocr-padding" style={{ paddingLeft: 0, paddingRight: 0 }}>
      <span style={{ flex: 1 }}>
        <Segment id="loading-screen" basic style={{ height: "100%", width: "100%", margin: 0, paddingBottom: 0 }}>
          <AnimationCheck
            header={t(headerMessageKey ?? "OCR_Labs.Loading.Automatic_Refresh_Header")}
            text={t(subHeaderTranslationKey ?? "OCR_Labs.Loading.Automatic_Refresh_SubHeader")}
            svgComponent={state.org.theme.config.checkSvg && <state.org.theme.config.checkSvg fill={state.org.theme.themeColor} />}
          />
        </Segment>
      </span>
      <Segment basic className="service-item-button-container mt-0">
        <Button
          color="green"
          floated="right"
          type="submit"
          disabled
          loading
        >
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </div>
  )
}