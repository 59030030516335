import { ApiKeyListStatisticsHome } from "../../Statistics/home/ApiKeyListStatisticsHome";
import { Grid, Header, Segment } from "semantic-ui-react";
import { StatisticsHome } from "../../Statistics/home/StatisticsHome";
import { StatisticsHomeSidebar } from "../../Statistics/home/StatisticsHomeSidebar";

export const AdminHome = () => {

  return (
    <>
      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column width={3}>
            <StatisticsHomeSidebar />
          </Grid.Column>
          <Grid.Column width={12}>
            <Header as="h4" attached="top">
              Statistieken
            </Header>
            <Segment attached>
              <StatisticsHome />
            </Segment>
            <Header as="h4" attached>
              Actieve API keys
            </Header>
            <Segment attached>
              <ApiKeyListStatisticsHome />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  )
}