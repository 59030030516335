import InformationPageLayout from '../../Layouts/InformationPageLayout'
import { Button, Header, List, Segment } from 'semantic-ui-react'
import { EMitekErrorType } from '../../../../../Enums/EMitekErrorType'
import { StateValue } from '../../../../Context'
import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'

type IProps = {
  errors: EMitekErrorType[]
}

const MitekErrorPage = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const errors = useMemo(() => Array.from(new Set(props.errors)), [])

  return (
    <InformationPageLayout>
      <div id="generic-error-screen" className="transition-container">
        <ReactMarkdown skipHtml className='ui header service-item-header' >
          {t("Mitek.Error.Something_Went_Wrong_Checking_Documents")!}
        </ReactMarkdown>
        <List bulleted>
          {
            errors.map((error, index) => {
              return (
                <List.Item key={index}>
                  {t(`Mitek.Error.${error}`)}
                </List.Item>
              )
            })
          }
        </List>
      </div>
      <Segment basic className="service-item-button-container mt-0">
        <Button className='full-width' color="red" floated="right" type="submit" onClick={() => { window.location.reload(); }} >
          {t("General_UI.Retry")}
        </Button>
      </Segment>
    </InformationPageLayout>
  )
}

export default MitekErrorPage