import { EToastType } from "../Enums/EToastType";
import { IManager, ManagerFormValues } from "../models/user/IManager";
import { RootStore } from "./RootStore";
import { history, routePrefixDashboard } from "../ApplicationRouter";
import { makeAutoObservable, runInAction } from "mobx";

export default class CustomerManagerStore {
  rootStore: RootStore;
  loadingCustomerManagers: boolean = false;
  submitting: boolean = false;
  customerManagers: IManager[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getCustomerManagers = async () => {
    runInAction(() => {
      this.loadingCustomerManagers = true;
    });

    const { success, data }: { success: boolean; data: IManager[] } = await this.rootStore.baseApiService.get("customerManager");
    if (success) {
      runInAction(() => {
        this.customerManagers = data;
        this.loadingCustomerManagers = false;
      });
    } else {
      runInAction(() => {
        this.loadingCustomerManagers = false;
      });
    }
  }

  getCustomerManager = async (id: string) => {
    runInAction(() => {
      this.loadingCustomerManagers = true;
    });

    const { success, data }: { success: boolean; data: IManager } = await this.rootStore.baseApiService.get(`customerManager/${id}`);
    if (success) {
      runInAction(() => {
        this.loadingCustomerManagers = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingCustomerManagers = false;
      });
    }
  }

  addCustomerManager = async (values: ManagerFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.post('customerManager', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      history.push(`${routePrefixDashboard}/customer-managers`);
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  editCustomerManager = async (values: ManagerFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.put(`customerManager/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      });
      history.push(`${routePrefixDashboard}/customer-managers`);
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  deleteCustomerManager = async (id: string) => {
    runInAction(() => {
      this.submitting = true;
    });

    const success = await this.rootStore.baseApiService.delete(`customerManager/${id}`);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.confirmModalStore.closeConfirmModal();
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Delete_Success");
      });
      this.getCustomerManagers();
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }
}