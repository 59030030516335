export enum ETransactionStatusCode {
  /// <summary>
  /// Transaction has recently been created and is processing.
  /// </summary>
  InProgress = 0,

  /// <summary>
  /// Transaction has been completed successfully.
  /// </summary>
  Completed = 1,

  /// <summary>
  /// Transaction failed.
  /// </summary>
  Failed = 2,

  /// <summary>
  /// Transaction is incomplete.
  /// </summary>
  Incomplete = 3
}