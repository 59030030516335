import React, { useContext } from 'react'
import { autoCaptureHints as DefaultMitekHintsEnglish } from './Services/Mitek/MitekCheckHints'
import { EService } from '../Enums/EService'
import { FlowItem, ServiceComponentType } from '../Types'
import { IdentityServices } from '../IdentityServices'
import { ReactComponent as CheckDefaultSvg } from '../Assets/SVG/Check.svg'
import { ReactComponent as IbanCheckDefaultSvg } from '../Assets/SVG/IbanCheck.svg'
import { ReactComponent as MitekDefaultSvg } from '../Assets/SVG/MitekCheck.svg'
import { ReactComponent as StartDefaultSvg } from '../Assets/SVG/Start.svg'
import { ReactComponent as SuccessDefaultSvg } from '../Assets/SVG/Success.svg'
import { themeConfig } from '../themeConfig'

const defaultTheme: themeConfig = {
  className: "layout",
  classNameMobile: "mobile-content-container",
  translationKey: "default",
  logoContainer: false,
  startSvg: StartDefaultSvg,
  successSvg: SuccessDefaultSvg,
  checkSvg: CheckDefaultSvg,
  ibanCheckSvg: IbanCheckDefaultSvg,
  mitekSvg: MitekDefaultSvg,
  mitekHints: DefaultMitekHintsEnglish,
  idinSvg: null,
  verticleSteps: true,
  ordered: false
}

export type state = {
  targetUrl: string;
  view: "form" | "checking" | "success";
  current: FlowItem;
  lastFinishedAccordingToBackend?: number;
  flow: FlowItem[];
  flowDescription: string;
  org: {
    id: number;
    name: string;
    theme: {
      themeColor: string;
      logo: string;
      clientClass?: string;
      config: themeConfig;
    }
  };
  loading: boolean;
  header: string;
  errors: any;
  ctxId: string;
};

export function reducer(state: state, action: action): state {
  switch (action.type) {
    case "setTargetUrl":
      return { ...state, targetUrl: action.data };
    case "setLastFinishedAccordingToBackend":
      return { ...state, lastFinishedAccordingToBackend: action.data };
    case "setView":
      return { ...state, view: action.data };
    case "setHeader":
      return { ...state, header: action.data };
    case "setErrors":
      return { ...state, errors: action.data };
    case "setOrg":
      return { ...state, org: action.data };
    case "setCurrent":
      if (state.org.theme.config.isIframe) {
        // var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?current_step=${action.data.order}`;
        // window.history.pushState({path:newurl},'',newurl);
        window.parent.postMessage({current_step: action.data.order}, '*');
      }
      return { ...state, current: { ...action.data } };
    case "setFlow":
      return { ...state, flow: action.data };
    case "setLoading":
      return { ...state, loading: action.data };
    case "setCtxId":
      return { ...state, ctxId: action.data };
    case "init":
      return action.data;
    default:
      throw new Error(JSON.stringify(action));
  }
}

export type action =
  | { type: "setTargetUrl"; data: string }
  | { type: "setView"; data: "form" | "checking" | "success" }
  | { type: "setHeader"; data: string }
  | { type: "setErrors"; data: any }
  | {
    type: "setOrg";
    data: {
      name: string;
      id: number;
      theme: {
        themeColor: string;
        logo: string,
        clientClass: string
        config: themeConfig,
      }
    };
  }
  | { type: "setFlow"; data: { service: EService; order: number, identityService: ServiceComponentType }[] }
  | { type: "setLoading"; data: boolean }
  | { type: "setCurrent"; data: FlowItem }
  | { type: "setLastFinishedAccordingToBackend"; data: number }
  | { type: "init"; data: state }
  | { type: "setCtxId"; data: string }

export const initState: state = {
  targetUrl: "",
  lastFinishedAccordingToBackend: -1,
  current: { order: -1, identityService: IdentityServices.find(is => is.service == EService.START)! },
  flow: [],
  flowDescription: "",
  loading: true,
  org: {
    id: 0,
    name: "",
    theme: {
      themeColor: "",
      logo: "",
      clientClass: undefined,
      config: defaultTheme,
    }
  },
  view: "form",
  errors: undefined,
  header: "",
  ctxId: "",
};

export const StateDispatch = React.createContext<React.Dispatch<action>>(
  () => null
);

export const StateValue = React.createContext<state>(initState);

export const useStateDispatch = () => useContext(StateDispatch);
