import "./Login.css";
import { Grid, Segment, Image } from "semantic-ui-react";
import { LoginForm } from "./LoginForm";

export const Login = () => {

  return (
    <Grid className="login" columns="2">
      <Grid.Column className="center">
        <Segment basic>
          <Segment padded="very" className="form">
            <LoginForm />
          </Segment>
        </Segment>
      </Grid.Column>

      <Grid.Column className="loginimage">
        <Image src="/loginsvg.svg" style={{ height: "100%", objectFit: "cover" }} />
      </Grid.Column>
    </Grid>
  );
};
