import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Table } from "semantic-ui-react";
import { SerivceCombiEnums, ServiceBackofficeEnums } from "../../../Enums/EService";
import { IStatisticTotal } from "../../../models/statistics/IStatisticsReport";

interface IProps {
  statisticTotals: IStatisticTotal[]
}

export const StatisticsTotal: React.FC<IProps> = ({ statisticTotals }) => {
  const { t } = useTranslation();
  const serviceCombiEnums = SerivceCombiEnums();
  const backOfficeEnums = ServiceBackofficeEnums();
  const [statisticsTotals, setStatisticsTotals] = useState<IStatisticTotal[]>([]);

  useEffect(() => {
    let combiEnums = serviceCombiEnums.map((x) => x.value);
    let officeEnums = backOfficeEnums.map((x) => x.value);
    let filteredTotals = statisticTotals.filter((x) => x.total > 0 && !combiEnums.find((e) => e === x.service) && !officeEnums.find(e => e === x.service));

    setStatisticsTotals(filteredTotals);
  }, [statisticTotals])

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t("Statistics.Name")}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t("Statistics.Total_Calls")}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t("Statistics.Finished")}</Table.HeaderCell>
            <Table.HeaderCell width={3}>{t("Statistics.Finished_Not")}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {statisticsTotals.map((statisticTotal, index) => {
            return (
              <Table.Row key={`${statisticTotal.name}-${index}`}>
                <Table.Cell>{statisticTotal.name}</Table.Cell>
                <Table.Cell>{statisticTotal.total}</Table.Cell>
                <Table.Cell>{statisticTotal.completed}</Table.Cell>
                <Table.Cell>{statisticTotal.notCompleted}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table>
    </>
  )
}