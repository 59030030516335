import { FunctionComponent, useContext } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { StateValue } from '../Context'
import { useTranslation } from 'react-i18next'

interface IProps {
  text: any
  header: string
  svgComponent?: any
}

export const AnimationCheck: FunctionComponent<IProps> = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const background = encodeURIComponent(renderToStaticMarkup(props.svgComponent));

  return (
    <>
      <div className='animation-check' style={{ width: "100%", height: "100%", background: `url("data:image/svg+xml,${background}")`, backgroundSize: "contain", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat", flex: 1 }}>
        <span style={{ display: "flex", flexDirection: "column" }}>
          <h1 className="service-item-header">{t(props.header)}</h1>
          <h2 className="service-item-subheader">{t(props.text)}</h2>
        </span>
      </div>
    </>
  )
};
