import { Container, Header, Divider } from "semantic-ui-react"
import { CustomerParent } from "../../../Components/secure/customer/CustomerParent"

export const CreateCustomer = () => {
  return (
    <>
      <Container>
        <Header as="h1">Create</Header>
        <Divider />
        <CustomerParent />
      </Container>
    </>
  )
}