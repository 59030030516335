import React, { useContext, useRef } from 'react'
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { Segment, Button, Icon, Message } from 'semantic-ui-react';
import { StateDispatch, StateValue } from '../../Context';

interface IProps {
  setPageCallback: (e: any) => void;
  imageCallBack: (data: any) => void
}

const UtilityBillInformation = (props: IProps) => {
  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  // const background = state.org.theme.config.startSvg ? encodeURIComponent(renderToStaticMarkup(<state.org.theme.config.startSvg fill={state.org.theme.themeColor} />)) : null;
  const background = null;

  const handleImageCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (reader.result) {
            props.imageCallBack({ utilityBillImageBase64: reader.result.toString() });
          }
        };
      }
    }
  };

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${state.view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container" style={{ background: state.view !== "success" ? `url("data:image/svg+xml,${background}")` : "" }}>
            <span>
              <ReactMarkdown className='service-item-header' skipHtml>{t("UtilityBill.Information.Title")!}</ReactMarkdown>
              <ReactMarkdown className='explanation-text' skipHtml>{t("UtilityBill.Information.Text")!}</ReactMarkdown>
              <Message warning className={`image-warning`}>
                <Icon name='warning' />
                <ReactMarkdown skipHtml>{t("UtilityBill.ImageCapture.Warning")!}</ReactMarkdown>
              </Message>
            </span>
          </div>

        </CSSTransition>
      </SwitchTransition>

      <Segment basic className="service-item-button-container mt-0">
        <input ref={inputRef} style={{ display: "none" }} type="file" name="imageCapture" accept="image/*" onChange={handleImageCapture} />
        <Button fluid onClick={() => inputRef.current?.click()}>{isDesktop ? t("UtilityBill.ImageCapture.Select_Picture") : t("UtilityBill.ImageCapture.Take_Picture")}</Button>
      </Segment>
    </>
  );
}

export default UtilityBillInformation