import { EMakeAChoice } from "../../Enums/EMakeAChoice";

export interface IOrganization {
  id?: number;
  name: string;
  customerId: number;
}

export interface IOrganizationForm {
  id?: number;
  name: string;
  target: string;
  api: string;
  customerId: number;
  resultCallbackBaseURLDev: string;
  resultCallbackBaseURLAcc: string;
  resultCallbackBaseURLProd: string;
  resultCallbackEndpoint: string;
  // apiEnd: string;
}

export class OrganizationFormValues {
  id?: number | undefined = undefined;
  name: string = "";
  resultCallbackBaseURLDev: string = "";
  resultCallbackBaseURLAcc: string = "";
  resultCallbackBaseURLProd: string = "";
  resultCallbackEndpoint: string = "";
  api: string = "";
  customerId: number = EMakeAChoice.Value;
  // apiEnd: string | null = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0, 10);

  constructor(init?: IOrganization) {
    Object.assign(this, init);
    if (init) {
      // this.apiEnd = init.apiEnd === null
      //   ? new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0, 10)
      //   : init.apiEnd;
    }
  }
}

export interface IOrganizationApiForm {
  customerClientId: number;
  apiEnd: string;
}

export class OrganizationApiFormValues {
  customerClientId: number = 0;
  apiEnd: string = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0, 10);

  constructor(init?: IOrganizationApiForm) {
    Object.assign(this, init);
    if (init) {
      this.apiEnd = init.apiEnd === '' ? new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().slice(0, 10) : init.apiEnd;
    }
  }
}

export interface IOrganizationPreviewForm {
  id: number;
  logo: any;
  themeColor: string;
}

export class OrganizationPreviewFormValues {
  id: number = 0;
  logo: any;
  themeColor: string = "";

  constructor(init?: IOrganizationPreviewForm) {
    Object.assign(this, init);
  }
}