import "./Organization.css";
import { ClientCreateEditDto } from "../../../Types";
import { OrganizationApiAccess } from "./OrganizationApiAccess";
import { OrganizationDescriptionForm } from "./OrganizationDescriptionForm";
import { OrganizationFlowDnD } from "./OrganizationFlowDnD";
import { OrganizationPreview } from "./OrganizationPreview";
import { RootStoreContext } from "../../../stores/RootStore";
import { Segment } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";

interface IProps {
  id?: string;
}

export const OrganizationParent: React.FC<IProps> = observer(({ id }) => {
  const rootStore = useContext(RootStoreContext);
  const { getClientById } = rootStore.customerClientStore;

  const [client, setClient] = useState<ClientCreateEditDto | undefined>(undefined);
  const [currentFlow, setCurrentFlow] = useState<number>(0);

  useEffect(() => {
    if (id !== undefined) {
      const getClient = async () => {
        var response = await getClientById(parseInt(id));
        setClient(response);
      }
      getClient();
    }
  }, [id])

  return (
    <>
      <OrganizationDescriptionForm id={id} client={client} />

      {id != undefined && (
        <OrganizationApiAccess id={id} client={client} />
      )}
      <OrganizationPreview id={id !== undefined ? parseInt(id) : 0} name={client?.name ?? "Placeholder"} color={client?.themeColor ?? undefined} />

      {id != undefined && (
        <OrganizationFlowDnD id={id} currentFlow={currentFlow} setCurrentFlow={setCurrentFlow} />
      )}
      <Segment attached />
    </>
  )
})