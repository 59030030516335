/**
 *
 * @param date CDDN date type YYYYMMdd
 * @returns input type date value YYYY-MM-dd
 */
export const parseDateCDDN = (date: string): string => {
  const toParse = date;
  const yyyy = toParse.slice(0, 4);
  const mm = toParse.slice(4, 6);
  const dd = toParse.slice(6, 8);
  return `${yyyy}-${mm}=${dd}`
}