import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { CheckBox } from "../../../Common/form/CheckBox";
import { Field, Formik } from "formik";
import { FlowSettingsBigFormValues, IFlowSettingsBig } from "../../../../models/flow/settings/IFlowOrder";
import { FlowSettingsBigValidation } from "./FlowSettingsBig.Validation";
import { RootStoreContext } from "../../../../stores/RootStore";
import { Spinner } from "../../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  flowId: number;
}

export const FlowSettingsBig = observer(({ flowId }: IProps) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { loading, submitting, getBigSettingsForFlow, updateBigSettingsForFlow } = rootStore.flowSettingsStore;

  const [flowSettings, setFlowSettings] = useState<IFlowSettingsBig>();
  const [formValues, setFormValues] = useState(new FlowSettingsBigFormValues());

  useEffect(() => {
    const loadData = async () => {
      var flowSettings = await getBigSettingsForFlow(flowId);
      setFlowSettings(flowSettings);
      setFormValues(new FlowSettingsBigFormValues(flowSettings));
    }
    loadData();
  }, [flowId])

  const handleSubmit = async (values: FlowSettingsBigFormValues) => {
    await updateBigSettingsForFlow(flowId, values);
  }

  return (
    <>
      <Header as="h1" attached="top">
        {t("Flow_Settings.Big.Title")}
      </Header>
      <Segment loading={loading}>
        {!flowSettings ? <Spinner /> : (
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={FlowSettingsBigValidation}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">{t("Flow_Settings.General.Heading")}</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="isVisible"
                          label={t("Flow_Settings.General.Visible")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {/* Flow settings */}
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">{t("Flow_Settings.Big.Heading_Person")}</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.personIdentCheck"
                          label={t("Flow_Settings.Big.PersonIdentCheck")}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.personIdentAddressCheck"
                          label={t("Flow_Settings.Big.PersonIdentAddressCheck")}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.personIdentWatchListCheck"
                          label={t("Flow_Settings.Big.PersonIdentWatchListCheck")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {formik.values.bigSettings.personIdentWatchListCheck && (
                      <>
                        <Grid.Row>
                          <Grid.Column>
                            <Header as="h3">{t("Flow_Settings.Big.Heading_WatchList")}</Header>
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={5}>
                          <Grid.Column>
                            <Field
                              component={CheckBox}
                              name="bigSettings.watchListCheckPEP"
                              label={t("Flow_Settings.Big.WatchListCheckPEP")}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Field
                              component={CheckBox}
                              name="bigSettings.watchListCheckSanction"
                              label={t("Flow_Settings.Big.WatchListCheckSanction")}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Field
                              component={CheckBox}
                              name="bigSettings.watchListCheckAssociatedEntity"
                              label={t("Flow_Settings.Big.WatchListCheckAssociatedEntity")}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Field
                              component={CheckBox}
                              name="bigSettings.watchListCheckEnforcements"
                              label={t("Flow_Settings.Big.WatchListCheckEnforcements")}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Field
                              component={CheckBox}
                              name="bigSettings.watchListCheckAdverseMedia"
                              label={t("Flow_Settings.Big.WatchListCheckAdverseMedia")}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">The below are not implemented yet</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.scoreCheck"
                          label={t("Flow_Settings.Big.ScoreCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.personFraudCheck"
                          label={t("Flow_Settings.Big.PersonFraudCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.personIdentNewCustomerCheck"
                          label={t("Flow_Settings.Big.PersonIdentNewCustomerCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.personIdentFeatureListCheck"
                          label={t("Flow_Settings.Big.PersonIdentFeatureListCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.bankAccountCreditCheck"
                          label={t("Flow_Settings.Big.BankAccountCreditCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.bankAccountFraudCheck"
                          label={t("Flow_Settings.Big.BankAccountFraudCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.bankAccountIdentCheck"
                          label={t("Flow_Settings.Big.BankAccountIdentCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.bankAccountIdentFeatureListCheck"
                          label={t("Flow_Settings.Big.BankAccountIdentFeatureListCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.emailFraudCheck"
                          label={t("Flow_Settings.Big.EmailFraudCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.emailIdentFeatureListCheck"
                          label={t("Flow_Settings.Big.EmailIdentFeatureListCheck")}
                          disabled
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="bigSettings.deviceFraudCheck"
                          label={t("Flow_Settings.Big.DeviceFraudCheck")}
                          disabled
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row textAlign="right">
                      <Grid.Column>
                        <Button type="submit" primary disabled={submitting}>
                          {t("Button.Submit")}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Form>
            )}
          </Formik>
        )}
      </Segment>
    </>
  )
})