import MitekHeader from '../../MitekHeader';
import { Segment, Button } from 'semantic-ui-react';
import { renderToStaticMarkup } from 'react-dom/server';
import { state } from '../../../../Context';
import { useTranslation, Trans } from 'react-i18next';

type MitekCheckBackExplanationProps = {
  themeColor: string
  setCurrentPage: (page: number) => void;
  state: state;
}

const MitekCheckBackExplanationComponent = (props: MitekCheckBackExplanationProps) => {
  const { t } = useTranslation(props.state.org.theme.config.translationKey);

  const background = props.state.org.theme.config.mitekSvg ? encodeURIComponent(renderToStaticMarkup(<props.state.org.theme.config.mitekSvg fill={props.state.org.theme.themeColor} />)) : null;

  return (
    <>
      <MitekHeader />
      <Segment id="explanation-screen" className="mitek" basic style={{ backgroundColor: "white", height: "100%", width: "100%", margin: 0 }}>
        <h1 className="service-item-header">{t("General_UI.Great")}</h1>
        <h2 className="service-item-subheader">
          <Trans i18nKey="Mitek.Help.Back" ns={props.state.org.theme.config.translationKey}>
            Placeholder <u></u>
          </Trans>
        </h2>
        <p>{t("Mitek.Help.Hold_Phone")}</p>
        <p>{t("Mitek.Help.Hold_Phone_Extra")}</p>
        <div className='explanation-background' style={{ background: props.state.view !== "success" ? `url("data:image/svg+xml,${background}")` : "", backgroundSize: "contain", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }} />
      </Segment>
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }} >
        <Button className="full-width" color="green" floated="right" onClick={() => props.setCurrentPage(2)}>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
}

export default MitekCheckBackExplanationComponent