export interface ICustomer {
  id?: number;
  name: string;
  phone: string;
  email: string;
  website: string;
}

export interface ISimpleCustomer {
  id: number;
  name: string;
}

export class CustomerFormValues {
  id?: number | undefined = undefined;
  name: string = "";
  phone: string = "";
  email: string = "";
  website: string = "";

  constructor(init?: ICustomer) {
    Object.assign(this, init)
  }
}

export interface ICustomerServices {
  id: number;
  name: string;
  serviceIds: number[];
}
