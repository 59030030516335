import { Container, Divider, Header } from "semantic-ui-react"
import { Statistics } from "../../../../Components/secure/statistics/Statistics"

export const StatisticsReport = () => {

  return (
    <>
      <Container>
        <Header as="h1">Statistics</Header>
        <Divider />
        <Statistics />
      </Container>
    </>
  )
}