import { EMakeAChoice } from "../../Enums/EMakeAChoice";
import { IPassword } from "./IPassword";

export interface IManager {
  id: string;
  userName: string;
  email: string;
  firstName: string;
  lastName: string;
  lastActivity: Date;
  password: IPassword;
  customerId: number;
  clientId?: number;
}

export class ManagerFormValues {
  id?: string | undefined;
  email: string = "";
  userName: string = "";
  firstName: string = "";
  lastName: string = "";
  customerId: number = EMakeAChoice.Value;
  clientId: number | undefined = EMakeAChoice.Value;

  constructor(init?: IManager) {
    Object.assign(this, init);
  }
}