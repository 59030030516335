import { ChangePasswordProfileFormValidation } from "./ChangePasswordProfileForm.Validation";
import { ChangePasswordProfileFormvalues } from "../../../models/user/IPassword";
import { Formik, Field } from "formik";
import { Grid, Form, Segment, Button, Header } from "semantic-ui-react";
import { PasswordInput } from "../../Common/form/PasswordInput";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";


export const ChangePasswordProfileForm = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { submitting, changePassword } = rootStore.profileStore;
  const [formValues, setFormValues] = useState(new ChangePasswordProfileFormvalues());

  const handleSubmit = async (values: ChangePasswordProfileFormvalues) => {
    setFormValues(values);
    changePassword(values);
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={8}>
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={ChangePasswordProfileFormValidation}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Segment padded className='bg-lightest-grey'>
                  <Header as="h2">{t("Password.Change_Current")}</Header>
                  <Field
                    component={PasswordInput}
                    label={t("Password.Current")}
                    name="currentPassword"
                    required
                  />
                  <Field
                    component={PasswordInput}
                    label={t("Password.Password_New")}
                    name="password"
                    required
                  />
                  <Field
                    component={PasswordInput}
                    label={t("Password.Repeat_New")}
                    name="confirmPassword"
                    required
                  />
                </Segment>
                <Button disabled={submitting} floated='right' loading={submitting} primary type="submit">
                  {t("Button.Save")}
                </Button>
              </Form>
            )}
          </Formik>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
})