import { Field, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, DropdownItemProps, Form, Grid, Segment } from "semantic-ui-react";
import { EnvironmentEnums, IEnvironmentEnum } from "../../../Enums/EEnvironmentType";
import { EMakeAChoice } from "../../../Enums/EMakeAChoice";
import { ISimpleClient, ISimpleFlowId } from "../../../models/client/IClient";
import { GetStatisticsReportFormValues } from "../../../models/statistics/IStatisticsReport";
import { RootStoreContext } from "../../../stores/RootStore";
import { DatePicker } from "../../Common/form/DatePicker";
import { DropDown } from "../../Common/form/Dropdown";

interface IProps {
  handleSubmit: (values: GetStatisticsReportFormValues) => void
  loading: boolean
}

export const StatisticsForm: React.FC<IProps> = observer(({ handleSubmit, loading }) => {
  const { t } = useTranslation();

  const [customerItemProps, setCustomerItemProps] = useState<DropdownItemProps[]>([]);
  const [clients, setClients] = useState<ISimpleClient[]>([]);
  const [flowIds, setFlowIds] = useState<ISimpleFlowId[]>([]);

  const [formValues, setFormValues] = useState(new GetStatisticsReportFormValues());
  const [envTypeOptions, setEnvTypeOptions] = useState<IEnvironmentEnum[]>([]);

  const rootStore = useContext(RootStoreContext);
  const { getAllowedCustomers, getAllowedClientsForCustomer, GetAllowedFlowIdsForCustomerAndClient } = rootStore.statisticsStore;

  const handleCustomSubmit = (values: GetStatisticsReportFormValues) => {
    // Setting the formvalues on submit, to prevent resetting the form when a different customer is chosen
    setFormValues(values);

    if (values.customerId == EMakeAChoice.Value) values.customerId = undefined;
    if (values.clientId == EMakeAChoice.Value) values.clientId = undefined;
    if (values.envType == EMakeAChoice.Value) values.envType = undefined;
    handleSubmit(values);
  }

  useEffect(() => {
    const loadCustomers = async () => {
      var allowedCustomers = await getAllowedCustomers();
      if (allowedCustomers) {
        let customerNames: DropdownItemProps[] = [
          { key: EMakeAChoice.Value, value: EMakeAChoice.Value, text: t(EMakeAChoice.Text) },
          ...allowedCustomers.map(c => { return { key: c.id, text: c.name, value: c.id } })
        ]
        setCustomerItemProps(customerNames);
      }
    }
    loadCustomers();
  }, [])

  useEffect(() => {
    setEnvTypeOptions(EnvironmentEnums());
    handleCustomSubmit(formValues)
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={handleCustomSubmit}
    >
      {(formik) => {
        useEffect(() => {
          formik.setFieldValue("clientId", EMakeAChoice.Value as number)
          const loadClients = async () => {
            if (formik.values.customerId && formik.values.customerId != EMakeAChoice.Value) {
              var allowedClients = await getAllowedClientsForCustomer(formik.values.customerId.toString());
              if (allowedClients) {
                setClients(allowedClients);
              }
            } else {
              setClients([]);
            }
          }
          loadClients();
        }, [formik.values.customerId])

        useEffect(() => {
          const loadFlowIds = async () => {
            if (formik.values.customerId && formik.values.customerId != EMakeAChoice.Value) {
              var clientId = (formik.values.clientId == undefined || formik.values.clientId == EMakeAChoice.Value) ? undefined : formik.values.clientId;
              var allowedFlowIds = await GetAllowedFlowIdsForCustomerAndClient(formik.values.customerId, clientId);
              if (allowedFlowIds) {
                setFlowIds(allowedFlowIds);
              }
            } else {
              setFlowIds([]);
            }
          }
          if (formik.values.customerId != formValues.customerId || (formik.values.clientId != formValues.clientId)) {
            formik.setFieldValue("flowId", EMakeAChoice.Value as number)
          }
          loadFlowIds();
        }, [formik.values.customerId, formik.values.clientId])
        return (
          <Form onSubmit={formik.handleSubmit}>
            <Segment padded className='bg-lightest-grey'>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={4}>
                    <Field
                      component={DatePicker}
                      required
                      name="start"
                      label={t("Statistics.Form.Start_Date")}
                      type="datetime-local"
                      activationDate={formik.values.start}
                      onChange={(e: any) => console.log('datepicker', e)}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}>
                    <Field
                      component={DatePicker}
                      required
                      name="end"
                      label={t("Statistics.Form.End_Date")}
                      type="datetime-local"
                      activationDate={formik.values.end}
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column width={3}>
                    <div className="mb-1"><label htmlFor="customerId">{t("Statistics.Form.Customer")}</label></div>
                    <Dropdown
                      fluid
                      id="customerId"
                      onChange={(_, data) => formik.setFieldValue("customerId", data.value as number)}
                      options={customerItemProps}
                      search
                      selection
                      value={formik.values.customerId}
                    />
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Field
                      component={DropDown}
                      name="clientId"
                      label={t("Statistics.Form.Client")}
                      value={formik.values.clientId}
                      options={clients.map((clients) => {
                        return {
                          key: clients.id,
                          value: clients.id,
                          text: clients.name
                        }
                      })}
                    />
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Field
                      component={DropDown}
                      name="flowId"
                      label={t("Statistics.Form.FlowId")}
                      value={formik.values.flowId}
                      options={flowIds.map((flowId) => {
                        return {
                          key: flowId.id,
                          value: flowId.id,
                          text: flowId.name
                        }
                      })}
                    />
                  </Grid.Column>
                  <Grid.Column width={3}>
                    <Field
                      component={DropDown}
                      required
                      name="envType"
                      label={t("Statistics.Form.Keys_Used")}
                      value={formik.values.envType}
                      options={envTypeOptions.map((envType) => {
                        return {
                          key: envType.value,
                          value: envType.value,
                          text: t(`Enum.EnvironmentType.${envType.name}`)
                        }
                      })}
                    />
                  </Grid.Column>
                </Grid.Row>
                {(formik.values.envType === undefined || formik.values.envType === EMakeAChoice.Value) && (
                  <Grid.Row>
                    <Grid.Column>
                      <h4>Since no specific key has been selected, the results will be the sum of all keys.</h4>
                    </Grid.Column>
                  </Grid.Row>
                )}
                <Grid.Row textAlign="right">
                  <Grid.Column>
                    <Button type="submit" primary disabled={loading}>
                      {t("Button.Submit")}
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Form>
        )
      }}
    </Formik>
  )
})