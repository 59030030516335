import BottomButtonGroup from '../../Components/BottomButtonGroup';
import { Button, Icon, Segment, Transition, Image } from 'semantic-ui-react';
import { EMitekPageType } from '../../../../../Enums/EMitekPageType';
import { MitekScanPageProps } from '../../../../../Types';
import { useTranslation } from 'react-i18next';

const MitekScanSignatureComponent = (props: MitekScanPageProps) => {
  const { t } = useTranslation(props.state.org.theme.config.translationKey);

  return (
    <>
      <Image style={{ zIndex: 1001, position: "absolute", top: "1em", left: "1em" }} src={props.state.org.theme.logo} className="logoImg" fluid />
      <div style={{ position: "absolute", margin: "auto", zIndex: 1000, width: window.outerWidth, bottom: 0, left: 0, right: 0, top: 0, height: "1px" }}>

        {props.countDown < 5 && (
          <Transition visible={props.debouncedIsVisible} animation='fly right' duration={500}>
            <h2 className="hintMessage" style={{ marginTop: "-0.5em" }}>
              {props.hint}
            </h2>
          </Transition>
        )}
      </div>
      <Segment className="mitek" basic style={{ height: "100%", width: "100%", padding: 0, margin: 0 }} compact loading={props.loadingCamera}>
        <div id="mitekContainer" style={{ width: "100%", height: "100%", zIndex: 1 }}></div>
      </Segment>

      <BottomButtonGroup buttons={(<>
        <Button disabled={props.countDown !== 0} onClick={() => { props.mitekScienceSDK.cmd("SDK_STOP"); props.setCurrentPage(EMitekPageType.MANUAL_CAPTURE_SIGNATURE); }} >
          <Icon name="photo" /> {t("Mitek.Manual")} {`${props.countDown !== 0 ? "(" + props.countDown + ")" : ""}`}
        </Button>
        <Button onClick={() => { props.mitekScienceSDK.cmd("SDK_STOP"); window.location.reload(); }}>
          <Icon name="id card outline" /> {t("Mitek.Other")}
        </Button>
      </>)}
      />
    </>
  )
}

export default MitekScanSignatureComponent