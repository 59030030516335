import axios from 'axios'
import coverArt_Atlantis from '../../Assets/Atlantis_Cover.jpg'
import coverArt_Dts from '../../Assets/DTS_Cover.jpg'
import coverArt_Sonio from '../../Assets/Sonio_Cover.png'
import coverArt_Tipico from '../../Assets/Tipico_Cover.jpg'
import { Button, Container, Form, Header, Icon, Message } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { StateValue } from '../Context'
import { useContext, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

export const StartDevSession = () => {

  const [view, setView] = useState<"code" | "start">("code");
  const [code, setCode] = useState<string>("");
  const [isError, setIsError] = useState<boolean>(false);

  let params = useParams<{ organization: string }>();

  const state = useContext(StateValue);
  const [isLoading, setIsLoading] = useState(false);

  const handleCodeSubmit = () => {
    setIsLoading(true);
    axios.get(`/api/Development/start-dev-session?ctxId=${state.ctxId}&flowId=${flowId}&clientId=${clientIdStaging}&unlockCode=${code}`).then(res => {
      setIsLoading(false);
      setIsError(false);
      if (res.status === 200) {
        window.location.href = res.data.redirectUrl;
      } else {
        setIsError(true);
        setView("code");
      }
    }).catch(err => {
      setIsLoading(false);
      setIsError(true);
      setView("code");
    })
  }

  const clientIdStaging = useMemo(() => {
    if (params.organization === "atlantis") {
      return 1048;
    } else if (params.organization === "sonio") {
      return 1046;
    } else if (params.organization === "tipico") {
      return 1045;
    } else if (params.organization === "betway") {
      return 666;
    } else if (params.organization === 'dts') {
      return 6666;
    }
  }, [params]);

  const flowId = useMemo(() => {
    if (params.organization === 'dts') {
      return 1112
    } else return 1107
  }, [params])

  const backgroundImage = useMemo(() => {
    let bgImage = coverArt_Sonio;
    switch (params?.organization) {
      case "atlantis":
        bgImage = coverArt_Atlantis;
        break;
      case "tipico":
        bgImage = coverArt_Tipico;
        break;
      case "dts":
        bgImage = coverArt_Dts;
        break;
      default:
        bgImage = coverArt_Sonio;
        break;
    }
    return bgImage;
  }, [params])

  const organization = useMemo(() => {
    switch (params?.organization) {
      case "tipico":
        return "Tipico";
      case "atlantis":
        return "Atlantis Resorts";
      case "sonio":
        return "Sonio";
      case "betway":
        return "Betway";
      case "dts":
        return "DTS"
      default:
        return "Sonio";
    }
  }, [params])

  const handleCodeChange = (e: any) => {
    setCode(e.target.value);
  }

  const renderView = () => {
    switch (view) {
      case "code":
        return (
          <>
            <Container style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundSize: "cover" }}>
              <Form error={isError} style={{ width: "300px" }}>
                <Form.Field>
                  <label>Enter Code</label>
                  <input onChange={handleCodeChange} />
                </Form.Field>
                <Message
                  style={{ display: isError ? "block" : "none", width: "300px" }}
                  warning
                  header='Action Forbidden'
                  content='The code you provided was invalid.'
                />
                <Button loading={isLoading} onClick={() => setView("start")}>Continue</Button>
              </Form>
            </Container>
          </>
        )
      case "start":
        return (
          <Container style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundImage: `url(${backgroundImage})`, backgroundSize: "cover", backgroundPosition: "bottom center" }}>
            <div style={{ textAlign: "center", background: "white", padding: "3em", borderRadius: "14px", boxShadow: "0px 16px 40px 0px rgba(0,0,0,0.3)" }}>
              <Header as="h1">
                Welcome to {organization}!
              </Header>
              <Header as="h2" style={{ marginBottom: "4em" }}>
                Kindly proceed with the verification process.
              </Header>
              <Button primary onClick={handleCodeSubmit} loading={isLoading}>
                Start Verification <Icon name='angle right' />
              </Button>
            </div>
          </Container>
        )
    }
  }

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={`${view}-trans`}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <div className="transition-container">
          {renderView()}
        </div>
      </CSSTransition>
    </SwitchTransition>
  )
};
