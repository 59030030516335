import { Container } from "semantic-ui-react";
import { CustomerManagerList } from "../../../../Components/secure/customerManager/CustomerManagerList";

export const CustomerManagers = () => {

  return (
    <Container>
      <CustomerManagerList />
    </Container>
  )
};