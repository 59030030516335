import React, { useEffect, useState } from 'react'
import { Button } from 'semantic-ui-react'


interface IProps {
  callback: () => void
  countDownStart?: number
}

export const TimerButton: React.FC<IProps> = ({ callback, countDownStart }) => {
  const [countDown, setCountDown] = useState(countDownStart ?? 5);
  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    }
    if (countDown === 0) {
      callback();
    }

  }, [countDown])

  return (
    <Button color="green" floated="right" type="submit">
      {countDown}
    </Button>
  )
}
