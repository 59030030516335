export enum Roles {
  ADMIN = 'admin',
  CUSTOMERMANAGER = 'customermanager',
  DTSMANAGER = 'dtsmanager',
  CLIENTMANAGER = 'clientmanager',
}

export const RolesAdmins = [
  Roles.ADMIN,
  Roles.CUSTOMERMANAGER,
  Roles.DTSMANAGER,
  Roles.CLIENTMANAGER
]