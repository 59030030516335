import * as Yup from "yup";
import { PasswordValidation } from "../../../utils/Validations";

export const ChangePasswordProfileFormValidation = () => Yup.object().shape({
  currentPassword: Yup.string().required(),
  password: Yup.string()
    .required()
    .matches(PasswordValidation, "Password.Rules"),
  confirmPassword: Yup.string()
    .when('password', (password: string, schema: any) => {
      if (password) return schema.required()
    }).oneOf([Yup.ref('password'), null], "Password.Confirm_invalid")
})