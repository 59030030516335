import { Button, Segment } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { IMitekSettings } from '../../../models/flow/settings/IMitekSettings'
import { state } from '../../Context'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

type MitekCheckSubmitExplanationProps = {
  props: any,
  themeColor: string,
  frontImage: string,
  backImage?: string,
  postButton: any,
  state: state,
  settings: IMitekSettings | undefined;
}

export default function MitekCheckSubmitExplanation(props: MitekCheckSubmitExplanationProps) {

  const [currentPage, setCurrentPage] = useState(0);

  const { t } = useTranslation(props.state.org.theme.config.translationKey);

  const renderView = () => {
    switch (currentPage) {
      case 0:
        return (
          <Segment id="mitek-preview-container" className="mitek" basic style={{ display: "flex", margin: 0, paddingTop: 0, flexDirection: "column", alignContent: "center", height: "100%" }}>
            <div>
              <h1 style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>{props.backImage !== undefined ? t("Mitek.Submit.Confirm_Header_Next") : t("Mitek.Submit.Confirm_Header_Submit")}</h1>
              <h2 style={{ marginTop: 0 }}>{t("Mitek.Submit.Confirm")}</h2>
            </div>
            <Segment id="mitek-preview-segment" placeholder style={{ display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flex: 1, padding: 0 }}>
              <div style={{ paddingTop: "1em", display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flexGrow: 1, flexShrink: 1, flexBasis: "100%", maxHeight: "100%", width: "100%" }}>
                <div style={{ flex: 1, alignSelf: "center", width: "100%", backgroundSize: "contain", backgroundPositionX: "center", backgroundPositionY: "center", backgroundRepeat: "no-repeat", backgroundImage: `url(data:image/png;base64,${props.frontImage})` }}>
                  {/* <img src={`data:image/png;base64,${props.frontImage}`} style={{ maxWidth: "100%", maxHeight: "100%" }} /> */}
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "3rem" }}>
                  <h2>{t("Mitek.Front")}</h2>
                </div>
              </div>
            </Segment>
          </Segment>
        );

      case 1:
        return (
          <Segment id="mitek-preview-container" className="mitek" basic style={{ display: "flex", margin: 0, paddingTop: 0, flexDirection: "column", alignContent: "center", height: "100%" }}>
            <div>
              <h1 style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>{t("Mitek.Submit.Confirm_Header_Submit")}</h1>
              <h2 style={{ marginTop: 0 }}>{t("Mitek.Submit.Confirm")}</h2>
            </div>
            <Segment id="mitek-preview-segment" placeholder style={{ display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flex: 1, padding: 0 }}>
              <div style={{ paddingTop: "1em", display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flexGrow: 1, flexShrink: 1, flexBasis: "100%", maxHeight: "100%", width: "100%" }}>
                <div style={{ flex: 1, alignSelf: "center", width: "100%", backgroundSize: "contain", backgroundPositionX: "center", backgroundPositionY: "center", backgroundRepeat: "no-repeat", backgroundImage: `url(data:image/png;base64,${props.backImage})` }}>
                </div>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "3rem" }}>
                  <h2>{t("Mitek.Back")}</h2>
                </div>
              </div>
            </Segment>
          </Segment>
        )
    }
  }

  const getButtons = () => {
    switch (currentPage) {
      case 0:
        if (props.backImage) {
          return (
            <>
              <span>
                <Button id="retry-button" style={{ marginRight: "1em" }} basic onClick={() => window.location.reload()}>{t("General_UI.Retry")}</Button>
              </span>
              <span>
                <Button id="next-button" basic color="green" onClick={() => setCurrentPage(1)}>{t("General_UI.Next")}</Button>
              </span>
            </>
          )
        }
        return (
          <>
            <span>
              <Button id="retry-button" style={{ marginRight: "1em" }} basic onClick={() => window.location.reload()}>{t("General_UI.Retry")}</Button>
            </span>
            <span>
              {props.postButton}
            </span>
          </>
        );
      case 1:
        return (
          <>
            <span>
              <Button id="retry-button" basic style={{ marginRight: "1em" }} onClick={() => window.location.reload()}>{t("General_UI.Retry")}</Button>
            </span>
            <span>
              <Button id="back-button" basic onClick={() => setCurrentPage(0)}>{t("General_UI.Back")}</Button>
              {props.postButton}
            </span>
          </>
        );
    }
  }

  return (<>
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={`${currentPage}-trans`}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <div className="transition-container" style={{ flex: 1, backgroundColor: "white", height: "calc(100% - 4em" }} id="mitek-preview">
          {renderView()}
        </div>
      </CSSTransition>
    </SwitchTransition>

    <Segment id="stacked-buttons" className="mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
      {getButtons()}
    </Segment>
  </>
  )
}
