import MitekHeader from '../../MitekHeader'
import ReactMarkdown from 'react-markdown'
import { Button, Segment } from 'semantic-ui-react'
import { renderToStaticMarkup } from 'react-dom/server'
import { useTranslation } from 'react-i18next'

const MitekCheckCheckDesktop = (props: any) => {
  const { t } = useTranslation(props.state.org.theme.config.translationKey as string);

  const background = props.state.org.theme.config.mitekSvg ? encodeURIComponent(renderToStaticMarkup(<props.state.org.theme.config.mitekSvg fill={props.state.org.theme.themeColor} />)) : null;

  return (
    <>
      <MitekHeader />
      <Segment id="explanation-screen" className="mitek" basic style={{ backgroundColor: "white", height: "90%", width: "100%", margin: 0 }}>
        <h1 className="service-item-header">{t("Mitek.Check_Desktop.Great")}</h1>
        <h2 className="service-item-subheader">
          <ReactMarkdown skipHtml>{t("Mitek.Check_Desktop.Header")!}</ReactMarkdown>
        </h2>
        <p>{t("Mitek.Check_Desktop.SubHeader")}</p>
        <div className='explanation-background' style={{ background: props.state.view !== "success" ? `url("data:image/svg+xml,${background}")` : "", backgroundSize: "contain", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }} />
      </Segment>
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }} >
        <Button className="full-width" color="green" floated="right" disabled>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>

  )
}

export default MitekCheckCheckDesktop