import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { Card, Container, Divider } from "semantic-ui-react";
import { GetStatisticsReportFormValues, IStatisticReportResponse } from "../../../models/statistics/IStatisticsReport";
import { RootStoreContext } from "../../../stores/RootStore";
import { Spinner } from "../../Common/spinner/Spinner";
import { StatisticResponseItem } from "./StatisticResponseItem";
import { StatisticsForm } from "./StatisticsForm";
import { StatisticsTotal } from "./StatisticsTotal";

export const Statistics = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const [statisticReportResponse, setStatisticReportResponse] = useState<IStatisticReportResponse>();
  const { getStatisticsReport, loading } = rootStore.statisticsStore;

  const handleSubmit = async (values: GetStatisticsReportFormValues) => {
    var response = await getStatisticsReport(values);
    setStatisticReportResponse(response);
  }

  return (
    <Container>
      <StatisticsForm handleSubmit={handleSubmit} loading={loading} />
      <Divider />
      {loading
        ? <Spinner />
        : statisticReportResponse === undefined || statisticReportResponse.statisticReports.length === 0 || statisticReportResponse.statisticTotals.length === 0
          ? <h2>No data</h2>
          : (
            <>
              {/* Total per service*/}
              <h3>Total number of calls to the different services from the selection above (checks in alphabetical order)</h3>
              <StatisticsTotal statisticTotals={statisticReportResponse.statisticTotals} />

              <h3>Total number of unique sessions per flow and total number of calls to the different services per flow (checks in flow-order)</h3>
              {/* Divided by customer */}
              <Card.Group itemsPerRow={1}>
                {statisticReportResponse.statisticReports.map((statisticReport, index) => {
                  return <StatisticResponseItem stats={statisticReport} key={index} />
                })}
              </Card.Group>
            </>
          )}
    </Container>
  )
})