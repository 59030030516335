import { Segment } from 'semantic-ui-react';
import { deleteCookie } from '../../../utils/ReadWriteCookie';
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';

function MitekHandoff(props: MitekHandoffProps) {
  const [key, setKey] = useState<any>("");
  const history = useHistory();

  useEffect(() => {
    localStorage.clear();
    deleteCookie("ctxId");
    deleteCookie("invitationCode");
    deleteCookie("X-Api-Key");

    const url = new URLSearchParams(window.location.search);
    const lang = url.get("lang");
    const ctxId = url.get("ctxId")
    const service = url.get("service");

    document.cookie = `ctxId=${ctxId};path=/;SameSite=Lax;`
    localStorage.setItem("handoff", "true")

    history.push(`/${ctxId}/${lang}/true${service !== null ? `/${service}` : ""}`);
  }, [])


  return (
    <Segment.Group className="containersegment" style={{ height: "100%", display: "flex" }}>
      <Segment loading style={{ flex: 1 }} />
    </Segment.Group>
  )
}

type MitekHandoffProps = {
  match: any
}

export default MitekHandoff

