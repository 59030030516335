export enum EService {
  NULL = 0,
  IDINCHECK = 1,
  IBANCHECK = 2,
  AGECHECK = 3,
  PASPOORTCHECK = 4,
  PEPCHECK = 5,
  NATEGISCHECK = 6,
  CURATELECHECK = 7,
  MITEKCHECK = 8,
  MNICHECK = 10,
  ADDRESSCHECK = 11,
  UTILITYBILLCHECK = 14,
  MBUCHECK = 15,
  BIGCHECK = 16,
  PERSONALINFORMATIONCHECK = 17, // Only on backend (as of 17-07-2022)
  HPGIBANCHECK = 18,
  EMAILVERIFICATIONCHECK = 19,
  OCRLABSCHECK = 20,

  START = 100,
  SUCCESS = 200
}

export interface IServiceOptionEnums {
  id: EService;
  name: string;
  icon: string;
}

const serviceOptionItem = (id: EService, name: string, icon: string): IServiceOptionEnums => ({ id, name, icon });

export const ServiceOptionEnums = (): IServiceOptionEnums[] => {
  let list: IServiceOptionEnums[] = [];

  list.push(serviceOptionItem(EService.IDINCHECK, EService[EService.IDINCHECK], "address card outline"));
  list.push(serviceOptionItem(EService.IBANCHECK, EService[EService.IBANCHECK], "credit card outline"));
  list.push(serviceOptionItem(EService.AGECHECK, EService[EService.AGECHECK], "user"));
  list.push(serviceOptionItem(EService.PASPOORTCHECK, EService[EService.PASPOORTCHECK], "user"));
  list.push(serviceOptionItem(EService.PEPCHECK, EService[EService.PEPCHECK], "user"));
  list.push(serviceOptionItem(EService.NATEGISCHECK, EService[EService.NATEGISCHECK], "clipboard list"));
  list.push(serviceOptionItem(EService.CURATELECHECK, EService[EService.CURATELECHECK], "user times"));
  list.push(serviceOptionItem(EService.MITEKCHECK, EService[EService.MITEKCHECK], "user times"));
  list.push(serviceOptionItem(EService.MNICHECK, EService[EService.MNICHECK], "user"));
  list.push(serviceOptionItem(EService.ADDRESSCHECK, EService[EService.ADDRESSCHECK], "address book outline"));
  list.push(serviceOptionItem(EService.UTILITYBILLCHECK, EService[EService.UTILITYBILLCHECK], "credit card outline"));
  list.push(serviceOptionItem(EService.MBUCHECK, EService[EService.MBUCHECK], "user"));
  list.push(serviceOptionItem(EService.BIGCHECK, EService[EService.BIGCHECK], "address card outline"));
  list.push(serviceOptionItem(EService.HPGIBANCHECK, EService[EService.HPGIBANCHECK], "credit card outline"));
  list.push(serviceOptionItem(EService.EMAILVERIFICATIONCHECK, EService[EService.EMAILVERIFICATIONCHECK], "envelope outline"));
  list.push(serviceOptionItem(EService.OCRLABSCHECK, EService[EService.OCRLABSCHECK], "user times"));

  return list;
}

export interface IServiceEnums {
  value: EService;
  name: string;
}

const serviceItem = (value: EService, name: string): IServiceEnums => ({ value, name });

export const ServicEnums = (): IServiceEnums[] => {
  let list: IServiceEnums[] = [];

  list.push(serviceItem(EService.IDINCHECK, EService[EService.IDINCHECK]));
  list.push(serviceItem(EService.IBANCHECK, EService[EService.IBANCHECK]));
  list.push(serviceItem(EService.AGECHECK, EService[EService.AGECHECK]));
  list.push(serviceItem(EService.PASPOORTCHECK, EService[EService.PASPOORTCHECK]));
  list.push(serviceItem(EService.PEPCHECK, EService[EService.PEPCHECK]));
  list.push(serviceItem(EService.NATEGISCHECK, EService[EService.NATEGISCHECK]));
  list.push(serviceItem(EService.CURATELECHECK, EService[EService.CURATELECHECK]));
  list.push(serviceItem(EService.MITEKCHECK, EService[EService.MITEKCHECK]));
  list.push(serviceItem(EService.ADDRESSCHECK, EService[EService.ADDRESSCHECK]));
  list.push(serviceItem(EService.UTILITYBILLCHECK, EService[EService.UTILITYBILLCHECK]));
  list.push(serviceItem(EService.BIGCHECK, EService[EService.BIGCHECK]));
  list.push(serviceItem(EService.HPGIBANCHECK, EService[EService.HPGIBANCHECK]));
  list.push(serviceItem(EService.EMAILVERIFICATIONCHECK, EService[EService.EMAILVERIFICATIONCHECK]));
  list.push(serviceItem(EService.OCRLABSCHECK, EService[EService.OCRLABSCHECK]));

  return list;
}

export const SerivceCombiEnums = (): IServiceEnums[] => {
  let list: IServiceEnums[] = [];

  list.push(serviceItem(EService.MNICHECK, EService[EService.MNICHECK]));
  list.push(serviceItem(EService.MBUCHECK, EService[EService.MBUCHECK]));

  return list;
}

export const ServiceBackofficeEnums = (): IServiceEnums[] => {
  let list: IServiceEnums[] = [];

  list.push(serviceItem(EService.PERSONALINFORMATIONCHECK, EService[EService.PERSONALINFORMATIONCHECK]));
  list.push(serviceItem(EService.HPGIBANCHECK, EService[EService.HPGIBANCHECK]));

  return list;
}