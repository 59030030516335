import { Button, Form, Header, Segment } from "semantic-ui-react";
import { CustomerFormValidationSchema } from "./CustomerForm.Validation";
import { CustomerFormValues } from "../../../models/customer/ICustomer";
import { Field, Formik } from "formik";
import { GetCustomerDto } from "../../../Types";
import { RootStoreContext } from "../../../stores/RootStore";
import { TextInput } from "../../Common/form/TextInput";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  id?: string;
  customer?: GetCustomerDto;
}

export const CustomerForm: React.FC<IProps> = observer(({ id, customer }) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { createCustomer, editCustomer, submitting } = rootStore.customerClientStore;

  const [formValues, setFormValues] = useState(new CustomerFormValues())

  useEffect(() => {
    if (customer) {
      setFormValues(customer);
    }
  }, [customer])

  const handleSubmit = (values: CustomerFormValues) => {
    if (id !== undefined) {
      editCustomer(values);
    } else {
      createCustomer(values);
    }
  }

  return (
    <>
      <Header as="h4" attached="top">Informatie</Header>
      <Segment attached>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={CustomerFormValidationSchema}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Field
                component={TextInput}
                name="name"
                label="Naam"
                required
              />
              <Field
                component={TextInput}
                name="email"
                label="Email"
                required
              />
              <Field
                component={TextInput}
                name="phone"
                label="Telefoon"
              />
              <Field
                component={TextInput}
                name="website"
                label="Website"
                required
              />
              <Button type="submit" primary disabled={submitting}>{t("Button.Save")}</Button>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  )
})