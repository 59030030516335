import { Button, Dropdown, DropdownItemProps, Form, FormFieldProps, Label, Popup } from "semantic-ui-react";
import { EMakeAChoice } from "../../../Enums/EMakeAChoice";
import { FieldProps, getIn, useField } from "formik";
import { Spinner } from "../spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

export interface IDropDownProps extends FieldProps<string, HTMLElement>, FormFieldProps { }
export const DropDown = observer((props: IDropDownProps) => {
  const {
    disabled,
    field,
    form: { touched, errors },
    id,
    label,
    loading,
    options,
    placeholder,
    required,
    search,
    showMakeAChoice = true,
    tooltip,
    value,
  } = props;

  const [, , helpers] = useField(field.name);
  const { t } = useTranslation();

  let newOptions: DropdownItemProps[] = []
  if (showMakeAChoice) {
    // Add 'Make a choice' as the first option
    newOptions = [{ key: EMakeAChoice.Value, value: EMakeAChoice.Value, text: t(EMakeAChoice.Text) }, ...options]
  } else {
    newOptions = [...options]
  }

  if (newOptions.length === 0) {
    return <Spinner />;
  }

  const currentValue =
    value === null ||
      value === "" ||
      value === " " ||
      value === true ||
      value === false
      ? newOptions[0].value
      : value; // Use the provided value, else use the first option

  return (
    <Form.Field>
      {label &&
        <label>
          {label}
          {required && <> *</>}
          {tooltip && (
            <Popup
              content={tooltip}
              trigger={<Button type="button" className="tooltip" icon="info circle" />}
            />
          )}
        </label>
      }

      <Dropdown
        disabled={disabled}
        name={field.name}
        id={id}
        search={search}
        selection
        fluid
        loading={loading}
        options={newOptions}
        placeholder={placeholder}
        value={currentValue} // Wanneer de dropdown niet bijgewerkt wordt, controleer dan of je 'value={(formik/props).values.xxx}' meegeeft
        onChange={(e, v) => helpers.setValue(v.value)}
      />
      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label
          size="large"
          color="red"
          icon="times circle"
          content={t(getIn(errors, field.name))}
        />
      )}
    </Form.Field>
  );
});
