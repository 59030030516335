import { useContext } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Header, Segment } from "semantic-ui-react"
import { StateValue } from "../../../Context";

export interface IOCRInformationProps {
  /**
   * Background to display
   */
  background?: string
  /** Header translation key, displayed at the top of the page, defaults to OCR.Information.Header */
  headerMessageKey?: string
  /** Sub header translation key, displayed at the top of the page, defaults to OCR.Information.SubHeader */
  subHeaderMessageKey?: string
  onCallback?: () => void
}

export const OCRInformation = (props: IOCRInformationProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  return (
    <div className="ocr space-between ocr-padding">
      <span>
        <Header as="h1" className="service-item-header">
          {t(props.headerMessageKey ?? "OCR.Information.Header")}
          <Header.Subheader>
            {t(props.subHeaderMessageKey ?? "OCR.Information.SubHeader")}
          </Header.Subheader>
        </Header>
      </span>
      <Segment basic className="service-item-button-container mt-0">
        <Button
          color="green"
          floated="right"
          type="submit"
          disabled={props.onCallback === undefined}
          onClick={() => props.onCallback?.()}
        >
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </div>
  )
}
