export const readCookie = (name: string) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0)
      return c.substring(nameEQ.length, c.length);
  }
  return undefined;
};


export const setCookie = (cname: string, cvalue: string, exdays: number) => {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var now = new Date();
  now.setTime(now.getTime() + 1 * 3600 * 1000);
  document.cookie = cname + "=" + cvalue + "; expires=" + now.toUTCString() + "; path=/; SameSite=Lax";
}

export const deleteCookie = function (name: string) {
  document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT; Path=/';
};