import * as Yup from "yup";
import { EMakeAChoice } from "../../../Enums/EMakeAChoice";

export const ValidationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    target: Yup.string(),
    resultCallbackBaseURLDev: Yup.string().required(),
    resultCallbackBaseURLAcc: Yup.string().required(),
    resultCallbackBaseURLProd: Yup.string().required(),
    resultCallbackEndpoint: Yup.string().required(),
    customerId: Yup.number().required().notOneOf([EMakeAChoice.Value], "Enum.Make_A_Choice")
  })