import { IRoute } from '../models/routes/IRoute';
import { NotFound } from '../pages/errorPages/NotFound';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import { memo } from 'react';

interface IProps {
  routes: IRoute[];
  routeMatch: string;
}

const MapAllowedRoutes = (props: IProps) => {
  const match = useRouteMatch(props.routeMatch);

  return (
    <Switch>
      {props.routes.map((route: any) => {
        const { component: Component, childs, dropdown, path, ...rest } = route;
        return (
          // If the current route is of type dropdown, don't use it as a route.
          !dropdown
            ? (
              <Route key={path} path={`${match?.path}${path}`} {...rest}>
                <Component children={childs} />
              </Route>
            )
            : (
              // Route its childs instead
              childs.map((childRoute: any) => {
                const { path, component: Component, childs } = childRoute;
                return (
                  <Route key={path} path={`${match?.path}${path}`} {...rest}>
                    <Component children={childs} />
                  </Route>
                );
              })
            )
        );
      })}
      <Route component={NotFound} />
    </Switch>
  )
}

export default memo(MapAllowedRoutes);