import { Container, Divider, Header } from "semantic-ui-react"
import { ProfileForm } from "../../../../Components/secure/account/ProfileForm"

export const Profile = () => {
  return (
    <Container>
      <Header as="h1">Profile</Header>
      <Divider />
      <ProfileForm />
    </Container>
  )
}