import { Header, Image, Segment } from 'semantic-ui-react'
import { StateValue } from '../Components/Context'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import './CDDN/CDDN.scss'
import './CDDN/CDDN_iframe.scss'
import './DesktopLayout.scss'
import './Helper.css'
import './HPG/HPG.scss'
import './Jacks/Jacks.scss'
import './ComeOn/ComeOn.scss'
import './NovaMedia/NovaMedia.scss'
import './Tipico/Tipico.scss'
import './Wildmania/Wildmania.scss'
import './Sonnenspiele/Sonnenspiele.scss'
import './Sonio/Sonio.scss'
import './Atlantis/Atlantis.scss'
import './Fairplay/Fairplay.scss'
import './Betway/Betway.scss'
import './Lucky7/Lucky7.scss'
import './Hommerson/Hommerson.scss'


interface IProps {
  children: any,
  sidebar: any,
  title?: string,
  smallScreen?: boolean,
  mediumScreen?: boolean,
  useLayout?: boolean,
  clientClass?: string,
}

const DesktopLayout = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  if (!props.useLayout) {
    return (props.children)
  } else {
    return (
      <div className={state.org.theme.config.className}>
        {state.org.theme.config.logoContainer && !props.smallScreen && (
          <div className="logo-container">
            <Image size="medium" src={state.org.theme.logo} />
          </div>
        )}
        <div className="main">
          <div style={props.smallScreen ? { display: "none" } : {}} className={"sidebar-container"}>
            {state.org.theme.config.headerContainer && (
              <div className='header-container'>
                <Image size="medium" src={state.org.theme.logo} style={{ height: "2.5em", width: "auto" }} />
              </div>
            )}
            {props.sidebar}
          </div>
          <div style={{ flex: props.smallScreen ? 1 : 0.7 }} className="ui fluid container segments content-container mt-0 mb-0 ml-0 mr-0">
            <Segment className={`content-header-container${props.clientClass ? "-" + props.clientClass : ""}`}>
              <Header as="h3">
                <Image size="medium" src={state.org.theme.logo} style={{ height: "2.5em", width: "auto" }} />
                <span className="content-header-title">{props.title && t(props.title)}</span>
              </Header>
            </Segment>

            <Segment className="service-item-container">
              {props.children}
            </Segment>
          </div>
        </div>
      </div>
    )
  }
}

export default DesktopLayout
