import { Loader } from "semantic-ui-react";
import { SemanticSIZES } from "semantic-ui-react/dist/commonjs/generic";
import { useTranslation } from "react-i18next";

interface ISpinnerProps {
  text?: string;
  size?: SemanticSIZES;
}

export const Spinner = (props: ISpinnerProps) => {
  const { t } = useTranslation();

  return (
    <div style={{ paddingTop: "10rem", position: "relative" }}>
      <Loader active size={props.size}>
        {props.text ? props.text : t("Loading")}
      </Loader>
    </div>
  );
};
