import MitekCheckSubmitExplanation from '../MitekCheckSubmitExplanation'
import MitekHeader from '../MitekHeader'
import { Button } from 'semantic-ui-react'
import { IMitekSettings } from '../../../../models/flow/settings/IMitekSettings'
import { MitekRequestBuilder } from '../../../../Types'
import { state } from '../../../Context'
import { useTranslation } from 'react-i18next'

type IProps = {
  postBackend: () => void;
  setCurrentPage: (page: number) => void;
  props: any;
  request: MitekRequestBuilder | any;
  state: state;
  type: "PASSPORT" | "ID" | "DRIVERSLICENSE" | "RESIDENCEPERMIT" | undefined;
  settings: IMitekSettings | undefined;
}

const MitekSubmitComponent = (props: IProps) => {
  const { t } = useTranslation(props.state.org.theme.config.translationKey);
  console.log(props.request);

  return (
    <>
      <MitekHeader />
      <MitekCheckSubmitExplanation settings={props.settings} postButton={<Button className="full-width" color="green" floated="right" onClick={() => {
        if (props.props.match.params.handoff !== undefined) {
          props.postBackend();
          props.setCurrentPage(7)
        } else {
          props.postBackend();
          props.setCurrentPage(6)
        }
      }}>
        <>&nbsp;</>{`${t("Mitek.Submit.Submit")}`}<>&nbsp;</>
      </Button>} frontImage={props?.request?.front?.substring(props.request.front.indexOf(",") + 1)} backImage={(props.type !== "PASSPORT" || props.settings?.enablePassportSignature) ? props.request?.back?.substring(props.request.back?.indexOf(",") + 1) : undefined} state={props.state} props={props.props} themeColor={props.state.org.theme.themeColor} />
    </>
  )
}

export default MitekSubmitComponent