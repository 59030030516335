import { RootStoreContext } from "../../../stores/RootStore"
import { useContext } from "react"

export const RenderGuard = (props: any) => {
  const rootStore = useContext(RootStoreContext);

  if (!rootStore.authorizationStore.isAuthenticated()) {
    return null;
  }

  return rootStore.authorizationStore.isInRoles(props.roles.split(","))
    ? props.children
    : null
}