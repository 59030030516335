import { EToastType } from "../Enums/EToastType";
import { RootStore } from "../stores/RootStore";
import { baseFetch, baseSubmit } from "../utils/Fetch";
import { removeSurroundingQuotes } from "../utils/General";

export const frontendReferrer = "TTS_Frontend";

export class BaseApiService {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  showApiError(data: any) {
    const errorCode = this.getErrorCode(data);
    this.rootStore.notificationStore.showNotification(EToastType.Error, errorCode || "API.ERROR");
  }

  showApiAccessDenied(data?: any) {
    const errorCode = this.getErrorCode(data);
    this.rootStore.notificationStore.showNotification(EToastType.Error, errorCode || "API.ACCESS_DENIED");
  }

  failed() {
    return { success: false, data: undefined };
  }

  isSuccessResponse(response: any) {
    return response.status >= 200 && response.status < 300;
  }

  async put(url: string, data: any, silentError?: boolean, jsonBody = true) {
    const response = await baseSubmit("PUT", url, this.rootStore.authorizationStore.token, data, jsonBody);
    if (response) {
      const { success } = await this.processResponse(response, silentError);
      return { success };
    }
    this.showApiAccessDenied();
    return this.failed();
  }

  async post(url: string, data: any, silentError?: boolean, jsonBody = true) {
    const response = await baseSubmit("POST", url, this.rootStore.authorizationStore.token, data, jsonBody);
    if (response) {
      const { success, data } = await this.processResponse(response, silentError);
      return { success, data };
    }
    this.showApiAccessDenied();
    return this.failed();
  }

  async get(url: string, silentError?: boolean) {
    const response = await baseFetch("GET", url, this.rootStore.authorizationStore.token);
    if (response) {
      const { success, data } = await this.processResponse(response, silentError);
      return { success, data };
    }
    this.showApiAccessDenied();
    return this.failed();
  }

  async delete(url: string, silentError?: boolean) {
    const response = await baseFetch("DELETE", url, this.rootStore.authorizationStore.token);
    if (response) {
      const { success } = await this.processResponse(response, silentError);
      return success;
    }
    this.showApiAccessDenied();
    return false;
  }

  getErrorCode(data: any) {
    return data && data.Errors && data.Errors[0] && data.Errors[0].ErrorCode
      ? "API.Error_" + data.Errors[0].ErrorCode
      : undefined;
  }

  setToken(response: any) {
    if (response) {
      const newToken = response.headers.get("set-authentication");
      if (newToken)
        this.rootStore.authorizationStore.token = newToken;
    }
  }

  async processResponse(response: any, silentError?: boolean) {
    this.setToken(response);
    const success = this.isSuccessResponse(response);
    const data = await this.getJsonOrText(response);
    if (!success && !silentError) this.showApiError(data);
    return { success, data };
  }

  async getJsonOrText(response: any) {
    const text = await response.text();
    try {
      return JSON.parse(text);
    } catch {
      return removeSurroundingQuotes(text)
    }
  }
}