export interface IProfile {
  lastActivity: Date;
  email: string;
  firstName: string;
  lastName: string;
  lastNamePrefix: string;
}

export class ProfileFormValues {
  firstName: string = "";
  lastName: string = "";
  lastNamePrefix: string = "";

  constructor(init?: IProfile) {
    Object.assign(this, init);
  }
}