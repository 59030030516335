import { Button, Header, Image, List, Segment } from "semantic-ui-react";
import { IClientByCustomer } from "../../../models/client/IClient";
import { NavLink } from "react-router-dom";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { routePrefixDashboard } from "../../../ApplicationRouter";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  id?: string
  clients: IClientByCustomer[]
  setClients: (clients: IClientByCustomer[]) => void;
}

export const CustomerOrganizations: React.FC<IProps> = observer(({ id, clients, setClients }) => {
  const rootStore = useContext(RootStoreContext);
  const { toggleClientForCustomer, submitting } = rootStore.customerClientStore;
  const { t } = useTranslation();

  const toggleIsActive = async (id: number, index: number) => {
    var success = await toggleClientForCustomer(id)
    if (success) {
      const old = [...clients];
      old[index].isActive = !old[index].isActive;
      setClients(old);
    }
  }

  return (
    <>
      <Segment attached>
        <List.Header>
          <Header as="h2">Aangesloten organisaties</Header>
        </List.Header>
        <List divided verticalAlign="middle" relaxed>
          {clients.map((x, index) => (
            <List.Item key={index} style={!x.isActive ? { backgroundColor: "#EFEFEF" } : {}}>
              <List.Content floated="right">
                <Button basic disabled={submitting} onClick={() => toggleIsActive(x.customerClientId!, index)}>
                  {x.isActive ? "Actief" : "Inactief"}
                </Button>
                <Button basic as={NavLink} to={`${routePrefixDashboard}/organization/${x.customerClientId}`} disabled={!x.isActive} >{t("Button.Edit")}</Button>
              </List.Content>
              <Image avatar style={{ backgroundColor: x.themeColor ?? "grey" }} />
              <List.Content verticalAlign="middle">{x.name}</List.Content>
            </List.Item>
          ))}
        </List>
      </Segment>
    </>
  )
})