import { AdminFormValidation } from "./AdminForm.Validation";
import { AdminFormValues, IAdmin } from "../../../models/user/IAdmin";
import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { CancelButton } from "../../Common/Button/CancelButton";
import { DropDown } from "../../Common/form/Dropdown";
import { Field, Formik } from "formik";
import { ICustomerServices } from "../../../models/customer/ICustomer";
import { PasswordInput } from "../../Common/form/PasswordInput";
import { RootStoreContext } from "../../../stores/RootStore";
import { Spinner } from "../../Common/spinner/Spinner";
import { TextInput } from "../../Common/form/TextInput";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  id?: string;
}

export const AdminForm: React.FC<IProps> = observer(({ id }) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { loadingAdmins, submitting, addAdmin, getAdmin, editAdmin } = rootStore.adminStore;
  const { getAllCustomers } = rootStore.customerClientStore;
  const [formValues, setFormValues] = useState(new AdminFormValues());
  const [lastActivity, setLastActivity] = useState<Date>();
  const [customers, setCustomers] = useState<ICustomerServices[]>([]);

  useEffect(() => {
    const fetchAdmin = async (id: string) => {
      const admin: IAdmin | undefined = await getAdmin(id);
      setFormValues(new AdminFormValues(admin));
      setLastActivity(admin?.lastActivity);
    }
    if (id) {
      fetchAdmin(id);
    }
  }, [id])

  useEffect(() => {
    const getCustomers = async () => {
      const customers = await getAllCustomers();
      if (customers) {
        setCustomers(customers);
      }
    }
    getCustomers();
  }, [])

  const handleSubmit = async (values: AdminFormValues) => {
    if (id) {
      await editAdmin(values);
    } else {
      await addAdmin(values);
    }
  };

  if (loadingAdmins) return <Spinner />

  return (
    <Grid stackable>
      {id && (
        <Grid.Row>
          <Grid.Column width={3}>
            <span><strong>Last logged in</strong></span>
          </Grid.Column>
          <Grid.Column width={5}>
            <span>{lastActivity !== undefined ? t("I18n.Formatted.DateTime", { date: new Date(lastActivity) }) : "-"}</span>
          </Grid.Column>
        </Grid.Row>
      )}
      <Grid.Column width={8}>
        <Formik
          enableReinitialize
          initialValues={formValues}
          onSubmit={handleSubmit}
          validationSchema={AdminFormValidation(id === undefined)}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Segment padded className='bg-lightest-grey'>
                <Field
                  component={TextInput}
                  disabled={id !== undefined}
                  label={t("Label.Email")}
                  name="email"
                  required={!id}
                />
                <Field
                  component={TextInput}
                  label={t("Label.Name.First")}
                  name="firstName"
                  required
                />
                <Field
                  component={TextInput}
                  label={t("Label.Name.Last_Prefix")}
                  name="lastNamePrefix"
                />
                <Field
                  component={TextInput}
                  label={t("Label.Name.Last")}
                  name="lastName"
                  required
                />
                <Field
                  component={DropDown}
                  disabled={id}
                  required
                  label="Customer"
                  name="customerId"
                  value={formik.values.customerId}
                  options={customers.map((x) => {
                    return {
                      key: x.id,
                      value: x.id,
                      text: x.name
                    }
                  })}
                />
              </Segment>

              <Segment padded className='bg-lightest-grey'>
                {id && <p style={{ color: "#ca005d" }}>{t("Password.Only_Fill_When_Change")}</p>}
                <Field
                  component={PasswordInput}
                  label={t("Password.Password")}
                  name="password.password"
                  required={!id}
                />
                <Field
                  component={PasswordInput}
                  label={t("Password.Repeat")}
                  name="password.confirmPassword"
                  required={!id}
                />
              </Segment>

              <Button disabled={submitting} floated='right' loading={submitting} primary type="submit">
                {t("Button.Save")}
              </Button>
              <CancelButton floated='left' />
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
  )
})