import { useContext, useEffect, useMemo, useState } from "react";
import { Formik, FastField, useFormik } from "formik";
import * as Yup from "yup";
import { Segment, Button, Form, Header } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { RenderInputComponent } from "./EComponentTypeToComponentMapping";
import { StateValue } from "../../../Context";
import { EValidationCategory } from "../../../../Enums/EValidationCategory";
import { OcrLabsValidationField } from "../../../../Types";
import { OCRLabsCurrentPage } from "../../../../Types/OCRLabs/OCRLabsCurrentPage";
import { postOcrLabsAppendMissingProperties, postTransactionProgressLog } from "../../../../Services/Backend";
import { EOCRLabsPageType } from "../../../../Types/OCRLabs/Enums/EOCRLabsPageType";
import { toPascalCase } from "../../../../utils/toPascalCase";
import { OCRLabsContext } from "../useOCR";
import './form-row.scss';
import { ELogType } from "../../../../Enums/ELogType";

export interface OcrLabsValidationFieldResponse {
  fields: OcrLabsValidationField[];
  detectedFields: { [key: string]: string }
  onCallback?: (data: any) => void;
}

interface FormData {
  [key: string]: string;
}

export const OCRDynamicValidatePropertiesComponent = (props: OcrLabsValidationFieldResponse) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const { fields, detectedFields } = props;
  const { setPage } = useContext(OCRLabsContext);

  // Create form data object with initial values for each field
  const [initialFormData, setInitialFormData] = useState<FormData>({} as FormData);
  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckEnteredAppendMissingProperties });
    const initialFormData = fields.reduce((formData, field) => {
      formData[field.name] = toPascalCase((detectedFields[field.name] ?? "").toLowerCase());
      return formData;
    }, {} as FormData);
    setInitialFormData(initialFormData);
  }, [fields])

  // Group fields by category and row and fix any width issues (e.g. if they add up to more than 100% or less than 100%)
  const categoryGroups = useMemo(() => {
    const groups: Record<EValidationCategory, Record<number, OcrLabsValidationField[]>> = {} as Record<EValidationCategory, Record<number, OcrLabsValidationField[]>>;
    for (const field of fields) {
      const categoryGroup = groups[field.category] || {};
      const rowGroup = categoryGroup[field.row] || [];
      rowGroup.push(field);
      categoryGroup[field.row] = rowGroup;
      groups[field.category] = categoryGroup;
    }

    // Adjust widths for each row
    for (const categoryGroup of Object.values(groups)) {
      for (const rowGroup of Object.values(categoryGroup)) {
        const totalWidth = rowGroup.reduce((total, field) => total + field.width, 0);
        if (totalWidth > 100) {
          // Reduce widths proportionally to make them add up to 100%
          const factor = 100 / totalWidth;
          rowGroup.forEach(field => field.width = parseFloat((field.width * factor).toFixed(2)));
        } else if (totalWidth < 100) {
          // Add the difference evenly to each field's width
          const diff = 100 - totalWidth;
          const factor = diff / rowGroup.length;
          rowGroup.forEach(field => field.width = parseFloat((field.width + factor).toFixed(2)));
        }
      }
    }
    return groups;
  }, [fields]);

  // Create validation schema dynamically based on required fields
  const validationSchema = useMemo(() => Yup.object().shape(
    fields.reduce((schema, field) => {
      if (field.required) {
        schema[field.name] = Yup.string().required(
          `${t(`OCR_Labs.Validate_Properties.Categories.${field.category}.Entries.${field.name}.label`)} ${t(
            'General_UI.Is_Required'
          )}`)
      } else {
        schema[field.name] = Yup.string();
      }
      return schema;
    }, {} as Record<string, Yup.StringSchema>)
  ), [fields]);


  // Form submission handler -> set Loading page
  const handleSubmit = async (values: FormData, { setErrors }: { setErrors: (errors: Record<string, string>) => void }) => {
    const response = await postOcrLabsAppendMissingProperties({ ctxId: state.ctxId, validationFields: values });
    if (response.status === 200) {
      if (response.data.errors && Object.keys(response.data.errors).length > 0) {
        // Parse the error response into Formik error format
        const formikErrors = Object.entries(response.data.errors).reduce((errors, [fieldName]) => {
          errors[fieldName] = "je hebt " + response.data.errors[fieldName as any].actualEdits + 'maar mag maar ' + response.data.errors[fieldName as any].allowedEdits; // Replace with actual error message
          return errors;
        }, {} as Record<string, string>);

        setErrors(formikErrors);
      }
      else {
        sessionStorage.setItem("ocr-document-validated", "true")
        if (props.onCallback) {
          props.onCallback(response.data); // In case of a combination check we can do whatever we need after completion.
        } else {
          setPage({
            page: EOCRLabsPageType.Success,
            renderButtonContainer: false,
            data: {
              headerTranslationKey: "OCR_Labs.Success.Header",
              subHeaderTranslationKey: "OCR_Labs.Success.SubHeader",
            }
          });
        }
      }
    } else {
      setPage({
        page: EOCRLabsPageType.Error,
        renderButtonContainer: false,
        data: {
          messageKeys: ["OCR_Labs.Error.Generic_Error"],
          headerTranslationKey: "OCR_Labs.Error.Generic_Error",
          messageHeaderTranslationKey: "OCR_Labs.Error.Generic_Error_Header"
        }
      });
    }
  };

  return (
    <Formik
      initialValues={initialFormData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) =>
        <Form
          onSubmit={formik.handleSubmit}
          style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: "flex-start", overflowY: "auto" }}
        >
          {
            Object.entries(categoryGroups).map(([category, rowGroups]) => (

              <div key={`category-${category}`} className='category-group ocr-padding'>
                <Header as="h3" style={{ marginBottom: 0 }}>{t(`OCR_Labs.Validate_Properties.Categories.${category}.Title`)}</Header>
                <Segment className="form-group" style={{ width: "100%", backgroundColor: "#e6e7e940", boxShadow: "none", border: "1px solid rgba(34,36,38,.05)", marginBottom: "1em", display: "flex", flexDirection: 'column', gap: '0.5em', paddingTop: '0.25em' }}>
                  {
                    Object.entries(rowGroups).map(([row, fields], index) => (
                      <Form.Group className="form-row" key={`row-${row}`} style={{ marginBottom: "2em", display: "flex", justifyContent: "flex-start", flexWrap: 'nowrap', gap: '0.5em', margin: "0px" }} unstackable>
                        {fields
                          .sort((a, b) => a.order - b.order)
                          .map((field, index) => {
                            return (
                              // Flex basis and max width are set to the same value to prevent the field from growing beyond its intended width, while still allowing the field to shrink if the window is resized
                              // Padding right is set to 1em for all fields except the last one in the row to create a small gap between fields
                              <div key={`field-${field.id}`} style={{ flex: `${field.width / 100}` }} className='meme123'>
                                {/* We're using FastField for its performance benefits and no values have a dependency on other values. */}
                                <FastField
                                  componentType={field.component}
                                  component={RenderInputComponent}
                                  id={field.name}
                                  label={t(`OCR_Labs.Validate_Properties.Categories.${category}.Entries.${field.name}.label`)}
                                  name={field.name}
                                  formInput={{ placeholder: t(`OCR_Labs.Validate_Properties.Categories.${category}.Entries.${field.name}.placeholder`) }}
                                  required={field.required}
                                  error={formik.errors[field.name]}
                                />
                              </div>
                            )
                          }
                          )}
                      </Form.Group>
                    ))
                  }
                </Segment>
              </div>
            ))}
          <Segment basic className="service-item-button-container mt-0">
            <Button type="submit" disabled={formik.isSubmitting} floated='right' color='green'>
              {t("OCR_Labs.Validate_Properties.Submit")}
            </Button>
          </Segment>
        </Form>

      }
    </Formik>
  );
};

export default OCRDynamicValidatePropertiesComponent;