import axios from 'axios'
import MitekCheckSuccessComponent from '../Mitek/Views/Information/MitekCheckSuccessComponent'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { Button, Loader, Segment } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { EService } from '../../../Enums/EService'
import { IdinCheckComponent } from '../Idin/IdinCheckComponent'
import { isDesktop } from 'react-device-detect'
import { MitekCheckComponent } from '../Mitek/MitekCheckComponent'
import { MNIAppendMissingProperties } from './MNIAppendMissingPropertiesComponent'
import { StateDispatch, StateValue } from '../../Context'
import { TimerButton } from '../../Common/Button/TimerButton'
import { useContext, useEffect, useState } from 'react'
import { useIsInitialRender } from '../../../Hooks/useIsInitialRender'
import { useTranslation } from 'react-i18next'
import { formatViewString } from '../../../Hooks/useView'

export type MNIView = "MITEK" | "IDIN" | "SUCCESS" | "SUCCESS-HANDOFF" | "LOADING-DATA" | "ERROR" | "LOADING"
export type MNIStatus = {
  lastFinished: EService,
  idinNeeded?: boolean
}

const MNICheckComponent = (props: any) => {
  const state = useContext(StateValue);
  const dispatch = useContext(StateDispatch);
  // We cannot use the useView hook here as it will interfere with the data gathering and analysis of Fair Play.
  const [view, setView] = useState<MNIView>("LOADING");
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const { handoff } = props.props.match.params;

  const isInitialRender = useIsInitialRender();

  const mitekFinishedCallback = () => {
    getStatus();
  }

  useEffect(() => {
    if (!isInitialRender) {
      dispatch({ type: "setCurrent", data: { ...state.current, identityService: { ...state.current.identityService, useLayout: isDesktop || (view !== "MITEK" && !handoff) } } })
    }
  }, [view])

  // Get status on mount only
  useEffect(() => {
    if (isInitialRender) {
      getStatus();
    }
  }, [])

  const getStatus = async () => {
    const res = await axios.get<MNIStatus>(`/api/MNICheck/status?ctxId=${state.ctxId}`);
    switch (res.data.lastFinished) {
      case EService.MITEKCHECK:
        setView("LOADING-DATA");
        break;
      // In reality this is where transactions on this flow will end.
      case EService.NATEGISCHECK:
        if (res.data.idinNeeded === true) {
          if (handoff === undefined) {
            dispatch({ type: "setCurrent", data: { ...state.current, identityService: { ...state.current.identityService!, combinationCheckCompleted: 1, combinationCheckTotal: 2 } } });
            setView("IDIN");
          } else {
            setView("SUCCESS-HANDOFF");
          }
        } else if (res.data.idinNeeded === false) {
          // As we don't use the useView hook, we have to manually post the message to the parent window.
          window.parent.postMessage({ current_step: state.current.order, current_section: formatViewString("SUCCESS") }, "*");
          setView("SUCCESS");
        }
        break;

      case EService.IDINCHECK:
      case EService.MNICHECK:
        if (handoff === undefined) {
          dispatch({ data: state.flow[state.current.order + 1], type: "setCurrent" })
          setView("SUCCESS");
        } else {
          setView("SUCCESS-HANDOFF")
        }
        break;

      default:
        setView("MITEK");
    }
  }

  const renderView = () => {
    switch (view) {
      case "LOADING":
        return <>
          <Segment id="loading" basic style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Loader inverted={isDesktop} active> {t("General_UI.Loading")} </Loader>
          </Segment>
        </>
      case "ERROR":
        return <h1>Unknown Error</h1>;
      case "MITEK":
        return <MitekCheckComponent combinedCheckProps={{ service: EService.MNICHECK, component: MNIAppendMissingProperties, callback: () => { mitekFinishedCallback() } }} {...props} />
      case "IDIN":
        return <IdinCheckComponent />
      case "SUCCESS":
        return (<>
          <AnimationSuccess header="General_UI.Great" text="General_UI.Correct" key={`${state.view}-success`} />
          <Segment basic className="service-item-button-container mt-0">
            <TimerButton countDownStart={state.org.theme.config.countDownStart ?? 5} callback={() => { dispatch({ data: state.flow[state.current.order + 1], type: "setCurrent" }) }} />
          </Segment>
        </>)
      case "SUCCESS-HANDOFF":
        return <>
          <Segment.Group className="containersegment" horizontal={false}>
            <Segment.Group className="mitek" style={{ height: "100%", width: "100%", margin: 0 }} basic horizontal={false}>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={`${0}-trans`}
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  classNames="fade"
                >
                  {/* Checks if currentpage is a page that uses the mitekSDK, sets display accordingly. */}
                  <div className="transition-container" style={{ height: "100%", flexDirection: "column", display: "flex" }}>
                    <MitekCheckSuccessComponent onSuccess={() => null} props={props.props} state={state} />
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </Segment.Group>
          </Segment.Group>
        </>
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          {renderView()}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
}
export default MNICheckComponent