import { Dispatch, SetStateAction } from 'react'
import { Header, Icon } from "semantic-ui-react";
import { IMitekSettings } from "../../../../../models/flow/settings/IMitekSettings";
import { state } from "../../../../Context";
import { useTranslation } from "react-i18next";

type IProps = {
  setType: Dispatch<SetStateAction<"PASSPORT" | "ID" | "DRIVERSLICENSE" | "RESIDENCEPERMIT" | undefined>>
  setCurrentPage: (val: number) => void
  type: "PASSPORT" | "ID" | "DRIVERSLICENSE" | "RESIDENCEPERMIT" | string;
  state: state;
  settings: IMitekSettings | undefined;
}

const MitekCheckDocumentOption = (props: IProps) => {
  const { t } = useTranslation(props.state.org.theme.config.translationKey);

  switch (props.type) {
    case "PASSPORT":
      return (
        <div className="mitek-option mitek-option--fixedWidth" onClick={() => { props.setType("PASSPORT"); props.setCurrentPage(4); }}>
          <div className="mitek-option__description">
            <Header as='h5' icon>
              <Icon name='id card outline' />
              {t("Mitek.Front")} {props.settings?.enablePassportSignature ? ` ${t("General_UI.And")} ${t("Mitek.Back")}` : ""}
            </Header>
          </div>
          <div className="mitek-option__text">{t("Mitek.Passport")}</div>
        </div>
      );

    case "ID":
      return (
        <div className="mitek-option mitek-option--fixedWidth" onClick={() => { props.setType("ID"); props.setCurrentPage(4); }}>
          <div className="mitek-option__description">
            <Header as='h5' icon>
              <Icon name='vcard' />
              {t("Mitek.Front")} {t("General_UI.And")} {t("Mitek.Back")}
            </Header>
          </div>
          <div className="mitek-option__text">{t("Mitek.Identity_Document")}</div>
        </div>
      );

    case "DRIVERSLICENSE":
      return (
        <div className="mitek-option mitek-option--fixedWidth" onClick={() => { props.setType("DRIVERSLICENSE"); props.setCurrentPage(4); }}>
          <div className="mitek-option__description">
            <Header as='h5' icon>
              <Icon name='drivers license' />
              {t("Mitek.Front")} {t("General_UI.And")} {t("Mitek.Back")}
            </Header>
          </div>
          <div className="mitek-option__text">{t("Mitek.Driving_License")}</div>
        </div>
      );

    case "RESIDENCEPERMIT":
      return (
        <div className="mitek-option mitek-option--fixedWidth" onClick={() => { props.setType("ID"); props.setCurrentPage(4); }}>
          <div className="mitek-option__description">
            <Header as='h5' icon>
              <Icon name='wpforms' />
              {t("Mitek.Front")} {t("General_UI.And")} {t("Mitek.Back")}
            </Header>
          </div>
          <div className="mitek-option__text">{t("Mitek.Residence_Permit")}</div>
        </div>
      )
    default:
      return null;
  }
}

export default MitekCheckDocumentOption
