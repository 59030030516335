import { Segment, Header, Image } from 'semantic-ui-react'
import { StateValue } from '../../Context';
import { useContext } from 'react'
import { useTranslation } from 'react-i18next';

const MitekHeader = () => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  return (<>
    {
      state.org.theme.config.mobileHeaderImageOnly ? (
        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignContent: "center" }} id="mitek-header">
          <Image src={state.org.theme.logo} style={{ height: "2.5em", width: "auto" }} />
        </div>) : (
        <Segment className="vertCenter mitek" style={{ width: "100%", height: "4em", marginBottom: 0 }}>

          <Header as="h3" className="vertCenter">
            <Image src={state.org.theme.logo} className="logoImg" fluid /> {t("Mitek.Title")}
          </Header>
        </Segment>
      )
    }
  </>
  )
}

export default MitekHeader
