import { Button, Dropdown, Form, FormFieldProps, Icon, Label, Popup } from 'semantic-ui-react';
import { FieldProps, getIn } from 'formik';

interface IProps extends FieldProps<string, HTMLInputElement>, FormFieldProps { }

export const SelectDropdown = (props: IProps) => {
  const {
    disabled,
    field,
    form: { touched, errors, values, setFieldValue },
    label,
    options,
    placeholder = '',
    readonly,
    required,
    type,
    search,
    multiple,
    clearable,
    inline = false
  } = props;

  const { value }: { value: string } = field

  const handleChange = (e: any, { value }: { value: string }) => {
    setFieldValue(field.name, value)
  }

  return (
    <Form.Field required={required}>
      <label>
        {props.label}
        {props.tooltip && (<>
          <Popup
            {...props.tooltip}
            position='top center'
            trigger={
              <Button style={{border: "none", boxShadow: "none", paddingRight: 0, paddingBottom: "0.25em"}} compact size="small" basic type="button" icon={<Icon style={{ marginBottom: "1em" }} name="info circle" fitted />} />
            }
          />
        </>)}
      </label>
      <Form.Field
        control={Dropdown}
        name={field.name}
        value={value}
        onChange={handleChange}
        fluid
        required={required}
        selection
        options={options}
        multiple={multiple}
        search={search}
        clearable={clearable}
        placeholder={placeholder}
        disabled={disabled}
        error={inline && (!!(getIn(touched, field.name) && getIn(errors, field.name)))}
      />

      {!inline && getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label pointing color="red">
          {getIn(errors, field.name)}
        </Label>
      )}
    </Form.Field>
  )
}

