import { autoCaptureHints as DefaultMitekHintsEnglish } from './Components/Services/Mitek/MitekCheckHints'
import { FunctionComponent } from 'react'
import { ReactComponent as StartDefaultSvg } from './Assets/SVG/Start.svg'
import { ReactComponent as MitekDefaultSvg } from './Assets/SVG/MitekCheck.svg'
import { ReactComponent as IbanCheckDefaultSvg } from './Assets/SVG/IbanCheck.svg'
import { ReactComponent as SuccessDefaultSvg } from './Assets/SVG/Success.svg'
import { ReactComponent as CheckDefaultSvg } from './Assets/SVG/Check.svg'
import { ReactComponent as StartNovaMediaSvg } from './Assets/SVG/NovaMedia/hand-schild-blauw.svg'
import { ReactComponent as IbanIdinNovaMediaSvg } from './Assets/SVG/NovaMedia/hand-bankpas.svg'
import { ReactComponent as CheckNovaMediaSvg } from './Assets/SVG/NovaMedia/hand-persoon-vinkje.svg'
import { ReactComponent as MitekNovaMediaSvg } from './Assets/SVG/NovaMedia/hand-persoon.svg'
import { ReactComponent as SuccessNovaMediaSvg } from './Assets/SVG/NovaMedia/hand-schild-groen.svg'
import { ReactComponent as IdinWildmaniaSvg } from './Assets/SVG/Wildmania/hand-bankpas.svg'
import { ReactComponent as SuccessWildmaniaSvg } from './Assets/SVG/Wildmania/Success.svg'
import { ReactComponent as HoldCardSonnenspiele } from './Assets/SVG/Sonnenspiele/hand-device-id-front.svg'
import { ReactComponent as SuccessSonnenspieleSvg } from './Assets/SVG/Sonnenspiele/devides-sonnenspiele.svg'
import { MockStep } from './Types/MockStep'
//default svg
//nova media svg
//Wildmania
// Sonnenspiele
export type themeConfig = {
  // Id corresponding with flowId
  id?: number[],
  // ClassName to use in your styling
  className: string,
  // ClassName to use in your styling for mobile
  classNameMobile: string,
  // Translation key to put the client specific translations under
  translationKey: string,
  // Boolean to decide if we want a seperate container containing the lgo
  logoContainer: boolean,
  // Svg's that will be used during several steps
  startSvg: FunctionComponent<React.SVGProps<SVGSVGElement>> | null
  successSvg: FunctionComponent<React.SVGProps<SVGSVGElement>> | null
  idinSvg: FunctionComponent<React.SVGProps<SVGSVGElement>> | null
  checkSvg: FunctionComponent<React.SVGProps<SVGSVGElement>> | null
  ibanCheckSvg: FunctionComponent<React.SVGProps<SVGSVGElement>> | null
  mitekSvg: FunctionComponent<React.SVGProps<SVGSVGElement>> | null
  // Mitek hints during auto and manual capture
  mitekHints: any,
  // Show only the image in the header on mobile
  mobileHeaderImageOnly?: boolean,
  // ClassName to use in mobile styling for mitek specific pages
  mitekClassNameMobile?: string,
  // Decide if the steps will be displayed verticle or horizontal
  verticleSteps: boolean,
  // Amount of steps to show before our first step, steps will show as completed
  predecessingExternalSteps?: MockStep[] | number,
  // Amount of steps to show after our last, steps well not show as completed
  successiveExternalSteps?: MockStep[] | number,
  // Seperate container for a header text, used in desktopLayout
  headerContainer?: boolean,
  // Display the stepnumbers
  ordered: boolean
  // Show Title in service-item-content container
  showTitle?: boolean,
  // Some functions will only work with a workaround on iframes
  isIframe?: boolean,
  // Skip the start screen
  skipStart?: boolean,
  countDownStart?: number,
  gtmHead?: string,
  gtmBody?: string,
  hotjar?: string,
}

const Hommerson = {
  id: [3266, 8017],
  className: "hommerson-layout",
  classNameMobile: "hommerson-layout-mobile",
  translationKey: "hommerson",
  logoContainer: false,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: null,
  idinSvg: null,
  mobileHeaderImageOnly: true,
  mitekClassNameMobile: "hommerson-mitek-mobile",
  verticleSteps: false,
  successiveExternalSteps: [{ title: "General_UI.Mock_Steps.First.Title", description: "General_UI.Mock_Steps.First.Description" }, { title: "General_UI.Mock_Steps.Second.Title", description: "General_UI.Mock_Steps.Second.Description" }],
  headerContainer: true,
  ordered: true,
  showTitle: true,
  isIframe: true,
  skipStart: true,
  countDownStart: 0,
}

const Lucky7 = {
  id: [3265],
  className: "lucky7-layout",
  classNameMobile: "lucky7-layout-mobile",
  translationKey: "lucky7",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: null,
  idinSvg: null,
  mobileHeaderImageOnly: true,
  mitekClassNameMobile: "lucky7-mitek-mobile",
  verticleSteps: false,
  successiveExternalSteps: [{ title: "General_UI.Mock_Steps.First.Title", description: "General_UI.Mock_Steps.First.Description" }, { title: "General_UI.Mock_Steps.Second.Title", description: "General_UI.Mock_Steps.Second.Description" }],
  headerContainer: true,
  ordered: true,
  showTitle: true,
  isIframe: true,
  skipStart: true,
  countDownStart: 0,
}

const Bingoal: themeConfig = {
  id: [1036],
  className: "layout",
  classNameMobile: "mobile-content-container",
  translationKey: "bingoal",
  logoContainer: false,
  startSvg: StartDefaultSvg,
  successSvg: SuccessDefaultSvg,
  checkSvg: CheckDefaultSvg,
  ibanCheckSvg: IbanCheckDefaultSvg,
  mitekSvg: MitekDefaultSvg,
  mitekHints: DefaultMitekHintsEnglish,
  idinSvg: null,
  verticleSteps: true,
  ordered: false
}

const CDDN: themeConfig = {
  id: [1029],
  className: "cddn-layout",
  classNameMobile: "cddn-layout-mobile",
  translationKey: "cddn",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: null,
  idinSvg: null,
  mobileHeaderImageOnly: true,
  mitekClassNameMobile: "cddn-mitek-mobile",
  verticleSteps: false,
  successiveExternalSteps: 2,
  headerContainer: true,
  ordered: true,
  showTitle: true,
  countDownStart: 0
}

const CDDN_IFrame: themeConfig = {
  id: [774],
  className: "cddn-layout-iframe",
  classNameMobile: "cddn-layout-mobile",
  translationKey: "cddn",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: null,
  idinSvg: null,
  mobileHeaderImageOnly: true,
  mitekClassNameMobile: "cddn-mitek-mobile",
  verticleSteps: false,
  successiveExternalSteps: 2,
  headerContainer: true,
  ordered: true,
  showTitle: true,
  isIframe: true,
  skipStart: true,
  countDownStart: 0,
}

const NovaMedia: themeConfig = {
  id: [1034],
  className: "nova-media-layout",
  classNameMobile: "nova-media-layout-mobile",
  translationKey: "nova_media",
  logoContainer: true,
  startSvg: StartNovaMediaSvg,
  ibanCheckSvg: IbanIdinNovaMediaSvg,
  checkSvg: CheckNovaMediaSvg,
  mitekSvg: MitekNovaMediaSvg,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: SuccessNovaMediaSvg,
  idinSvg: IbanIdinNovaMediaSvg,
  mobileHeaderImageOnly: true,
  mitekClassNameMobile: "nova-media-mitek-mobile",
  verticleSteps: true,
  ordered: false,
  showTitle: true
}

const Fairplay: themeConfig = {
  id: [585],
  className: "fairplay-layout",
  classNameMobile: "fairplay-layout-mobile",
  translationKey: "fairplay",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: null,
  idinSvg: null,
  mobileHeaderImageOnly: true,
  mitekClassNameMobile: "fairplay-mitek-mobile",
  verticleSteps: false,
  successiveExternalSteps: 2,
  headerContainer: true,
  ordered: true,
  showTitle: true,
  isIframe: true,
  skipStart: true,
  countDownStart: 0,
  gtmHead: `!function(){"use strict";function e(e){return function(e){for(var t=0,r=document.cookie.split(";");t<r.length;t++){var n=r[t].split("=");if(n[0].trim()===e)return n[1]}}(e)}function t(e){return localStorage.getItem(e)}function r(e){return window[e]}function n(e,t){var r=document.querySelector(e);return t?null==r?void 0:r.getAttribute(t):null==r?void 0:r.textContent}!function(o,i,a,c,u,l,s,A,E,d,v){var I,f,g,_;try{f=E&&(g=navigator.userAgent,(_=new RegExp("Version/([0-9._]+)(.*Mobile)?.*Safari.*").exec(g))&&parseFloat(_[1])>=16.4)?function(o,i,a){void 0===i&&(i="");var c={cookie:e,localStorage:t,jsVariable:r,cssSelector:n},u=Array.isArray(i)?i:[i];if(o&&c[o])for(var l=c[o],s=0,A=u;s<A.length;s++){var E=A[s],d=a?l(E,a):l(E);if(d)return d}else console.warn("invalid uid source",o)}(E,"fp_cookie",""):void 0}catch(e){console.error(e)}var T=o;T[c]=T[c]||[],T[c].push({"gtm.start":(new Date).getTime(),event:"gtm.js"});var m=i.getElementsByTagName(a)[0],R="dataLayer"===c?"":"&l="+c,D=f?"&bi="+encodeURIComponent(f):"",p=i.createElement(a),w=f?"kp"+A:A,N=!f&&s?s:https://st.fairplaycasino.nl;p.async=!0,p.src=N+"/"+w+".js?id=GTM-K292ZJ9Z"+R+D,null===(I=m.parentNode)||void 0===I||I.insertBefore(p,m)}(window,document,"script","dataLayer",0,0,"","yxzrdscr","cookie")}();`,
  gtmBody: `<iframe src=https://st.fairplaycasino.nl/ns.html?id=GTM-K292ZJ9Z height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
  hotjar: ` (function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:2614807,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`
}

const HPG: themeConfig = {
  id: [1035, 1041, 1039],
  className: "hpg-layout",
  classNameMobile: "hpg-layout-mobile",
  translationKey: "hpg",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: null,
  idinSvg: null,
  mobileHeaderImageOnly: true,
  mitekClassNameMobile: "hpg-mitek-mobile",
  verticleSteps: false,
  successiveExternalSteps: 2,
  headerContainer: true,
  ordered: true,
  showTitle: true,
  isIframe: true,
  skipStart: true,
  countDownStart: 0,
}

const Jacks: themeConfig = {
  id: [1042, 1049],
  className: "jacks-layout",
  classNameMobile: "jacks-layout-mobile",
  translationKey: "jacks",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: null,
  idinSvg: null,
  mobileHeaderImageOnly: true,
  mitekClassNameMobile: "jacks-mitek-mobile",
  verticleSteps: false,
  successiveExternalSteps: 2,
  headerContainer: true,
  ordered: true,
  showTitle: true,
  isIframe: true,
  skipStart: true
}

const ComeOn: themeConfig = {
  id: [1054, 1112],
  className: "comeon-layout",
  classNameMobile: "comeon-layout-mobile",
  translationKey: "comeon",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: null,
  idinSvg: null,
  mobileHeaderImageOnly: true,
  mitekClassNameMobile: "comeon-mitek-mobile",
  verticleSteps: false,
  successiveExternalSteps: 2,
  headerContainer: true,
  ordered: true,
  showTitle: true,
  isIframe: true,
  skipStart: true
}

const Sonnenspiele: themeConfig = {
  id: [1234, 1055],
  className: "sonnenspiele-layout",
  classNameMobile: "sonnenspiele-layout-mobile",
  mitekClassNameMobile: "sonnenspiele-mitek-mobile",
  translationKey: "sonnenspiele",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: SuccessSonnenspieleSvg,
  mitekSvg: HoldCardSonnenspiele,
  idinSvg: null,
  verticleSteps: true,
  ordered: false,
  showTitle: false,
  headerContainer: false,
}

const Betway: themeConfig = {
  id: [666],
  className: "betway-layout",
  classNameMobile: "betway-layout-mobile",
  mitekClassNameMobile: "betway-mitek-mobile",
  translationKey: "betway",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: SuccessDefaultSvg,
  mitekSvg: HoldCardSonnenspiele,
  idinSvg: null,
  verticleSteps: true,
  ordered: false,
  showTitle: false,
  headerContainer: false,
}

const Wildmania: themeConfig = {
  id: [1044],
  className: "wildmania-layout",
  classNameMobile: "wildmania-layout-mobile",
  mitekClassNameMobile: "wildmania-mitek-mobile",
  translationKey: "wildmania",
  logoContainer: true,
  startSvg: null,
  ibanCheckSvg: null,
  checkSvg: null,
  mitekHints: DefaultMitekHintsEnglish,
  successSvg: SuccessWildmaniaSvg,
  mitekSvg: IdinWildmaniaSvg,
  idinSvg: null,
  verticleSteps: true,
  ordered: false,
  showTitle: false,
  headerContainer: false,
}

const Tipico: themeConfig = {
  id: [1050, 1045],
  className: "tipico-layout",
  classNameMobile: "mobile-content-container",
  translationKey: "tipico",
  logoContainer: true,
  startSvg: StartDefaultSvg,
  successSvg: SuccessDefaultSvg,
  checkSvg: CheckDefaultSvg,
  ibanCheckSvg: IbanCheckDefaultSvg,
  mitekSvg: MitekDefaultSvg,
  mitekHints: DefaultMitekHintsEnglish,
  idinSvg: null,
  verticleSteps: false,
  ordered: false,
  showTitle: false,
  headerContainer: false,
}

const FiveG: themeConfig = {
  id: [372],
  className: "sonio-layout",
  classNameMobile: "mobile-content-container",
  translationKey: "default",
  logoContainer: true,
  startSvg: StartDefaultSvg,
  successSvg: SuccessDefaultSvg,
  checkSvg: CheckDefaultSvg,
  ibanCheckSvg: IbanCheckDefaultSvg,
  mitekSvg: MitekDefaultSvg,
  mitekHints: DefaultMitekHintsEnglish,
  idinSvg: null,
  verticleSteps: false,
  ordered: false,
  showTitle: false,
  headerContainer: false,
}

const Sonio: themeConfig = {
  id: [1046],
  className: "sonio-layout",
  classNameMobile: "mobile-content-container",
  translationKey: "default",
  logoContainer: true,
  startSvg: StartDefaultSvg,
  successSvg: SuccessDefaultSvg,
  checkSvg: CheckDefaultSvg,
  ibanCheckSvg: IbanCheckDefaultSvg,
  mitekSvg: MitekDefaultSvg,
  mitekHints: DefaultMitekHintsEnglish,
  idinSvg: null,
  verticleSteps: false,
  ordered: false,
  showTitle: false,
  headerContainer: false,
}

const Atlantis: themeConfig = {
  id: [1048],
  className: "atlantis-layout",
  classNameMobile: "mobile-content-container",
  translationKey: "default",
  logoContainer: true,
  startSvg: StartDefaultSvg,
  successSvg: SuccessDefaultSvg,
  checkSvg: CheckDefaultSvg,
  ibanCheckSvg: IbanCheckDefaultSvg,
  mitekSvg: MitekDefaultSvg,
  mitekHints: DefaultMitekHintsEnglish,
  idinSvg: null,
  verticleSteps: false,
  ordered: false,
  showTitle: false,
  headerContainer: false,
}

const Default: themeConfig = {
  id: [0],
  className: "layout",
  classNameMobile: "mobile-content-container",
  translationKey: "default",
  logoContainer: false,
  startSvg: StartDefaultSvg,
  successSvg: SuccessDefaultSvg,
  checkSvg: CheckDefaultSvg,
  ibanCheckSvg: IbanCheckDefaultSvg,
  mitekSvg: MitekDefaultSvg,
  mitekHints: DefaultMitekHintsEnglish,
  idinSvg: null,
  verticleSteps: true,
  ordered: false,
  showTitle: false,
  headerContainer: false,
}

const styles: themeConfig[] = [
  Hommerson,
  Lucky7,
  NovaMedia,
  HPG,
  Bingoal,
  Wildmania,
  CDDN,
  CDDN_IFrame,
  Tipico,
  Jacks,
  ComeOn,
  Sonnenspiele,
  Sonio,
  Atlantis,
  FiveG,
  Fairplay,
  Betway,
]

export const initiateStyling = (id: number): themeConfig => {
  const style = styles.find((style: themeConfig) => style.id?.includes(id));

  if (style) {
    return style
  }

  return Default
}