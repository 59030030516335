import MitekHeader from '../../MitekHeader';
import { Button, Segment } from 'semantic-ui-react'
import { Trans, useTranslation } from 'react-i18next';
import { renderToStaticMarkup } from 'react-dom/server';
import { state } from '../../../../Context';

type MitekCheckFrontExplanationProps = {
  themeColor: string
  setCurrentPage: (page: number) => void;
  state: state;
}

const MitekCheckFrontExplanationComponent = (props: MitekCheckFrontExplanationProps) => {
  const { t } = useTranslation(props.state.org.theme.config.translationKey);

  const background = props.state.org.theme.config.mitekSvg ? encodeURIComponent(renderToStaticMarkup(<props.state.org.theme.config.mitekSvg fill={props.state.org.theme.themeColor} />)) : null;

  return (
    <>
      <MitekHeader />
      <Segment id="explanation-screen" className="mitek" basic style={{ backgroundColor: "white", height: "90%", width: "100%", margin: 0 }}>
        <h1 className="service-item-header">{t("General_UI.Hello")}</h1>
        <h2 className="service-item-subheader">
          <Trans i18nKey="Mitek.Help.Front" ns={props.state.org.theme.config.translationKey}>
            Placeholder <u></u>
          </Trans>
        </h2>
        <p>{t("Mitek.Help.Hold_Phone")}</p>
        <p>{t("Mitek.Help.Hold_Phone_Extra")}</p>
        <div className='explanation-background' style={{ background: props.state.view !== "success" ? `url("data:image/svg+xml,${background}")` : "", backgroundSize: "contain", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }} />
      </Segment>
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center", }} >
        <Button className="full-width" color="green" floated="right" onClick={() => props.setCurrentPage(1)}>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>

  )
}

export default MitekCheckFrontExplanationComponent