import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { QrReader } from 'react-qr-reader';
import { Header, Segment, Icon, Button, Popup } from 'semantic-ui-react'
import { EOCRLabsPageType } from '../../../../Types/OCRLabs/Enums/EOCRLabsPageType';
import { StateValue } from '../../../Context';
import { OCRLabsContext } from '../useOCR';
import useInterval from '../../../../Hooks/useInterval';
import { postOcrLabsBsn, postTransactionProgressLog } from '../../../../Services/Backend';
import { ELogType } from '../../../../Enums/ELogType';

export const OCRScanQRCode = () => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const { request, setPage, onDocumentValidated, settings, response } = useContext(OCRLabsContext);

  const [count, setCount] = React.useState(0);
  const [lastScanTime, setLastScanTime] = React.useState(Date.now());

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckEnteredSecondScan })
  }, [])

  useInterval(() => {
    setCount(count + 1);
  }, 1000);

  const handleRetry = () => {
    setPage({
      page: EOCRLabsPageType.TakePicture,
      data: { isBackside: false },
      renderButtonContainer: false,
    });
  }

  const handleScan = (result: any, error: any) => {
    if (result?.text && Date.now() - lastScanTime > 2500) {
      console.log("QR code scanned: " + result.text);

      postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckTookSecondPicture });
      postOcrLabsBsn(state.ctxId, result.text).then((res) => {
        if (res.status !== 200) return;

        if (!res.data.isValid) return;

        if (onDocumentValidated !== undefined) {
          console.log("onDocumentValidated callback provided, calling it.");
          postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckHandoffCompleted });
          onDocumentValidated(result);
          return;
        } else {
          if (settings?.performFaceAndLiveness) {
            setPage({
              page: EOCRLabsPageType.TakeSelfie,
              data: { hasErrored: true },
            });
          } else {
            setPage({
              page: EOCRLabsPageType.ValidateProperties,
              data: {
                fields: settings!.ocrLabsValidationFields,
                detectedFields: response!.detectedFields,
              },
              renderButtonContainer: false
            });
          }
        }
      });
    }
    setLastScanTime(Date.now());
  }

  return (
    <div className="ocr space-between ocr-padding" style={{ paddingBottom: '1em' }}>
      <span>
        <Header as="h1" className="service-item-header">
          {t("OCR_Labs.RetryOrPassportSignature.Scan_QR")}
          <Header.Subheader>
            {t("OCR_Labs.RetryOrPassportSignature.Backside")}
          </Header.Subheader>
        </Header>
      </span>
      <QrReader
        onResult={handleScan}
        containerStyle={{ flex: 1, width: "100%" }}
        constraints={{ facingMode: 'environment' }}
      />
      <Segment basic className="service-item-button-container mt-0">
        <Popup content={t("OCR_Labs.RetryOrPassportSignature.Different_Document")} open={count > 4} trigger={
          <Button primary color="green" floated="right" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} onClick={handleRetry}>{t("General_UI.Retry")}</Button>
        } />
      </Segment>
    </div>
  )
}
