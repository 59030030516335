import { AdminRoutes } from "./subRoutes/AdminRoutes";
import { ClientManagerRoutes } from "./subRoutes/ClientManagerRoutes";
import { CustomerManagerRoutes } from "./subRoutes/CustomerManagerRoutes";
import { CustomerOrganizationRoutes } from "./subRoutes/CustomerOrganizationRoutes";
import { DtsManagerRoutes } from "./subRoutes/DtsManagerRoutes";
import { GeneralRoutes } from "./subRoutes/GeneralRoutes";
import { IRoute } from "../models/routes/IRoute";
import { StatisticsRoutes } from "./subRoutes/StatisticsRoutes";

const privateRoutesConfig: IRoute[] = [
  ...GeneralRoutes,
  ...StatisticsRoutes,
  ...CustomerOrganizationRoutes,
  ...AdminRoutes,
  ...DtsManagerRoutes,
  ...CustomerManagerRoutes,
  ...ClientManagerRoutes
];

export default privateRoutesConfig;