import Backend from 'i18next-http-backend'
import betway_de from './language/locales/de-DE/betway.json'
import betway_en from './language/locales/en-GB/betway.json'
import lucky7_en from './language/locales/en-GB/lucky7.json'
import lucky7_nl from './language/locales/nl-NL/lucky7.json'
import bingoal from './language/locales/nl-NL/bingoal.json'
import cddn_translation from './language/locales/en-GB/cddn.json'
import cddn_translation_nl from './language/locales/nl-NL/cddn.json'
import comeon_translation from './language/locales/en-GB/comeon.json'
import comeon_translation_nl from './language/locales/nl-NL/comeon.json'
import default_translation from './language/locales/en-GB/translation.json'
import default_translation_de from './language/locales/de-DE/translation.json'
import default_translation_nl from './language/locales/nl-NL/translation.json'
import fairplay_tranlation_nl from './language/locales/nl-NL/fairplay.json'
import hpg_translation from './language/locales/en-GB/hpg.json'
import hpg_translation_nl from './language/locales/nl-NL/hpg.json'
import hommerson_translation from './language/locales/en-GB/hommerson.json'
import hommerson_translation_nl from './language/locales/nl-NL/hommerson.json'
import i18n from 'i18next'
import jacks_translation from './language/locales/en-GB/jacks.json'
import jacks_translation_nl from './language/locales/nl-NL/jacks.json'
import nova_media_translation from './language/locales/en-GB/nova_media.json'
import nova_media_translation_nl from './language/locales/nl-NL/nova_media.json'
import sonnenspiele_de from './language/locales/de-DE/sonnenspiele.json'
import sonnenspiele_en from './language/locales/en-GB/sonnenspiele.json'
import tipico_translation_de from './language/locales/de-DE/tipico.json'
import tipico_translation_en from './language/locales/en-GB/tipico.json'
import wildmania_translation from './language/locales/en-GB/wildmania.json'
import wildmania_translation_de from './language/locales/de-DE/wildmania.json'
import wildmania_translation_nl from './language/locales/nl-NL/wildmania.json'
import { initReactI18next } from 'react-i18next'
import { setLocale } from 'yup'
import { isDev } from './utils/IsDev'

const resources = {
  'de': {
    wildmania: wildmania_translation_de,
    tipico: tipico_translation_de,
    default: default_translation_de,
    sonnenspiele: sonnenspiele_de,
    betway: betway_de
  },
  'DE': {
    wildmania: wildmania_translation_de,
    tipico: tipico_translation_de,
    default: default_translation_de,
    sonnenspiele: sonnenspiele_de,
    betway: betway_de
  },
  'en': {
    // Namespaces
    default: default_translation,
    nova_media: nova_media_translation,
    hpg: hpg_translation,
    cddn: cddn_translation,
    wildmania: wildmania_translation,
    tipico: tipico_translation_en,
    jacks: jacks_translation,
    comeon: comeon_translation,
    sonnenspiele: sonnenspiele_en,
    fairplay: fairplay_tranlation_nl,
    betway: betway_en,
    lucky7: lucky7_en,
    hommerson:hommerson_translation
  },
  'EN': {
    // Namespaces
    default: default_translation,
    nova_media: nova_media_translation,
    hpg: hpg_translation,
    cddn: cddn_translation,
    wildmania: wildmania_translation,
    tipico: tipico_translation_en,
    jacks: jacks_translation,
    comeon: comeon_translation,
    sonnenspiele: sonnenspiele_en,
    fairplay: fairplay_tranlation_nl,
    betway: betway_en,
    lucky7: lucky7_en,
    hommerson: hommerson_translation
  },
  'nl': {
    default: default_translation_nl,
    nova_media: nova_media_translation_nl,
    bingoal: bingoal,
    hpg: hpg_translation_nl,
    cddn: cddn_translation_nl,
    wildmania: wildmania_translation_nl,
    jacks: jacks_translation_nl,
    comeon: comeon_translation_nl,
    fairplay: fairplay_tranlation_nl,
    lucky7: lucky7_nl,
    hommerson: hommerson_translation_nl
  },
  'NL': {
    default: default_translation_nl,
    nova_media: nova_media_translation_nl,
    bingoal: bingoal,
    hpg: hpg_translation_nl,
    cddn: cddn_translation_nl,
    wildmania: wildmania_translation_nl,
    jacks: jacks_translation_nl,
    comeon: comeon_translation_nl,
    fairplay: fairplay_tranlation_nl,
    lucky7: lucky7_nl,
    hommerson: hommerson_translation_nl
  }
};

// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .use(Backend)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    react: {
      useSuspense: false,
    },
    resources,
    defaultNS: 'default',
    fallbackNS: 'default',
    fallbackLng: 'en',
    supportedLngs: ['en', 'nl', 'de', 'NL', 'EN', 'DE'],
    load: "currentOnly",
    debug: isDev,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });

export const yupVal = {
  array: {
    max: (item: any) => i18n.t("Yup.Array.Max", item),
    min: (item: any) => i18n.t("Yup.Array.Min", item),
  },
  date: {
    max: (item: any) => i18n.t("Yup.Date.Max", item),
    min: (item: any) => i18n.t("Yup.Date.Min", item),
  },
  mixed: {
    default: (item: any) => i18n.t("Yup.Mixed.Default", item),
    defined: (item: any) => i18n.t("Yup.Mixed.Defined", item),
    notOneOf: (item: any) => i18n.t("Yup.Mixed.NotOneOf", item),
    oneOf: (item: any) => i18n.t("Yup.Mixed.OneOf", item),
    required: (item: any) => i18n.t("Yup.Mixed.Required", item),
  },
  number: {
    integer: (item: any) => i18n.t("Yup.Number.Integer", item),
    lessThan: (item: any) => i18n.t("Yup.Number.LessThan", item),
    max: (item: any) => i18n.t("Yup.Number.Max", item),
    min: (item: any) => i18n.t("Yup.Number.Min", item),
    moreThan: (item: any) => i18n.t("Yup.Number.MoreThan", item),
    negative: (item: any) => i18n.t("Yup.Number.Negative", item),
    notEqual: (item: any) => i18n.t("Yup.Number.NotEqual", item),
    positive: (item: any) => i18n.t("Yup.Number.Positive", item),
  },
  object: {
    noUnknown: (item: any) => i18n.t("Yup.Object.NoUnknown", item),
  },
  string: {
    email: (item: any) => i18n.t("Yup.String.Email", item),
    length: (item: any) => i18n.t("Yup.String.Length", item),
    lowercase: (item: any) => i18n.t("Yup.String.Lowercase", item),
    matches: (item: any) => i18n.t("Yup.String.Matches", item),
    max: (item: any) => i18n.t("Yup.String.Max", item),
    min: (item: any) => i18n.t("Yup.String.Min", item),
    trim: (item: any) => i18n.t("Yup.String.Trim", item),
    uppercase: (item: any) => i18n.t("Yup.String.Uppercase", item),
    url: (item: any) => i18n.t("Yup.String.Url", item),
  },
};

setLocale(yupVal);

export default i18n;