import * as yup from 'yup'
import { AnimationCheck } from '../../AnimationViews/AnimationCheck'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { Button, Flag, Form as SemanticForm, Segment } from 'semantic-ui-react'
import { countries } from '../../../Countries'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { DevLog } from '../../../utils/DevLog'
import { ELogType } from '../../../Enums/ELogType'
import { FastField, Field, Formik } from 'formik'
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { getFirstName, getInitials, getLastName, getMiddleName } from '../../../utils/nameUtils'
import { postPepCheck, postTransactionProgressLog } from '../../../Services/Backend'
import { SelectDropdown } from '../../Common/SelectDropdown'
import { StateDispatch, StateValue } from '../../Context'
import { TextInput } from '../../Common/form/TextInput'
import { TimerButton } from '../../Common/Button/TimerButton'
import { useTranslation } from 'react-i18next'

export const PepCheckComponent: FunctionComponent = () => {
  const state = useContext(StateValue);
  const dispatch = useContext(StateDispatch);

  const { t } = useTranslation(state.org.theme.config.translationKey);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const optionsCountries = useMemo(() => countries.map(el => ({ key: el.countryCode, text: el.name, value: el.countryCode.toUpperCase(), content: (<><Flag name={el.countryCode.toLowerCase() as any} /> {el.name}</>) })), [])

  const validationSchema = yup.object({
    INITIALS: yup.string().min(1, "").required(t("Pep.Initials_Required")),
    FIRSTNAME: yup.string().min(1, ""),
    MIDDLENAME: yup.string(),
    LASTNAME: yup.string().min(1, "").required(t("Pep.Last_Name_Required")),
    BIRTHDATE: yup.string().required(t("Pep.Birthdate_Required")),
    GENDER: yup.string().min(1, t("Pep.Gender_Required")).required(t("Pep.Gender_Required")),
    COUNTRY: yup.string()
  }
  );

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.PepCheckEntered });
  }, [])

  const optionsGender = [
    { key: 'M', text: t("Pep.Male"), value: 'M' },
    { key: 'F', text: t("Pep.Female"), value: 'F' },
  ]

  const onSuccess = () => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.PepCheckCompleted });
    dispatch({ type: "setView", data: "form" });
    setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
  }

  const handleSubmit = (data: any, formProps: any) => {
    setIsSubmitting(true);
    DevLog("handleSubmit")
    DevLog(data);
    postPepCheck({
      service: 5,
      initials: data.INITIALS,
      firstName: data.FIRSTNAME,
      middleName: data.MIDDLENAME,
      lastName: data.LASTNAME,
      birthDate: data.BIRTHDATE.replace(/-/g, ''),
      country: data.COUNTRY,
      gender: data.GENDER,
      ctxId: state.ctxId
    })
      .then(res => {
        DevLog("THEN")
        if (res.status !== 200) {
          setIsError(true);
        } else {
          dispatch({ type: "setView", data: "success" });
        }
        setIsSubmitting(false);
      }).catch(err => {
        setIsError(true);
        setIsSubmitting(false);
      })
  };

  const renderView = () => {
    switch (state.view) {
      case "form":
        return (
          <div id="PepCheckComponent" style={{ flex: 1 }} className={`service-item-container pl-0 pr-0 pt-0`}>
            <span className='mb-2'>
              <h3 className={`service-item-header${state.org.theme.clientClass ? '-' + state.org.theme.clientClass : ''}`}>
                {t('Pep.Personal_Details')}
              </h3>
            </span>
            <Formik
              initialValues={{
                INITIALS: getInitials(localStorage.getItem("GIVEN_NAMES") ?? '').toString(),
                FIRSTNAME: getFirstName(localStorage.getItem("GIVEN_NAMES") ?? '').toString(),
                MIDDLENAME: getMiddleName(localStorage.getItem("SURNAME") ?? '').toString(),
                LASTNAME: getLastName(localStorage.getItem("SURNAME") ?? '').toString(),
                BIRTHDATE: (localStorage.getItem("DATEOFBIRTH") ?? '').toString(),
                GENDER: (localStorage.getItem("GENDER") ?? '').toString(),
                COUNTRY: '',
              }}
              onSubmit={(data: any, formProps: any) => handleSubmit(data, formProps)}
              validationSchema={validationSchema}
            >
              {(formProps) => {
                return (
                  <SemanticForm
                    onSubmit={formProps.handleSubmit}
                    style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: "space-between" }}
                  >
                    <span style={{ flex: 1, overflowY: "auto", overflowX: "hidden" }}>
                      <SemanticForm.Group id="group1" unstackable={formProps.values.LASTNAME.length < 15} className='mb-4'>
                        <Field
                          component={TextInput}
                          id="INITIALS"
                          label={t('Pep.Initials')}
                          name="INITIALS"
                          type="text"
                          placeholder={t(
                            'Pep.Initials'
                          )}
                          required={true}
                          error={formProps.errors.INITIALS}
                          formField={{ className: "four wide field", fluid: true }}
                        />
                        <Field
                          component={TextInput}
                          id="MIDDLENAME"
                          label={t(
                            'Pep.Middle_Name'
                          )}
                          name="MIDDLENAME"
                          type="text"
                          placeholder={t(
                            'Pep.Middle_Name'
                          )}
                          required={false}
                          error={formProps.errors.MIDDLENAME}
                          formField={{ className: "five wide field" }}
                        />

                        <Field
                          width={3}
                          component={TextInput}
                          id="LASTNAME"
                          label={t(
                            'Pep.Last_Name'
                          )}
                          name="LASTNAME"
                          type="text"
                          placeholder={t(
                            'Pep.Last_Name'
                          )}
                          error={formProps.errors.LASTNAME}
                          required={true}
                          formField={{ className: "seven wide field" }}
                        />
                      </SemanticForm.Group>

                      <SemanticForm.Group id="group2" unstackable widths={2} className='mb-4'>
                        <Field
                          component={SelectDropdown}
                          options={optionsGender}
                          id="GENDER"
                          search
                          label={t('Pep.Gender')}
                          name="GENDER"
                          placeholder={t('Pep.Gender')}
                          required
                        />
                        <Field
                          component={TextInput}
                          id="BIRTHDATE"
                          label={t(
                            'Pep.Birthdate'
                          )}
                          name="BIRTHDATE"
                          type="date"
                          placeholder={t(
                            'Pep.Birthdate'
                          )}
                          formInput={{ fluid: true }}
                          error={formProps.errors.BIRTHDATE}
                          required
                        />
                      </SemanticForm.Group>
                      <FastField
                        component={SelectDropdown}
                        options={optionsCountries}
                        id="COUNTRY"
                        search
                        label={t('Pep.Country')}
                        name="COUNTRY"
                        placeholder={t('Pep.Country')}
                        required
                      />
                    </span>

                    <Segment basic className={`service-item-button-container mt-0`} >
                      <Button
                        floated="right"
                        disabled={isSubmitting}
                        loading={isSubmitting}
                        color={isError ? 'red' : 'green'}
                        type="submit"
                      >
                        {isError
                          ? t('General_UI.Try_Again')
                          : t('General_UI.Submit')}
                      </Button>
                    </Segment>

                  </SemanticForm>)
              }}
            </Formik>
          </div>
        )
      case "checking":
        return (
          <AnimationCheck svgComponent={state.org.theme.config.checkSvg && <state.org.theme.config.checkSvg fill={state.org.theme.themeColor} />} header={t("General_UI.Verifying")} text={t("General_UI.One_Moment")} key={`${state.view}-check`} />)
      case "success":
        return <AnimationSuccess header={t("General_UI.Great")} text={t("General_UI.Correct")} key={`${state.view}-success`} />
    }
  }
  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${state.view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container" style={{ flex: 1 }}>
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
      {
        state.view !== "form" && (
          <Segment basic className="service-item-button-container mt-0">
            {state.view === "success" && <TimerButton countDownStart={state.org.theme.config.countDownStart ?? 5} callback={onSuccess} />}
          </Segment>
        )
      }

    </>
  );
};

