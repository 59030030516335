import { IUserFormLoginValues } from "../models/user/IUser";
import { RolesAdmins } from "../Enums/Roles";
import { RootStore } from "./RootStore";
import { authTokenKey } from "./AuthorizationStore";
import { frontendReferrer } from "../Services/BaseApiService";
import { history, routePrefixDashboard } from "../ApplicationRouter";
import { localhostLogFetch, localhostLogFetchResult, removeSurroundingQuotes } from "../utils/General";
import { sanitizeUrl } from "../utils/Security";
import { DevLog } from "../utils/DevLog";

export default class AuthenticationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  login = async (values: IUserFormLoginValues, redirectPath: string) => {
    const type = "POST";
    const url = `${process.env.REACT_APP_API_URL}/login`;
    const body = JSON.stringify(values);
    localhostLogFetch(type, url, body);

    const response = await fetch(url, {
      method: type,
      cache: "no-cache",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json"
      },
      redirect: "follow",
      referrer: frontendReferrer,
      body: body
    });

    const success = response.status === 200;
    const responseText = await response.text();
    localhostLogFetchResult(type, url, responseText);

    let redirect = "";
    let message = "";

    if (success) {
      const responseBody = removeSurroundingQuotes(responseText);
      this.rootStore.authorizationStore.token = responseBody;

      if (!this.rootStore.authorizationStore.hasRole()) {
        this.signOut();
      } else {
        if (redirectPath && redirectPath.length > 0) {
          redirectPath = sanitizeUrl(redirectPath) as string;
          redirect += `${redirectPath}`;
        }
      }
      message = "Authenticated, redirecting";
    } else {
      message = response.status === 401 ? responseText : "An error has occured";
    }

    return { success: success, message: message, redirect: redirect };
  }

  redirectToRole = () => {
    if (this.rootStore.authorizationStore.hasRole()) {
      DevLog("Role", this.rootStore.authorizationStore.role);

      if (this.rootStore.authorizationStore.isInRoles(RolesAdmins)) {
        return routePrefixDashboard;
      }
    }
    return "/"
  }

  async signOut() {
    localStorage.removeItem(authTokenKey);

    history.push("/logout");
  }
}