import { Segment, Header, Image } from 'semantic-ui-react';
import { StateValue } from '../../../Context';
import { StyledProgressBar } from '../../../StyledComponents/StyledProgressbar';
import { useContext } from 'react'

type IProps = {
  children?: any;
  title?: string;
  id?: string;
}

const InformationPageLayout = (props: IProps) => {

  const state = useContext(StateValue);

  return (
    <div className={state.org.theme.config.classNameMobile + " mitek-information-page"} id={props.id ?? "mitek-information-page"}>
      <Segment className={`mobile-content-header-container`}>
        <StyledProgressBar percent={(state.current.order / (state.flow.length)) * 100} attached="top" color={state.org.theme.themeColor} className="" />
        {
          state.org.theme.config.mobileHeaderImageOnly ? (
            <Image src={state.org.theme.logo} style={{ height: "4.5em", width: "auto" }} />
          ) : (
            <Header as="h3" className="mt-0">
              <Image src={state.org.theme.logo} style={{ height: "2.5em", width: "auto" }} /> {props.title}
            </Header>
          )
        }
      </Segment>
      <div style={{ flex: 1 }} className={`mobile-service-item-container`}>
        {props.children}
      </div>
    </div>
  )
}

export default InformationPageLayout