import { AnimationCheck } from '../../AnimationViews/AnimationCheck'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { Button, Dropdown, Header, Segment } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { ELogType } from '../../../Enums/ELogType'
import { postIdinAgeStartService, postTransactionProgressLog } from '../../../Services/Backend'
import { StateDispatch, StateValue } from '../../Context'
import { TimerButton } from '../../Common/Button/TimerButton'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const IdinAgeCheckComponent = () => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const dispatch = useContext(StateDispatch);

  const bankoptions = [
    {
      key: 'ABN-amro',
      text: 'ABN AMRO',
      value: 'ABNANL2A',
    },
    {
      key: 'ASN-Bank',
      text: 'ASN Bank',
      value: 'ASNBNL21',
    },
    {
      key: 'Bunq',
      text: 'Bunq',
      value: 'BUNQNL2A',
    },
    {
      key: 'ING',
      text: 'ING',
      value: 'INGBNL2A',
    },
    {
      key: 'Rabobank',
      text: 'Rabobank',
      value: 'RABONL2U',
    },
    {
      key: 'Regiobank',
      text: 'Regiobank',
      value: 'RBRBNL21',
    },
    {
      key: 'SNS',
      text: 'SNS',
      value: 'SNSBNL2A',
    },
  ]

  const [currentBank, setCurrentBank] = useState<string | undefined>(undefined);


  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.AgeCheckEntered });
    if (localStorage.getItem("idinAgeRedirect") === "true" && state.lastFinishedAccordingToBackend! >= state.current.order && state.lastFinishedAccordingToBackend !== null) {
      dispatch({ type: "setView", data: "success" });
    }
  }, [])

  const postBackend = () => {
    postIdinAgeStartService(state.ctxId, currentBank!).then(res => {
      if (res.status === 200) {
        postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.AgeCheckCompleted });
        localStorage.setItem("idinAgeRedirect", "true");
        window.location.href = res.data.transactionUrl;
      }
    }).catch(error => {
      dispatch({ type: "setErrors", data: [t("General_UI.Incorrect")] })
      dispatch({ type: "setView", data: "form" })
    })
  }

  const renderView = () => {
    switch (state.view) {
      case "form":
        return (
          <>
            <span id="idin-container">
              {
                state.org.theme.config.showTitle && (
                  <h1 className="service-item-header"> {t("Idin.Title")} </h1>
                )
              }

              <Header as={state.org.theme.config.showTitle ? "h2" : "h1"} className="service-item-subheader">
                {t("IdinAge.Header")}
              </Header>

              {
                state.org.theme.config.showTitle && (<br />)
              }

              <h3 className="service-item-explanation">
                {t("IdinAge.SubHeader")}
              </h3>
              {
                !state.org.theme.config.showTitle && (<br />)
              }
              <div className="bank-dropdown-container">
                <b className="mb-2">{t("Idin.Choose_Bank")}*</b>
                <Dropdown
                  placeholder={t("Idin.Choose_Bank")}
                  fluid
                  onChange={(e, d) => setCurrentBank(d.value?.toString() ?? undefined)}
                  selection
                  scrolling
                  options={bankoptions}
                  className="bank-dropdown mb-2"
                />
                <i>{t("Idin.Info")}</i>
              </div>
            </span>
          </>
        )
      case "checking":
        return <AnimationCheck svgComponent={state.org.theme.config.checkSvg && <state.org.theme.config.checkSvg fill={state.org.theme.themeColor} />} header={t("General_UI.Verifying")} text={t("General_UI.One_Moment")} key={`${state.view}-check`} />
      case "success":
        return <AnimationSuccess header={t("General_UI.Great")} text={t("General_UI.Correct")} key={`${state.view}-success`} />
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${state.view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container">
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Segment basic className="service-item-button-container mt-0">
        {(state.lastFinishedAccordingToBackend ?? - 1) < state.current.order && localStorage.getItem("idinAgeRedirect") !== "true" ? <Button floated="right" type="submit" disabled={currentBank == undefined} onClick={() => { postBackend(); }} >
          {t("IdinAge.Open")}
        </Button> : null}
        {(state.lastFinishedAccordingToBackend ?? - 1) < state.current.order && localStorage.getItem("idinAgeRedirect") === "true" ? <Button negative basic floated="right" type="submit" disabled={currentBank == undefined} onClick={() => { postBackend(); }} >
          {t("General_UI.Retry")}
        </Button> : null}
        {(state.lastFinishedAccordingToBackend ?? 0) >= state.current.order && state.lastFinishedAccordingToBackend != undefined ? <TimerButton countDownStart={state.org.theme.config.countDownStart ?? 5} callback={() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] })} /> : null}
      </Segment>
    </>
  );
};