export enum EEnvironmentType {
  Unknown = 0,
  Test = 10,
  Acc = 20,
  Prod = 30
}

export interface IEnvironmentEnum {
  value: EEnvironmentType;
  name: string;
}

const environmentItem = (value: EEnvironmentType, name: string): IEnvironmentEnum => ({ value, name })

export const EnvironmentEnums = (): IEnvironmentEnum[] => {
  let list: IEnvironmentEnum[] = [];

  list.push(environmentItem(EEnvironmentType.Test, EEnvironmentType[EEnvironmentType.Test]));
  list.push(environmentItem(EEnvironmentType.Acc, EEnvironmentType[EEnvironmentType.Acc]));
  list.push(environmentItem(EEnvironmentType.Prod, EEnvironmentType[EEnvironmentType.Prod]));

  return list;
}