import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Header, Segment, Button, Message } from 'semantic-ui-react'
import { StateValue } from '../../../Context';
import { OCRLabsContext } from '../useOCR';
import { EOCRLabsPageType } from '../../../../Types/OCRLabs/Enums/EOCRLabsPageType';

export const OCRRetryOrPassportSignature = () => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const { setPage } = useContext(OCRLabsContext);

  const handleQRCode = () => {
    setPage({
      page: EOCRLabsPageType.ScanQRCode,
      renderButtonContainer: false,
    });
  }

  const handleRetry = () => {
    setPage({
      page: EOCRLabsPageType.TakePicture,
      data: { isBackside: false },
      renderButtonContainer: false,
    });
  }

  return (
    <div className="ocr space-between ocr-padding">
      <span>
        <Message
          warning
          header={t("OCR_Labs.RetryOrPassportSignature.Header")}
          content={t("OCR_Labs.RetryOrPassportSignature.SubHeader")}
        />
        {/* <Header as="h1" className="service-item-header">

          <Header.Subheader>

          </Header.Subheader>
        </Header> */}
      </span>
      <Segment basic id="stacked-buttons" className="service-item-button-container mt-0">
        <Button id="retry-button" color="green" floated='right' onClick={handleRetry}>
          {t("OCR_Labs.RetryOrPassportSignature.Try_Again")}
        </Button>
        <Button color="green" floated='right' onClick={handleQRCode}>
          {t("OCR_Labs.RetryOrPassportSignature.Scan_QR")}
        </Button>
      </Segment>
    </div>
  )
}
