import { EValidatePropertiesComponentType } from "../../../../Types/OCRLabs/Enums/EValidatePropertiesComponentType";
import { TextInput } from "../../../Common/form/TextInput";

// Remark: Typings aren't necessary for this as all props are passed through and their respective types are defined in the components themselves.
const ComponentMapping = (props: any) => {
  switch (props.componentType) {
    case EValidatePropertiesComponentType.Text:
      return <TextInput {...props} />;
    case EValidatePropertiesComponentType.Number:
      return <TextInput {...props} />;
    case EValidatePropertiesComponentType.Date:
      return <TextInput {...props} type="date" />;
    // case EComponentType.Dropdown:
    //   return <DropDown {...props} />;
    // case EComponentType.Checkbox:
    //   return <CheckBox {...props} />;
    default:
      return null;
  }
};

export const RenderInputComponent = (props: any) => {
  return <ComponentMapping {...props} />;
};