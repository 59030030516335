import { CustomerForm } from "./CustomerForm";
import { CustomerOrganizations } from "./CustomerOrganizations";
import { IClientByCustomer } from "../../../models/client/IClient";
import { ICustomer } from "../../../models/customer/ICustomer";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";

interface IProps {
  id?: string
}

export const CustomerParent: React.FC<IProps> = observer(({ id }) => {
  const rootStore = useContext(RootStoreContext);
  const { getCustomerById, getClientsByCustomerId } = rootStore.customerClientStore;

  const [customer, setCustomer] = useState<ICustomer>();
  const [clients, setClients] = useState<IClientByCustomer[]>([]);

  useEffect(() => {
    const load = async () => {
      if (id !== undefined) {
        var customer = await getCustomerById(parseInt(id));
        if (customer) {
          setCustomer(customer)
        }

        var client = await getClientsByCustomerId(parseInt(id));
        if (client) {
          setClients(client)
        }
      }
    }
    load();
  }, [id])
  return (
    <>
      <CustomerForm id={id} customer={customer} />
      <CustomerOrganizations id={id} clients={clients} setClients={setClients} />
    </>
  )
})