import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Grid, Table } from "semantic-ui-react";
import { SerivceCombiEnums, ServiceBackofficeEnums } from "../../../Enums/EService";
import { IStatisticDistribution, IStatisticsReport } from "../../../models/statistics/IStatisticsReport";
import "./StatisticResponseItemStyling.css";
import { StatisticResponseServiceFinished, StatisticResponseServiceInformation, StatisticResponseServiceNotFinished } from "./StatisticResponseServiceInformation";

interface IProps {
  stats: IStatisticsReport
}

export const StatisticResponseItem: React.FC<IProps> = observer(({ stats }) => {
  const { t } = useTranslation();
  // const [distributions, setDistributions] = useState<IStatisticsReportDistribution[]>([]);
  const [statisticDistributions, setStatisticDistributions] = useState<IStatisticDistribution[]>([]);
  const [totalConversion, setTotalConversion] = useState("");

  useEffect(() => {
    let serviceCombiEnums = SerivceCombiEnums().map(x => x.value);
    let backOfficeEnums = ServiceBackofficeEnums().map(x => x.value);
    // Kept just in case
    // let filteredDistributions = stats.distributions.filter((x) => x.apiCalls > 0 && !combiEnums.find(e => e === x.service) && !officeEnums.find(e => e === x.service));
    // setDistributions(filteredDistributions);
    let filteredStatisticDistri = stats.statisticDistributions.filter((x) => x.totalUniqueUsers > 0 && !serviceCombiEnums.find(e => e === x.service) && !backOfficeEnums.find(e => e === x.service));
    setStatisticDistributions(filteredStatisticDistri);
    let conversion = ((stats.completedTransactionCount / filteredStatisticDistri[0]?.totalUniqueUsers ?? 0) * 100).toFixed(0);
    setTotalConversion(conversion);
  }, [stats])

  return (
    <Card
      key={`${stats.customerClientId}-${stats.flowId}`}
      style={{ backgroundColor: "#f0f0f0", marginBottom: "40px" }}
    >
      <Card.Header>
        <Grid celled>
          <Grid.Row>
            <Grid.Column width={8}>
              {t("Statistics.Customer_Name")}
            </Grid.Column>
            <Grid.Column width={8}>
              <strong>{stats.customerName}</strong>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              {t("Statistics.Client_Name")}
            </Grid.Column>
            <Grid.Column width={8}>
              <strong>{stats.clientName}</strong>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              {t("Statistics.FlowId")}
            </Grid.Column>
            <Grid.Column width={8}>
              {stats.flowId}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              {t("Statistics.Total_Unique_Sessions")}
            </Grid.Column>
            <Grid.Column width={8}>
              {stats.transactionCount}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card.Header>
      <Card.Content>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t("Statistics.Name")}</Table.HeaderCell>
              <Table.HeaderCell width={3}>{t("Statistics.Unique_Sessions")}</Table.HeaderCell>
              <Table.HeaderCell width={3} className="table-border-right">{t("Statistics.Conversion")}</Table.HeaderCell>
              <Table.HeaderCell width={2}>{t("Statistics.Total_Calls")}</Table.HeaderCell>
              <Table.HeaderCell width={2}>{t("Statistics.Finished")}</Table.HeaderCell>
              <Table.HeaderCell width={2}>{t("Statistics.Finished_Not")}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>

            {statisticDistributions.map((distribution, index) => {
              const conversion = index === 0 ? "100" : (distribution.totalUniqueUsers / statisticDistributions[0].totalUniqueUsers * 100).toFixed(0);
              return (
                <Table.Row key={`${distribution.name}-${index}`}>
                  <Table.Cell>{distribution.name}{' '}<StatisticResponseServiceInformation service={distribution.service} /></Table.Cell>
                  <Table.Cell>{distribution.totalUniqueUsers}</Table.Cell>
                  <Table.Cell className="table-border-right">{conversion}%</Table.Cell>
                  <Table.Cell>{distribution.totalCalls}</Table.Cell>
                  <Table.Cell>{distribution.valid}{' '}{<StatisticResponseServiceFinished service={distribution.service} />}</Table.Cell>
                  <Table.Cell>{distribution.invalid}{' '}{<StatisticResponseServiceNotFinished service={distribution.service} />}</Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        {/* {distributions.map((distribution, index) => (
          <Grid celled key={`${distribution.name}-${index}`}>
            <Grid.Row>
              <Grid.Column width={8}>
                <p>{distribution.name}</p>
              </Grid.Column>
              <Grid.Column width={5}>
                <p>{t(`Statistics.Finished${distribution.validated ? "" : "_Not"}`)}</p>
              </Grid.Column>
              <Grid.Column width={3}>
                <p>{distribution.apiCalls}</p>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
            </Grid.Row>
          </Grid>
        ))} */}
      </Card.Content>
      <Grid celled>
        <Grid.Row>
          <Grid.Column width={8}>
            {t("Statistics.Completed_Transactions")}
          </Grid.Column>
          <Grid.Column width={8}>
            {stats.completedTransactionCount}
          </Grid.Column>
        </Grid.Row>
        {statisticDistributions[0] !== undefined && (
          <Grid.Row>
            <Grid.Column width={8}>
              {t("Statistics.Completed_Conversion", { service_check: statisticDistributions[0].name })}
            </Grid.Column>
            <Grid.Column width={8}>
              {totalConversion}%
            </Grid.Column>
          </Grid.Row>
        )}
      </Grid>
    </Card>
  )
})