import "./OrganizationEditFlowModal.css";
import 'react-mde/lib/styles/css/react-mde-all.css';
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import { Button, Form, Grid, Header, Modal, Segment } from "semantic-ui-react";
import { Field, Formik } from "formik";
import { FlowFormValues, IFlow } from "../../../models/flow/IFlow";
import { RootStoreContext } from "../../../stores/RootStore";
import { TextInput } from "../../Common/form/TextInput";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DevLog } from "../../../utils/DevLog";

interface IProps {
  flow: IFlow;
  trigger: any;
  reload: () => void
}

export const OrganizationEditFlowModal: React.FC<IProps> = observer(({ trigger, flow, reload }) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { editFlowForClient, submittingFlow } = rootStore.customerClientStore;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [values, setValues] = useState(new FlowFormValues(flow));

  const [description, setDescription] = useState<string>(flow.description !== "" ? flow.description : `### Koptekst (ctrl + h) 

**Dikgedrukte tekst (ctrl + b)**

*Italics (ctrl + i)*

Tweemaal enter voor een newline.`);

  const handleSubmit = async (values: FlowFormValues) => {
    DevLog("values", values);
    values.description = description;

    var success = await editFlowForClient(values);
    if (success) {
      reload()
      setModalOpen(false);
    }
  }

  return (
    <Grid>
      <Grid.Row>
        <Grid.Column width={4}>
          <Modal
            open={modalOpen}
            trigger={trigger}
            onOpen={() => setModalOpen(true)}
            onClose={() => setModalOpen(false)}
          >
            <Modal.Header as="h2">{flow.name !== "" ? `${flow.name} aanpassen` : "Nieuwe flow aanmaken"}</Modal.Header>
            <Modal.Content>
              <Formik
                enableReinitialize
                initialValues={values}
                onSubmit={handleSubmit}
              >
                {(formik) => (
                  <Form onSubmit={formik.handleSubmit}>
                    <Segment attached>
                      <Field
                        component={TextInput}
                        name="name"
                        label="Naam"
                        required
                      />
                    </Segment>
                    <Segment.Group>
                      <Header as="h3" attached="top">
                        Tekst op welkom scherm (Wordt niet gebruikt)
                      </Header>
                      <div className="containerEditor">
                        <ReactMde
                          value={description}
                          toolbarCommands={[["header", "bold", "italic"]]}
                          onChange={setDescription}
                          disablePreview
                        />
                      </div>
                      <Header as="h3" attached="top">Preview</Header>
                      <Segment.Group>
                        <Segment>
                          <ReactMarkdown skipHtml>{description}</ReactMarkdown>
                        </Segment>
                      </Segment.Group>
                    </Segment.Group>
                    <Button
                      onClick={() => setModalOpen(false)}
                      negative
                      type="button"
                    >
                      {t("Button.Cancel")}
                    </Button>
                    <Button
                      floated="right"
                      type="submit"
                      loading={submittingFlow}
                      primary
                    >
                      {t("Button.Save")}
                    </Button>
                  </Form>
                )}
              </Formik>
            </Modal.Content>
          </Modal>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
})