export const autoCaptureHints = {
  MISNAP_HEAD_OUTSIDE: "Place face in oval, remove glasses if present",
  MISNAP_HEAD_SKEWED: "Look straight ahead",
  MISNAP_AXIS_ANGLE: "Hold phone upright",
  MISNAP_HEAD_TOO_CLOSE: "Move further away",
  MISNAP_HEAD_TOO_FAR: "Get closer",
  MISNAP_STAY_STILL: "Hold still",
  MISNAP_SUCCESS: "Success",
  MISNAP_STOP_SMILING: "Keep a neutral face",
  MISNAP_SMILE: "Smile!",
  MISNAP_READY_POSE: "Hold this pose",
  NO_FACE_FOUND: "Can't find face",
  MITEK_ERROR_GLARE: "The light is reflecting too much, use a matte background",
  MITEK_ERROR_FOUR_CORNER: "Can't find document",
  MITEK_ERROR_TOO_DARK: "A little too dark, use a well lit environment",
  MITEK_ERROR_FOCUS: "Hold Steady",
  MITEK_ERROR_MRZ_MISSING: "Can't read every element on the photo page of your passport",
  CV_NO_BARCODE_FOUND: "Scanning for barcode",
  MITEK_ERROR_TOO_FAR: "A little closer",
  MITEK_ERROR_TOO_CLOSE: "A little too close",
  MITEK_ERROR_NOT_CENTERED: "A little more centered",
  MITEK_ERROR_MIN_PADDING: "A little further away",
  MITEK_ERROR_HORIZONTAL_FILL: "A little closer",
  MITEK_ERROR_LOW_CONTRAST: "Place the document on a dark surface",
  MITEK_ERROR_BUSY_BACKGROUND: "Place the document on a dark surface",
  MITEK_ERROR_SKEW_ANGLE: "",
};