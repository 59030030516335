import { CreateCustomer } from "../../pages/Secure/Customer/CreateCustomer";
import { CreateOrganization } from "../../pages/Secure/Organization/CreateOrganization";
import { EditCustomer } from "../../pages/Secure/Customer/EditCustomer";
import { EditOrganization } from "../../pages/Secure/Organization/EditOrganization";
import { IRoute } from "../../models/routes/IRoute";
import { Roles } from "../../Enums/Roles";
import { CustomerStatistics } from "../../pages/Secure/Statistics/customer/CustomerStatistics";

export const CustomerOrganizationRoutes: IRoute[] = [
  {
    component: CreateOrganization,
    path: "/organization",
    title: "Navbar",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.CUSTOMERMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: EditOrganization,
    path: "/organization/:id",
    title: "Navbar",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.CUSTOMERMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: CreateCustomer,
    path: "/customer",
    title: "Navbar",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: EditCustomer,
    path: "/customer/:id",
    title: "Navbar",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.CUSTOMERMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: CustomerStatistics,
    path: "/organization-stats/:id",
    title: "Navbar",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
]