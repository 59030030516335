import { Button, Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import { ClientCreateEditDto } from "../../../Types";
import { DatePicker } from "../../Common/form/DatePicker";
import { Field, Formik } from "formik";
import { IOrganizationApiForm, OrganizationApiFormValues } from "../../../models/organization/IOrganization";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DevLog } from "../../../utils/DevLog";
var FileSaver = require('file-saver');

interface IProps {
  id: string;
  client?: ClientCreateEditDto;
}

export const OrganizationApiAccess: React.FC<IProps> = observer(({ id, client }) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { openConfirmModal } = rootStore.confirmModalStore;
  const { submitting, createNewApiKeyForClient } = rootStore.customerClientStore;

  const [values, setFormValues] = useState(new OrganizationApiFormValues());

  useEffect(() => {
    if (client?.apiKey?.expiryDate) {
      var t: IOrganizationApiForm = { apiEnd: client?.apiKey?.expiryDate, customerClientId: parseInt(id) }
      setFormValues(new OrganizationApiFormValues(t))
    }
  }, [client])

  const createApiKey = async (values: OrganizationApiFormValues) => {
    DevLog("createApiKey values", values);
    var response = await createNewApiKeyForClient(values);
    if (response) {
      var blob = new Blob([`API-key ${client?.name}: ${response.apiKey}`], { type: "text/plain;charset=utf-8;" });
      FileSaver.saveAs(blob, `API-key ${client?.name}.txt`);
    }
  }

  return (
    <>
      <Header as="h4" attached>API access</Header>
      <Segment attached>
        <span>
          <Formik
            enableReinitialize
            initialValues={values}
            onSubmit={createApiKey}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column width={5}>
                      <Field
                        component={DatePicker}
                        label="Vervaldatum"
                        name="apiEnd"
                        activationDate={formik.values.apiEnd}
                      />
                    </Grid.Column>
                    <Grid.Column width={5}>
                      <label>API-KEY</label>
                      <Button
                        style={{ height: "3em" }}
                        labelPosition="left"
                        basic fluid
                        icon
                        type="button"
                        disabled={submitting}
                        onClick={() => {
                          if (client?.apiKey?.expiryDate !== undefined) {
                            openConfirmModal(t("Button.Cancel"),
                              t("Confirm_Modal.Create_ApiKey"),
                              t("Confirm_Modal.Delete_Api_Key"),
                              async () => {
                                await createApiKey(formik.values);
                              }
                            )
                          } else {
                            createApiKey(formik.values);
                          }
                        }
                        }>
                        <Icon name="add" />
                        Genereer nieuwe API-Key
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </Formik>
        </span>
      </Segment>
    </>
  )
})