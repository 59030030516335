import axios from 'axios'
import { Button, Segment } from 'semantic-ui-react'
import { DevLog } from '../../utils/DevLog'
import { renderToStaticMarkup } from 'react-dom/server'
import { StateValue } from '../Context'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
// import { deleteCookie } from "../../utils/ReadWriteCookie";

export const SuccessMobile = (props: any) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const [didPost, setDidPost] = useState(false);

  useEffect(() => {
    axios.post(`/api/frontend/completed/?ctxId=${state.ctxId}`).then((res) => {
      DevLog(res);
      if (res.status == 200 || res.status == 204) {
        setDidPost(true);
        localStorage.clear();
      }
    });
  }, [])

  // const complete = () => {
  //   if (didRedirect) {
  //     window.location.href = state.targetUrl;
  //   } else {
  //     axios.post(`/api/frontend/completed/?ctxId=${state.ctxId}`).then((res) => {
  //       DevLog(res);
  //       if (res.status == 200) {
  //         var ctxId = state.ctxId;
  //         localStorage.clear();
  //         deleteCookie("X-Api-Key");
  //         deleteCookie("ctxId");
  //         deleteCookie("invitationCode");
  //         if (state.targetUrl.length < 1) {
  //           window.close();
  //         } else {
  //           setDidRedirect(true);
  //           window.location.href = state.targetUrl;
  //         }
  //       }
  //     });
  //   }
  // };

  const background = state.org.theme.config.successSvg ? encodeURIComponent(renderToStaticMarkup(<state.org.theme.config.successSvg fill={state.org.theme.themeColor} />)) : null;

  return (
    <>
      <div className="transition-container" style={{ background: `url("data:image/svg+xml,${background}")`, backgroundSize: "contain", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat", flex: 1 }} id="success-animation">
        <span>
          <h1 className="service-item-header"> {t("Success.Title")} </h1>
          <h2 className="service-item-subheader">
            {t("Success.Subtitle")}
          </h2>
          <h3 className="service-item-explanation">
            {state.targetUrl.length > 1 ?
              `${t("Success.Submit")}${t("Success.Submit_2")}` : `${t("Success.Submit")}${t("Success.Submit_2_Close")}`
            }
          </h3>
        </span>
      </div>
      <Segment basic className="service-item-button-container  mt-0">
        <Button href={state.targetUrl} loading={!didPost} disabled={!didPost} color="green" floated="right" type="submit"> {t("Success.Submit_Button")} </Button>
      </Segment>
    </>
  )
}