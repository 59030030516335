import { DashboardHome } from "../../pages/Secure/dashboard/DashboardHome";
import { IRoute } from "../../models/routes/IRoute";
import { Profile } from "../../pages/Secure/users/account/Profile";
import { Roles } from "../../Enums/Roles";

export const GeneralRoutes: IRoute[] = [
  {
    component: DashboardHome,
    path: "",
    title: "Navbar.Dashboard",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.CUSTOMERMANAGER, Roles.DTSMANAGER, Roles.CLIENTMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: Profile,
    path: "/profile",
    title: "Navbar.Profile",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.CUSTOMERMANAGER, Roles.DTSMANAGER, Roles.CLIENTMANAGER],
    dropdown: false,
    childs: []
  },
]