import { Button, Form, Header, Segment } from "semantic-ui-react";
import { ClientCreateEditDto } from "../../../Types";
import { DropDown } from "../../Common/form/Dropdown";
import { Field, Formik } from "formik";
import { IOrganizationForm, OrganizationFormValues } from "../../../models/organization/IOrganization";
import { Roles } from "../../../Enums/Roles";
import { RootStoreContext } from "../../../stores/RootStore";
import { TextInput } from "../../Common/form/TextInput";
import { history, routePrefixDashboard } from "../../../ApplicationRouter";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ValidationSchema } from "./OrganizationDescription.Validation";
var FileSaver = require('file-saver');

interface IProps {
  id?: string;
  client?: ClientCreateEditDto;
}

type dropDownOptions = {
  key: string | number;
  value: any;
  text: string;
};

export const OrganizationDescriptionForm: React.FC<IProps> = observer(({ id, client }) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { authorizationStore } = rootStore;
  const { getAllCustomers, createClient, updateClient, submitting } = rootStore.customerClientStore;

  const [formValues, setFormValues] = useState(new OrganizationFormValues());
  const [orgOptions, setOrgOptions] = useState<dropDownOptions[]>([]);
  const isAdmin = authorizationStore.isInRole(Roles.ADMIN);

  useEffect(() => {
    const getCustomers = async () => {
      var customers = await getAllCustomers();
      if (customers) {
        setOrgOptions(customers.map((x) => ({ key: x.id, text: `${x.name} id: ${x.id}`, value: x.id })));
      }
    }
    getCustomers();
  }, [])

  useEffect(() => {
    if (client) {
      var value: IOrganizationForm = {
        id: id === undefined ? undefined : parseInt(id),
        name: client.name,
        target: client.target,
        api: client.apiKey?.apiKeyId?.toString() ?? "",
        customerId: client.customerId ?? 0,
        resultCallbackBaseURLDev: client.resultCallbackBaseURLDev ?? "",
        resultCallbackBaseURLAcc: client.resultCallbackBaseURLAcc ?? "",
        resultCallbackBaseURLProd: client.resultCallbackBaseURLProd ?? "",
        resultCallbackEndpoint: client.resultCallbackEndpoint ?? "",
        // apiEnd: client.apiKey?.expiryDate?.slice(0, 10) ?? ""
      };
      setFormValues(new OrganizationFormValues(value));
    }
  }, [client])

  const handleSubmit = async (values: OrganizationFormValues) => {
    if (id !== undefined) {
      // TODO: edit
      await updateClient(values);
    } else {
      var response = await createClient(values);
      if (response) {
        var blob = new Blob([`API-key ${values.name}: ${response.apiKey?.apiKey}`], { type: "text/plain;charset=utf-8;" });
        FileSaver.saveAs(blob, `API-key ${values.name}.txt`);
        history.push(`${routePrefixDashboard}/organization/${response.customerClientId}`);
      }
    }
  }

  return (
    <>
      <Header as="h4" attached="top">Informatie</Header>
      <Segment attached>
        <Formik
          initialValues={formValues}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={ValidationSchema}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Field
                component={TextInput}
                label="Naam"
                name="name"
                id="name"
                placeholder="Naam"
              />
              <Field
                component={TextInput}
                label="Development callback URL"
                name="resultCallbackBaseURLDev"
                id="resultCallbackBaseURLDev"
                placeholder="https://www.google.com"
              />
              <Field
                component={TextInput}
                label="Acceptance callback URL"
                name="resultCallbackBaseURLAcc"
                id="resultCallbackBaseURLAcc"
                placeholder="https://www.google.com"
              />
              <Field
                component={TextInput}
                label="Production callback URL"
                name="resultCallbackBaseURLProd"
                id="resultCallbackBaseURLProd"
                placeholder="https://www.google.com"
              />
              <Field
                component={TextInput}
                label="Callback endpoint"
                name="resultCallbackEndpoint"
                id="resultCallbackEndpoint"
                placeholder="Callback endpoint"
              />
              <Field
                component={TextInput}
                label="Target"
                name="target"
                id="target"
                placeholder="https://www.google.com"
              />
              <Field
                component={DropDown}
                disabled={(id != undefined && id !== "0") || !isAdmin}
                label="Beheerd door"
                name="customerId"
                id="customerId"
                value={formik.values.customerId}
                options={orgOptions.map((x) => {
                  return {
                    key: x.value,
                    value: x.value,
                    text: x.text
                  }
                })}
              />
              <Button type="submit" disabled={submitting} primary>{t("Button.Save")}</Button>
            </Form>
          )}
        </Formik>
      </Segment>
    </>
  )
})