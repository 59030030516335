import MitekCheckSuccessComponent from '../Mitek/Views/Information/MitekCheckSuccessComponent'
import UtilityBillComponent from '../UtilityBill/UtilityBillComponent'
import axios from 'axios'
import useInterval from '../../../Hooks/useInterval';
import { AnimationCheck } from '../../AnimationViews/AnimationCheck';
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess';
import { Button, Loader, Progress, Segment } from 'semantic-ui-react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { DevLog } from '../../../utils/DevLog';
import { EService } from '../../../Enums/EService';
import { MBUAppendMissingProperties } from './MBUAppendMissingPropertiesComponent'
import { MitekCheckComponent } from '../Mitek/MitekCheckComponent'
import { StateDispatch, StateValue } from '../../Context'
import { isDesktop } from 'react-device-detect';
import { useContext, useEffect, useState } from 'react'
import { useIsInitialRender } from '../../../Hooks/useIsInitialRender';
import { useTranslation } from 'react-i18next';

export type MBUView = "MITEK" | "UTILITYBILL" | "SUCCESS" | "SUCCESS-HANDOFF" | "LOADING-DATA" | "ERROR" | "LOADING"
export type MBUStatus = {
  lastFinished: EService,
  utilityBillNeeded?: boolean
}

const MBUCheckComponent = (props: any) => {
  const state = useContext(StateValue);
  const dispatch = useContext(StateDispatch);

  const [view, setView] = useState<MBUView>("LOADING");
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const [pollInfo, setPollInfo] = useState<{ count: number, percent: number }>({ count: 0, percent: 0 });
  const [didError, setDidError] = useState(false);

  const { handoff } = props.props.match.params;

  const isInitialRender = useIsInitialRender();

  useEffect(() => {
    if (didError) {
      setView("ERROR");
    }
  }, [didError])

  // Reset state on error
  useEffect(() => {
    if (view === "ERROR") {
      setTimeout(() => {
        setView("MITEK");
        setDidError(false);
        setPollInfo({ count: 0, percent: 0 });
      }, 1000);
    }
  }, [view])

  const mitekFinishedCallback = () => {
    getStatus(false);
  }

  useEffect(() => {
    if (!isInitialRender) {
      dispatch({ type: "setCurrent", data: { ...state.current, identityService: { ...state.current.identityService, useLayout: isDesktop || (view !== "MITEK" && !handoff) } } })
    }
  }, [view])


  // Get status on mount only
  useEffect(() => {
    if (isInitialRender) {
      getStatus(false);
    }
  }, [])

  // Poll for status change every second (when in LOADING-DATA view)
  useInterval(() => {
    DevLog(pollInfo);
    if (view === "LOADING-DATA") {
      if (pollInfo.count < 10) {
        getStatus(true).then(res => {
          if (!res) throw new Error("No response");
          switch (res.lastFinished) {
            case EService.MITEKCHECK:
              setPollInfo({ percent: Math.min((pollInfo.percent + Math.random() * 25), 100, 101), count: pollInfo.count + 1 });
              break;
            case EService.BIGCHECK:
              if (res.utilityBillNeeded === true) {
                if (handoff === undefined) {
                  setPollInfo({ percent: 100, count: pollInfo.count + 1 });
                  dispatch({ type: "setCurrent", data: { ...state.current, identityService: { ...state.current.identityService!, combinationCheckCompleted: 1, combinationCheckTotal: 2 } } });
                  setTimeout(() => {
                    setView("UTILITYBILL");
                  }, 1000);
                } else {
                  setView("SUCCESS-HANDOFF");
                }
              } else if (res.utilityBillNeeded === false) {
                setPollInfo({ percent: 100, count: pollInfo.count + 1 });
                setTimeout(() => {
                  setView("SUCCESS");
                }, 1000);

              }
              break;

            case EService.MBUCHECK:
            case EService.UTILITYBILLCHECK:
              if (handoff === undefined) {
                dispatch({ data: state.flow[state.current.order + 1], type: "setCurrent" })
                setView("SUCCESS");
              } else {
                setView("SUCCESS-HANDOFF")
              }
              break;
          }
        });
      } else {
        setView("ERROR");
      }
    }
  }, 4000);

  const getStatus = async (resOnly: boolean) => {
    const res = await axios.get<MBUStatus>(`/api/MBUCheck/status?ctxId=${state.ctxId}`);
    if (resOnly) return res.data; // TODO: Change this to make more sense
    switch (res.data.lastFinished) {
      case EService.MITEKCHECK:
        setView("LOADING-DATA");
        break;
      case EService.BIGCHECK:
        if (res.data.utilityBillNeeded === true) {
          if (handoff === undefined) {
            dispatch({ type: "setCurrent", data: { ...state.current, identityService: { ...state.current.identityService!, combinationCheckCompleted: 1, combinationCheckTotal: 2 } } });
            setView("UTILITYBILL");
          } else {
            setView("SUCCESS-HANDOFF");
          }
        } else if (res.data.utilityBillNeeded === false) {
          setView("SUCCESS");
        }
        break;

      case EService.MBUCHECK:
      case EService.UTILITYBILLCHECK:
        if (handoff === undefined) {
          dispatch({ data: state.flow[state.current.order + 1], type: "setCurrent" })
          setView("SUCCESS");
        } else {
          setView("SUCCESS-HANDOFF")
        }
        break;

      default:
        setView("MITEK");
    }
  }

  const renderView = () => {
    switch (view) {
      case "LOADING":
        return <>
          <Segment basic style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Loader inverted={isDesktop} active> {t("General_UI.Loading")} </Loader>
          </Segment>
        </>
      case "ERROR":
        return <h1>Unknown Error</h1>;
      case "LOADING-DATA":
        return <>
          <AnimationCheck header="General_UI.Loading" text="General_UI.Loading" key={`${state.view}-success`} />
          <Segment basic className="service-item-button-container mt-0">
            <Progress style={{ width: "100%" }} percent={pollInfo.percent} indicating />
          </Segment>
        </>;
      case "MITEK":
        return <MitekCheckComponent combinedCheckProps={{ service: 15, component: MBUAppendMissingProperties, callback: () => { mitekFinishedCallback() } }} {...props} />
      case "UTILITYBILL":
        return <UtilityBillComponent />
      case "SUCCESS":
        return (<>
          <AnimationSuccess header="General_UI.Great" text="General_UI.Correct" key={`${state.view}-success`} />
          <Segment basic className="service-item-button-container mt-0">
            <Button color="green" floated="right" type="submit" onClick={() => { dispatch({ data: state.flow[state.current.order + 1], type: "setCurrent" }) }}>
              {t("General_UI.Next")}
            </Button>
          </Segment>
        </>)
      case "SUCCESS-HANDOFF":
        return <>
          <Segment.Group className="containersegment" horizontal={false}>
            <Segment.Group className="mitek" style={{ height: "100%", width: "100%", margin: 0 }} basic horizontal={false}>
              <SwitchTransition mode="out-in">
                <CSSTransition
                  key={`${0}-trans`}
                  addEndListener={(node, done) => {
                    node.addEventListener("transitionend", done, false);
                  }}
                  classNames="fade"
                >
                  {/* Checks if currentpage is a page that uses the mitekSDK, sets display accordingly. */}
                  <div className="transition-container" style={{ height: "100%", flexDirection: "column", display: "flex" }}>
                    <MitekCheckSuccessComponent onSuccess={() => null} props={props.props} state={state} />
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </Segment.Group>
          </Segment.Group>
        </>
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          {renderView()}
        </CSSTransition>
      </SwitchTransition>
    </>
  );
}
export default MBUCheckComponent