import { DevLog } from '../../../utils/DevLog';
import { SidebarProps } from '../../../Types'
import { StateValue } from '../../Context';
import { Step, Icon } from 'semantic-ui-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next';

const SidebarMBU = (props: SidebarProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const { combinationCheckCompleted } = props.flowItem.identityService!;

  const mitekCheckSidebar = <>
    <Step completed={state.current.order > props.flowItem.order || props.flowItem.identityService?.combinationCheckCompleted! > 0} active={props.flowItem.order === state.current.order && combinationCheckCompleted! === 0} key="mbus-1">
      {!props.mediumScreen && <Icon name="user" className={`${props.flowItem.order > state.current.order ? "sidebar-icon-completed" : "sidebar-icon"}`} />}
      <Step.Content className="sidebar-header-cont">
        <Step.Title className="sidebar-header">{t("Mitek.IdentityCheck")}</Step.Title>
        <Step.Description className="sidebar-subheader">{t("Mitek.Verify_Document")}</Step.Description>
      </Step.Content>
    </Step>
  </>

  const utilityBillSidebar = <>
    <Step completed={state.current.order > props.flowItem.order} active={props.flowItem.order === state.current.order && combinationCheckCompleted! === 1} key="mbus-2">
      {!props.mediumScreen && <Icon name="file alternate outline" className={`${props.flowItem.order > state.current.order ? "sidebar-icon-completed" : "sidebar-icon"}`} />}
      <Step.Content className="sidebar-header-cont">
        <Step.Title className="sidebar-header">{t("UtilityBill.Title")}</Step.Title>
        <Step.Description className="sidebar-subheader">{t("UtilityBill.Verify_Address")}</Step.Description>
      </Step.Content>
    </Step>
  </>

  DevLog(`total ${props.flowItem.identityService?.combinationCheckTotal}`);
  DevLog(`completed ${props.flowItem.identityService?.combinationCheckCompleted}`);
  DevLog('re-rendering MBU SIBDEBAR');

  const renderSteps = () => {
    let res = [mitekCheckSidebar];

    if (props.flowItem.identityService?.combinationCheckTotal === 2 || state.current.order > props.flowItem.order) {
      res.push(utilityBillSidebar);
    }

    return <> {res} </>;
  }

  return renderSteps()
}

export default SidebarMBU
