import { ELogType } from '../../../Enums/ELogType';
import { EOCRLabsPageType } from '../../../Types/OCRLabs/Enums/EOCRLabsPageType';
import { EService } from '../../../Enums/EService';
import { IOcrLabsAppendMissingPropertiesResponse, IOcrLabsResponse, OCRLabsRequestBuilder } from '../../../Types';
import { IOcrLabsSettings } from '../../../models/flow/settings/IOcrLabsSettings';
import { Loader } from 'semantic-ui-react';
import { OCRLabsContext } from './useOCR';
import { OCRLabsCurrentPage } from '../../../Types/OCRLabs/OCRLabsCurrentPage';
import { RenderOCRPage } from './OCRCheckMap';
import { StateDispatch, StateValue } from '../../Context';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { getOcrLabsSettings, postTransactionProgressLog } from '../../../Services/Backend';
import { isDesktop } from 'react-device-detect';
import { useContext, useEffect, useState } from 'react'

interface OCRLabsCheckComponentProps {
  onPropertiesValidated?: (response: IOcrLabsAppendMissingPropertiesResponse) => void;
  onDocumentValidated?: (e: any) => (e: any) => void;
  combinationService?: EService;
}

export const OCRLabsCheckComponent = (props: OCRLabsCheckComponentProps) => {
  const state = useContext(StateValue);
  const dispatch = useContext(StateDispatch);
  const documentValidated = (sessionStorage.getItem("ocr-document-validated") == "true");
  const [currentPage, setCurrentPage] = useState<OCRLabsCurrentPage | undefined>(undefined);
  const [onDocumentValidated, setOnDocumentValidated] = useState<((e: any) => (e: any) => void) | undefined>(props.onDocumentValidated);

  const [onPropertiesValidated, setOnPropertiesValidated] = useState<((response: IOcrLabsAppendMissingPropertiesResponse) => void) | undefined>(props.onPropertiesValidated);
  const [settings, setSettings] = useState<IOcrLabsSettings | undefined>();
  const [request, setRequest] = useState<OCRLabsRequestBuilder>({ front: undefined, back: undefined });
  const [response, setResponse] = useState<IOcrLabsResponse | undefined>();

  const handleSetOnDocumentValidated = (func: any) => {
    setOnDocumentValidated(func);
  }

  const handleSetOnPropertiesValidated = (e: any) => {
    setOnPropertiesValidated(e);
  }

  useEffect(() => {
    // Fetch settings and flow to complete on load.
    if (!documentValidated && !isDesktop) {
      postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckEntered })
    }
    initFetch();
  }, []);

  const fetchSettings = async () => {
    var response = await getOcrLabsSettings(state.ctxId, EService.OCRLABSCHECK);
    if (response.status === 200) setSettings(response.data);
    return response;
  }

  const initFetch = async () => {
    const isHandoff = window.localStorage.getItem("handoff") == "true";
    // If we've already completed the check in this session, we don't need to do it again.
    if (documentValidated) {
      console.log("document validated, skipping");
      setCurrentPage({
        page: EOCRLabsPageType.Success,
        data: {
          headerTranslationKey: `OCR_Labs.Success.Header`,
          subHeaderTranslationKey: `OCR_Labs.Success.SubHeader${isHandoff ? "_Handoff" : ""}`,
        },
        renderButtonContainer: false
      })
      return;
    }

    // If we get here through a mobile -> desktop handoff, we set the onDocumentValidated callback to go to the "success" page.
    if (isHandoff) {
      setOnDocumentValidated(() => (data: any) => {
        console.log(data)
        setCurrentPage({
          page: EOCRLabsPageType.Success,
          data: {
            headerTranslationKey: "OCR_Labs.Success.Header",
            subHeaderTranslationKey: "OCR_Labs.Success.SubHeader_Handoff",
          },
          renderButtonContainer: false
        })
      });
    }

    // Fetch the settings and flow we need to complete, if either fail, we show the error page.
    const settingsResult = await fetchSettings();
    if (settingsResult) {
      if (isDesktop) {
        setCurrentPage({
          page: EOCRLabsPageType.Information_Desktop,
          data: {
            settings: settingsResult.data,
            onCallback: () => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }),
            headerMessageKey: "OCR_Labs.Information.Header",
            subHeaderMessageKey: "OCR_Labs.Information.SubHeader",
          },
          renderButtonContainer: false
        });
      } else {
        setCurrentPage({
          page: EOCRLabsPageType.Information,
          renderButtonContainer: false,
          data: {
            headerMessageKey: "OCR_Labs.Information.Header",
            subHeaderMessageKey: "OCR_Labs.Information.SubHeader",
            onCallback: () => setCurrentPage({
              page: EOCRLabsPageType.TakePicture,
              data: {
                isBackside: false,
              },
              renderButtonContainer: false
            })
          }
        });
      }
    } else {
      let errors = [];
      errors.push("OCR_Labs.Error.Error_Fetching_Settings");
      setCurrentPage({
        renderButtonContainer: true,
        page: EOCRLabsPageType.Error,
        data: {
          messageKeys: errors,
          headerTranslationKey: "OCR_Labs.Error.Header",
          messageHeaderTranslationKey: "OCR_Labs.Error.SubHeader"
        }
      });
    }
  }

  if (!currentPage) return <Loader active inline='centered' />
  return (
    <OCRLabsContext.Provider value={{ currentPage, setPage: setCurrentPage, settings, setSettings, request, setRequest, response, setResponse, onPropertiesValidated: onPropertiesValidated as any, onDocumentValidated, setOnDocumentValidated: handleSetOnDocumentValidated, setOnPropertiesValidated: handleSetOnPropertiesValidated, combinationService: props.combinationService }}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${currentPage.page}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container">
            {settings || documentValidated ? RenderOCRPage(currentPage) : <Loader active inline='centered' />}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </OCRLabsContext.Provider>
  )
}