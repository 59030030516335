import { SidebarProps } from '../../../Types'
import { StateValue } from '../../Context';
import { Step, Icon } from 'semantic-ui-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next';

const SidebarIdinAge = (props: SidebarProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  return (
    <Step completed={state.current.order > props.flowItem.order} active={props.flowItem.order === state.current.order}>
      {!props.mediumScreen && <Icon name="user" className={`${props.flowItem.order > state.current.order ? "sidebar-icon-completed" : "sidebar-icon"}`} />}
      <Step.Content className="sidebar-header-cont">
        <Step.Title className="sidebar-header">{t("IdinAge.Title")}</Step.Title>
        <Step.Description className="sidebar-subheader">{t("IdinAge.Verify_Age")}</Step.Description>
      </Step.Content>
    </Step>
  )
}

export default SidebarIdinAge
