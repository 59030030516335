import { Admins } from "../../pages/Secure/users/admin/Admins";
import { CreateAdmin } from "../../pages/Secure/users/admin/CreateAdmin";
import { EditAdmin } from "../../pages/Secure/users/admin/EditAdmin";
import { IRoute } from "../../models/routes/IRoute";
import { JsonExport } from "../../pages/Secure/jsonExport/JsonExport";
import { Roles } from "../../Enums/Roles";
import { TestLinks } from "../../pages/Secure/testLinks/TestLinks";

export const AdminRoutes: IRoute[] = [
  {
    component: TestLinks,
    path: "/test-links",
    title: "Navbar.TestLinks",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: []
  },
  {
    component: JsonExport,
    path: "/export-json",
    title: "Navbar.ExportJson",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: []
  },
  {
    component: Admins,
    path: "/admins",
    title: "Navbar.Admins",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: []
  },
  {
    component: EditAdmin,
    path: "/admin/edit/:id",
    title: "Navbar.Admins",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
  {
    component: CreateAdmin,
    path: "/admin/new",
    title: "Navbar.Admins",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN],
    dropdown: false,
    childs: [],
  },
]