import { EMitekPageType } from "../../../../Enums/EMitekPageType";
import { IMitekSettings } from "../../../../models/flow/settings/IMitekSettings";
import { MitekAutoCaptureResponse } from "../Types/Types";

export const appendRequestCurrentPageCallback = (currentPage: EMitekPageType, settings: IMitekSettings, type: "PASSPORT" | "ID" | "DRIVERSLICENSE" | "RESIDENCEPERMIT", res: MitekAutoCaptureResponse) => {
  switch (currentPage) {
    case EMitekPageType.SCAN_FRONT:
    case EMitekPageType.MANUAL_CAPTURE_FRONT:
      if (type == "PASSPORT") {
        console.log("res", res);
        console.log("res?.response?.mrz?.countryCode", res?.response?.mrz?.countryCode);
        console.log("res?.response?.mrz?.hasOptionalData", res?.response?.mrz?.hasOptionalData);
        console.log("The IF statement is ", (res?.response?.mrz?.countryCode === "NLD" && !res?.response?.mrz?.hasOptionalData));

        if (res?.response?.mrz?.countryCode === "NLD" && !res?.response?.mrz?.hasOptionalData) return EMitekPageType.EXPLANATION_QR;
        if (settings?.enablePassportSignature) return EMitekPageType.EXPLANATION_SIGNATURE;
        if (settings?.enableSelfie) return EMitekPageType.EXPLANATION_SELFIE;
        return EMitekPageType.GENERAL_SUBMIT;
      }
      return EMitekPageType.EXPLANATION_BACK;
    case EMitekPageType.SCAN_BACK:
    case EMitekPageType.MANUAL_CAPTURE_BACK:
    case EMitekPageType.SCAN_SIGNATURE:
    case EMitekPageType.MANUAL_CAPTURE_SIGNATURE:
    case EMitekPageType.SCAN_QR:
    case EMitekPageType.MANUAL_CAPTURE_QR:
      if (settings?.enableSelfie) {
        return EMitekPageType.EXPLANATION_SELFIE;
      }
      return EMitekPageType.GENERAL_SUBMIT;
    case EMitekPageType.SCAN_SELFIE:
    case EMitekPageType.MANUAL_CAPTURE_SELFIE:
      return EMitekPageType.GENERAL_SUBMIT;
  }
  return currentPage // Shouldn't be possible.
}