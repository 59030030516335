import MitekCheckDocumentOption from '../Information/MitekCheckDocumentOption'
import MitekHeader from '../../MitekHeader'
import { Dispatch, SetStateAction } from 'react'
import { IMitekSettings } from '../../../../../models/flow/settings/IMitekSettings'
import { Segment } from 'semantic-ui-react'
import { state } from '../../../../Context'
import { useTranslation } from 'react-i18next'

export type DocumentSelectionProps = {
  setType: Dispatch<SetStateAction<"PASSPORT" | "ID" | "DRIVERSLICENSE" | "RESIDENCEPERMIT" | undefined>>
  setCurrentPage: (val: number) => void;
  state: state;
  settings: IMitekSettings | undefined;
}

const MitekCheckDocumentSelectionComponent = (props: DocumentSelectionProps) => {
  const { t } = useTranslation(props.state.org.theme.config.translationKey);

  const allowedDocuments = [];
  if (props.settings?.hasPassport) allowedDocuments.push("PASSPORT");
  if (props.settings?.hasId) allowedDocuments.push("ID");
  if (props.settings?.hasDriversLicence) allowedDocuments.push("DRIVERSLICENSE");
  if (props.settings?.hasResidencePermit) allowedDocuments.push("RESIDENCEPERMIT");

  return (
    <>
      <MitekHeader />
      <Segment style={{ height: "100%", width: "100%", marginTop: 0, border: 0 }} id="mitek-options" className="mb-0">
        <h1 className="service-item-header "> {t("Mitek.Choose_Document_Type")} </h1>
        <div id="mitek-options-list">
          {allowedDocuments.map((val) => <MitekCheckDocumentOption type={val} {...props} />)}
        </div>
      </Segment>
    </>
  )
}

export default MitekCheckDocumentSelectionComponent