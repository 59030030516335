import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, Header, Segment } from "semantic-ui-react";
import { ELogType } from "../../../../Enums/ELogType";
import { EOcrLabsErrorType } from "../../../../Enums/EOcrLabsErrorType";
import { postOcrLabsCheck, postTransactionProgressLog } from "../../../../Services/Backend";
import { EOCRLabsPageType } from "../../../../Types/OCRLabs/Enums/EOCRLabsPageType";
import { OCRLabsCurrentPage } from "../../../../Types/OCRLabs/OCRLabsCurrentPage";
import { StateValue } from "../../../Context";
import { OCRLabsContext } from "../useOCR";
import Base64Image from "../../../Common/Base64Image";

export const OCRConfirmAndSubmitPicture = () => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const { request, setPage, onDocumentValidated, settings, setResponse, setRequest } = useContext(OCRLabsContext);
  const isBackside = request.back != undefined;

  const onRetry = (isBackSide: boolean) => {
    console.log("onRetry: " + isBackSide);
    if (!isBackSide) setRequest({ front: undefined, back: undefined });
    setPage({
      page: EOCRLabsPageType.TakePicture,
      data: { isBackside: isBackSide },
      renderButtonContainer: false,
    });
  }

  const onSubmit = async () => {
    const isHandoff = window.localStorage.getItem("handoff") == "true";
    console.log("isHandoff: " + isHandoff);
    setPage({
      page: EOCRLabsPageType.Loading,
      data: {
        headerMessageKey: "OCR_Labs.Loading.Automatic_Refresh_Header" + (isHandoff ? "_Handoff" : ""),
        subHeaderTranslationKey: "OCR_Labs.Loading.Automatic_Refresh_SubHeader",
      },
      renderButtonContainer: false
    })
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckSubmittedDocuments })
    postOcrLabsCheck({ ctxId: state.ctxId, documentFront: request.front, documentBack: request.back })
      .then((response) => {
        let data = response.data;
        setResponse(data);
        // If the document is valid and the backside is not required (or sent already), show the ValidateProperties page.
        if (data.documentValid && !data.isBackSideNeeded && data.errorReasons.length == 0) {
          if (settings?.performFaceAndLiveness) {
            setPage({
              page: EOCRLabsPageType.TakeSelfie,
              data: { hasErrored: false },
            });
            return;
          } else {
            if (onDocumentValidated !== undefined) {
              console.log("onDocumentValidated callback provided, calling it.");
              postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.OCRLabsCheckHandoffCompleted });
              onDocumentValidated(data);
              return;
            } else {
              setPage({
                page: EOCRLabsPageType.ValidateProperties,
                data: {
                  fields: settings!.ocrLabsValidationFields,
                  detectedFields: data.detectedFields,
                },
                renderButtonContainer: false
              });
              return;
            }
          }
        }

        // If the document is valid but we also need the backside, show the TakePicture page again.
        if (data.documentValid && (data.isBackSideNeeded && request.back == undefined)) {
          // If the only thing we're missing is the document number, and the document type is passport, show the RetryOrPassportSignature (QR code) page.
          if (data.missingFields.every(s => s == "documentNumber") && data.documentType == "Passport") {
            setPage({
              page: EOCRLabsPageType.RetryOrPassportSignature,
              renderButtonContainer: false,
            })
            return;
          }

          setPage({
            page: EOCRLabsPageType.TakePicture,
            renderButtonContainer: false,
            data: {
              isBackside: true
            }
          });
          return;
        }

        // If the document is not valid and we need the backside, show the TakePicture page again.
        // TODO: Not sure if this state is valid, we should probably send OCR Labs an email about this.
        if (!data.documentValid && (data.isBackSideNeeded && request.back == undefined)) {
          setPage({
            page: EOCRLabsPageType.TakePicture,
            renderButtonContainer: false,
            data: {
              isBackside: true
            }
          });
          return;
        }

        if (data.errorReasons.length > 0) {
          setPage({
            page: EOCRLabsPageType.Error,
            renderButtonContainer: true,
            data: {
              messageKeys: data.errorReasons.map(s => t(`OCR_Labs.Error.${s}`)),
              headerTranslationKey: "OCR_Labs.Error.Header",
              messageHeaderTranslationKey: "OCR_Labs.Error.SubHeader",
              onCallback: () => onRetry(data.documentValid)
            }
          })
          return;
        }

        // A catch all error, if we get here, something went wrong.
        // The callback (done when user clicks "next" button) will return the user to the take picture page, so they can try again.
        setPage({
          page: EOCRLabsPageType.Error,
          renderButtonContainer: false,
          data: {
            messageKeys: ["OCR_Labs.Error.CatchAll"],
            headerTranslationKey: "OCR_Labs.Error.Header",
            messageHeaderTranslationKey: "OCR_Labs.Error.SubHeader",
            onCallback: () => onRetry(false)
          }
        });
        return;
      })
      .catch((error) => {
        setPage({
          page: EOCRLabsPageType.Error,
          renderButtonContainer: false,
          data: {
            messageKeys: ["OCR_Labs.Error.Header"],
            headerTranslationKey: "OCR_Labs.Error.Header",
            messageHeaderTranslationKey: "OCR_Labs.Error.MessageHeader",
            onCallback: () => onRetry(false)
          }
        });
        return;
      });
  }


  return (
    <div className="ocr space-between ocr-padding">
      <Header as="h1" className="service-item-header">
        {t("OCR_Labs.Confirm.Header")}
        <Header.Subheader>
          {t("OCR_Labs.Confirm.SubHeader")}
        </Header.Subheader>
      </Header>
      <div style={{ flex: 1 }}>
        <Base64Image base64Image={(isBackside ? request.back : request.front) ?? ""} />
      </div>
      <Segment basic id="stacked-buttons" className="service-item-button-container mt-0">
        <Button id="retry-button" color="green" floated='right' onClick={() => onRetry(isBackside)}>
          {t("General_UI.Back")}
        </Button>
        <Button color="green" floated='right' onClick={onSubmit}>
          {t("General_UI.Submit")}
        </Button>
      </Segment>
    </div>
  )
}
