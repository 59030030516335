import ClosePageComponent from './Components/ClosePage/ClosePageComponent'
import MitekHandoff from './Components/Services/Mitek/MitekHandoff'
import PrivateRoutes from './routes/PrivateRoutes'
import { createBrowserHistory } from 'history'
import { Login } from './pages/login/Login'
import { LogOut } from './pages/login/Logout'
import { MainComponent } from './Components/MainComponent'
import { observer } from 'mobx-react-lite'
import { ResultDevSession } from './Components/Dev/ResultDevSession'
import { Route, Router, Switch } from 'react-router-dom'
import { StartDevSession } from './Components/Dev/StartDevSession'
import { OCRHandoff } from './Components/Services/OCR/OCRHandoff'

export const history = createBrowserHistory();

export const routePrefixDashboard = "/dashboard";

interface IProps {
  isLandscape: any;
  isPortrait: any;
}

export const ApplicationRouter = observer((props: IProps) => {

  const { isLandscape, isPortrait } = props;

  return (
    <Router history={history}>
      <Switch>
        <Route path="/login" exact component={Login} />
        <Route path="/logout" exact component={LogOut} />
        <Route path="/close" exact component={ClosePageComponent} />
        <Route path="/start-dev-session/:organization" exact component={StartDevSession} />
        <Route path="/result-dev-session/:ctxId" exact component={ResultDevSession} />
        <Route path={routePrefixDashboard}>
          <PrivateRoutes path={routePrefixDashboard} />
        </Route>

        <Route exact path="/:ctxId/:lang" render={
          (props) => <MainComponent match={props.match} history={props.history} isLandscape={isLandscape} isPortait={isPortrait} />
        } />
        <Route exact path="/:ctxId/:lang/:handoff" component={MainComponent} />
        <Route exact path="/:ctxId/:lang/:handoff/:service" component={MainComponent} />
        <Route path="/handoff" component={MitekHandoff} />
        <Route path="/handoff-ocr" component={OCRHandoff} />
        <Route exact path="/" component={MainComponent} />
      </Switch>
    </Router>
  )
})