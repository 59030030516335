import { ButtonGroup, Segment } from 'semantic-ui-react';

export type ButtonGroupProps = {
  buttons: JSX.Element;
}

const BottomButtonGroup = (props: ButtonGroupProps) => {
  return (
    <Segment id="mitek-button-group" className="mitek" style={{ bottom: 0, zIndex: 1000, position: "absolute", height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", opacity: ".85" }} size="small">
      <ButtonGroup basic widths="3">
        {props.buttons}
      </ButtonGroup>
    </Segment>
  )
}

export default BottomButtonGroup