import { countries } from 'countries-list'
import OTPInput from 'otp-input-react'
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { isDesktop } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Button, Dropdown, DropdownItemProps, Form, Icon, Message, Popup, Segment } from 'semantic-ui-react'

import { ELogType } from '../../../Enums/ELogType'
import { useDebounce } from '../../../Hooks/useDebounce'
import { useView } from '../../../Hooks/useView'
import { postEmailVerificationCheckCode, postEmailVerificationCheckEmail, postTransactionProgressLog } from '../../../Services/Backend'
import { AnimationCheck } from '../../AnimationViews/AnimationCheck'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { TimerButton } from '../../Common/Button/TimerButton'
import { StateDispatch, StateValue } from '../../Context'

export const EmailVerificationCheckComponent: FunctionComponent = () => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const dispatch = useContext(StateDispatch);
  const [view, setView] = useView<"form-email" | "form-code" | "checking" | "success">("form-email");

  const [email, setEmail] = useState("");
  const debouncedEmail: string = useDebounce<string>(email, 500);
  const isEmailError = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return debouncedEmail === "" ? false : !emailRegex.test(debouncedEmail);
  }, [debouncedEmail]);
  const [countryCode, setCountryCode] = useState("+31");
  const [phoneNumber, setPhoneNumber] = useState("");
  const debouncedPhoneNumber: string = useDebounce<string>(phoneNumber, 500);
  const isPhoneError = useMemo(() => {
    if (countryCode === "+31") {
      return debouncedPhoneNumber === "" ? false : /[a-zA-Z]+/g.test(debouncedPhoneNumber) || debouncedPhoneNumber.length !== 10;
    } else {
      return debouncedPhoneNumber === "" ? false : /[a-zA-Z]+/g.test(debouncedPhoneNumber);
    }
  }, [debouncedPhoneNumber, countryCode]);


  const [code, setCode] = useState("");
  const [error, setError] = useState(false);

  const countryDropDownOptions = useMemo(() =>
    Object.keys(countries).map(c => ({
      key: (countries as any)[c].name,
      value: (countries as any)[c].phone,
      text: `+${(countries as any)[c].phone}`,
      searchText: `${(countries as any)[c].name} ${(countries as any)[c].phone} 00${(countries as any)[c].phone} ${(countries as any)[c].native}`,
      flag: c.toLowerCase()
    })), []
  );


  const searchFunc = (options: any, query: any) => {
    return options.filter((option: { searchText: string }) =>
      option.searchText.toLowerCase().includes(query.toLowerCase())
    );
  };

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.Email_VerificationCheckEntered });
  }, [])

  const postBackendEmail = () => {
    setView("checking");
    postEmailVerificationCheckEmail(state.ctxId, email, `${countryCode}${phoneNumber}`).then(res => {
      if (res.status === 200) {
        setView("form-code");
      } else {
        setView("form-email");
        setError(true);
      }
    }).catch(e => {
      setView("form-email");
    })
  }

  const postBackendCode = () => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.Email_VerificationCodeEntered });
    setView("checking");
    postEmailVerificationCheckCode(state.ctxId, code.toUpperCase()).then(res => {
      if (res.data.success) {
        setView("success");
        postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.Email_VerificationCheckCompleted });
      } else {
        setView("form-email");
        setError(true);
        setCode("");
      }
    }).catch(e => {
      setView("form-email");
    })
  }

  const onSuccess = () => {
    dispatch({ type: "setView", data: "form" });
    setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
  }

  const handleChange = (e: any) => {
    switch (e?.target?.name) {
      case "email":
        setEmail(e.target.value);
        break;
      case "code":
        setCode(e.target.value);
        break;
      case "phoneNumber": {
        const numericValue = e.target.value.replace(/[^0-9]/g, '');
        setPhoneNumber(numericValue);
        break;
      }
      default:
        setCode(e);
        break;
    }
  }

  const renderView = () => {
    switch (view) {
      case "form-email":
        return (
          <span id="emailverification-email-container">
            <h1 className="service-item-header">{t("Email_Verification.Header_Email")}</h1>
            <h2 className="service-item-subheader"><ReactMarkdown skipHtml>{t("Email_Verification.SubHeader_Email")!}</ReactMarkdown></h2>
            <span className={`service-item-input-group`}>
              <Form.Input value={email} name="email" label={t("Email_Verification.Email")} placeholder={t("Email_Verification.Email")} onChange={handleChange} fluid error={isEmailError ? { content: t("Email_Verification.Email_Error"), color: "red" } : undefined} />
              <div style={{ width: "100%", display: "flex", flexDirection: isDesktop ? "row" : "column" }}>
                <div style={{ flex: isDesktop ? 0.4 : 1, marginRight: isDesktop ? "1em" : "0em" }} className="field">
                  <label> ‎</label>
                  <Dropdown
                    placeholder='Select Country'
                    fluid
                    search={searchFunc}
                    selection
                    defaultValue={countries["NL"].phone}
                    onChange={(e, data) => setCountryCode(`+${data.value}`)}
                    options={countryDropDownOptions}
                  />
                </div>
                <div style={{ flex: 1 }}>
                  <Form.Input
                    type="text"
                    value={phoneNumber}
                    name="phoneNumber"
                    label={t("Email_Verification.PhoneNumber_Placeholder")}
                    placeholder={t("Email_Verification.PhoneNumber_Placeholder")}
                    onChange={handleChange}
                    fluid
                    error={isPhoneError ? { content: t("Email_Verification.PhoneNumber_Error"), pointing: 'above', color: "red" } : undefined}
                  />

                </div>
              </div>
              {error && <Message warning className='ui red pointing label'>{t("Email_Verification.SubHeader_Email_Retry")}</Message>}
            </span>
          </span>
        )
      case "form-code":
        return (
          <span id="emailverification-code-container" style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            <h1 className="service-item-header">{t("Email_Verification.Header_Code")}</h1>
            <h2 className="service-item-subheader"><ReactMarkdown skipHtml>{t("Email_Verification.SubHeader_Code", { email: email })!}</ReactMarkdown></h2>
            <span className={`service-item-input-group otp-email`} style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <OTPInput value={code} onChange={handleChange} autoFocus OTPLength={4} otpType="alphanumeric" disabled={false} />
            </span>
          </span>
        );
      case "checking":
        return <AnimationCheck header="General_UI.Verifying" text="General_UI.One_Moment" key={`${view}-check`} svgComponent={state.org.theme.config.ibanCheckSvg && <state.org.theme.config.ibanCheckSvg fill={state.org.theme.themeColor} />} />
      case "success":
        return <AnimationSuccess header="General_UI.Great" text="General_UI.Correct" key={`${view}-success`} />
    }
  }

  const renderButton = () => {
    switch (view) {
      case "form-email":
        return (
          <Button color="green" floated="right" type="submit" onClick={() => postBackendEmail()} disabled={phoneNumber === "" || email === "" || isPhoneError === true}>
            {t("General_UI.Next")}
          </Button>
        )
      case "form-code":
        return (
          <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Button color="green" floated="right" type="submit" style={{ marginBottom: "1.5em" }} onClick={() => postBackendCode()} disabled={code.length !== 4}>
              {t("Email_Verification.Verify")}
            </Button>

            <label>{t("Email_Verification.Email_Retry")} <a onClick={() => { setCode(""); setView("form-email") }}>{t("Email_Verification.Send_Again")}</a></label>
            <label className='mt-4'>
              <Popup trigger={<Icon name='info circle' />} content={<> <p>{t("Email_Verification.Contact_Support_Info")}</p> </>} size='large' position='top right' />
              {t("Email_Verification.Contact_Support")}<a target='_blank' href={t("Email_Verification.Contact_Support_Link_Url")}>{t("Email_Verification.Contact_Support_Link")}</a>
            </label>
          </div>
        )
      case "checking":
        return (
          <Button color="green" floated="right" type="submit" loading>
            {t("General_UI.Loading")}
          </Button>
        )
      case "success":
        return (
          <TimerButton countDownStart={state.org.theme.config.countDownStart ?? 5} callback={onSuccess} />
        )
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container">
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Segment basic className="email-verification service-item-button-container mt-0">
        {
          renderButton()
        }
      </Segment>
    </>
  );
};
