import useInterval from '../../../Hooks/useInterval'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { AxiosResponse } from 'axios'
import { Button, Container, Header, Icon, Modal, Popup, Segment } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { ELogType } from '../../../Enums/ELogType'
import { EMitekPageType } from '../../../Enums/EMitekPageType'
import { EService } from '../../../Enums/EService'
import { ETransactionStatusCode } from '../../../Enums/ETransactionStatusCode'
import { getDidMitekFinish, postTransactionProgressLog } from '../../../Services/Backend'
import { IMitekBaseResponse, MitekNategisIdinDidFinishDto } from '../../../Types'
import { isLastCheck } from '../../../utils/isLastCheck'
import { renderToStaticMarkup } from 'react-dom/server'
import { StateDispatch, StateValue } from '../../Context'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useView } from '../../../Hooks/useView'
import { QRCodeCanvas } from 'qrcode.react';
import './View.scss'

type MitekCheckDesktopProps = {
  ctxId: string;
  lang: string;
  logo: string;
  setResponse: (res: IMitekBaseResponse) => void;
  combinedCheckProps?: any;
  setPageCallback: (page: EMitekPageType) => void;
}

export default function MitekCheckDesktop(props: MitekCheckDesktopProps) {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const [res, setRes] = useState<MitekNategisIdinDidFinishDto | undefined>(undefined);
  const [open, setOpen] = useState(false)
  const [view, setView] = useView<"info-update" | "info-start" | "success" | "append-missing-characters">("info-start");

  const dispatch = useContext(StateDispatch);

  const [count, setCount] = useState(0);

  const onSuccess = () => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.MitekCheckCompleted });

    if (state.current.identityService?.service == EService.MNICHECK) {
      if (res?.idinNeeded) {
        setTimeout(() => props.combinedCheckProps.callback("idin", res.idinNeeded), 700);
      } else {
        setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
      }
    } else if (state.current.identityService?.service == EService.MBUCHECK) {
      props.combinedCheckProps.callback();
    } else {
      setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
    }
  }

  useInterval(() => {
    setCount(count + 1);
    if (count < 120 && view !== "success" && view !== "append-missing-characters") {
      getDidMitekFinish(props.ctxId).then((res: AxiosResponse<MitekNategisIdinDidFinishDto>) => {
        // Stop polling if the request is an agent handoff.
        if (res.data?.status) {
          setRes(res.data);
          switch (+res.data.status) {
            case ETransactionStatusCode.Incomplete:
              if (res.data?.settings?.appendMissingPropertiesDesktop) {
                props.setResponse(res.data);
                props.setPageCallback(EMitekPageType.DESKTOP_APPEND_ERROR_MISSING_DATA);
                break;
              } else {
                setView("info-update");
              }
              break;
            case ETransactionStatusCode.Completed:
              if (res.data?.settings?.extractedDataOnDesktop) {
                localStorage.setItem("DATEOFBIRTH", res.data.extractedData?.dateOfBirth ?? "");
                localStorage.setItem("GIVEN_NAMES", res.data.extractedData?.name?.givenNames ?? "");
                localStorage.setItem("GENDER", res.data.extractedData?.dynamicProperties?.sex ?? "");
                localStorage.setItem("SURNAME", res.data.extractedData?.name?.surname ?? "");
              }
              if (isLastCheck(state) && state.current.identityService?.service === EService.MITEKCHECK) {
                dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] });
              } else {
                setView("success");
              }
              break;
            case ETransactionStatusCode.Failed:
            case ETransactionStatusCode.InProgress:
              setView("info-update");
              break;

            default:
              setView("info-start")
              break;
          }
        }
      });
    }
  }, 15000);

  const background = state.org.theme.config.mitekSvg ? encodeURIComponent(renderToStaticMarkup(<state.org.theme.config.mitekSvg fill={state.org.theme.themeColor} />)) : null;

  const getButtons = () => {
    switch (view) {
      case "info-update":
      case "info-start":
        return <Button color="green" floated="right" loading> Loading...</Button>
      case "success":
        return <Button color="green" floated="right" onClick={onSuccess}>{t("General_UI.Next")}</Button>

      default:
        break;
    }
  }

  const renderView = () => {
    switch (view) {
      case "info-start":
        return (
          <>
            <span>
              <h1 className={`service-item-header`}>{t("Mitek.Desktop.Header")}</h1>
              <h2 className={`service-item-subheader`}>{t("Mitek.Desktop.SubHeader")}</h2>
              <h3 className={`service-item-explanation`}>{t("Mitek.Desktop.Explanation_1")} <br />
                {t("Mitek.Desktop.Explanation_2")}</h3>
            </span>

            <span className="mb-5 mt-4" id="mitek-qr">
              <QRCodeCanvas value={`${window.location.origin}/handoff?ctxId=${props.ctxId}&lang=${props.lang}${props?.combinedCheckProps?.service !== undefined ? `&service=${props.combinedCheckProps.service}` : ''}`} /><br />{" "}
            </span>
            <span className="explanation-text mb-4">
              {
                state.org.theme.config.isIframe && (
                  <Modal
                    basic
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    size='small'
                    trigger={<div>{t("Mitek.Info.Title")}<Icon name='info circle' /></div>}
                  >
                    <Modal.Header style={{ marginLeft: "1rem", marginRight: "1rem" }}>{t("Mitek.Info.Title")}</Modal.Header>
                    <Modal.Content>
                      <Container style={{ marginLeft: 0 }}>
                        <p>{t("Mitek.Info.1")}</p>
                        <p>{t("Mitek.Info.2")} </p>
                        <p>{t("Mitek.Info.3")}{t("Mitek.Info.4")}</p>
                      </Container>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button basic color='red' inverted onClick={() => setOpen(false)}>
                        <Icon name='remove' />{t("General_UI.Close")}
                      </Button>
                    </Modal.Actions>
                  </Modal>
                )
              }
              {
                !state.org.theme.config.isIframe && (
                  <>
                    {t("Mitek.Info.Title")}
                    <Popup
                      trigger={<Icon name='info circle' />}
                      content={<>
                        <Header textAlign='center' as="h2">{t("Mitek.Info.Header")}</Header>
                        <p>{t("Mitek.Info.1")}</p>
                        <p>{t("Mitek.Info.2")} </p>
                        <p>{t("Mitek.Info.3")}{t("Mitek.Info.4")}</p>
                      </>}
                      size='large'
                      position='top right'
                    />
                  </>
                )
              }
            </span>
          </>
        )
      case "info-update":
        return (
          <>
            <span>
              <h1 className={`service-item-header`}>{`${t("Mitek.Desktop.Status_Changed")}`}</h1>
              <h2 className={`service-item-subheader`}>
                {t("Mitek.Desktop.Status_Changed_2")}
              </h2>
              <h3 className={`service-item-explanation`}>
                {t("Mitek.Desktop.Status_Changed_3")}
              </h3>
            </span>
            <span className="mb-6 mt-4" id="mitek-qr">
              <QRCodeCanvas value={`${window.location.origin}/handoff?ctxId=${props.ctxId}&lang=${props.lang}${props?.combinedCheckProps?.service !== undefined ? `&service=${props?.combinedCheckProps?.service}` : ''}`} /><br />{" "}
            </span>
            <span className="explanation-text mb-4">
              {
                state.org.theme.config.isIframe && (
                  <Modal
                    basic
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    size='small'
                    trigger={<div>{t("Mitek.Info.Title")}<Icon name='info circle' /></div>}
                  >
                    <Modal.Header style={{ marginLeft: "1rem", marginRight: "1rem" }}>{t("Mitek.Info.Title")}</Modal.Header>
                    <Modal.Content>
                      <Container style={{ marginLeft: 0 }}>
                        <p>{t("Mitek.Info.1")}</p>
                        <p>{t("Mitek.Info.2")} </p>
                        <p>{t("Mitek.Info.3")}{t("Mitek.Info.4")}</p>
                      </Container>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button basic color='red' inverted onClick={() => setOpen(false)}>
                        <Icon name='remove' />{t("General_UI.Close")}
                      </Button>
                    </Modal.Actions>
                  </Modal>
                )
              }
              {
                !state.org.theme.config.isIframe && (
                  <>
                    {t("Mitek.Info.Title")}
                    <Popup
                      trigger={<Icon name='info circle' />}
                      content={<>
                        <Header textAlign='center' as="h2">{t("Mitek.Info.Header")}</Header>
                        <p>{t("Mitek.Info.1")}</p>
                        <p>{t("Mitek.Info.2")} </p>
                        <p>{t("Mitek.Info.3")}{t("Mitek.Info.4")}</p>
                      </>}
                      size='large'
                      position='top right'
                    />
                  </>
                )
              }
            </span >
          </>
        )
      case "append-missing-characters":
        return <props.combinedCheckProps.component callback={props.combinedCheckProps.callback} missingProperties={res?.requiredFields?.missingFields} data={{
          documentNumber: res?.extractedData?.documentNumber ?? "",
          givenNames: res?.extractedData?.name?.givenNames ?? "",
          surname: res?.extractedData?.name?.surname ?? "",
          issuingAuthority: res?.extractedData?.dynamicProperties?.issuingAuthority ?? "",
          personalGovID: res?.extractedData?.dynamicProperties?.personalGovID ?? "",
          dateOfIssue: res?.extractedData?.dateOfIssue ?? "",
          dateOfExpiry: res?.extractedData?.dateOfExpiry ?? "",
        }}
        />
      case "success":
        return <AnimationSuccess showSvg={false} header={t("General_UI.Great")} text={t("General_UI.Correct")} key={`${state.view}-success`} />
    }
  }
  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${state.view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container space-between" style={{ background: state.view !== "success" ? `url("data:image/svg+xml,${background}")` : "", backgroundSize: "contain", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat", }}>
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>

      {view !== "append-missing-characters" && (
        <Segment basic className={`service-item-button-container mt-0`}>
          {
            getButtons()
          }
        </Segment>
      )}

    </>
  )
}
