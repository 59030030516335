import { Button, Form, FormFieldProps, Header, Icon, Label, Modal } from "semantic-ui-react";
import { FieldProps, getIn } from "formik";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps extends FieldProps<string, HTMLInputElement>, FormFieldProps { }

export interface IDatePickerProps extends IProps {
  activationDate: string;
}

export type dateOptions = "date" | "datetime-local";

export const DatePicker: React.FC<IDatePickerProps> = ({ activationDate, field, form: { errors, touched, handleChange }, label, tooltip, required, errorMarginTop, type = "date" || "datetime-local" }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const labelName = required ? label + " *" : label;
  const inputDate = activationDate ?
    type === "datetime-local"
      ? activationDate.slice(0, 19)
      : activationDate.slice(0, 10)
    : null;

  return (
    <Form.Field>
      <label>
        {label && labelName}
        {tooltip && (
          <Modal
            basic
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            open={open}
            size='small'
            trigger={<Button style={{ border: "none", boxShadow: "none", paddingRight: 0, paddingBottom: "0.25em" }} compact size="small" basic type="button" icon={<Icon style={{ marginBottom: "1em" }} name="info circle" fitted />} />}
          >
            <Header icon>
              <Icon name='info circle' />
              {t("General_UI.Information")}
            </Header>
            <Modal.Content>
              <p>
                {tooltip?.content}
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button color='green' inverted onClick={() => setOpen(false)}>
                {t("General_UI.Close")}
              </Button>
            </Modal.Actions>
          </Modal>
        )}
      </label>
      <Form.Input
        {...field}
        name={field.name}
        type={type}
        value={inputDate || String(field.value)}
        onChange={handleChange}
      />
      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label
          size="large"
          color="red"
          style={{ marginTop: errorMarginTop }}
          icon="times circle"
          content={t(getIn(errors, field.name))}
        />
      )}
    </Form.Field>
  )
}