import { MitekScanPageProps } from '../../../../../Types';
import { Transition, Segment, ButtonGroup, Button, Icon, Image } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

const MitekScanSelfieComponent = (props: MitekScanPageProps) => {
  const { t } = useTranslation(props.state.org.theme.config.translationKey);

  return (
    <>
      <Image style={{ zIndex: 1001, position: "absolute", top: "1em", left: "1em" }} src={props.state?.org.theme.logo} className="logoImg" fluid />

      <div style={{ position: "absolute", margin: "auto", zIndex: 1000, width: window.outerWidth, bottom: 0, left: 0, right: 0, top: 0, height: "1px" }}>
        {props.countDown < 5 ? (
          <Transition visible={true} animation='fly right' duration={500}>
            <h2 className="hintMessage" style={{ marginTop: "-0.5em" }}>
              {props.hint ?? "Move away then return in frame with face placed in oval."}
            </h2>
          </Transition>
        ) : (!props.loadingCamera ? <h2 className="hintMessage" style={{ marginTop: "-0.5em" }}>{t("Mitek.Hints.MISNAP_HEAD_OUTSIDE")}</h2> : null)}
      </div>

      <Segment className="mitek" basic style={{ height: "100%", width: "100%", padding: 0, margin: 0 }} compact loading={props.loadingCamera}>
        <div id="mitekContainer" style={{ width: "100%", height: "100%", zIndex: 1 }}></div>
      </Segment>

      <Segment className="mitek" style={{ bottom: 0, zIndex: 1000, position: "absolute", height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", opacity: ".85" }} size="small">
        <ButtonGroup basic widths="3">
          <Button disabled={props.countDown != 0} onClick={() => { props.mitekScienceSDK.cmd("SDK_STOP"); props.setCurrentPage(18); }} >
            <Icon name="photo" /> {t("Mitek.Manual")} {`${props.countDown !== 0 ? "(" + props.countDown + ")" : ""}`}
          </Button>
          <Button disabled onClick={() => { props.mitekScienceSDK.cmd("SDK_STOP"); window.location.reload(); }}>
            <Icon name="id card outline" /> {t("Mitek.Other")}
          </Button>
        </ButtonGroup>
      </Segment>
    </>
  )
}

export default MitekScanSelfieComponent