export const getMiddleName = (lastName: string) => {
  const names = lastName.split(" ");
  return names.length > 1 ? names[0] : "";
}

export const getLastName = (lastName: string) => {
  const names = lastName.split(" ");
  return names.length > 1 ? names.slice(1) : lastName;
}

export const getFirstName = (fullName: string) => {
  const names = fullName.split(" ");
  return names.length > 1 ? names[0] : fullName;
}

export const getInitials = (str: string) => {
  if (str.length === 0) {
    return "";
  }
  const firstLetters = str
    .split(' ')
    .map(word => word[0])
    .join('. ');

  return firstLetters + ".";
};