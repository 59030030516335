import MapAllowedRoutes from "./MapAllowedRoutes";
import privateRoutesConfig from "./PrivateRoutesConfig";
import { DashboardLayout } from "../Layouts/DashboardLayout";
import { GetAllowedRoutes } from "../utils/RouteUtils";
import { IRoute } from "../models/routes/IRoute";
import { Redirect } from "react-router-dom";
import { RootStoreContext } from "../stores/RootStore"
import { useContext } from "react"

interface IProps {
  path: string;
}

const PrivateRoutes: React.FC<IProps> = ({ path }) => {
  const rootStore = useContext(RootStoreContext);
  let allowedRoutes: IRoute[] = [];

  if (rootStore.authorizationStore.isAuthenticated()) {
    allowedRoutes = GetAllowedRoutes(privateRoutesConfig);
  } else {
    return (
      <Redirect to="/" />
    )
  }

  return (
    <DashboardLayout>
      <MapAllowedRoutes routeMatch={path} routes={allowedRoutes} />
    </DashboardLayout>
  )
}

export default PrivateRoutes;