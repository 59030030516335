export interface IMitekRequiredFields {
  id?: number;
  flowId: number;
  givenNames: boolean;
  surname: boolean;
  fullName: boolean;
  documentNumber: boolean;
  dateOfBirth: boolean;
  dateOfIssue: boolean;
  dateOfExpiry: boolean;
  issuingCountry: boolean;
  nationality: boolean;
  nationalityCode: boolean;
  sex: boolean;
  issuingAuthority: boolean;
  personalGovId: boolean;
  placeOfBirth: boolean;
}

export class IMitekRequiredFieldsFormValues {
  givenNames: boolean = false;
  surname: boolean = false;
  fullName: boolean = false;
  documentNumber: boolean = false;
  dateOfBirth: boolean = false;
  dateOfIssue: boolean = false;
  dateOfExpiry: boolean = false;
  issuingCountry: boolean = false;
  nationality: boolean = false;
  nationalityCode: boolean = false;
  sex: boolean = false;
  issuingAuthority: boolean = false;
  personalGovId: boolean = false;
  placeOfBirth: boolean = false;

  constructor(init?: IMitekRequiredFields) {
    Object.assign(this, init);
  }
}