import './StatisticsHomeSidebar.css'
import { Button, Container, Dropdown, Header, Icon, Input, Label } from "semantic-ui-react";
import { IClientByCustomer } from '../../../../models/client/IClient';
import { ICustomer } from '../../../../models/customer/ICustomer';
import { NavLink } from "react-router-dom";
import { RenderGuard } from "../../../../Components/Common/guards/RenderGuard";
import { Roles } from "../../../../Enums/Roles";
import { RootStoreContext } from "../../../../stores/RootStore"
import { observer } from "mobx-react-lite"
import { routePrefixDashboard } from '../../../../ApplicationRouter';
import { useContext, useEffect, useState } from "react"

export const StatisticsHomeSidebar = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { getAllClients, getCustomersRecent } = rootStore.customerClientStore;
  const [clients, setClients] = useState<IClientByCustomer[]>([])
  const [filteredClients, setFilteredClients] = useState<IClientByCustomer[]>([]);
  const [searchStringClients, setSearchStringClients] = useState<string>("");
  const [recentCustomers, setRecentCustomers] = useState<ICustomer[]>([]);
  const [filteredCustomers, setFilteredCustomers] = useState<ICustomer[]>([]);
  const [searchStringCustomer, setSearchStringCustomer] = useState<string>("");

  useEffect(() => {
    const loadClients = async () => {
      var res = await getAllClients();
      if (res) {
        setClients(res);
      }
    }

    const loadCustomers = async () => {
      var res = await getCustomersRecent();
      if (res) {
        setRecentCustomers(res);
      }
    }

    loadClients();
    loadCustomers();
  }, []);

  useEffect(() => {
    setFilteredClients(clients?.filter((opt) => opt.name.toUpperCase().includes(searchStringClients?.toUpperCase() ?? "")));
  }, [searchStringClients]);

  useEffect(() => {
    setFilteredCustomers(recentCustomers?.filter((opt) => opt.name?.toUpperCase().includes(searchStringCustomer?.toUpperCase() ?? "")));
  }, [searchStringCustomer]);

  return (
    <Container>
      <Dropdown.Menu className="dropdownmenu">
        <Header as="h3">
          Organisaties
        </Header>
        {/* <Dropdown.Header content="Zoek organisaties" /> */}
        <Input icon="search" iconPosition="left" name="search" placeholder="Zoek organisaties" onChange={(e) => setSearchStringClients(e.target.value)} />
        <Dropdown.Divider />
        <div className="dropdownitemsmaxheight">
          {searchStringClients === ""
            ? clients?.map((x, index) =>
              <Dropdown.Item
                key={`${x.customerClientId}-${x.id}-${index}-opt`}
                label={
                  <>
                    <Label as={NavLink} to={`${routePrefixDashboard}/organization-stats/${x.customerClientId}`}
                      icon={<Icon name="pie chart" size="large" fitted />}
                      style={{ color: x.themeColor, backgroundColor: "white" }}
                      size="mini" />
                    <Label as={NavLink} to={`${routePrefixDashboard}/organization/${x.customerClientId}`}
                      icon={<Icon name="edit outline" size="large" fitted />}
                      style={{ color: x.themeColor, backgroundColor: "white" }}
                      size="mini" />
                  </>
                }
                text={x.name} />
            )
            : filteredClients?.map((x, index) =>
              <Dropdown.Item
                key={`${x.customerClientId}-${x.id}-${index}-optFilter`}
                label={
                  <>
                    <Label as={NavLink} to={`${routePrefixDashboard}/organization-stats/${x.customerClientId}`}
                      icon={<Icon name="pie chart" size="large" fitted />}
                      style={{ color: x.themeColor, backgroundColor: "white" }}
                      size="mini" />
                    <Label as={NavLink} to={`${routePrefixDashboard}/organization/${x.customerClientId}`}
                      icon={<Icon name="edit outline" size="large" fitted />}
                      style={{ color: x.themeColor, backgroundColor: "white" }}
                      size="mini" />
                  </>
                }
                text={x.name} />
            )}
        </div>
        <div style={{ textAlign: "center", paddingTop: "1.5em", paddingBottom: "0.5em" }}>
          <Button basic as={NavLink} to={`${routePrefixDashboard}/organization`} icon="add" size="tiny" />
        </div>
      </Dropdown.Menu>

      <RenderGuard roles={Roles.ADMIN}> {/* Only show for admins */}
        <Dropdown.Menu className="dropdownmenu">
          <Header as="h3">
            Klanten
          </Header>
          {/* <Dropdown.Header content="Zoek klanten" /> */}
          <Input icon="search" iconPosition="left" name="search" placeholder="Zoek klanten" onChange={(e) => setSearchStringCustomer(e.target.value)} />
          <Dropdown.Divider />
          <div className="dropdownitemsmaxheight" style={{ maxHeight: "250px", overflowY: "scroll", height: "250px", display: 'flex', flexDirection: "column" }}>
            {searchStringCustomer === ""
              ? recentCustomers.map((x) =>
                <Dropdown.Item
                  key={x.name}
                  label={{ color: "grey", empty: true, circular: true }}
                  text={x.name}
                  as={NavLink}
                  to={`${routePrefixDashboard}/customer/${x.id}`}
                />
              )
              : filteredCustomers?.map((x) =>
                <Dropdown.Item
                  key={x.name}
                  label={{ color: "grey", empty: true, circular: true }}
                  text={x.name}
                  as={NavLink}
                  to={`${routePrefixDashboard}/customer/${x.id}`}
                />
              )
            }
          </div>
          <div style={{ textAlign: "center", paddingTop: "1.5em", paddingBottom: "0.5em" }}>
            <Button basic as={NavLink} to={`${routePrefixDashboard}/customer`} icon="add" size="tiny" />
          </div>
        </Dropdown.Menu>
      </RenderGuard>
    </Container >
  )
})