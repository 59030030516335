import { Container } from "semantic-ui-react"
import { ClientManagerList } from "../../../../Components/secure/clientManager/ClientManagerList"

export const ClientManagers = () => {

  return (
    <Container>
      <ClientManagerList />
    </Container>
  )
}