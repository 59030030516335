import "react-toastify/dist/ReactToastify.css";
import { ApplicationRouter } from "./ApplicationRouter";
import { withOrientationChange } from "react-device-detect";

export const routePrefixHome = "/home";

const App = withOrientationChange((props: any) => {

  const { isLandscape, isPortrait } = props;
  return (
    <ApplicationRouter isLandscape={isLandscape} isPortrait={isPortrait} />
  );
})

export default App;