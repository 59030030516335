import "../../App.css";
import "../../Layouts/DesktopLayout.scss";
import ReactMarkdown from "react-markdown";
import { Button, Segment } from "semantic-ui-react";
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { FunctionComponent, useContext, useEffect } from "react";
import { StateDispatch, StateValue } from "../Context";
import { renderToStaticMarkup } from 'react-dom/server';
import { useTranslation } from "react-i18next";

export const Start: FunctionComponent = () => {
  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  // Skip the start screen if the theme commands it.
  useEffect(() => {
    if (state.org.theme.config.skipStart) {
      dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] })
    }
  }, []);


  const renderView = () => {
    switch (state.view) {
      default:
        return (
          <span className="start-text">
            <ReactMarkdown skipHtml>{t("Start.Text")!}</ReactMarkdown>
          </span>
        )
    }
  }

  const background = state.org.theme.config.startSvg ? encodeURIComponent(renderToStaticMarkup(<state.org.theme.config.startSvg fill={state.org.theme.themeColor} />)) : null;

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${state.view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container" style={{ background: state.view !== "success" ? `url("data:image/svg+xml,${background}")` : "" }}>
            {renderView()}
          </div>

        </CSSTransition>
      </SwitchTransition>

      <Segment basic className="service-item-button-container mt-0">
        <Button color="green" floated="right" type="submit" onClick={() => { dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }); }} >
          {t("Start.Button")}
        </Button>
      </Segment>
    </>
  );
};
