import { EOCRLabsPageType } from "../../../Types/OCRLabs/Enums/EOCRLabsPageType";
import { OCRLabsPropsByPage } from "../../../Types/OCRLabs/OCRLabsPropsByPage";
import OCRDynamicValidatePropertiesComponent from "./AppendMissingProperties/OCRDynanicValidatePropertiesComponent";
import { OCRConfirmAndSubmitPicture } from "./Views/OCRConfirmAndSubmitPicture";
import { OCRGenericError, OCRGenericWarning } from "./Views/OCRGenericWarningError";
import { OCRInformation } from "./Views/OCRInformation";
import { OCRDesktop } from "./Views/OCRInformationDesktop";
import { OCRLoading } from "./Views/OCRLoading";
import { OCRRetryOrPassportSignature } from "./Views/OCRRetryOrPassportSignature";
import { OCRScanQRCode } from "./Views/OCRScanQRCode";
import { OCRSuccess } from "./Views/OCRSuccess";
import { OCRTakePicture } from "./Views/OCRTakePicture";
import { OCRTakeSelfie } from "./Views/OCRTakeSelfie";

export const RenderOCRPage = (props: OCRLabsPropsByPage) => {

  switch (props.page) {
    case EOCRLabsPageType.Information:
      return <OCRInformation {...props.data} />
    case EOCRLabsPageType.Information_Desktop:
      return <OCRDesktop {...props.data} />
    case EOCRLabsPageType.ConfirmAndSubmitPicture:
      return <OCRConfirmAndSubmitPicture />
    case EOCRLabsPageType.Error:
      return <OCRGenericError {...props.data} />
    case EOCRLabsPageType.Warning:
      return <OCRGenericWarning {...props.data} />
    case EOCRLabsPageType.TakePicture:
      return <OCRTakePicture {...props.data} />
    case EOCRLabsPageType.TakeSelfie:
      return <OCRTakeSelfie {...props.data} />
    case EOCRLabsPageType.Success:
      return <OCRSuccess {...props.data} />
    case EOCRLabsPageType.Loading:
      return <OCRLoading {...props.data} />
    case EOCRLabsPageType.ValidateProperties:
      return <OCRDynamicValidatePropertiesComponent {...props.data} />
    case EOCRLabsPageType.RetryOrPassportSignature:
      return <OCRRetryOrPassportSignature />
    case EOCRLabsPageType.ScanQRCode:
      return <OCRScanQRCode />

  }
}