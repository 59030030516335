import { Tab } from 'semantic-ui-react'
import { FlowSettingsMitek } from './FlowSettingsMitek';
import { MitekRequiredFields } from './MitekRequiredFields';

interface IProps {
  flowId: number;
}

export const MitekSettingTabs = ({ flowId }: IProps) => {

  const panes = [
    {
      menuItem: 'Settings',
      pane: { key: "Settings", content: (<FlowSettingsMitek flowId={flowId} />) }
    },
    {
      menuItem: 'Required fields',
      pane: { key: "Required fields", content: (<MitekRequiredFields flowId={flowId} />) }
    }
  ]

  return (
    <Tab panes={panes} renderActiveOnly={false} />
  )
}