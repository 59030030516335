import { Button, Container, Dropdown, Grid, Header, Icon, Image, Pagination, Popup, Segment, Statistic, Table } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../../../stores/RootStore";
import { ICustomerStatistics } from "../../../../models/customer/ICustomerStatistics";
import { groupBy } from "../../../../Services/GroupBy";
import { formatDistanceToNow } from "date-fns";
import { nl } from "date-fns/locale";

interface RouteParams {
  id: string;
}

export const CustomerStatistics: React.FC<RouteComponentProps<RouteParams>> = observer(() => {
  const { id } = useParams<RouteParams>();
  const rootStore = useContext(RootStoreContext);
  const { loading, getStatisticsForCustomer } = rootStore.customerClientStore;
  const [activePage, setActivePage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [customerStats, setCustomerStats] = useState<ICustomerStatistics[]>([]);
  const [groupedStats, setGroupedStats] = useState<[string, ICustomerStatistics[]][]>();
  const [filter, setFilter] = useState<string[]>([]); // Active option(s) to filter by
  const [filterOptions, setFilterOptions] = useState<any[]>([]); // Options to filter by service
  const [filterClient, setFilterClient] = useState<string[]>([]); // Options to filter by client
  const [filterOptionsClient, setFilterOptionsClient] = useState<any[]>([]); // Active option(s) to filter by client

  useEffect(() => {
    const loadStats = async (id: number) => {
      var response = await getStatisticsForCustomer(id);
      if (response) {
        setCustomerStats(response);

        const grouped = groupBy(response, x => x.service);
        const groupedClient = groupBy(response, x => x.clientName);

        setGroupedStats(Array.from(grouped));
        setFilterOptions(Array.from(grouped.keys()).map(x => ({ text: x, value: x, key: x })));
        setFilterOptionsClient(Array.from(groupedClient.keys()).map(opt => ({ text: opt, value: opt, key: opt })));
      }
    }
    if (id !== undefined) {
      loadStats(parseInt(id))
    }
  }, [id])

  return (
    <Container >
      <Segment>
        <Segment floated="left" basic>
          <Header as="h2" floated="left">
            Overzicht
          </Header>
        </Segment>
        <Segment floated="right" basic>
          <Dropdown floating placeholder="Filter per service" search multiple
            selection options={filterOptions}
            onChange={(_, e) => setFilter(e.value as any)}
          />
          <Dropdown floating placeholder="Filter per klant"
            value={filterClient} search multiple
            selection options={filterOptionsClient}
            onChange={(_, e) => setFilterClient(e.value as any)}
          />
        </Segment>
        <Table sortable celled fixed padded basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Client</Table.HeaderCell>
              <Table.HeaderCell>TxId</Table.HeaderCell>
              <Table.HeaderCell>TxStatId</Table.HeaderCell>
              <Table.HeaderCell>Service</Table.HeaderCell>
              <Table.HeaderCell>Validated</Table.HeaderCell>
              <Table.HeaderCell>Completed (transaction)</Table.HeaderCell>
              <Table.HeaderCell>Tijd geleden</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {customerStats
              .filter(x => filterClient.length === 0 || filterClient.includes(x.clientName))
              .filter(x => filter.length === 0 || filter.includes(x.service))
              .slice((activePage * perPage) - perPage, activePage * perPage)
              .map((stat) => (
                <Table.Row key={stat.transactionStatusId}>
                  <Table.Cell>
                    <Popup
                      mouseEnterDelay={200}
                      mouseLeaveDelay={150}
                      flowing
                      hoverable
                      trigger={
                        <Header as='h4' image>
                          <Image src={stat.clientLogo} rounded size="mini" />
                          <Header.Content>
                            {stat.clientName}
                            <Header.Subheader>{formatDistanceToNow(Date.parse(stat.date), { locale: nl })} geleden</Header.Subheader>
                          </Header.Content>
                        </Header>
                      }
                    >
                      <Button.Group basic>
                        <Button basic
                          onClick={() => {
                            if (!filterClient.includes(stat.clientName)) {
                              setFilterClient([...filterClient, stat.clientName])
                            }
                          }}
                        >
                          Voeg <b>{stat.clientName}</b> toe als filter
                        </Button>
                      </Button.Group>
                    </Popup>
                  </Table.Cell>
                  <Table.Cell>{stat.transactionId}</Table.Cell>
                  <Table.Cell>{stat.transactionStatusId}</Table.Cell>
                  <Table.Cell>{stat.service}</Table.Cell>
                  <Table.Cell>
                    {stat.validated
                      ? <Icon color="green" name='checkmark' size="large" />
                      : <Icon color='red' name='close' size='large' />}
                  </Table.Cell>
                  <Table.Cell>
                    {stat.completedParent
                      ? <Icon color="green" name='checkmark' size="large" />
                      : <Icon color='red' name='close' size='large' />}
                  </Table.Cell>
                  <Table.Cell>{formatDistanceToNow(Date.parse(stat.date), { locale: nl })} geleden</Table.Cell>
                </Table.Row>
              ))
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan="7">
                <Grid columns={2} verticalAlign="middle">
                  <Grid.Row>

                    <Grid.Column width={10}>
                      <Statistic.Group horizontal size="tiny" style={{ display: "flex", flexDirection: "row" }}>
                        {
                          groupedStats?.filter(x => filter.length == 0 || filter.includes(x[0])).map(x => (
                            <Statistic horizontal size="mini" key={`key-${x[1][0].transactionStatusId}`}>
                              <Statistic.Value>{x[1].filter(z => filterClient.length == 0 || filterClient.includes(z.clientName)).length}</Statistic.Value>
                              <Statistic.Label>{x[0]} API calls</Statistic.Label>
                            </Statistic>
                          ))
                        }
                      </Statistic.Group>
                    </Grid.Column>
                    <Grid.Column width={3}>
                      <Pagination onPageChange={(e, d) => setActivePage(parseInt(d.activePage?.toString() ?? "0"))} defaultActivePage={1} totalPages={Math.ceil(customerStats.filter(tx => filterClient.includes(tx.clientName) || filterClient.length === 0).filter(tx => filter.includes(tx.service) || filter.length == 0).length / perPage)} />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </Segment>
    </Container>
  )
})