import { IRoute } from "../../models/routes/IRoute";
import { Roles } from "../../Enums/Roles";
import { StatisticsReport } from "../../pages/Secure/Statistics/report/StatisticsReport";

export const StatisticsRoutes: IRoute[] = [
  {
    component: StatisticsReport,
    path: "/statistics-report",
    title: "Navbar.StatisticsReport",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN, Roles.CUSTOMERMANAGER, Roles.DTSMANAGER, Roles.CLIENTMANAGER],
    dropdown: false,
    childs: [],
  }
]