import { Button, Form, Grid, Segment } from "semantic-ui-react";
import { DropDown } from "../../Common/form/Dropdown";
import { EMakeAChoice } from "../../../Enums/EMakeAChoice";
import { EnvironmentEnums, IEnvironmentEnum } from "../../../Enums/EEnvironmentType";
import { Field, Formik } from "formik";
import { IApiKeyLink } from "../../../models/apiKey/IApikey";
import { IClientByCustomer } from "../../../models/client/IClient";
import { IFlow } from "../../../models/flow/IFlow";
import { ITestLink, TestLinkFormValues } from "../../../models/testLinks/ITestLink";
import { LinksFormValidation } from "./LinksForm.Validation";
import { RootStoreContext } from "../../../stores/RootStore";
import { TextInput } from "../../Common/form/TextInput";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
var FileSaver = require('file-saver');

export const LinksForm = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { getAllClients } = rootStore.customerClientStore;
  const { getFlows, getLinkApiKeys, createTestLinks, submitting } = rootStore.linkStore;
  const { t } = useTranslation();

  const [formValues, setFormValues] = useState(new TestLinkFormValues());
  const [customerClients, setCustomerClients] = useState<IClientByCustomer[]>([]);
  const [envTypeOptions, setEnvTypeOptions] = useState<IEnvironmentEnum[]>([]);
  const [apiKeys, setApikeys] = useState<IApiKeyLink[]>([]);
  const [flows, setFlows] = useState<IFlow[]>([]);

  useEffect(() => {
    const loadData = async () => {
      var response = await getAllClients();
      if (response) {
        setCustomerClients(response);
      }
      var apiKeys = await getLinkApiKeys();
      if (apiKeys) {
        setApikeys(apiKeys);
      }
      var flows = await getFlows();
      if (flows) {
        setFlows(flows);
      }
    }
    loadData();
    setEnvTypeOptions(EnvironmentEnums());
  }, [])

  const handleSubmit = async (values: ITestLink) => {
    var response = await createTestLinks(values);
    if (response && response.links.length > 0) {
      var str = "";
      response.links.forEach((x) => str = str + `${x} \r\n`)
      var blob = new Blob([str], { type: "text/plain;charset=utf-8" });
      var name = customerClients.find((x) => x.customerClientId == values.customerClientId)?.name ?? values.customerClientId;
      FileSaver.saveAs(blob, `Links for ${name}.txt`)
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={handleSubmit}
      validationSchema={LinksFormValidation}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Segment padded className="bg-lightest-grey">
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Field
                    component={DropDown}
                    required
                    name="customerClientId"
                    label={t("Links.Customer")}
                    value={formik.values.customerClientId}
                    options={customerClients.map((client) => {
                      return {
                        key: client.customerClientId,
                        value: client.customerClientId,
                        text: client.name
                      }
                    })}
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <Field
                    component={DropDown}
                    required
                    name="envType"
                    label={t("Statistics.Form.Environment_Type")}
                    value={formik.values.envType}
                    showMakeAChoice={false}
                    options={envTypeOptions.map((envType) => {
                      return {
                        key: envType.value,
                        value: envType.value,
                        text: t(`Enum.EnvironmentType.${envType.name}`)
                      }
                    })}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Field
                    component={DropDown}
                    required
                    name="flowId"
                    label={t("Links.Flow")}
                    value={formik.values.flowId}
                    disabled={formik.values.customerClientId == EMakeAChoice.Value}
                    options={flows.filter((x) => x.customerClientId == formik.values.customerClientId).map((flow) => {
                      return {
                        key: flow.id,
                        value: flow.id,
                        text: `${flow.id} - ${flow.name}`
                      }
                    })}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Field
                    component={DropDown}
                    required
                    name="apiKeyId"
                    label={t("Links.ApiKey")}
                    value={formik.values.apiKeyId}
                    disabled={formik.values.customerClientId == EMakeAChoice.Value}
                    options={apiKeys.filter((x) => x.customerClientId == formik.values.customerClientId).map((apiKey) => {
                      return {
                        key: apiKey.id,
                        value: apiKey.id,
                        text: apiKey.id
                      }
                    })}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <Field
                    component={TextInput}
                    name="amount"
                    required
                    type="number"
                    label={t("Links.Amount")}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Field
                    component={TextInput}
                    name="language"
                    required
                    label={t("Links.Language")}
                  />
                </Grid.Column>
                <Grid.Column width={4}>
                  <Field
                    component={TextInput}
                    name={"target"}
                    required
                    label={t("Links.Target")}
                  />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row textAlign="right">
                <Grid.Column>
                  <Button type="submit" primary disabled={submitting || formik.values.customerClientId == EMakeAChoice.Value}>
                    {t("Button.Submit")}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Form>
      )}

    </Formik>
  )
})