import { AdminForm } from "../../../../Components/secure/admin/AdminForm";
import { Container, Divider, Header } from "semantic-ui-react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface RouteParams {
  id: string;
}

export const EditAdmin: React.FC<RouteComponentProps<RouteParams>> = () => {
  const { id } = useParams<RouteParams>();
  const { t } = useTranslation();
  return (
    <Container>
      <Header as="h1">{t("Admin.Edit")}</Header>
      <Divider />
      <AdminForm id={id} />
    </Container>
  )
}