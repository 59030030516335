import MBUCheckComponent from './Components/Services/MBU/MBUCheckComponent'
import MNICheckComponent from './Components/Services/MNI/MNICheckComponent'
import SidebarCuratele from './Components/Services/Curatele/SidebarCuratele'
import SidebarEmailVerification from './Components/Services/EmailVerification/SidebarEmailVerification'
import SidebarHPGIban from './Components/Services/HPGIban/SidebarIban'
import SidebarIban from './Components/Services/Iban/SidebarIban'
import SidebarIdin from './Components/Services/Idin/SidebarIdin'
import SidebarIdinAge from './Components/Services/IdinAge/SidebarIdinAge'
import SidebarIdinNategisCombinationComponent from './Components/Services/IdinNategisCombination/SidebarIdinNategisCombinationComponent'
import SidebarMBU from './Components/Services/MBU/SidebarMBU'
import SidebarMitek from './Components/Services/Mitek/Sidebar/SidebarMitek'
import SidebarNategis from './Components/Services/Nategis/SidebarNategis'
import SidebarPep from './Components/Services/Pep/SidebarPep'
import SidebarUtilityBill from './Components/Services/UtilityBill/SidebarUtilityBill'
import StartSidebar from './Components/Views/StartSidebar'
import SuccessComponent from './Components/Views/SuccessComponent'
import UtilityBillComponent from './Components/Services/UtilityBill/UtilityBillComponent'
import { CurateleCheck } from './Components/Services/Curatele/CurateleCheckComponent'
import { EmailVerificationCheckComponent } from './Components/Services/EmailVerification/EmailVerificationCheckComponent'
import { EService } from './Enums/EService'
import { HPGIbanCheckComponent } from './Components/Services/HPGIban/HPGIbanCheckComponent'
import { IbanCheckComponent } from './Components/Services/Iban/IbanCheckComponent'
import { IdinAgeCheckComponent } from './Components/Services/IdinAge/IdinAgeCheckComponent'
import { IdinCheckComponent } from './Components/Services/Idin/IdinCheckComponent'
import { isDesktop, isIPad13 } from 'react-device-detect'
import { MitekCheckComponent } from './Components/Services/Mitek/MitekCheckComponent'
import { NategisCheckComponent } from './Components/Services/Nategis/NategisCheckComponent'
import { PepCheckComponent } from './Components/Services/Pep/PepCheckComponent'
import { PostalCodeCheckComponent } from './Components/Services/PostalCode/PostalCodeCheckComponent'
import { ServiceComponentType } from './Types'
import { Start } from './Components/Views/Start'
import { OCRLabsCheckComponent } from './Components/Services/OCR/OCRCheckComponent'
import { SidebarOCRCheck } from './Components/Services/OCR/SidebarOCRCheck'
import SidebarPostalCode from './Components/Services/PostalCode/SidebarPostalCodeCheckComponent'
export const IdentityServices: ServiceComponentType[] = [
  {
    component: Start,
    service: EService.START,
    sidebarComponent: StartSidebar,
    title: "General_UI.Start",
    useLayout: true,
  },
  {
    component: SuccessComponent,
    service: EService.SUCCESS,
    sidebarComponent: StartSidebar,
    title: "Success.Title",
    useLayout: true
  },
  {
    component: MitekCheckComponent,
    sidebarComponent: SidebarMitek,
    title: "Mitek.Title",
    useLayout: (isDesktop && !isIPad13),
    service: 8,
  },
  {
    component: IdinCheckComponent,
    sidebarComponent: SidebarIdin,
    title: "Idin.Title",
    useLayout: true,
    service: 1,
  },
  {
    component: IbanCheckComponent,
    sidebarComponent: SidebarIban,
    title: "Iban.Title",
    useLayout: true,
    service: 2
  },
  {
    component: IdinAgeCheckComponent,
    sidebarComponent: SidebarIdinAge,
    title: "IdinAge.Title",
    useLayout: true,
    service: 3
  },
  {
    component: PepCheckComponent,
    sidebarComponent: SidebarPep,
    title: "Pep.Title",
    useLayout: true,
    service: 5
  },
  {
    component: NategisCheckComponent,
    sidebarComponent: SidebarNategis,
    title: "Nategis.Title",
    useLayout: true,
    service: 6
  },
  {
    component: CurateleCheck,
    sidebarComponent: SidebarCuratele,
    title: "Curatele.Title",
    useLayout: true,
    service: 7
  },
  {
    component: MNICheckComponent,
    sidebarComponent: SidebarIdinNategisCombinationComponent,
    title: "IdinNategis.Title",
    useLayout: (isDesktop && !isIPad13),
    service: 10
  },
  {
    component: PostalCodeCheckComponent,
    sidebarComponent: SidebarPostalCode,
    title: "IdinNategis.Title",
    useLayout: true,
    service: 11
  },
  {
    component: UtilityBillComponent,
    sidebarComponent: SidebarUtilityBill,
    title: "UtilityBill.Title",
    useLayout: true,
    service: 14
  },
  {
    component: MBUCheckComponent,
    sidebarComponent: SidebarMBU,
    title: "UtilityBill.Title",
    useLayout: isDesktop ? true : false,
    service: 15,
    combinationCheckTotal: 1,
    combinationCheckCompleted: 0
  },
  {
    component: HPGIbanCheckComponent,
    sidebarComponent: SidebarHPGIban,
    title: "Iban.Title",
    service: 18,
    useLayout: true
  },
  {
    component: EmailVerificationCheckComponent,
    sidebarComponent: SidebarEmailVerification,
    title: "Email_Verification.Title",
    service: 19,
    useLayout: true
  }, {
    component: OCRLabsCheckComponent,
    sidebarComponent: SidebarOCRCheck,
    title: "OCR_Labs.Title",
    service: EService.OCRLABSCHECK,
    useLayout: true,
  }
];
