import { Container, Divider, Header } from "semantic-ui-react"
import { JsonExportForm } from "../../../Components/secure/jsonExport/JsonExportForm"

export const JsonExport = () => {
  return (
    <Container>
      <Header as="h1">Json export</Header>
      <Divider />
      <JsonExportForm />
    </Container>
  )
}