import { Button, Form, Segment } from "semantic-ui-react";
import { Field, Formik } from "formik";
import { IJsonExport, JsonExportFormValues } from "../../../models/export/IJsonExport";
import { JsonExportFormValidation } from "./JsonExportForm.Validation";
import { RootStoreContext } from "../../../stores/RootStore";
import { TextInput } from "../../Common/form/TextInput";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
var FileSaver = require('file-saver');

export const JsonExportForm = observer(() => {
  const { t } = useTranslation();
  const rootStore = useContext(RootStoreContext);
  const { submitting, createJsonExport } = rootStore.exportStore;
  const [formValues, setFormValues] = useState(new JsonExportFormValues());

  const handleSubmit = async (values: IJsonExport) => {
    var response = await createJsonExport(values);
    if (response) {
      var jsonString = JSON.stringify(response);
      var blob = new Blob([jsonString], { type: "application/json;charset=utf-8" });
      FileSaver.saveAs(blob, `${values.ctxId}.json`)
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      onSubmit={handleSubmit}
      validationSchema={JsonExportFormValidation}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit}>
          <Segment padded className="bg-lightest-grey">
            <Field
              component={TextInput}
              required
              name="ctxId"
              label={"Transaction CtxId"}
            />
            <Button type="submit" primary disabled={submitting}>
              {t("Button.Submit")}
            </Button>
          </Segment>
        </Form>
      )}
    </Formik>
  )
})