import { CreateDtsManager } from "../../pages/Secure/users/dtsManagers/CreateDtsManager";
import { DtsManagers } from "../../pages/Secure/users/dtsManagers/DtsManagers";
import { EditDtsManager } from "../../pages/Secure/users/dtsManagers/EditDtsManager";
import { IRoute } from "../../models/routes/IRoute";
import { Roles } from "../../Enums/Roles";

export const DtsManagerRoutes: IRoute[] = [
  {
    component: DtsManagers,
    path: "/dts-managers",
    title: "Navbar.DtsManagers",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: []
  },
  {
    component: EditDtsManager,
    path: "/dts-manager/edit/:id",
    title: "Navbar.DtsManagers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: CreateDtsManager,
    path: "/dts-manager/new",
    title: "Navbar.DtsManagers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER],
    dropdown: false,
    childs: [],
  },
]