import MitekHeader from '../../MitekHeader'
import React from 'react'
import { Segment, Header, Icon, Button } from 'semantic-ui-react'
import { state } from '../../../../Context'
import { useTranslation } from 'react-i18next'

type IProps = {
  hint: string | undefined;
  loadingCamera: boolean;
  startManualCapture: (e: "SELFIE" | "QR_BARCODE") => void;
  state: state;
}

const MitekCheckManualCaptureSelfieComponent = (props: IProps) => {
  const { t } = useTranslation(props.state?.org.theme.config.translationKey);

  return (
    <>
      <MitekHeader />
      <Segment id="manual-select" className="mitek manual-screen" basic style={{ height: "calc(100% - 5em - 4em)", width: "100%", margin: 0 }}>
        <h2>{t("Mitek.Selfie.Selfie")}</h2>
        <div style={{ display: "flex", flexDirection: "column", alignContent: "space-around", flexWrap: "nowrap", alignItems: "stretch", justifyContent: "space-between", overflowY: "auto" }}>
          <div>
            {
              props.hint !== null && props.hint !== undefined && props.hint.length > 2 && (
                <h2>
                  {props.hint}, {t("General_UI.Could_You_Try_Again").toLowerCase()}
                </h2>
              )
            }
          </div>

          <Segment placeholder loading={props.loadingCamera}>
            <Header icon as="h3" size="small">
              <Icon name='camera' />
            </Header>
            <Button primary color="green" floated="right" onClick={() => props.startManualCapture("SELFIE")}>{t("Mitek.Open_Camera")}</Button>
          </Segment>
        </div>
      </Segment>
      <Segment className="service-item-button-container mitek" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
        <Button primary color="green" floated="right" disabled loading={props.loadingCamera}>
          {t("Next")}
        </Button>
      </Segment>
    </>
  )
}

export default MitekCheckManualCaptureSelfieComponent