import { useEffect, useState } from 'react';

const useLocalStorageSupportCheck = () => {
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    const checkLocalStorageSupport = () => {
      try {
        // Check if localStorage is accessible without errors
        localStorage.setItem('testLocalStorage', 'valid');
        localStorage.removeItem('testLocalStorage');
        setIsSupported(true);
      } catch (error) {
        setIsSupported(false);
        window.parent.postMessage({ error: "third_party_cookies_not_supported", message: "The current browser does not support local storage, ask the user to check to exit incognito mode (or check their privacy settings)." }, "*");
      }
    };

    checkLocalStorageSupport();
  }, []);

  return isSupported;
};

export default useLocalStorageSupportCheck;
