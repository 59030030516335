export interface IBigSettings {
  scoreCheck: boolean;
  personCreditCheck: boolean;
  personFraudCheck: boolean;
  personIdentCheck: boolean;
  personIdentAddressCheck: boolean;
  personIdentPhoneCheck: boolean;
  personIdentNewCustomerCheck: boolean;
  personIdentFeatureListCheck: boolean;
  bankAccountCreditCheck: boolean;
  bankAccountFraudCheck: boolean;
  bankAccountIdentCheck: boolean;
  bankAccountIdentFeatureListCheck: boolean;
  emailFraudCheck: boolean;
  emailIdentFeatureListCheck: boolean;
  deviceFraudCheck: boolean;
  personIdentWatchListCheck: boolean;

  watchListCheckPEP: boolean;
  watchListCheckSanction: boolean;
  watchListCheckAssociatedEntity: boolean;
  watchListCheckEnforcements: boolean;
  watchListCheckAdverseMedia: boolean;
}

export class BigSettingsFormValues {
  scoreCheck: boolean = false;
  personCreditCheck: boolean = false;
  personFraudCheck: boolean = false;
  personIdentCheck: boolean = false;
  personIdentAddressCheck: boolean = false;
  personIdentPhoneCheck: boolean = false;
  personIdentNewCustomerCheck: boolean = false;
  personIdentFeatureListCheck: boolean = false;
  bankAccountCreditCheck: boolean = false;
  bankAccountFraudCheck: boolean = false;
  bankAccountIdentCheck: boolean = false;
  bankAccountIdentFeatureListCheck: boolean = false;
  emailFraudCheck: boolean = false;
  emailIdentFeatureListCheck: boolean = false;
  deviceFraudCheck: boolean = false;
  personIdentWatchListCheck: boolean = false;

  watchListCheckPEP: boolean = false;
  watchListCheckSanction: boolean = false;
  watchListCheckAssociatedEntity: boolean = false;
  watchListCheckEnforcements: boolean = false;
  watchListCheckAdverseMedia: boolean = false;
  constructor(init?: IBigSettings) {
    Object.assign(this, init);
  }
}