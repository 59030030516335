import * as yup from 'yup'
import { AnimationCheck } from '../../AnimationViews/AnimationCheck'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { Button, Form, Header, Segment } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { ELogType } from '../../../Enums/ELogType'
import { FunctionComponent, useContext, useEffect } from 'react'
import { getFieldValue, setFieldValue } from '../../../utils/LocalStoragePersonalInformation'
import { isDev } from '../../../utils/IsDev'
import { parseDateCDDN } from '../../../utils/ParseDateCDDN'
import { postCurateleCheck, postTransactionProgressLog } from '../../../Services/Backend'
import { StateDispatch, StateValue } from '../../Context'
import { TimerButton } from '../../Common/Button/TimerButton'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const CurateleCheck: FunctionComponent = () => {
  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const validationSchema = yup.object({
    INITIALS: yup.string().min(1, ""),
    MIDDLENAME: yup.string(),
    LASTNAME: yup.string().required("Achternaam is verplicht"),
    BIRTHDATE: yup.string(),
  }
  );

  const handleNext = () => {
    state.view === "success" ? onSuccess() : postBackend();
  }

  const formik = useFormik({
    initialValues: {
      INITIALS: "",
      MIDDLENAME: "",
      LASTNAME: "",
      BIRTHDATE: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleNext,
  });

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.CurateleCheckEntered });
    formik.setFieldValue("INITIALS", getFieldValue("INITIALS") ?? "");
    formik.setFieldValue("FIRSTNAME", getFieldValue("FIRSTNAME") ?? "");
    formik.setFieldValue("MIDDLENAME", getFieldValue("MIDDLENAME") ?? "");
    formik.setFieldValue("LASTNAME", getFieldValue("LASTNAME") ?? "");
    formik.setFieldValue("BIRTHDATE", getFieldValue("BIRTHDATE") ?? "");
  }, [])

  const postBackend = () => {
    dispatch({ type: "setView", data: "checking" });
    parseDateCDDN(formik.values.BIRTHDATE);
    setFieldValue("INITIALS", formik.values.INITIALS);
    setFieldValue("MIDDLENAME", formik.values.MIDDLENAME);
    setFieldValue("LASTNAME", formik.values.LASTNAME);
    setFieldValue("BIRTHDATE", formik.values.BIRTHDATE);
    postCurateleCheck({
      initials: formik.values.INITIALS,
      middleName: formik.values.MIDDLENAME,
      lastName: formik.values.LASTNAME,
      birthDate: formik.values.BIRTHDATE.replace(/-/g, ''),
      ctxId: state.ctxId
    }).then(res => {
      postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.CurateleCheckCompleted });
      dispatch({ type: "setView", data: "success" });
    }).catch((e) => {
      if (isDev) {
        onSuccess();
      } else {
        dispatch({ type: "setView", data: "form" })
      }
    })
  }

  const onSuccess = () => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.CurateleCheckCompleted });
    dispatch({ type: "setView", data: "form" });
    setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
  }

  const renderPage = () => {
    return (
      <>
        <Header as='h3'> {t("Curatele.Personal_Details")} </Header>
        <Form widths="equal" className="w100 h100">
          <Form.Group unstackable>
            <Form.Input label={t("Curatele.Initials")} value={formik.values.INITIALS} error={formik.touched.INITIALS && formik.errors.INITIALS} placeholder={getFieldValue("INITIALS") ?? t("Curatele.Initials")} name="INITIALS" id="INITIALS" onFocus={() => formik.setFieldTouched("INITIALS", true)} onChange={(e) => formik.handleChange(e)} width={4} />
            <Form.Input label={t("Curatele.Middle_Name")} value={formik.values.MIDDLENAME} placeholder={getFieldValue("MIDDLENAME") ?? t("Curatele.Middle_Name")} name="MIDDLENAME" id="MIDDLENAME" onFocus={(e: any) => formik.setFieldTouched("MIDDLENAME", true)} onChange={formik.handleChange} error={formik.touched.MIDDLENAME && formik.errors.MIDDLENAME} width={3} />
            <Form.Input label={t("Curatele.Last_Name")} value={formik.values.LASTNAME} placeholder={getFieldValue("LASTNAME") ?? t("Curatele.Last_Name")} name="LASTNAME" id="LASTNAME" onFocus={(e: any) => formik.setFieldTouched("LASTNAME", true)} onChange={formik.handleChange} error={formik.touched.LASTNAME && formik.errors.LASTNAME} width={3} required />
          </Form.Group>
          <Form.Group unstackable>
            <Form.Field width="6" error={formik.touched.BIRTHDATE && formik.errors.BIRTHDATE}>
              <label>{t("Curatele.Birthdate")}</label>
              <input type="date" id="BIRTHDATE" name="BIRTHDATE" value={formik.values.BIRTHDATE} onChange={formik.handleChange} />
            </Form.Field>
          </Form.Group>
        </Form>
      </>
    )
  }

  const renderView = () => {
    switch (state.view) {
      case "form":
        return (<>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={`trans`}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <div className="transition-container nategis">
                {renderPage()}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </>
        )
      case "checking":
        return <AnimationCheck svgComponent={state.org.theme.config.checkSvg && <state.org.theme.config.checkSvg fill={state.org.theme.themeColor} />} header={t("General_UI.Verifying")} text={t("General_UI.One_Moment")} key={`${state.view}-check`} />
      case "success":
        return <AnimationSuccess header={t("General_UI.Great")} text={t("General_UI.Correct")} key={`${state.view}-success`} />
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${state.view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container nategis">
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Segment basic className="service-item-button-container mt-0">
        {state.view === "success" ?
          <TimerButton countDownStart={state.org.theme.config.countDownStart ?? 5} callback={formik.submitForm} /> :
          <Button title={Object.values(formik.errors ?? []).toString()} floated="right" type="submit" onClick={() => formik.submitForm()} loading={state.view === "checking"}>
            Volgende
          </Button>
        }
      </Segment>
    </>
  );
};

