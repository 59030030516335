import { addMonths, format } from "date-fns";
import { EEnvironmentType } from "../../Enums/EEnvironmentType";
import { EMakeAChoice } from "../../Enums/EMakeAChoice";
import { EService } from "../../Enums/EService";

export interface IStatisticReportResponse {
  statisticReports: IStatisticsReport[];
  statisticTotals: IStatisticTotal[];
}

export interface IStatisticsReport {
  customerClientId: number;
  flowId: number;
  customerName: string;
  clientName: string;
  transactionCount: number;
  completedTransactionCount: number;
  distributions: IStatisticsReportDistribution[];
  statisticDistributions: IStatisticDistribution[];
}

export interface IStatisticDistribution {
  name: string;
  service: EService;
  totalUniqueUsers: number;
  totalCalls: number;
  valid: number;
  invalid: number;
}

export interface IStatisticsReportDistribution {
  name: string;
  validated: boolean;
  apiCalls: number;
  service: EService;
}

export interface IStatisticTotal {
  name: string;
  completed: number;
  notCompleted: number;
  total: number;
  service: EService;
}

export interface IGetStatisticsReport {
  start: string;
  end: string;
  envType: EEnvironmentType;
  customerId: number;
  clientId: number;
}

export class GetStatisticsReportFormValues {
  start: string | null = format(addMonths(new Date(), -1), "yyyy-MM-dd'T'00:00:00");
  end: string | null = format(new Date(), "yyyy-MM-dd'T'23:59:59");
  envType: number | undefined = EEnvironmentType.Prod;

  customerId: number | undefined = EMakeAChoice.Value;
  clientId: number | undefined = EMakeAChoice.Value;
  flowId: number | undefined = EMakeAChoice.Value;

  constructor(init?: IGetStatisticsReport) {
    Object.assign(this, init);
    if (init) {
      this.start = init.start === null ? format(addMonths(new Date(), -1), "yyyy-MM-dd'T'00:00:00") : format(new Date(init.start), "yyyy-MM-dd'T'hh:mm:ss")
      this.end = init.end === null ? format(new Date(), "yyyy-MM-dd'T'23:59:59") : format(new Date(init.end), "yyyy-MM-dd'T'hh:mm:ss")
    }
  }
}