import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { Step } from 'semantic-ui-react'
import { MockStep } from '../../Types/MockStep'
import { StateValue } from '../Context';

interface IProps {
  val: number | MockStep[] | undefined | null;
  completed?: boolean;
}

export const MockStepsComponent = (props: IProps) => {
  const { val, completed } = props;
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  if (val == undefined || val == null) {
    return null;
  }

  if (typeof val === 'number') {
    return (
      <>
        {
          [...Array(val)].map(element =>
            <Step completed={completed ?? false} />
          )
        }
      </>
    )
  }

  if (Array.isArray(val)) {
    return (
      <>
        {
          val.map(element =>
            <Step completed={completed ?? false}>
              <Step.Content className="sidebar-header-cont">
                <Step.Title className="sidebar-header">{t(element.title)}</Step.Title>
                <Step.Description className="sidebar-subheader">{t(element.description)}</Step.Description>
              </Step.Content>
            </Step>
          )
        }
      </>
    )
  }

  return null;
}
