import { Field, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { IMitekRequiredFields, IMitekRequiredFieldsFormValues } from "../../../../models/flow/requiredFields/IMitekRequiredFields";
import { RootStoreContext } from "../../../../stores/RootStore";
import { CheckBox } from "../../../Common/form/CheckBox";
import { Spinner } from "../../../Common/spinner/Spinner";

interface IProps {
  flowId: number;
}

export const MitekRequiredFields = observer(({ flowId }: IProps) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { loading, getMitekRequiredFieldsForFlow, submitting, updateMitekRequiredFieldsForFlow } = rootStore.flowSettingsStore;

  const [requiredFields, setRequiredFields] = useState<IMitekRequiredFields>();
  const [formValues, setFormValues] = useState(new IMitekRequiredFieldsFormValues());

  useEffect(() => {
    const loadData = async () => {
      var requiredFields = await getMitekRequiredFieldsForFlow(flowId);
      setRequiredFields(requiredFields);
      setFormValues(new IMitekRequiredFieldsFormValues(requiredFields));
    }
    loadData();
  }, [flowId])

  const handleSubmit = async (values: IMitekRequiredFieldsFormValues) => {
    await updateMitekRequiredFieldsForFlow(flowId, values);
  }

  return (
    <>
      <Header as="h1">{t("Flow_Settings.General.RequiredFields")}</Header>
      <Segment loading={loading}>
        {!requiredFields ? <Spinner /> : (
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="givenNames"
                        label={t("Required_Fields.Mitek.GivenName")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="surname"
                        label={t("Required_Fields.Mitek.Surname")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="fullName"
                        label={t("Required_Fields.Mitek.FullName")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="documentNumber"
                        label={t("Required_Fields.Mitek.DocumentNumber")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="dateOfBirth"
                        label={t("Required_Fields.Mitek.DateOfBirth")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="dateOfIssue"
                        label={t("Required_Fields.Mitek.DateOfIssue")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="dateOfExpiry"
                        label={t("Required_Fields.Mitek.DateOfExpiry")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="issuingCountry"
                        label={t("Required_Fields.Mitek.IssuingCountry")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="nationality"
                        label={t("Required_Fields.Mitek.Nationality")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="nationalityCode"
                        label={t("Required_Fields.Mitek.NationalityCode")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="sex"
                        label={t("Required_Fields.Mitek.Sex")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="issuingAuthority"
                        label={t("Required_Fields.Mitek.IssuingAuthority")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="personalGovId"
                        label={t("Required_Fields.Mitek.PersonalGovId")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="placeOfBirth"
                        label={t("Required_Fields.Mitek.PlaceOfBirth")}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="right">
                      <Button type="submit" primary disabled={submitting}>
                        {t("Button.Submit")}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Segment>
    </>
  )
})