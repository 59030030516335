import * as Yup from "yup";
import { EEnvironmentType } from "../../../Enums/EEnvironmentType";
import { EMakeAChoice } from "../../../Enums/EMakeAChoice";

export const LinksFormValidation = () => Yup.object().shape({
  envType: Yup.number()
    .required()
    .notOneOf([EEnvironmentType.Unknown]),
  customerClientId: Yup.number()
    .required()
    .notOneOf([EMakeAChoice.Value], "Enum.Make_A_Choice"),
  flowId: Yup.number()
    .required()
    .notOneOf([EMakeAChoice.Value], "Enum.Make_A_Choice"),
  apiKeyId: Yup.number()
    .required()
    .notOneOf([EMakeAChoice.Value], "Enum.Make_A_Choice"),
  amount: Yup.number()
    .required()
    .label("Links.Amount")
    .positive(),
  language: Yup.string()
    .required(),
  target: Yup.string()
    .url()
    .required()
})