import { SidebarProps } from '../../../Types'
import { DevLog } from '../../../utils/DevLog';
import { StateValue } from '../../Context';
import { Step, Icon } from 'semantic-ui-react'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next';

const SidebarIdin = (props: SidebarProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  DevLog(state);
  DevLog(props);

  return (
    <Step completed={state.current.order > props.flowItem.order} active={props.flowItem.order === state.current.order}>
      {!props.mediumScreen && <Icon name="credit card outline" className={`${props.flowItem.order > state.current.order ? "sidebar-icon-completed" : "sidebar-icon"}`} />}
      <Step.Content>
        <Step.Title>{t("Idin.Step_Title")}</Step.Title>
        <Step.Description> {t("Idin.Verify_Banking_Details")} </Step.Description>
      </Step.Content>
    </Step>
  )
}

export default SidebarIdin
