import React from 'react';

interface Props {
  base64Image: string;
}

const Base64Image: React.FC<Props> = ({ base64Image }) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundImage: `url('${base64Image}')`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    />
  );
};

export default Base64Image;