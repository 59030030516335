// Note, when a value has been added, also add it to the Backend.
export enum ELogType {
  // Entry or Exit 0-9
  EnteredSite = 0,
  CompletedAllSteps = 9,
  // IdinCheck 10-19
  IdinCheckEntered = 10,
  IdinCheckCompleted = 19,
  // IbanCheck 20-29
  IbanCheckEntered = 20,
  IbanCheckCompleted = 29,
  // AgeCheck 30-39
  AgeCheckEntered = 30,
  AgeCheckCompleted = 39,
  // PaspoortCheck 40-49
  PaspoortCheckEntered = 40,
  PasspoortCheckCompleted = 49,
  // PepCheck 50-59
  PepCheckEntered = 50,
  PepCheckCompleted = 59,
  // NategisCheck 60-69
  NategisCheckEntered = 60,
  NategisCheckCompleted = 69,
  // CurateleCheck 70-79
  CurateleCheckEntered = 70,
  CurateleCheckCompleted = 79,
  // MitekCheck 80-89
  MitekCheckEntered = 80,
  MitekCheckEnteredScan = 81,
  MitekCheckTookFirstPicture = 82,
  MitekCheckTookSecondPicture = 83,
  MitekCheckTookSelfie = 84,
  MitekCheckAppendMissingPropertiesCompleted = 85,
  MitekCheckCompleted = 89,

  // MitekNateGisIdinCheck 100-109
  MitekNateGisIdinCheckEntered = 100,
  MitekNateGisIdinCheckCompleted = 109,
  // AddressCheck 110-119
  AddressCheckEntered = 110,
  AddressCheckCompleted = 119,
  // EMPTY 120-129
  // EMPTY 130-139
  // UtilityBillCheck 140-149
  UtilityBillCheckEntered = 140,
  UtilityBillCheckCompleted = 149,
  // Email_VerificationCheck 190-199
  Email_VerificationCheckEntered = 190,
  Email_VerificationCodeEntered = 191,
  Email_VerificationCheckCompleted = 199,

  // OCRLabsCheck, Completed
  OCRLabsCheckEntered = 200,
  OCRLabsCheckTookFirstPicture = 201,
  OCRLabsCheckTookSecondPicture = 202,
  OCRLabsCheckTookSelfie = 203,
  OCRLabsCheckAppendMissingPropertiesCompleted = 204,
  OCRLabsCheckCompleted = 205,
  OCRLabsCheckHandoffCompleted = 206, // This means the desktop should show the append missing properties page.
  // OCRLabsCheck, Entered Screens
  OCRLabsCheckEnteredFirstScan = 2001,
  OCRLabsCheckEnteredSecondScan = 2002,
  OCRLabsCheckEnteredSelfie = 2003,
  OCRLabsCheckEnteredAppendMissingProperties = 2004,
  OCRLabsCheckSubmittedDocuments = 2069,
}