import { EToastType } from "../Enums/EToastType";
import { IManager, ManagerFormValues } from "../models/user/IManager";
import { RootStore } from "./RootStore";
import { history, routePrefixDashboard } from "../ApplicationRouter";
import { makeAutoObservable, runInAction } from "mobx";

export default class DtsManagerStore {
  rootStore: RootStore;
  loadingDtsManagers: boolean = false;
  submitting: boolean = false;
  dtsManagers: IManager[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getDtsManagers = async () => {
    runInAction(() => {
      this.loadingDtsManagers = true;
    });

    const { success, data }: { success: boolean; data: IManager[] } = await this.rootStore.baseApiService.get("dtsManager");
    if (success) {
      runInAction(() => {
        this.dtsManagers = data;
        this.loadingDtsManagers = false;
      });
    } else {
      runInAction(() => {
        this.loadingDtsManagers = false;
      });
    }
  }

  getDtsManager = async (id: string) => {
    runInAction(() => {
      this.loadingDtsManagers = true;
    });

    const { success, data }: { success: boolean; data: IManager } = await this.rootStore.baseApiService.get(`dtsManager/${id}`);
    if (success) {
      runInAction(() => {
        this.loadingDtsManagers = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingDtsManagers = false;
      });
    }
  }

  addDtsManager = async (values: ManagerFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.post('dtsManager', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      history.push(`${routePrefixDashboard}/dts-managers`);
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  editDtsManager = async (values: ManagerFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.put(`dtsManager/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      });
      history.push(`${routePrefixDashboard}/dts-managers`);
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  deleteDtsManager = async (id: string) => {
    runInAction(() => {
      this.submitting = true;
    });

    const success = await this.rootStore.baseApiService.delete(`dtsManager/${id}`);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.confirmModalStore.closeConfirmModal();
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Delete_Success");
      });
      this.getDtsManagers();
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }
}