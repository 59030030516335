export enum EValidationCategory {
  PERSONAL_INFORMATION = 0,
  DOCUMENT_INFORMATION = 1,
  ADDRESS_INFORMATION = 2,
  BANK_INFORMATION = 3,
}

export interface IValidationCategoryEnum {
  value: EValidationCategory;
  name: string;
}

const CategoryItem = (value: EValidationCategory, name: string): IValidationCategoryEnum => ({ value, name });

export const ValidationCategoryEnums  = (): IValidationCategoryEnum[] => {
  let list: IValidationCategoryEnum[] = [];

  list.push(CategoryItem(EValidationCategory.PERSONAL_INFORMATION, "Personal Information"));
  list.push(CategoryItem(EValidationCategory.DOCUMENT_INFORMATION, "Document Information"));
  list.push(CategoryItem(EValidationCategory.ADDRESS_INFORMATION, "Address Information"));
  list.push(CategoryItem(EValidationCategory.BANK_INFORMATION, "Bank Information"));

  return list;
};