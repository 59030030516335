import { ClientCreateEditDto, GetCustomerDto } from "../Types";
import { CustomerFormValues, ICustomer, ICustomerServices } from "../models/customer/ICustomer";
import { EToastType } from "../Enums/EToastType";
import { FlowFormValues, IFlow } from "../models/flow/IFlow";
import { IApiKeyResponse } from "../models/apiKey/IApikey";
import { IClientByCustomer } from "../models/client/IClient";
import { ICustomerStatistics } from "../models/customer/ICustomerStatistics";
import { OrganizationApiFormValues, OrganizationFormValues, OrganizationPreviewFormValues } from "../models/organization/IOrganization";
import { RootStore } from "./RootStore";
import { history, routePrefixDashboard } from "../ApplicationRouter";
import { makeAutoObservable, runInAction } from "mobx";

export default class CustomerClientStore {
  rootStore: RootStore;
  loading: boolean = false;
  submitting: boolean = false;
  submittingFlow: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  createClient = async (values: OrganizationFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success, data }: { success: boolean, data: ClientCreateEditDto } = await this.rootStore.baseApiService.post("customerclient/client", values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      return data;
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  updateClient = async (values: OrganizationFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`customerclient/client/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Edit_Success");
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  getAllClients = async () => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IClientByCustomer[] } = await this.rootStore.baseApiService.get("customerclient/clients/all");
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  getClientById = async (id: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: ClientCreateEditDto } = await this.rootStore.baseApiService.get(`customerclient/client/${id}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  getClientsByCustomerId = async (id: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IClientByCustomer[] } = await this.rootStore.baseApiService.get(`customerclient/clients/by-customer/${id}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  createNewApiKeyForClient = async (values: OrganizationApiFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success, data }: { success: boolean; data: IApiKeyResponse } = await this.rootStore.baseApiService.post(`customerclient/client/apikey/${values.customerClientId}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.confirmModalStore.closeConfirmModal();
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Save_Success");
      return data;
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  getFlowsByClient = async (customerClientId: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IFlow[] } = await this.rootStore.baseApiService.get(`customerclient/client/flow/${customerClientId}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      this.loading = false;
    }
  }

  createFlowForClient = async (values: FlowFormValues) => {
    runInAction(() => {
      this.submittingFlow = true;
    });
    const { success, data }: { success: boolean; data: IFlow } = await this.rootStore.baseApiService.post(`customerclient/client/flow`, values);
    if (success) {
      runInAction(() => {
        this.submittingFlow = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.submittingFlow = false;
      });
    }
  }

  editFlowForClient = async (values: FlowFormValues) => {
    runInAction(() => {
      this.submittingFlow = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`customerclient/client/flow/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submittingFlow = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
    } else {
      runInAction(() => {
        this.submittingFlow = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
    return success;
  }

  toggleFlowForClient = async (id: number) => {
    runInAction(() => {
      this.submittingFlow = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`customerclient/client/toggle-flow/${id}`, id);
    if (success) {
      runInAction(() => {
        this.submittingFlow = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Edit_Success");
      return true;
    } else {
      runInAction(() => {
        this.submittingFlow = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      return false;
    }
  }

  editFlowOrderForClient = async (values: FlowFormValues) => {
    runInAction(() => {
      this.submittingFlow = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`customerclient/client/flow-order/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submittingFlow = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Edit_Success");
    } else {
      runInAction(() => {
        this.submittingFlow = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  // Customer calls
  getCustomersRecent = async () => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: GetCustomerDto[] } = await this.rootStore.baseApiService.get("customerclient/customers/recent");
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  getAllCustomers = async () => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: ICustomerServices[] } = await this.rootStore.baseApiService.get("customerclient/customers/all");
    if (success) {
      runInAction(() => {
        this.loading = false;
      })
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  createCustomer = async (values: CustomerFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.post(`customerclient/customer`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      history.push(routePrefixDashboard)
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error")
    }
  }

  editCustomer = async (values: CustomerFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const success = await this.rootStore.baseApiService.put(`customerclient/customer/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Edit_Success");
      history.push(routePrefixDashboard)
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  getCustomerById = async (id: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: ICustomer } = await this.rootStore.baseApiService.get(`customerclient/customer/${id}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  toggleClientForCustomer = async (id: number) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`customerclient/customer/toggle-client/${id}`, id);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Edit_Success");
      return true;
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      return false;
    }
  }

  getStatisticsForCustomer = async (customerId: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: ICustomerStatistics[] } = await this.rootStore.baseApiService.get(`customerclient/customer/stats/${customerId}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  editThemeForCustomer = async (values: OrganizationPreviewFormValues) => {
    const formData = new FormData();
    formData.append("logo", values.logo);
    formData.append("themeColor", values.themeColor);

    runInAction(() => {
      this.submitting = true;
    });
    const { success } = await this.rootStore.baseApiService.post(`customerclient/client/theme/${values.id}`, formData, undefined, false);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Edit_Success");
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }
}