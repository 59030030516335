import { Field, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { ValidationCategoryEnums , IValidationCategoryEnum } from "../../../../Enums/EValidationCategory";
import { IComponentEnum, ComponentEnums } from "../../../../Enums/EComponent";
import { RootStoreContext } from "../../../../stores/RootStore";
import { OcrLabsValidationField, OcrLabsValidationFieldFormValues } from "../../../../Types";
import { CheckBox } from "../../../Common/form/CheckBox";
import { DropDown } from "../../../Common/form/Dropdown";
import { TextInput } from "../../../Common/form/TextInput";
import { Spinner } from "../../../Common/spinner/Spinner";
import { OcrValidationFieldsValidation } from "./OcrValidationFields.Validation";

interface IProps {
  flowId: number;
}

export const OcrValidationFields = observer(({ flowId }: IProps) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { loading, getOcrLabsValidationFieldsForFlow, submitting, updateOcrLabsValidationFieldsForFlow } = rootStore.flowSettingsStore;

  const [validationFields, setValidationFields] = useState<OcrLabsValidationField>();
  const [formValues, setFormValues] = useState(new OcrLabsValidationFieldFormValues());

  const [componentOptions, setComponentOptions] = useState<IComponentEnum[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<IValidationCategoryEnum[]>([]);

  useEffect(() => {
    const loadData = async () => {
      var validationFields = await getOcrLabsValidationFieldsForFlow(flowId);
      setValidationFields(validationFields);
      setFormValues(new OcrLabsValidationFieldFormValues(validationFields));
    }
    setComponentOptions(ComponentEnums());
    setCategoryOptions(ValidationCategoryEnums ());
    loadData();
  }, [flowId])

  const handleSubmit = async (values: OcrLabsValidationFieldFormValues) => {
    await updateOcrLabsValidationFieldsForFlow(flowId, values);
  }

  return (
    <>
      <Header as="h1">{t("Flow_Settings.General.ValidationFields")}</Header>
      <Segment loading={loading}>
        {!validationFields ? <Spinner /> : (
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={OcrValidationFieldsValidation}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Grid>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Field
                        component={DropDown}
                        name="category"
                        label={t("Validation_Fields.OCR_Labs.Category")}
                        value={formik.values.category}
                        options={categoryOptions.map((category) => {
                          return {
                            key: category.value,
                            value: category.value,
                            text: category.name
                          }
                        })}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={DropDown}
                        name="component"
                        label={t("Validation_Fields.OCR_Labs.Component")}
                        value={formik.values.component}
                        options={componentOptions.map((component) => {
                          return {
                            key: component.value,
                            value: component.value,
                            text: component.name
                          }
                        })}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Field
                        component={TextInput}
                        name="name"
                        label={t("Validation_Fields.OCR_Labs.Name")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={TextInput}
                        name="label"
                        label={t("Validation_Fields.OCR_Labs.Label")}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Field
                        component={TextInput}
                        name="width"
                        label={t("Validation_Fields.OCR_Labs.Width")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={TextInput}
                        name="order"
                        label={t("Validation_Fields.OCR_Labs.Order")}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2}>
                    <Grid.Column>
                      <Field
                        component={TextInput}
                        name="row"
                        label={t("Validation_Fields.OCR_Labs.Row")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="required"
                        label={t("Validation_Fields.OCR_Labs.Required")}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="right">
                      <Button type="submit" primary disabled={submitting}>
                        {t("Button.Submit")}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Segment>
    </>
  )

});