import * as yup from 'yup'
import axios from 'axios'
import { AnimationCheck } from '../../AnimationViews/AnimationCheck'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { Button, Form, Segment, Message } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { DevLog } from '../../../utils/DevLog'
import { ELogType } from '../../../Enums/ELogType'
import { FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { PostalCodeCheckResDto } from '../../../Types'
import { StateDispatch, StateValue } from '../../Context'
import { TimerButton } from '../../Common/Button/TimerButton'
import { postTransactionProgressLog } from '../../../Services/Backend'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useView } from '../../../Hooks/useView'

export const PostalCodeCheckComponent: FunctionComponent = () => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const dispatch = useContext(StateDispatch);
  const [res, setRes] = useState<PostalCodeCheckResDto | undefined>(undefined);
  const [view, setView] = useView<"form" | "success" | "form-addition" | "checking">("form");
  const [addition, setAddition] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [hasError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const dropdownOptions = useMemo(() => res?.address.houseNumberAdditions.map(el => el === "" ? ({ key: el, value: "NONE", text: t("Address.No_Addition") }) : ({ key: el, value: el, text: el })), [res]);

  const validationSchema = yup.object({
    POSTALCODE: yup
      .string().test("postalcode-test", t("Address.PostalCode_Error"), (e) => isValidPostalCode(e ?? "")),
    HOUSENUMBER: yup.number().required(),
  });

  const formik = useFormik({
    initialValues: {
      POSTALCODE: "",
      HOUSENUMBER: 0,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      DevLog(JSON.stringify(values));
    },
  });

  const isValidPostalCode = (e: string) => /^[0-9]{4,10}[a-zA-Z]{2}$/g.test(e.replaceAll(" ", "")) && !(e.replaceAll(" ", "").length! > 12);

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.AddressCheckEntered });
  }, [])

  useEffect(() => {
    setError(formik.errors.POSTALCODE !== undefined || formik.errors.HOUSENUMBER !== undefined);
  }, [formik.errors]);

  const postBackend = async () => {
    setView("checking")
    setErrorMessage(null);
    if (res == undefined) {
      await axios.post<PostalCodeCheckResDto>("/api/addressCheck/startService?ctxId=" + state.ctxId, { ctxid: state.ctxId, postalCode: formik.values.POSTALCODE.replaceAll(" ", ""), houseNumber: formik.values.HOUSENUMBER, service: 11 }).then(res => {
        if (res.status === 200) {
          setRes(res.data);
          if (res.data.address.houseNumberAdditions.length === 0) {
            setView("success");
          } else {
            setView("form-addition")
          }
        } else {
          setView("form")
        }
      }).catch(er => {
        if (er.response && er.response.status === 500) {
          setErrorMessage(t("Address.InvalidCombination"));
        }
        setView("form");
      });
    } else {
      axios.post("/api/addressCheck/finishService?ctxId=" + state.ctxId, { ctxid: state.ctxId, addition: addition === "NONE" ? "" : addition, service: 11 }).then(res => {
        if (res.status === 200) {
          setView("success");
          setSubmitted(true);
        }
      }).catch(err => {
        setView("form");
      });
    }
  }

  const onSuccess = () => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.AddressCheckCompleted });
    dispatch({ type: "setView", data: "form" });
    setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
  }

  const renderView = () => {
    switch (view) {
      case "form":
        return (
          <span id="postalcode-check-container">
            <h1 className="service-item-header">{t("Address.Header")}</h1>
            <h2 className="service-item-subheader">{t("Address.SubHeader")}</h2>
            <span className={`service-item-input-group`}>
              <Form.Input name="POSTALCODE" id="POSTALCODE" label={t("Address.PostalCode")} placeholder={t("Address.PostalCodePlaceholder")} onChange={(e) => formik.handleChange(e)} fluid error={formik.errors.POSTALCODE !== undefined ? { content: formik.errors.POSTALCODE, pointing: 'above', color: "red" } : undefined} />
              <Form.Input min="0" type="number" className="mt-4" label={t("Address.HouseNumber")} name="HOUSENUMBER" id="HOUSENUMBER" placeholder={t("Address.HouseNumber")} onChange={(e) => formik.handleChange(e)} fluid error={formik.errors.HOUSENUMBER !== undefined ? { content: formik.errors.HOUSENUMBER } : undefined} />
            </span>
            {errorMessage &&
              <Message color="red"> <Message.Header>{t("Notification.Error")}</Message.Header>{errorMessage}</Message>
            }
          </span>
        )
      case "form-addition":
        return (
          <span id="postalcode-check-container">
            <h1 className="service-item-header">{t("Address.Choose_Addition")}</h1>
            <span className={`service-item-input-group`}>
              <Form>
                <Form.Group>
                  <Form.Input width={10} label={t("Address.StreetName")} placeholder={t("Address.StreetName")} disabled value={`${res?.address.street} ${res?.address.houseNumber}`} />
                  <Form.Select width={6} loading={dropdownOptions === undefined} label={t("Address.Addition")} defaultValue="" options={dropdownOptions ?? []} onChange={(e, { value }) => setAddition(value as string)} placeholder={t("Address.Addition")} />
                </Form.Group>
              </Form>
            </span>
          </span>
        )
      case "checking":
        return <AnimationCheck header="General_UI.Verifying" text="General_UI.One_Moment" key={`${view}-check`} svgComponent={state.org.theme.config.ibanCheckSvg && <state.org.theme.config.ibanCheckSvg fill={state.org.theme.themeColor} />} />
      case "success":
        return <AnimationSuccess header="General_UI.Great" text="General_UI.Correct" key={`${view}-success`} />
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container">
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Segment basic className="service-item-button-container mt-0">
        {view === "success" ?
          <TimerButton countDownStart={state.org.theme.config.countDownStart ?? 5} callback={onSuccess} /> :
          <Button color="green" floated="right" onClick={() => { view as any == "success" ? onSuccess() : postBackend(); }} loading={view === "checking"} disabled={hasError || (res !== undefined && addition == "") || (formik.values.POSTALCODE == "" || formik.values.HOUSENUMBER == 0)}>
            {t("General_UI.Next")}
          </Button>
        }
      </Segment>
    </>
  );
};
