import { Button, Form, Grid } from "semantic-ui-react";
import { Field, Formik } from "formik";
import { IUserFormLoginValues } from "../../models/user/IUser";
import { LoginValidationSchema } from "./Login.Validation";
import { Redirect } from "react-router-dom";
import { RootStoreContext } from "../../stores/RootStore";
import { TextInput } from "../../Components/Common/form/TextInput";
import { history, routePrefixDashboard } from "../../ApplicationRouter";
import { observer } from "mobx-react-lite";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DevLog } from "../../utils/DevLog";

export const LoginForm = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { login, redirectToRole } = rootStore.authenticationStore;
  const isAuthenticated = rootStore.authorizationStore.isAuthenticated();
  const [message, setMessage] = useState("");
  const [submitting, setSubmitting] = useState(false);

  const handleLogin = async (values: IUserFormLoginValues) => {
    setSubmitting(true);

    const response = await login(values, routePrefixDashboard);
    setSubmitting(false);
    DevLog("Login Response", response);

    if (response.success) {
      history.push(response.redirect);
    }
    if (response.message === `"Error_Account_Locked"`) {
      setMessage(t("Authentication.Error.Account_Locked"))
    } else {
      setMessage(t("Authentication.Error.Invalid_Credentials"))
    }
  }

  return (
    isAuthenticated
      ? <Redirect to={redirectToRole()} />
      : <>
        <Formik
          initialValues={{ password: "", userName: "" }}
          onSubmit={handleLogin}
          validationSchema={LoginValidationSchema}
        >
          {(formik) => (
            <Form onSubmit={formik.handleSubmit}>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <Field
                      component={TextInput}
                      name="userName"
                      label="Gebruikersnaam"
                      type="email"
                      required
                    />
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                  <Grid.Column>
                    <Field
                      component={TextInput}
                      name="password"
                      label="Wachtwoord"
                      type="password"
                      required
                    />
                  </Grid.Column>
                </Grid.Row>

                <Grid.Row textAlign="right">
                  <Grid.Column>
                    <Button
                      disabled={submitting}
                      loading={submitting}
                      primary
                      type="submit"
                    >
                      Login
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </>
  )
})