import { Button, Divider, Grid, Header, Icon } from "semantic-ui-react";
import { DataView, DataViewSearch, IDataViewColumn } from "../../Common/dataview/Dataview";
import { IAdmin } from "../../../models/user/IAdmin";
import { Link } from "react-router-dom";
import { RootStoreContext } from "../../../stores/RootStore";
import { Spinner } from "../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { routePrefixDashboard } from "../../../ApplicationRouter";
import { toJS } from "mobx";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export const AdminList = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { admins, loadingAdmins, getAdmins, deleteAdmin } = rootStore.adminStore;
  const { openConfirmModal } = rootStore.confirmModalStore;
  const [searchInput, setSearchInput] = useState("");

  useEffect(() => {
    getAdmins();
  }, [getAdmins])

  const getColumns = (): IDataViewColumn[] => {
    return [
      // { headerName: "Username", field: "userName", sortable: true, flex: 1, filter: false, sort: "asc" },
      { headerName: t("Dataview.Email_Address"), field: "email", sortable: true, flex: 1, filter: false, sort: "asc" },
      { headerName: t("Dataview.Firstname"), field: "firstName", sortable: true, flex: 1, filter: false },
      { headerName: t("Dataview.Lastname"), field: "lastName", sortable: true, flex: 1, filter: false },
      { headerName: t("Dataview.Last_Logged_In"), cellRenderer: "datetime", field: "lastActivity", sortable: true, flex: 1, filter: false },
      { headerName: t("Dataview.Actions"), cellRenderer: "actions", sortable: true, flex: 1 },
    ]
  }

  const renderCellAction = (tableData: any) => {
    const admin: IAdmin = toJS(tableData.data);
    return (
      <div>
        <Button as={Link} className="icon" compact primary to={`${routePrefixDashboard}/admin/edit/${admin.id}`}>
          <Icon name="edit outline" />
        </Button>
        <Button className="icon" color="red" compact
          onClick={() => openConfirmModal(t("Button.Cancel"), t("Button.Delete"), t("Confirm_Modal.Delete_Admin"), () => deleteAdmin(admin.id))}>
          <Icon name="trash alternate outline" />
        </Button>
      </div>
    )
  }

  return (
    <>
      <Header as="h1">{t("Admin.List")}</Header>
      <Divider />
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <DataViewSearch onFilterChange={(input: any) => setSearchInput(input.target.value)} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Button as={Link} floated="right" color="green" to={`${routePrefixDashboard}/admin/new`}>
              <Icon name="plus" />
              {t("Admin.New")}
            </Button>
          </Grid.Column>
        </Grid.Row>
        {loadingAdmins ? <Spinner /> :
          <Grid.Row>
            <Grid.Column>
              <DataView
                actionsRenderer={(data: any) => renderCellAction(data)}
                columns={getColumns()}
                data={admins}
                quickFilter={searchInput}
              />
            </Grid.Column>
          </Grid.Row>
        }
      </Grid>
    </>
  )
});