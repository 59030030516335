import { Container, Divider, Header } from "semantic-ui-react";
import { DtsManagerForm } from "../../../../Components/secure/dtsManager/DtsManagerForm";
import { useTranslation } from "react-i18next"

export const CreateDtsManager = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Header as="h1">{t("DtsManager.New")}</Header>
      <Divider />
      <DtsManagerForm />
    </Container>
  )
}