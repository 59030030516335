import { IRoute } from "../models/routes/IRoute";
import { RootStoreContext } from "../stores/RootStore";
import { useContext } from "react";

export const isArrayWithLength = (arr: string[]) => {
  return Array.isArray(arr) && arr.length;
};

export const GetAllowedRoutes = (routes: IRoute[]) => {
  const rootStore = useContext(RootStoreContext);
  const role = rootStore.authorizationStore.role;

  return routes.filter((route: IRoute) => {
    if (!route.permission || !isArrayWithLength(route.permission)) {
      return false; // Don't render route if permission is not defined.
    } else {
      return route.permission.indexOf(role) > -1;
    }
  })
}