import * as yup from 'yup'
import ReactMarkdown from 'react-markdown'
import { AnimationCheck } from '../../AnimationViews/AnimationCheck'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { Button, Form, Segment } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { DevLog } from '../../../utils/DevLog'
import { ELogType } from '../../../Enums/ELogType'
import { FunctionComponent, useContext, useEffect } from 'react'
import { postIBANNameCheck, postTransactionProgressLog } from '../../../Services/Backend'
import { StateDispatch, StateValue } from '../../Context'
import { TimerButton } from '../../Common/Button/TimerButton'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useView } from '../../../Hooks/useView'
var IBAN = require("iban");

export const IbanCheckComponent: FunctionComponent = () => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const dispatch = useContext(StateDispatch);
  const [view, setView] = useView<"success" | "form" | "checking">("form");

  const getName = () => {
    let givenNames = localStorage.getItem("GIVEN_NAMES");
    let surname = localStorage.getItem("SURNAME");
    if (givenNames == null || surname == null) {
      return "";
    }
    return givenNames + " " + surname;
  }

  const validationSchema = yup.object({
    IBAN: yup
      .string()
      .min(1, t("Iban.Errors.Iban_Min"))
      .test("iban-test", t("Iban.Errors.Iban_Not_Valid"), (e) => IBAN.isValid(e)),
    NAME: yup.string().min(1, t("Iban.Errors.Name_Required")),
  });

  const formik = useFormik({
    initialValues: {
      IBAN: "",
      NAME: getName(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      DevLog(JSON.stringify(values));
    },
  });

  useEffect(() => {
    if (getName() !== "") {
      formik.setFieldValue("NAME", getName());
    }
  }, [])

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.IbanCheckEntered });
  }, [])

  const postBackend = () => {
    setView("checking");
    postIBANNameCheck(formik.values.NAME, formik.values.IBAN.toUpperCase().replace(/\s/g, ''), state.ctxId).then(res => {
      if (res.status === 200) {
        if (res.data.check.iban.found === false) {
          formik.setErrors({ IBAN: t("General_UI.Incorrect") })
          setView("form");
          return;
        }
        if (res.data.check.name.validity === "VALID" && res.data.check.iban.valid && res.data.ibanNameMatching != null && res.data.ibanNameMatching.type === "MATCHING") {
          postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.IbanCheckCompleted });
          setView("success");
          return;
        } else if (res.data.ibanNameMatching != null && res.data.ibanNameMatching.type === "MISTYPE") {
          setView("form");
          formik.setErrors({ IBAN: `${t("Iban.Name_Mismatch_1")}${formik.values.NAME}${t("Iban.Name_Mismatch_2")}` });
          return;
        }
        else {
          setView("form")
          formik.setErrors({ IBAN: t("General_UI.Incorrect") })
          return;
        }
      }
    }).catch(e => {
      setView("form");
      setTimeout(() => { e?.response?.data?.errors?.Name !== undefined ? formik.setErrors({ NAME: e?.response?.data?.errors?.Name[0] }) : formik.setErrors({ IBAN: e?.response?.data?.errors?.Iban[0] }) }, 700);
    })
  }

  const onSuccess = () => {
    setView("form");
    setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
  }

  const renderView = () => {
    switch (view) {
      case "form":
        return (
          <span id="iban-container">
            <h1 className="service-item-header">{t("Iban.Header")}</h1>
            <h2 className="service-item-subheader"><ReactMarkdown skipHtml>{t("Iban.SubHeader")!}</ReactMarkdown></h2>
            <span className={`service-item-input-group`}>
              <Form.Input value={formik.values.NAME} name="NAME" id="NAME" label={t("Iban.Name")} placeholder={t("Iban.Debit_Card")} onChange={(e) => formik.handleChange(e)} fluid error={formik.errors.NAME !== undefined ? { content: formik.errors.NAME, pointing: 'below' } : undefined} />
              <Form.Input className="mt-4" label="IBAN" name="IBAN" id="IBAN" placeholder={t("Iban.Iban")} onChange={(e) => formik.handleChange(e)} fluid error={formik.errors.IBAN !== undefined ? { content: formik.errors.IBAN } : undefined} />
            </span>
          </span>
        )
      case "checking":
        return <AnimationCheck header="General_UI.Verifying" text="General_UI.One_Moment" key={`${view}-check`} svgComponent={state.org.theme.config.ibanCheckSvg && <state.org.theme.config.ibanCheckSvg fill={state.org.theme.themeColor} />} />
      case "success":
        return <AnimationSuccess header="General_UI.Great" text="General_UI.Correct" key={`${view}-success`} />
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container">
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Segment basic className="service-item-button-container mt-0">
        {view === "success" ?
          <TimerButton countDownStart={state.org.theme.config.countDownStart ?? 5} callback={onSuccess} /> :
          <Button color="green" floated="right" type="submit" onClick={() => { postBackend(); }} loading={view === "checking"} disabled={Object.values(formik.errors ?? []).length < 1 && !formik.dirty}>
            {t("General_UI.Next")}
          </Button>
        }
      </Segment>
    </>
  );
};
