export enum EComponentType {
  Text = 0,
  Number = 1,
  Date = 2,
  Dropdown = 3,
  Checkbox = 4,
}

export interface IComponentEnum {
  value: EComponentType;
  name: string;
}

const ComponentItem = (value: EComponentType, name: string): IComponentEnum => ({ value, name})

export const ComponentEnums = (): IComponentEnum[] => {
  let list: IComponentEnum[] = [];

  list.push(ComponentItem(EComponentType.Text, EComponentType[EComponentType.Text]));
  list.push(ComponentItem(EComponentType.Number, EComponentType[EComponentType.Number]));
  list.push(ComponentItem(EComponentType.Date, EComponentType[EComponentType.Date]));
  list.push(ComponentItem(EComponentType.Dropdown, EComponentType[EComponentType.Dropdown]));
  list.push(ComponentItem(EComponentType.Checkbox, EComponentType[EComponentType.Checkbox]));

  return list;
}