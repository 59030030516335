import { AdminHome } from "../users/admin/AdminHome"
import { Container, Divider, Header } from "semantic-ui-react"
import { DtsManagerHome } from "../users/dtsManagers/DtsmanagerHome"
import { RenderGuard } from "../../../Components/Common/guards/RenderGuard"
import { Roles } from "../../../Enums/Roles"
import { CustomerManagerHome } from "../users/customerManager/CustomerManagerHome"
import { ClientManagerHome } from "../users/clientManager/ClientManagerHome"

export const DashboardHome = () => {
  return (
    <>
      <Container>
        <Header as="h1">Dashboard</Header>
      </Container>
      <Divider />
      <RenderGuard roles={Roles.ADMIN}>
        <AdminHome />
      </RenderGuard>
      <RenderGuard roles={Roles.CUSTOMERMANAGER}>
        <CustomerManagerHome />
      </RenderGuard>
      <RenderGuard roles={Roles.DTSMANAGER}>
        <DtsManagerHome />
      </RenderGuard>
      <RenderGuard roles={Roles.CLIENTMANAGER}>
        <ClientManagerHome />
      </RenderGuard>
    </>
  )
}