import { RootStore } from "./RootStore";
import { makeAutoObservable } from "mobx";

interface IConfirmModal {
  open: boolean;
  cancelText: string | null;
  confirmText: string | null;
  content: string | null;
  onConfirm: any;
}

export default class ConfirmModalStore {
  rootStore: RootStore;
  confirmModal: IConfirmModal;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.confirmModal = {
      open: false,
      cancelText: "Cancel",
      confirmText: "Yes",
      content: "Are you sure?",
      onConfirm: true,
    };
    makeAutoObservable(this);
  }

  openConfirmModal = (
    cancelText: string,
    confirmText: string,
    content: string,
    onConfirm: any
  ) => {
    this.confirmModal.open = true;
    this.confirmModal.cancelText = cancelText;
    this.confirmModal.confirmText = confirmText;
    this.confirmModal.content = content;
    this.confirmModal.onConfirm = onConfirm;
  };

  closeConfirmModal = () => {
    this.confirmModal.open = false;
    this.confirmModal.cancelText = null;
    this.confirmModal.confirmText = null;
    this.confirmModal.content = null;
  };
}
