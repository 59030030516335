import * as Yup from 'yup'
import axios from 'axios'
import { AppendErrors, MBUMissingPropertiesDto, RequiredFieldsDto } from '../../../Types'
import { Button, Form as SemanticForm, Segment } from 'semantic-ui-react'
import { Field, Formik } from 'formik'
import { IMitekSettings } from '../../../models/flow/settings/IMitekSettings'
import { NateGisCheckResponseDto } from '../../../Types/Nategis/NateGisCheckResponseDto'
import { SelectDropdown } from '../../Common/SelectDropdown'
import { StateValue } from '../../Context'
import { TextInput } from '../../Common/form/TextInput'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import './AppendMissingErrors.css'

type IProps = {
  data: MBUMissingPropertiesDto;
  callback: () => void;
  requiredAndMissingDto?: RequiredFieldsDto;
  mitekSettings: IMitekSettings;
};

type MNIAppendMissingPropertiesResponseDto = {
  nateGisCheckResponseDto: NateGisCheckResponseDto; // TODO: Type nategis response
  appendMissingResponseDto: { errors: AppendErrors[] };
}

export const MNIAppendMissingProperties = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const setErrorsState = (res: AppendErrors[]) => {
    const tempE: any = {};
    res.forEach((error: AppendErrors) => {
      tempE[error.fieldName as any] = t(`Mitek.Append_Missing.${error.fieldName.charAt(0).toUpperCase() + error.fieldName.slice(1)}`) + t("Mitek.Append_Missing.Error_1") + error.allowedEdits + t("Mitek.Append_Missing.Error_2") + error.actualEdits + ".";
    });
    return (tempE);
  }

  const optionsGender = [
    { key: 'M', text: t("Pep.Male"), value: 'M' },
    { key: 'F', text: t("Pep.Female"), value: 'F' },
  ]

  var validationSchema = Yup.object().shape({
    dateOfExpiryRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.dateOfExpiry ?? false),
    dateOfIssueRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.dateOfIssue ?? false),
    // dateOfBirthRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.dateOfBirth ?? false),

    fullNameRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.fullName ?? false),
    givenNamesRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.givenNames ?? false),
    surnameRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.surname ?? false),

    documentNumberRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.documentNumber ?? false),
    issuingAuthorityRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.issuingAuthority ?? false),
    personalGovIDRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.personalGovID ?? false),

    personalGovID: Yup.string()
      .label('Mitek.Append_Missing.personalGovID')
      .when('personalGovIDRequired', {
        is: true,
        then: Yup.string().required(
          `${t('Mitek.Append_Missing.PersonalGovID')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.string()
      }),
    issuingAuthority: Yup.string()
      .label('Mitek.Append_Missing.IssuingAuthority')
      .when('issuingAuthorityRequired', {
        is: true,
        then: Yup.string().required(
          `${t('Mitek.Append_Missing.IssuingAuthority')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.string()
      }),
    documentNumber: Yup.string()
      .label('Mitek.Append_Missing.DocumentNumber')
      .when('documentNumberRequired', {
        is: true,
        then: Yup.string().required(
          `${t('Mitek.Append_Missing.DocumentNumber')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.string()
      }),
    givenNames: Yup.string()
      .label('Mitek.Append_Missing.GivenNames')
      .when('givenNamesRequired', {
        is: true,
        then: Yup.string().required(
          `${t('Mitek.Append_Missing.GivenNames')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.string()
      }),
    surname: Yup.string()
      .label('Mitek.Append_Missing.Surname')
      .when('surnameRequired', {
        is: true,
        then: Yup.string().required(
          `${t('Mitek.Append_Missing.Surname')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.string()
      }),
    dateOfIssue: Yup.date()
      .label('Mitek.Append_Missing.DateOfIssue')
      .when('dateOfIssueRequired', {
        is: true,
        then: Yup.date().required(
          `${t('Mitek.Append_Missing.DateOfIssue')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.date()
      }),
    dateOfExpiry: Yup.date()
      .when('dateOfExpiryRequired', {
        is: true,
        then: Yup.date().required(
          `${t('Mitek.Append_Missing.DateOfExpiry')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.date()
      }),
    // dateOfBirth: Yup.date()
    //   .when('dateOfBirthRequired', {
    //     is: true,
    //     then: Yup.date().required(
    //       `${t('Mitek.Append_Missing.DateOfBirth')} ${t(
    //         'General_UI.Is_Required'
    //       )}`),
    //     otherwise: Yup.date()
    //   }),
    // phone: Yup.object().shape({
    //   phoneNumberMobile: Yup.string().required(t("Phone_Number.Mobile_Phone_Number") + " " + t("General_UI.Is_Required")),
    // }),
    gender: Yup.string()
      .label("Mitek.Append_Missing.Gender")
      .required(`${t('Mitek.Append_Missing.Gender')} ${t(
        'General_UI.Is_Required'
      )}`),
  });

  const handleSubmit = (data: any, formProps: any) => {
    setIsSubmitting(true);

    axios.post<MNIAppendMissingPropertiesResponseDto>(
      `/api/MNICheck/appendmissingproperties?ctxId=${state.ctxId}`,
      {
        ctxId: state.ctxId,
        givenNames: data.givenNames,
        surname: data.surname,
        fullName: data.givenNames + " " + data.surname,
        documentNumber: data.documentNumber,
        personalGovId: data.personalGovID,
        dateOfBirth: props.mitekSettings.freeCruksEdit && (data?.dateOfBirth !== "" && data.dateOfBirth !== undefined) ? new Date(data.dateOfBirth).toISOString() : "",
        dateOfIssue: (data?.dateOfIssue !== "" && data.dateOfIssue !== undefined) ? new Date(data.dateOfIssue).toISOString() : "",
        dateOfExpiry: (data?.dateOfExpiry !== "" && data.dateOfExpiry !== undefined) ? new Date(data.dateOfExpiry).toISOString() : "",
        issuingAuthority: data.issuingAuthority,
        gender: data.gender,
      }
    ).then(res => {
      if (res.status !== 200) {
        setIsError(true);
        setIsSubmitting(false);
      } else {
        if (res.data.appendMissingResponseDto.errors != null && res.data.appendMissingResponseDto.errors != undefined && res.data.appendMissingResponseDto.errors.length > 0) {
          formProps.setErrors(res.data.appendMissingResponseDto.errors.length > 0 ? setErrorsState(res.data.appendMissingResponseDto.errors) : undefined);
          setIsError(true);
        } else {
          // localStorage.setItem("DATEOFBIRTH", data.dateOfBirth ?? "");
          localStorage.setItem("GIVEN_NAMES", data.givenNames ?? "");
          localStorage.setItem("GENDER", data.gender ?? "");
          localStorage.setItem("SURNAME", data.surname ?? "");
          props.callback();
        }
      }
      setIsSubmitting(false);
    }).catch(err => {
      setIsError(true);
      setIsSubmitting(false);
    })
  };

  return (
    <>
      <span className='mb-2 mt-0'>
        <h1 className={`service-item-header${state.org.theme.clientClass ? '-' + state.org.theme.clientClass : ''} mt-0`}>
          {t('Mitek.Append_Missing.Look_Right')}
        </h1>
        <h2 className={`service-item-subheader${state.org.theme.clientClass ? '-' + state.org.theme.clientClass : ''} mb-4 mt-0`}>
          {isError ? t("Mitek.Append_Missing.Only_Small_Edits") : t('Mitek.Append_Missing.Append_Edit')}
        </h2>
      </span>

      <Formik
        initialValues={{
          personalGovID: props.data.personalGovID ?? '',
          issuingAuthority: props.data.issuingAuthority ?? '',
          documentNumber: props.data.documentNumber ?? '',
          givenNames: props.data.givenNames ?? '',
          surname: props.data.surname ?? '',
          dateOfIssue: props.data.dateOfIssue ?? '',
          dateOfExpiry: props.data.dateOfExpiry ?? '',
          dateOfBirth: props.data.dateOfBirth ?? '',
          // phone: {
          //   phoneNumberMobile: "",
          //   phoneNumber: ""
          // },
          gender: props.data.gender
        }}
        onSubmit={(data: any, formProps: any) => handleSubmit(data, formProps)}
        validationSchema={validationSchema}
        style={{ flex: 1 }}
      >
        {(formProps) => {
          return (
            <SemanticForm
              onSubmit={formProps.handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: "space-between" }}
            >
              <span style={{ flex: 1 }}>
                <SemanticForm.Group id="group1" unstackable={formProps.values.givenNames.length < 20} widths="2" className='mb-4'>
                  <Field
                    component={TextInput}
                    id="givenNames"
                    label={t('Mitek.Append_Missing.GivenNames')}
                    name="givenNames"
                    type="text"
                    required={props.requiredAndMissingDto?.requiredFields?.givenNames ?? false}
                    formInput={{ placeholder: t('Mitek.Append_Missing.GivenNames'), fluid: true }}
                    error={formProps.errors.givenNames}
                  />

                  <Field
                    component={TextInput}
                    id="surname"
                    label={t('Mitek.Append_Missing.Surname')}
                    name="surname"
                    type="text"
                    required={props.requiredAndMissingDto?.requiredFields?.surname ?? false}
                    formInput={{ placeholder: t('Mitek.Append_Missing.Surname'), fluid: true }}
                    error={formProps.errors.surname}
                  />
                </SemanticForm.Group>

                {
                  (props.requiredAndMissingDto?.requiredFields?.personalGovID || props.data.personalGovID) && (
                    <SemanticForm.Group unstackable widths="2" className='mb-4'>
                      <Field
                        component={TextInput}
                        id="personalGovID"
                        label={t('Mitek.Append_Missing.PersonalGovID')}
                        name="personalGovID"
                        type="text"
                        formInput={{ placeholder: t('Mitek.Append_Missing.PersonalGovID') }}
                        required={props.requiredAndMissingDto?.requiredFields?.personalGovID}
                        error={formProps.errors.personalGovID}
                      />
                      <Field
                        component={SelectDropdown}
                        options={optionsGender}
                        id="gender"
                        label={t("Pep.Gender")}
                        search
                        name="gender"
                        formInput={{ placeHolder: t("Pep.Gender"), fluid: true }}
                        required
                        error={formProps.errors.gender}
                      />
                    </SemanticForm.Group>
                  )
                }
                <SemanticForm.Group unstackable widths={(props.requiredAndMissingDto?.requiredFields?.dateOfBirth || props.data.dateOfBirth) ? 2 : 1} className='mb-4'>
                  <Field
                    component={TextInput}
                    id="documentNumber"
                    label={t('Mitek.Append_Missing.DocumentNumber')}
                    name="documentNumber"
                    type="text"
                    formInput={{ placeholder: t('Mitek.Append_Missing.DocumentNumber') }}
                    required={props.requiredAndMissingDto?.requiredFields?.documentNumber}
                    error={formProps.errors.documentNumber}
                  />
                  {
                    (props.requiredAndMissingDto?.requiredFields?.dateOfBirth || props.data.dateOfBirth) && (
                      <Field
                        component={TextInput}
                        id="dateOfBirth"
                        label={t('Mitek.Append_Missing.DateOfBirth')}
                        name="dateOfBirth"
                        type="date"
                        disabled={true && !props.mitekSettings.freeCruksEdit}
                        formInput={{ placeholder: t('Mitek.Append_Missing.DateOfBirth'), fluid: true, }}
                        error={formProps.errors.dateOfBirth}
                        required={props.requiredAndMissingDto?.requiredFields?.dateOfBirth}
                      />)
                  }
                </SemanticForm.Group>
                {
                  (props.requiredAndMissingDto?.requiredFields?.issuingAuthority || props.data.issuingAuthority) && (
                    <div className='mb-4'>
                      <Field
                        component={TextInput}
                        id="issuingAuthority"
                        label={t('Mitek.Append_Missing.IssuingAuthority')}
                        name="issuingAuthority"
                        type="text"
                        formInput={{ placeholder: t('Mitek.Append_Missing.IssuingAuthority') }}
                        required={props.requiredAndMissingDto?.requiredFields?.issuingAuthority}
                        error={formProps.errors.issuingAuthority}
                      />
                    </div>
                  )
                }

                <SemanticForm.Group id="group2" unstackable widths={props.requiredAndMissingDto?.requiredFields?.dateOfExpiry ? "2" : "equal"} className='mb-4'>
                  <Field
                    component={TextInput}
                    id="dateOfIssue"
                    label={t('Mitek.Append_Missing.DateOfIssue')}
                    name="dateOfIssue"
                    type="date"
                    formInput={{ placeholder: t('Mitek.Append_Missing.DateOfIssue'), fluid: true }}
                    error={formProps.errors.dateOfIssue}
                    required={props.requiredAndMissingDto?.requiredFields?.dateOfIssue}
                  />
                  {
                    (props.requiredAndMissingDto?.requiredFields?.dateOfExpiry || props.data.dateOfExpiry) && (
                      <Field
                        component={TextInput}
                        id="dateOfExpiry"
                        label={t('Mitek.Append_Missing.DateOfExpiry')}
                        name="dateOfExpiry"
                        type="date"
                        formInput={{ placeholder: t('Mitek.Append_Missing.DateOfExpiry'), fluid: true }}
                        error={formProps.errors.dateOfExpiry}
                        required={props.requiredAndMissingDto?.requiredFields?.dateOfExpiry}
                      />)
                  }
                </SemanticForm.Group>

                {/* <SemanticForm.Group id="group2" unstackable widths={"equal"} className='mb-4'>
                  <Field
                    component={TextInput}
                    id="phone.phoneNumber.phoneNumberMobile"
                    label={t(
                      'Phone_Number.Mobile_Phone_Number'
                    )}
                    required
                    name="phone.phoneNumberMobile"
                    type="tel"
                    formInput={{ placeholder: t('Phone_Number.Mobile_Phone_Number'), fluid: true }}
                  />
                </SemanticForm.Group> */}
              </span>

              <Segment basic className={`service-item-button-container mt-0`} >
                <Button
                  floated="right"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  color={isError ? 'red' : 'green'}
                  type="submit"
                  className="full-width"
                >
                  {isError
                    ? t('General_UI.Try_Again')
                    : t('General_UI.Next')}
                </Button>
              </Segment>

            </SemanticForm>
          )
        }}
      </Formik>
    </>
  );
};
