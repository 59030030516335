import * as Yup from "yup";

export const FlowSettingsBigValidation = () => Yup.object().shape({
  isVisible: Yup.boolean(),
  bigSettings: Yup.object().shape({
    scoreCheck: Yup.boolean(),
    bankAccountCreditCheck: Yup.boolean(),
    bankAccountFraudCheck: Yup.boolean(),
    bankAccountIdentCheck: Yup.boolean(),
    bankAccountIdentFeatureListCheck: Yup.boolean(),
    deviceFraudCheck: Yup.boolean(),
    emailFraudCheck: Yup.boolean(),
    emailIdentFeatureListCheck: Yup.boolean(),
    personCreditCheck: Yup.boolean(),
    personFraudCheck: Yup.boolean(),
    personIdentAddressCheck: Yup.boolean().test(
      'validate-person-validation-1', "Flow_Settings.Big.Error.Person_Validation_Required",
      function () {
        if (this.parent.personIdentCheck || this.parent.personIdentAddressCheck || this.parent.personIdentWatchListCheck) {
          return true;
        }
        this.createError();
        return false;
      }
    ),
    personIdentCheck: Yup.boolean().test(
      'validate-person-validation-2', "Flow_Settings.Big.Error.Person_Validation_Required",
      function () {
        if (this.parent.personIdentCheck || this.parent.personIdentAddressCheck || this.parent.personIdentWatchListCheck) {
          return true;
        }
        this.createError();
        return false;
      }
    ),
    personIdentFeatureListCheck: Yup.boolean(),
    personIdentNewCustomerCheck: Yup.boolean(),
    personIdentPhoneCheck: Yup.boolean(),
    personIdentWatchListCheck: Yup.boolean().test(
      'validate-person-watchlist', 'Flow_Settings.Big.Error.No_Watchlist_Type_Selected',
      function (value) {
        if (!value || this.parent.watchListCheckAdverseMedia || this.parent.watchListCheckAssociatedEntity || this.parent.watchListCheckEnforcements || this.parent.watchListCheckPEP || this.parent.watchListCheckSanction) {
          return true;
        }
        this.createError();
        return false;
      }
    ).test(
      'validate-person-validation-3', "Flow_Settings.Big.Error.Person_Validation_Required",
      function () {
        if (this.parent.personIdentCheck || this.parent.personIdentAddressCheck || this.parent.personIdentWatchListCheck) {
          return true;
        }
        this.createError();
        return false;
      }
    ),
    watchListCheckAdverseMedia: Yup.boolean(),
    watchListCheckAssociatedEntity: Yup.boolean(),
    watchListCheckEnforcements: Yup.boolean(),
    watchListCheckPEP: Yup.boolean(),
    watchListCheckSanction: Yup.boolean(),
  })
})