import OCRDynamicValidatePropertiesComponent from "../AppendMissingProperties/OCRDynanicValidatePropertiesComponent";
import ReactMarkdown from "react-markdown";
import { Button, Header, Progress, Segment } from "semantic-ui-react"
import { ELogType } from "../../../../Enums/ELogType";
import { EOCRLabsPageType } from "../../../../Types/OCRLabs/Enums/EOCRLabsPageType";
import { EService } from "../../../../Enums/EService";
import { IOcrLabsResponse, TransactionProgressLogDto } from "../../../../Types";
import { IOcrLabsSettings } from "../../../../models/flow/settings/IOcrLabsSettings";
import { OCRLabsContext } from "../useOCR";
import { QRCodeCanvas } from "qrcode.react";
import { StateValue } from "../../../Context";
import { constructWebSocketUrlOcrStatus } from "../../../../utils/constructWebSocketUrl";
import { getOcrLabsResults } from "../../../../Services/Backend";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IOCRDesktopProps {
  /** Header translation key, displayed at the top of the page, defaults to OCR_Labs.Desktop.Header */
  headerMessageKey?: string
  /** Sub header translation key, displayed at the top of the page, defaults to OCR_Labs.Desktop.SubHeader */
  subHeaderMessageKey?: string,
  onCallback?: () => void,
  settings: IOcrLabsSettings,
}

export const OCRDesktop = (props: IOCRDesktopProps) => {
  const { onCallback, settings } = props;
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const { setPage, combinationService } = useContext(OCRLabsContext);
  const [progress, setProgress] = useState(0);
  const [progressTranslationKey, setProgressTranslationKey] = useState("OCR_Labs.Desktop.Waiting_First_Picture");
  const socketRef = useRef<WebSocket | null>(null);
  const [results, setResults] = useState<IOcrLabsResponse | undefined>(undefined);
  const lang = localStorage.getItem("lang") || "en";

  const getResults = () => {
    getOcrLabsResults(state.ctxId).then(res => {
      if (res.status === 200) {
        setResults(res.data);
        socketRef?.current?.close();
      }
    })
  }

  const handleMessage = (e: TransactionProgressLogDto) => {
    if (results) return;
    switch (e.logType) {
      case ELogType.OCRLabsCheckEntered:
        setProgress(0);
        setProgressTranslationKey(`OCR_Labs.Desktop.${2001}`);
        break;

      case ELogType.OCRLabsCheckEnteredFirstScan:
        setProgress(props.settings.performFaceAndLiveness ? 10 : 20);
        setProgressTranslationKey(`OCR_Labs.Desktop.${ELogType.OCRLabsCheckEnteredFirstScan}`);
        break;
      case ELogType.OCRLabsCheckEnteredSecondScan:
        setProgress(props.settings.performFaceAndLiveness ? 30 : 40);
        setProgressTranslationKey(`OCR_Labs.Desktop.${ELogType.OCRLabsCheckEnteredSecondScan}`);
        break;
      case ELogType.OCRLabsCheckEnteredSelfie:
        setProgress(50);
        setProgressTranslationKey(`OCR_Labs.Desktop.${ELogType.OCRLabsCheckEnteredSelfie}`);
        break;
      case ELogType.OCRLabsCheckSubmittedDocuments:
        setProgress(60);
        setProgressTranslationKey(`OCR_Labs.Desktop.${ELogType.OCRLabsCheckSubmittedDocuments}`);
        break;
      case ELogType.OCRLabsCheckEnteredAppendMissingProperties:
        setProgress(80);
        if (onCallback) {
          getResults();
        }
        setProgressTranslationKey(`OCR_Labs.Desktop.${ELogType.OCRLabsCheckEnteredAppendMissingProperties}`);
        break;
      case ELogType.OCRLabsCheckAppendMissingPropertiesCompleted:
        setProgress(100);
        setProgressTranslationKey(`OCR_Labs.Desktop.${ELogType.OCRLabsCheckAppendMissingPropertiesCompleted}`);
        break;
      case ELogType.OCRLabsCheckHandoffCompleted:
        getResults();
        break;
    }
  }

  const handleButtonCallback = () => {
    if (onCallback) {
      onCallback();
    } else {
      setPage({
        page: EOCRLabsPageType.Success,
        data: {
          headerTranslationKey: undefined,
          subHeaderTranslationKey: undefined,
        }
      });
    }
  }

  const handleValidatedPropertiesCallback = () => {
    if (onCallback !== undefined && combinationService == EService.MNICHECK) {
      // In case of MNICheck, we skip the success page
      onCallback();
    } else {
      setPage({
        page: EOCRLabsPageType.Success,
        data: {
          headerTranslationKey: undefined,
          subHeaderTranslationKey: undefined,
        }
      });
    }
  }

  useEffect(() => {
    // Open WebSocket connection when component mounts
    const url = constructWebSocketUrlOcrStatus(state.ctxId);

    socketRef.current = new WebSocket(url);

    // Listen for updates from the backend via WebSocket
    socketRef.current.onmessage = (event: MessageEvent) => {
      const transactionProgressLogDto = JSON.parse(event.data);

      // Make keys of the object camelCase
      const camelCaseTransactionProgressLogDto = Object.keys(transactionProgressLogDto).reduce((acc, key) => {
        (acc as any)[key.charAt(0).toLowerCase() + key.slice(1)] = transactionProgressLogDto[key];
        return acc;
      }, {} as TransactionProgressLogDto);

      handleMessage(camelCaseTransactionProgressLogDto);
    };

    // Close WebSocket connection when component unmounts
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, []);

  const render = () => {
    if (results) {
      return <OCRDynamicValidatePropertiesComponent detectedFields={results!.detectedFields} fields={settings.ocrLabsValidationFields} onCallback={handleValidatedPropertiesCallback} />;
    }

    return (
      <div className="service-item-container">
        <span>
          <Header as="h1" className="service-item-header">
            {t(props.headerMessageKey ?? "OCR_Labs.Desktop.Header")}
            <Header.Subheader>
              {t(props.subHeaderMessageKey ?? "OCR_Labs.Desktop.SubHeader")}
            </Header.Subheader>
          </Header>
          <ReactMarkdown skipHtml>
            {t("OCR_Labs.Information.Explanation_Desktop")!}
          </ReactMarkdown>
          <span className="mb-6 mt-4" id="mitek-qr">
            <QRCodeCanvas value={`${window.location.origin}/handoff-ocr?ctxId=${state.ctxId}&lang=${lang}&service=${combinationService ?? 20}`} /><br />{" "}
          </span>
        </span>
        <div style={{ flex: 1, alignItems: 'stretch', flexDirection: 'column', display: "flex", justifyContent: 'flex-end', paddingBottom: "1em" }}>
          <Progress percent={progress} active content={t(progressTranslationKey)} />
        </div>
        <Segment basic className="service-item-button-container mt-0">
          <Button
            color="green"
            floated="right"
            type="submit"
            disabled={progress < 100}
            onClick={handleButtonCallback}
          >
            {t("General_UI.Next")}
          </Button>
        </Segment>
      </div>
    )
  }

  return render();
}
