import { EToastType } from "../Enums/EToastType";
import { IApiKeyLink } from "../models/apiKey/IApikey";
import { IFlow } from "../models/flow/IFlow";
import { ILink, TestLinkFormValues } from "../models/testLinks/ITestLink";
import { RootStore } from "./RootStore";
import { makeAutoObservable, runInAction } from "mobx";

export default class LinkStore {
  rootStore: RootStore;
  loading: boolean = false;
  submitting: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getLinkApiKeys = async () => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IApiKeyLink[] } = await this.rootStore.baseApiService.get('link/api-keys/all');
    if (success) {
      runInAction(() => {
        this.loading = false;
      })
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  getFlows = async () => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IFlow[] } = await this.rootStore.baseApiService.get('link/flows/all');
    if (success) {
      runInAction(() => {
        this.loading = false;
      })
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      })
    }
  }

  createTestLinks = async (values: TestLinkFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success, data }: { success: boolean, data: ILink } = await this.rootStore.baseApiService.post('link', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      return data;
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }
}