import { Button, Dropdown, Grid, Header, Icon, List, Segment } from "semantic-ui-react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { EService, IServiceOptionEnums, ServiceOptionEnums } from "../../../Enums/EService";
import { FlowFormValues, IFlow } from "../../../models/flow/IFlow";
import { FlowSettingsBig } from "../flowSettings/big/FlowSettingsBig";
import { FlowSettingsGeneral } from "../flowSettings/general/FlowSettingsGeneral";
import { FlowSettingsMitek } from "../flowSettings/mitek/FlowSettingsMitek";
import { OrganizationEditFlowModal } from "./OrganizationEditFlowModal";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { OcrSettingTabs } from "../flowSettings/ocr/OcrSettingTabs";
import { MitekSettingTabs } from "../flowSettings/mitek/MitekSettingsTabs";

interface IProps {
  id: string;
  currentFlow: number;
  setCurrentFlow: (value: number) => void
}

type optionprops = {
  option: number;
  index: any;
};

export const OrganizationFlowDnD: React.FC<IProps> = observer(({ id, currentFlow: currentFlowIndex, setCurrentFlow: setCurrentFlowIndex }) => {
  const rootStore = useContext(RootStoreContext);
  const { submittingFlow, createFlowForClient, getFlowsByClient, toggleFlowForClient, editFlowOrderForClient } = rootStore.customerClientStore;
  const { openModal } = rootStore.modalStore;

  const [flows, setFlows] = useState<IFlow[]>([]);
  const [originalFlowsServices, setOriginalFlowsServices] = useState<number[][]>([[]]);
  const [serviceOptions, setServiceOptions] = useState<IServiceOptionEnums[]>([]);

  const loadFlows = async () => {
    var response = await getFlowsByClient(parseInt(id));
    if (response) {
      setFlows(response);
      var flowServicesCopy = response.map((flow) => {
        return flow.flowServices.slice()
      })
      setOriginalFlowsServices(flowServicesCopy);
    }
  }

  useEffect(() => {
    setServiceOptions(ServiceOptionEnums());
  }, [])

  useEffect(() => {
    loadFlows();
  }, [id])

  const onDragEnd = (result: DropResult) => {
    // If dropped outside of draggable area
    if (!result.destination) return;

    // If not moved
    if (result.destination.index === result.source.index) return;

    // Re-order list
    const oldFlow = [...flows];
    const curr = oldFlow[currentFlowIndex];

    const [removedFlow] = curr.flowServices.splice(result.source.index, 1);
    curr.flowServices.splice(result.destination.index, 0, removedFlow);
    oldFlow[currentFlowIndex] = curr;
    setFlows(oldFlow);
  }

  const createFlow = async () => {
    var values = new FlowFormValues();
    values.customerClientId = parseInt(id);
    values.name = "New Flow";
    var response = await createFlowForClient(values);
    if (response) {
      await loadFlows();
    }
  }

  const removeFlow = async (id: number) => {
    await toggleFlowForClient(id);
    await loadFlows();
  }

  const removeFromFlow = (id: number) => {
    const newFlows = [...flows];
    newFlows[currentFlowIndex].flowServices = newFlows[currentFlowIndex].flowServices.filter(x => x != id);
    setFlows(newFlows);
  }

  const handleSaveFlowOrder = async () => {
    const current = flows[currentFlowIndex]
    if (current !== undefined) {
      var values = new FlowFormValues(current);
      await editFlowOrderForClient(values);
      await loadFlows();
    }
  }

  const addFlowToLocal = (serviceId: number) => {
    if (serviceOptions.filter((x) => x.id === serviceId).length <= 0) return

    // Add serviceId to current flow
    const oldFlow = [...flows];
    const curr = oldFlow[currentFlowIndex];

    if (curr.flowServices.includes(serviceId)) return;

    curr.flowServices.push(serviceId);
    oldFlow[currentFlowIndex] = curr;
    setFlows(oldFlow);
  }

  function editFlowSettings(service: EService) {
    const flowId = flows[currentFlowIndex].id!;

    if (service == undefined || service == EService.NULL) {
      return
    }

    switch (service) {
      case EService.MITEKCHECK:
        return (
          openModal({ content: <MitekSettingTabs flowId={flowId} /> })
        )
      case EService.BIGCHECK:
        return (
          openModal({ content: <FlowSettingsBig flowId={flowId} /> })
        )
      case EService.OCRLABSCHECK:
        return (
          openModal({ content: <OcrSettingTabs flowId={flowId} /> })
        )
      default:
        return (
          openModal({ content: <FlowSettingsGeneral flowId={flowId} service={service} /> })
        )
    }
  }

  function DragDropOption(option: optionprops) {
    // Check wether the current object is saved or newly created
    var isSaved = originalFlowsServices[currentFlowIndex].find((obj) => { return obj == option.option }) !== undefined;

    const service = serviceOptions.find((x) => x.id == option.option);
    return (
      <Draggable draggableId={option.option.toString()} index={option.index}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.dragHandleProps} {...provided.draggableProps}>
            <Segment textAlign="center" style={{ minWidth: "100px" }} attached="top" key={`segmentOption-${option.option}`}>
              <>
                <p>{service?.name.toLowerCase()}</p>
                <Icon name={service?.icon as any} />
              </>
            </Segment>
            <Segment attached="bottom" textAlign="center">
              <Grid columns={isSaved ? 2 : 1} divided>
                <Grid.Row>
                  <Grid.Column>
                    <Button
                      color='red'
                      icon="remove"
                      compact basic
                      onClick={() => removeFromFlow(option.option)}
                    />
                  </Grid.Column>
                  {/* Only show the edit button if the service has been saved to the flow */}
                  {isSaved && (
                    <Grid.Column>
                      <Button
                        icon="edit outline"
                        compact basic
                        onClick={() => editFlowSettings(option.option)}
                      />
                    </Grid.Column>
                  )}
                </Grid.Row>
              </Grid>
            </Segment>
          </div>
        )}
      </Draggable>
    )
  }

  const dragDropOptionsList = () => {
    return flows[currentFlowIndex].flowServices.map((serviceId, index) => (
      <span style={{ paddingLeft: "1em", paddingRight: "1em" }} key={`optionDnD-${serviceId}`}>
        <DragDropOption option={serviceId} index={index} />
      </span>
    ))
  }

  return (
    <>
      <Header as="h4" attached>
        Authenticatie flows
      </Header>
      <Segment attached style={{ display: "flex", alignItems: "center" }}>
        <List horizontal divided relaxed="very" style={{ display: "contents" }}>
          {flows.map((x, index) => (
            <List.Item active={currentFlowIndex === index} key={`api-access-list-${x.id}`}>
              <List.Content>
                <Segment attached className={currentFlowIndex === index ? "selected" : "standard"} onClick={() => setCurrentFlowIndex(index)} style={{ cursor: "pointer" }}>
                  <List.Header as="h3">
                    {x.name} (id: {x.id})
                  </List.Header>
                  {flows[index].flowServices.map((serviceId, indexFlow) =>
                    serviceOptions.find((service) => serviceId === service.id)?.name.toLowerCase() + (indexFlow !== flows[index].flowServices.length - 1
                      ? " -> "
                      : ""))}
                </Segment>
                <Segment attached="bottom" textAlign="center">
                  <Grid columns={2} divided textAlign="center">
                    <Grid.Row>
                      <Grid.Column >
                        <Button
                          icon="remove"
                          basic color="red" compact
                          onClick={() => x.id && removeFlow(x.id)} />
                      </Grid.Column>
                      <Grid.Column textAlign="center" >
                        <OrganizationEditFlowModal
                          flow={x}
                          trigger={<Button icon="edit outline" basic compact />}
                          reload={loadFlows}
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </List.Content>
            </List.Item>
          ))}
          <List.Item>
            <Button basic icon="add" onClick={createFlow} disabled={id === undefined} />
          </List.Item>
        </List>
      </Segment>
      <Header as="h4" attached>
        Flow order
      </Header>
      {flows.length <= 0
        ? (
          <Header as="h4" attached>
            Create at least one flow
          </Header>
        )
        : (
          <>
            <Segment attached>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={5}>
                    <Button
                      onClick={loadFlows}
                      disabled={id === undefined}
                      icon
                      labelPosition="right"
                    >
                      Reload
                      <Icon name="undo alternate" />
                    </Button>

                  </Grid.Column>
                  <Grid.Column width={6} textAlign="center">
                    Check toevoegen:
                    {serviceOptions && flows && flows.length > 0 && (
                      <Dropdown
                        search
                        labeled
                        placeholder="Make a choice..."
                        onChange={(_, { value }) => addFlowToLocal(value as number)}
                        options={serviceOptions
                          .filter((x) => !flows[currentFlowIndex].flowServices.includes(x.id))
                          .map((option) => {
                            return {
                              key: option.id,
                              value: option.id,
                              text: option.name
                            }
                          })}
                      />
                    )}
                  </Grid.Column>
                  <Grid.Column width={5} floated="right">

                    <Button floated="right" disabled={flows[currentFlowIndex].flowServices.length <= 0} loading={submittingFlow} primary onClick={handleSaveFlowOrder}>
                      Save flow order
                    </Button>
                  </Grid.Column>
                </Grid.Row>

              </Grid>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list" direction="horizontal">
                  {(provided) => (
                    <div className="horizontallist" ref={provided.innerRef} {...provided.droppableProps}>
                      {flows[currentFlowIndex] !== undefined ? dragDropOptionsList() : null}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Segment>
          </>
        )
      }
    </>
  )
})