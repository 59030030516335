import MitekCheckFinished from '../../MitekCheckFinished'
import MitekHeader from '../../MitekHeader'
import { Segment } from 'semantic-ui-react'
import { state } from '../../../../Context'
import { useTranslation } from 'react-i18next'

type IProps = {
  state: state
}

const MitekCheckCompletedComponent = (props: IProps) => {

  const { t } = useTranslation(props.state.org.theme.config.translationKey);

  return (
    <>
      <MitekHeader />
      <MitekCheckFinished themeColor={props.state.org.theme.themeColor} />
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }} >
        {/* <Button disabled color="green" floated="right">
				{t("General_UI.Next")}
			</Button> */}
      </Segment>
    </>
  )
}

export default MitekCheckCompletedComponent