import { EEnvironmentType } from "../../Enums/EEnvironmentType";
import { EMakeAChoice } from "../../Enums/EMakeAChoice";

export interface ILink {
  links: string[];
}

export interface ITestLink {
  envType: EEnvironmentType;
  amount: number;
  customerClientId: number;
  flowId: number;
  apiKeyId: number;
  language: string;
  target: string;
}

export class TestLinkFormValues {
  envType: EEnvironmentType = EEnvironmentType.Acc;
  amount: number = 10;
  customerClientId: number = EMakeAChoice.Value;
  flowId: number = EMakeAChoice.Value;
  apiKeyId: number = EMakeAChoice.Value;
  language: string = "nl";
  target: string = "https://www.google.com";

  constructor(init?: ITestLink) {
    Object.assign(this, init);
  }
}