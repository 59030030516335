import { Container, Divider, Header } from "semantic-ui-react"
import { Links } from "../../../Components/secure/links/Links"
import { useTranslation } from "react-i18next"

export const TestLinks = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Header as="h1">{t("Links.Create")}</Header>
      <Divider />
      <Links />
    </Container>
  )
}