import './AppendMissingErrors.css';
import * as Yup from 'yup';
import axios from "axios";
import { EBigErrorType } from "../../../Enums/EBigErrorType";
import { Formik, Field } from "formik";
import { IMitekSettings } from "../../../models/flow/settings/IMitekSettings";
import { RequiredFieldsDto, AppendErrors, MBUMissingPropertiesDto } from "../../../Types";
import { Segment, Button, Form as SemanticForm } from "semantic-ui-react";
import { StateValue } from "../../Context";
import { TextInput } from "../../Common/form/TextInput";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

type IProps = {
  data: MBUMissingPropertiesDto;
  callback: () => void;
  requiredAndMissingDto?: RequiredFieldsDto;
  mitekSettings: IMitekSettings;
};

type BigCheckResponseDto = {
  success: boolean;
  personIdentCheck: boolean;
  personAddressCheck: boolean;
  pepCheck: boolean;
  utilityBillNeeded: boolean;
  reasons: EBigErrorType[];
}

type MBUAppendMissingPropertiesResponseDto = {
  bigCheckResponseDto: BigCheckResponseDto;
  appendMissingResponseDto: { errors: AppendErrors[] };
}

export const MBUAppendMissingProperties = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const setErrorsState = (res: AppendErrors[]) => {
    const tempE: any = {};
    res.forEach((error: AppendErrors) => {
      tempE[error.fieldName as any] = t(`Mitek.Append_Missing.${error.fieldName.charAt(0).toUpperCase() + error.fieldName.slice(1)}`) + t("Mitek.Append_Missing.Error_1") + error.allowedEdits + t("Mitek.Append_Missing.Error_2") + error.actualEdits + ".";
    });
    return (tempE);
  }

  var validationSchema = Yup.object().shape({
    dateOfExpiryRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.dateOfExpiry ?? false),
    dateOfIssueRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.dateOfIssue ?? false),
    dateOfBirthRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.dateOfBirth ?? false),

    fullNameRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.fullName ?? false),
    givenNamesRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.givenNames ?? false),
    surnameRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.surname ?? false),

    documentNumberRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.documentNumber ?? false),
    issuingAuthorityRequired: Yup.bool().default(props.requiredAndMissingDto?.requiredFields?.issuingAuthority ?? false),

    issuingAuthority: Yup.string()
      .label('Mitek.Append_Missing.IssuingAuthority')
      .when('issuingAuthorityRequired', {
        is: true,
        then: Yup.string().required(
          `${t('Mitek.Append_Missing.IssuingAuthority')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.string()
      }),
    documentNumber: Yup.string()
      .label('Mitek.Append_Missing.DocumentNumber')
      .when('documentNumberRequired', {
        is: true,
        then: Yup.string().required(
          `${t('Mitek.Append_Missing.DocumentNumber')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.string()
      }),
    givenNames: Yup.string()
      .label('Mitek.Append_Missing.GivenNames')
      .when('givenNamesRequired', {
        is: true,
        then: Yup.string().required(
          `${t('Mitek.Append_Missing.GivenNames')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.string()
      }),
    surname: Yup.string()
      .label('Mitek.Append_Missing.Surname')
      .when('surnameRequired', {
        is: true,
        then: Yup.string().required(
          `${t('Mitek.Append_Missing.Surname')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.string()
      }),
    dateOfIssue: Yup.date() // TODO: Pretty sure this is always required
      .label('Mitek.Append_Missing.DateOfIssue')
      .when('dateOfIssueRequired', {
        is: true,
        then: Yup.date().required(
          `${t('Mitek.Append_Missing.DateOfIssue')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.date()
      }),
    dateOfExpiry: Yup.date()
      .when('dateOfExpiryRequired', {
        is: true,
        then: Yup.date().required(
          `${t('Mitek.Append_Missing.DateOfExpiry')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.date()
      }),
    dateOfBirth: Yup.date()
      .when('dateOfBirthRequired', {
        is: true,
        then: Yup.date().required(
          `${t('Mitek.Append_Missing.DateOfBirth')} ${t(
            'General_UI.Is_Required'
          )}`),
        otherwise: Yup.date()
      }),


    houseNumber: Yup.string().required(),
    houseNumberExtension: Yup.string(),
    street: Yup.string().required(),
    city: Yup.string().required(),
    postalCode: Yup.string().required(),

  });

  const handleSubmit = (data: MBUMissingPropertiesDto, formProps: any) => {
    setIsSubmitting(true);
    axios.post<MBUAppendMissingPropertiesResponseDto>(
      `/api/MBUCheck/appendmissingproperties?ctxId=${state.ctxId}`,
      {
        ctxId: state.ctxId,
        givenNames: data.givenNames,
        surname: data.surname,
        fullName: data.givenNames + " " + data.surname,
        documentNumber: data.documentNumber,
        dateOfBirth: (data?.dateOfBirth !== "" && data.dateOfBirth !== undefined) ? new Date(data.dateOfBirth).toISOString() : "",
        dateOfIssue: (data?.dateOfIssue !== "" && data.dateOfIssue !== undefined) ? new Date(data.dateOfIssue).toISOString() : "",
        dateOfExpiry: (data?.dateOfExpiry !== "" && data.dateOfExpiry !== undefined) ? new Date(data.dateOfExpiry).toISOString() : "",
        issuingAuthority: data.issuingAuthority,
        city: data.city,
        postalCode: data.postalCode,
        street: data.street,
        houseNumber: data.houseNumber,
        houseNumberExtension: data.houseNumberExtension,
      }
    ).then(res => {
      if (res.status !== 200) {
        setIsError(true);
        setIsSubmitting(false);
      } else {
        if (res.data.appendMissingResponseDto.errors != null && res.data.appendMissingResponseDto.errors != undefined && res.data.appendMissingResponseDto.errors.length > 0) {
          formProps.setErrors(res.data.appendMissingResponseDto.errors.length > 0 ? setErrorsState(res.data.appendMissingResponseDto.errors) : undefined);
          setIsError(true);
        } else {
          props.callback();
        }
      }
      setIsSubmitting(false);
    }).catch(err => {
      setIsError(true);
      setIsSubmitting(false);
    })
  };

  return (
    <>
      <span className='mb-2 mt-4'>
        <h1 className={`service-item-header${state.org.theme.clientClass ? '-' + state.org.theme.clientClass : ''}`}>
          {t('Mitek.Append_Missing.Look_Right')}
        </h1>
        <h2 className={`service-item-subheader${state.org.theme.clientClass ? '-' + state.org.theme.clientClass : ''} mb-4 mt-0`}>
          {isError ? t("Mitek.Append_Missing.Only_Small_Edits") : t('Mitek.Append_Missing.Append_Edit')}
        </h2>
      </span>

      <Formik
        initialValues={{
          personalGovID: props.data.personalGovID ?? '',
          issuingAuthority: props.data.issuingAuthority ?? '',
          documentNumber: props.data.documentNumber ?? '',
          givenNames: props.data.givenNames ?? '',
          surname: props.data.surname ?? '',
          dateOfIssue: props.data.dateOfIssue ?? '',
          dateOfExpiry: props.data.dateOfExpiry ?? '',
          dateOfBirth: props.data.dateOfBirth ?? '',
          street: props.data.street ?? '',
          houseNumber: props.data.houseNumber ?? '',
          houseNumberExtension: props.data.houseNumberExtension ?? '',
          city: props.data.city ?? '',
          postalCode: props.data.postalCode ?? '',
        }}
        onSubmit={(data: any, formProps: any) => handleSubmit(data, formProps)}
        validationSchema={validationSchema}
        style={{ flex: 1 }}
      >
        {(formProps) => {
          return (
            <SemanticForm
              onSubmit={formProps.handleSubmit}
              style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: "space-between" }}
            >
              <span style={{ flex: 1, overflowY: "auto", overflowX: "hidden" }}>
                <SemanticForm.Group id="group1" unstackable={formProps.values.givenNames.length < 20} widths="2" className='mb-4'>
                  <Field
                    component={TextInput}
                    id="givenNames"
                    label={t('Mitek.Append_Missing.GivenNames')}
                    name="givenNames"
                    type="text"
                    placeholder={t('Mitek.Append_Missing.GivenNames')}
                    required={props.requiredAndMissingDto?.requiredFields?.givenNames ?? false}
                    formInput={{ fluid: true }}
                    error={formProps.errors.givenNames}
                  />

                  <Field
                    component={TextInput}
                    id="surname"
                    label={t('Mitek.Append_Missing.Surname')}
                    name="surname"
                    type="text"
                    placeholder={t('Mitek.Append_Missing.Surname')}
                    required={props.requiredAndMissingDto?.requiredFields?.surname ?? false}
                    formInput={{ fluid: true }}
                    error={formProps.errors.surname}
                  />
                </SemanticForm.Group>

                <div className='mb-4'>
                  <Field
                    component={TextInput}
                    id="documentNumber"
                    label={t('Mitek.Append_Missing.DocumentNumber')}
                    name="documentNumber"
                    type="text"
                    placeholder={t('Mitek.Append_Missing.DocumentNumber')}
                    required={props.requiredAndMissingDto?.requiredFields?.documentNumber}
                    error={formProps.errors.documentNumber}
                  />
                </div>
                {
                  (props.requiredAndMissingDto?.requiredFields?.issuingAuthority || props.data.issuingAuthority) && (
                    <div className='mb-4'>
                      <Field
                        component={TextInput}
                        id="issuingAuthority"
                        label={t('Mitek.Append_Missing.IssuingAuthority')}
                        name="issuingAuthority"
                        type="text"
                        placeholder={t('Mitek.Append_Missing.IssuingAuthority')}
                        required={props.requiredAndMissingDto?.requiredFields?.issuingAuthority}
                        error={formProps.errors.issuingAuthority}
                      />
                    </div>
                  )
                }
                {
                  (props.requiredAndMissingDto?.requiredFields?.dateOfBirth || props.data.dateOfBirth) && (
                    <Field
                      component={TextInput}
                      id="dateOfBirth"
                      label={t('Mitek.Append_Missing.DateOfBirth')}
                      name="dateOfBirth"
                      type="date"
                      placeholder={t('Mitek.Append_Missing.DateOfBirth')}
                      formInput={{ fluid: true }}
                      error={formProps.errors.dateOfBirth}
                      required={props.requiredAndMissingDto?.requiredFields?.dateOfBirth}
                    />)
                }

                <SemanticForm.Group id="group2" unstackable widths={props.requiredAndMissingDto?.requiredFields?.dateOfExpiry ? "2" : "equal"} className='mb-4'>
                  <Field
                    component={TextInput}
                    id="dateOfIssue"
                    label={t('Mitek.Append_Missing.DateOfIssue')}
                    name="dateOfIssue"
                    type="date"
                    placeholder={t('Mitek.Append_Missing.DateOfIssue')}
                    formInput={{ fluid: true }}
                    error={formProps.errors.dateOfIssue}
                    required={props.requiredAndMissingDto?.requiredFields?.dateOfIssue}
                  />
                  {
                    (props.requiredAndMissingDto?.requiredFields?.dateOfExpiry || props.data.dateOfExpiry) && (
                      <Field
                        component={TextInput}
                        id="dateOfExpiry"
                        label={t('Mitek.Append_Missing.DateOfExpiry')}
                        name="dateOfExpiry"
                        type="date"
                        placeholder={t('Mitek.Append_Missing.DateOfExpiry')}
                        formInput={{ fluid: true }}
                        error={formProps.errors.dateOfExpiry}
                        required={props.requiredAndMissingDto?.requiredFields?.dateOfExpiry}
                      />)
                  }
                </SemanticForm.Group>

                <SemanticForm.Group id="group2" unstackable widths={3} className='mb-4'>
                  <Field
                    component={TextInput}
                    id="street"
                    label={t('Mitek.Append_Missing.Street')}
                    name="street"
                    type="text"
                    placeholder={t('Mitek.Append_Missing.Street')}
                    formInput={{ fluid: true }}
                    error={formProps.errors.street}
                    required={true}
                  />
                  <Field
                    component={TextInput}
                    id="houseNumber"
                    label={t('Mitek.Append_Missing.HouseNumber')}
                    name="houseNumber"
                    type="text"
                    placeholder={t('Mitek.Append_Missing.HouseNumber')}
                    formInput={{ fluid: true }}
                    error={formProps.errors.houseNumber}
                    required={true}
                  />
                  <Field
                    component={TextInput}
                    id="houseNumberExtension"
                    label={t('Mitek.Append_Missing.HouseNumberExtension')}
                    name="houseNumberExtension"
                    type="text"
                    placeholder={t('Mitek.Append_Missing.HouseNumberExtension')}
                    formInput={{ fluid: true }}
                    error={formProps.errors.houseNumberExtension}
                  />
                </SemanticForm.Group>
                <SemanticForm.Group id="group2" unstackable widths={2} className='mb-4'>
                  <Field
                    component={TextInput}
                    id="city"
                    label={t('Mitek.Append_Missing.City')}
                    name="city"
                    type="text"
                    placeholder={t('Mitek.Append_Missing.City')}
                    formInput={{ fluid: true }}
                    error={formProps.errors.city}
                    required={true}
                  />
                  <Field
                    component={TextInput}
                    id="postalCode"
                    label={t('Mitek.Append_Missing.PostalCode')}
                    name="postalCode"
                    type="text"
                    placeholder={t('Mitek.Append_Missing.PostalCode')}
                    formInput={{ fluid: true }}
                    error={formProps.errors.postalCode}
                    required={true}
                  />

                </SemanticForm.Group>
              </span>

              <Segment basic className={`service-item-button-container mt-0`} >
                <Button
                  floated="right"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  color={isError ? 'red' : 'green'}
                  type="submit"
                >
                  {isError
                    ? t('General_UI.Try_Again')
                    : t('General_UI.Submit')}
                </Button>
              </Segment>

            </SemanticForm>
          )
        }}
      </Formik>
    </>
  );
};
