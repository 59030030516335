export enum EOCRLabsPageType {
  /**
   * Generic information page
   */
  Information = 0,
  /**
   * Generic information page, specific for desktop
   */
  Information_Desktop = 9,
  /**
   * Generic warning page
   */
  Warning = 1,
  /**
   * Generic error page
   */
  Error = 2,
  /**
   * Page to take a picture of the document
   */
  TakePicture = 3,
  /**
   * Page to take a selfie
   */
  TakeSelfie = 4,
  /**
   * Page to confirm and submit the picture before sending to OCR
   */
  ConfirmAndSubmitPicture = 5,
  /**
   * Page to validate the properties after the OCR Labs has processed the document and returned the results
   */
  ValidateProperties = 6,
  /**
   * Success page
   */
  Success = 7,
  /**
   * Loading page
   */
  Loading = 8,
  /**
   * Select retry or passport signature page
   */
  RetryOrPassportSignature = 10,
  /**
   * Scan QR-code
   */
  ScanQRCode = 11,
}
