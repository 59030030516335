import * as yup from "yup";

export const CustomerFormValidationSchema = yup.object().shape({
  name: yup.string()
    .required(),
  phone: yup.string(),
  email: yup.string()
    .email()
    .required(),
  website: yup.string()
    .url()
    .required()
})