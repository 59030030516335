import ReactMarkdown from 'react-markdown'
import { Button, Segment } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { isDesktop } from 'react-device-detect'
import { StateDispatch, StateValue } from '../../Context'
import { TimerButtonManual } from '../../Common/Button/TimerButtonManual'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

type IProps = {
  translationKey: string,
  imageBase64: string
  isError: boolean,
  handleSubmit: () => void,
  handleBack: () => void
}

const ImagePreview = (props: IProps) => {
  const { t } = useTranslation(props.translationKey);
  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`$-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container">
            <span style={{ display: "flex", flexDirection: "column" }}>
              <h2 className='service-item-header' style={{ marginBottom: "0.5rem" }}>{t("UtilityBill.ImagePreview.Header")}</h2>
              <h3 className='explanation-text' style={{ marginTop: 0 }}>
                <ReactMarkdown skipHtml>{t("UtilityBill.ImagePreview.SubHeader")!}</ReactMarkdown>
              </h3>
              <Segment placeholder style={{ display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flex: 1, padding: 0 }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center", flexGrow: 1, flexShrink: 1, flexBasis: "100%", maxHeight: "100%", width: "100%" }}>
                  <div style={{ flex: 1, alignSelf: "center", width: "100%", backgroundSize: "contain", backgroundPositionX: "center", backgroundPositionY: "center", backgroundRepeat: "no-repeat", backgroundImage: `url(${props.imageBase64})` }}>
                  </div>
                </div>
              </Segment>
            </span>
          </div>
        </CSSTransition>
      </SwitchTransition>

      {
        isDesktop ? (
          <Segment basic className="service-item-button-container mt-0">
            <>
              <span>
                <Button id="retry-button" basic style={{ marginRight: "1em" }} onClick={props.handleBack}>{t("General_UI.Retry")}</Button>
              </span>
              <span>
                <TimerButtonManual text={t("UtilityBill.ImagePreview.Submit")} callback={props.handleSubmit} isError={props.isError} errorText={t("General_UI.Try_Again")} buttonProps={props.isError ? { color: "red" } : { color: "green" }} />
              </span>
            </>
          </Segment>
        ) : (
          <Segment id="stacked-buttons" className="mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
            <span id="retry-button-container">
              <Button basic style={{ marginRight: "1em" }} onClick={props.handleBack}>{t("General_UI.Retry")}</Button>
            </span>
            <span>
              <TimerButtonManual text={t("UtilityBill.ImagePreview.Submit")} callback={props.handleSubmit} isError={props.isError} errorText={t("General_UI.Try_Again")} buttonProps={props.isError ? { color: "red" } : { color: "green" }} />
            </span>
          </Segment>
        )
      }

    </>

  )
}

export default ImagePreview