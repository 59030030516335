import { EService } from "../../../Enums/EService";
import { BigSettingsFormValues, IBigSettings } from "./IBigSettings";
import { IMitekSettings, MitekSettingsFormValues } from "./IMitekSettings";
import { IOcrLabsSettings, OcrLabsSettingsFormValues } from "./IOcrLabsSettings";

export interface IFlowOrder {
  flowId: number;
  isVisible: boolean;
  service: EService;
}

export class FlowSettingsGeneralFormValues {
  isVisible: boolean = false;
  service: EService = EService.NULL;

  constructor(init?: IFlowOrder) {
    Object.assign(this, init);
  }
}

export interface IFlowSettingsBig extends IFlowOrder {
  bigSettings: IBigSettings;
}

export class FlowSettingsBigFormValues {
  isVisible: boolean = false;
  service: EService = EService.NULL;
  bigSettings: BigSettingsFormValues = new BigSettingsFormValues;

  constructor(init?: IFlowSettingsBig) {
    Object.assign(this, init);
    if (init?.bigSettings) {
      this.bigSettings = new BigSettingsFormValues(init.bigSettings);
    }
  }
}

export interface IFlowSettingsMitek extends IFlowOrder {
  mitekSettings: IMitekSettings;
}

export class FlowSettingsMitekFormValues {
  isVisible: boolean = false;
  service: EService = EService.NULL;
  mitekSettings: MitekSettingsFormValues = new MitekSettingsFormValues;

  constructor(init?: IFlowSettingsMitek) {
    Object.assign(this, init);
    if (init?.mitekSettings) {
      this.mitekSettings = new MitekSettingsFormValues(init.mitekSettings);
    }
  }
}

export interface IFlowSettingsOcrLabs extends IFlowOrder {
  ocrLabsSettings: IOcrLabsSettings;
}

export class FlowSettingsOcrLabsFormValues {
  isVisible: boolean = false;
  service: EService = EService.NULL;
  ocrLabsSettings: OcrLabsSettingsFormValues = new OcrLabsSettingsFormValues;

  constructor(init?: IFlowSettingsOcrLabs) {
    Object.assign(this, init);
    if (init?.ocrLabsSettings) {
      this.ocrLabsSettings = new OcrLabsSettingsFormValues(init.ocrLabsSettings);
    }
  }
}
