import { Icon, Popup } from "semantic-ui-react";
import { EService } from "../../../Enums/EService";
import { useTranslation } from "react-i18next";

interface IProps {
  service: EService
}

export const StatisticResponseServiceInformation = ({ service }: IProps) => {
  const { t } = useTranslation();
  switch (service) {
    case EService.OCRLABSCHECK:
      return <Popup
        content={`'${t("Statistics.Unique_Sessions")}' indicates the amount of unique session to OCR, whilst '${t("Statistics.Total_Calls")}' is the sum of all document and selfie scans performed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.EMAILVERIFICATIONCHECK:
      return <Popup
        content={`'${t("Statistics.Unique_Sessions")}' indicates the amount of users which have provided their email.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.ADDRESSCHECK:
      return <Popup
        content={`'${t("Statistics.Unique_Sessions")}' indicates the amount of users which have provided their address information.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.NATEGISCHECK:
      return <Popup
        content={`'${t("Statistics.Unique_Sessions")}' indicates the amount of users which have started a Nategis check.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.PEPCHECK:
      return <Popup
        content={`'${t("Statistics.Unique_Sessions")}' indicates the amount of users which have started a PEP check.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.IDINCHECK:
      return <Popup
        content={`'${t("Statistics.Unique_Sessions")}' indicates the amount of users which have started a iDIN check.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.IBANCHECK:
      return <Popup
        content={`'${t("Statistics.Unique_Sessions")}' indicates the amount of users which have started a IBAN check.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.CURATELECHECK:
      return <Popup
        content={`'${t("Statistics.Unique_Sessions")}' indicates the amount of users which have started a Curatele check.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.MITEKCHECK:
      return <Popup
        content={`'${t("Statistics.Unique_Sessions")}' indicates the amount of users which have started a Mitek check.`}
        trigger={<Icon name="info circle" />}
      />
    default:
      return null;
  }
}

export const StatisticResponseServiceFinished = ({ service }: IProps) => {
  switch (service) {
    case EService.OCRLABSCHECK:
      return <Popup
        content={`The amount of document or selfie scans which have succefully been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.EMAILVERIFICATIONCHECK:
      return <Popup
        content={`The amount of email checks which have succesfully been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.ADDRESSCHECK:
      return <Popup
        content={`The amount of address checks which have returned a response.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.NATEGISCHECK:
      return <Popup
        content={`The amount of Nategis checks which have been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.PEPCHECK:
      return <Popup
        content={`The amount of PEP checks which have been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.IDINCHECK:
      return <Popup
        content={`The amount of iDIN checks which have been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.IBANCHECK:
      return <Popup
        content={`The amount of iDIN checks which have been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.CURATELECHECK:
      return <Popup
        content={`The amount of Curatele checks which have been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.MITEKCHECK:
      return <Popup
        content={`The amount of Mitek checks which have been completed.`}
        trigger={<Icon name="info circle" />}
      />
    default:
      return null;
  }
}

export const StatisticResponseServiceNotFinished = ({ service }: IProps) => {
  switch (service) {
    case EService.OCRLABSCHECK:
      return <Popup
        content={`The amount of document or selfie scans which have not been completed, or did not contain all required fields.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.EMAILVERIFICATIONCHECK:
      return <Popup
        content={`The amount of email checks which have not been completed, or where a wrong code was provided.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.ADDRESSCHECK:
      return <Popup
        content={`The amount of address checks which have not returned a response.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.NATEGISCHECK:
      return <Popup
        content={`The amount of Nategis checks which have not been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.PEPCHECK:
      return <Popup
        content={`The amount of PEP checks which have not been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.IDINCHECK:
      return <Popup
        content={`The amount of iDIN checks which have not been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.IBANCHECK:
      return <Popup
        content={`The amount of iDIN checks which have not been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.CURATELECHECK:
      return <Popup
        content={`The amount of Curatele checks which have not been completed.`}
        trigger={<Icon name="info circle" />}
      />
    case EService.MITEKCHECK:
      return <Popup
        content={`The amount of Mitek checks which have not been completed, either because not all required fields were detected, or the provided document(s) could not be checked.`}
        trigger={<Icon name="info circle" />}
      />
    default:
      return null;
  }
}