import { RootStore } from "./RootStore";
import { makeAutoObservable } from "mobx";

export default class ApplicationStore {
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
  }

  async refreshSessionTimeout() {
    await this.rootStore.baseApiService.get("application/refresh");
  }
}