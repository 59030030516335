import { Container, Divider, Header } from "semantic-ui-react";
import { CustomerManagerForm } from "../../../../Components/secure/customerManager/CustomerManagerForm";
import { useTranslation } from "react-i18next"

export const CreateCustomerManager = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Header as="h1">{t("CustomerManager.New")}</Header>
      <Divider />
      <CustomerManagerForm />
    </Container>
  )
}