import MitekHeader from '../../MitekHeader'
import { Segment, Button } from 'semantic-ui-react'
import { state } from '../../../../Context'
import { useTranslation } from 'react-i18next'

type IProps = {
  state: state;
  setCurrentPage: (page: number) => void;
}

const MitekCheckSelfieExplanationComponent = (props: IProps) => {
  const { t } = useTranslation(props.state.org.theme.config.translationKey);

  return (
    <>
      <MitekHeader />
      <Segment id="explanation-screen" className="mitek" basic style={{ height: "90%", width: "100%", margin: 0 }}>
        <h1 className="service-item-header">{t("Mitek.Help.Almost_Done")}</h1>
        <h2 className="service-item-subheader">{t("Mitek.Help.Selfie")}</h2>
        <p>{t("Mitek.Help.Hold_Phone_Selfie")}</p>
        <p>{t("Mitek.Help.Hold_Phone_Extra_Selfie")}</p>
        <Segment className="svg mitek" basic floated="right">
        </Segment>
      </Segment>
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }} >
        <Button className="full-width" color="green" floated="right" onClick={() => props.setCurrentPage(19)}>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
}

export default MitekCheckSelfieExplanationComponent