export interface IFlow {
  id?: number;
  name: string;
  description: string;
  customerClientId: number;
  flowServices: number[];
}

export class FlowFormValues {
  id?: number = undefined;
  customerClientId: number = 0;
  name: string = "";
  description: string = "";
  flowServices: number[] = [];

  constructor(init?: IFlow) {
    Object.assign(this, init);
  }
}
