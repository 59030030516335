import React, { useEffect, useState } from 'react'

const ClosePageComponent = () => {

  const [shouldBeClosed, setShouldBeClosed] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setShouldBeClosed(true);
      window.close();
    }, 500);

  }, [])

  return (
    <>
      {shouldBeClosed && (
        <h1>Something went wrong closing this page. Please close this page manually and return to the opened signup tab / window.</h1>
      )}
    </>
  )
}

export default ClosePageComponent