import "./ApiKeyListStatisticsHome.css";
import { Button, Header, List } from "semantic-ui-react";
import { Dictionary, GetApiKeysDto } from "../../../../Types";
import { NavLink } from "react-router-dom";
import { RootStoreContext } from "../../../../stores/RootStore";
import { formatDistanceToNow } from "date-fns/esm";
import { nl } from 'date-fns/locale'
import { observer } from "mobx-react-lite";
import { routePrefixDashboard } from "../../../../ApplicationRouter";
import { useContext, useEffect, useState } from "react";
import { DevLog } from "../../../../utils/DevLog";

export const ApiKeyListStatisticsHome = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { getAllApiKeys } = rootStore.customerKeyStore;

  const [apiKeys, setApiKeys] = useState<Dictionary<GetApiKeysDto[]>>();

  useEffect(() => {
    loadData();
  }, [])

  const loadData = async () => {
    var res = await getAllApiKeys();
    if (res) {
      setApiKeys(res);
    }
  }

  const toggleActive = (id: number, key: string) => {
    DevLog("ToggleActive:Id", id, "Key:", key);
  }

  return (
    <div>
      {apiKeys === undefined ? null : Object.keys(apiKeys).map((key, index) => (
        <div style={index !== 0 ? { paddingTop: "3em" } : {}} key={key}>
          <List.Header className="listheader" key={`${key}-head`}>
            <Header as="h3" key={`${key}-head2`}>
              {key}
            </Header>
          </List.Header>
          <List divided relaxed verticalAlign="middle" style={{ maxHeight: "100%", overflowY: "auto" }} key={`${key}-item`}>
            {apiKeys[key].map((data) => (
              <List.Item className="flex-container" key={data.apiKeyId}>
                <List.Content floated="left" className="flex-item" key={`${data.apiKeyId}-content`}>
                  <List.Header as="h4" key={`${data.apiKeyId}-header`}>
                    {data.clientName}
                  </List.Header>
                  <List.Description as='a' key={`${data.apiKeyId}-desc`}>
                    <strong>{data.expiryDate !== null && data.expiryDate !== undefined
                      ? `Vervalt over ${formatDistanceToNow(Date.parse(data.expiryDate), { locale: nl })}`
                      : "Geen vervaldatum"}</strong>
                  </List.Description>
                </List.Content>
                <span></span>
                <List.Content floated="right" className="flex-item">
                  <Button.Group basic>
                    <Button icon="pie graph" as={NavLink} to={`${routePrefixDashboard}/organization-stats/${data.customerClientId}`}>Statistieken</Button>
                    <Button icon="edit outline" as={NavLink} to={`${routePrefixDashboard}/organization/${data.customerClientId}`}>Bewerk</Button>
                    <Button icon="delete" onClick={() => toggleActive(data.customerClientId!, key)} >Verwijder</Button>
                  </Button.Group>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </div>
      ))}
    </div>
  )
})