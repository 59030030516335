import { FunctionComponent, useContext } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import { StateValue } from '../Context'
import { useTranslation } from 'react-i18next'
// /* eslint-disable jsx-a11y/alt-text */

type IProps = {
  header: string | string[]
  text: string | string[]
  showSvg?: boolean
}

export const AnimationSuccess: FunctionComponent<IProps> = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const background = state.org.theme.config.successSvg ? encodeURIComponent(renderToStaticMarkup(<state.org.theme.config.successSvg fill={state.org.theme.themeColor} />)) : null;

  return (
    <div style={{ width: "100%", height: "100%", background: props.showSvg === true ? `url("data:image/svg+xml,${background}")` : '', backgroundSize: "contain", backgroundPosition: "right bottom", backgroundRepeat: "no-repeat" }} id="success-animation" className="transition-container animation-success">
      <span style={{ display: "flex", flexDirection: "column" }}>
        {
          typeof props.header === "string" ? (
            <h1 className={`service-item-header`}>{t(props.header)}</h1>
          ) : (
            props.header.map((header: string, index: number) => (
              <h1 key={index} className={`service-item-header`}>{t(header)}</h1>
            ))
          )
        }

        {
          typeof props.text === "string" ? (
            <h2 className={`service-item-subheader`}>{t(props.text)}</h2>
          ) : (
            props.text.map((text: string, index: number) => (
              <p key={index} className={`service-item-subheader`}>{t(text)}</p>
            ))
          )
        }
      </span>
    </div>
  )
}