import { Button, Checkbox, Form, FormFieldProps, Icon, Label, Popup } from "semantic-ui-react";
import { FieldProps, getIn } from "formik";
import { useTranslation } from "react-i18next";

export interface ICheckboxProps extends FieldProps<boolean, HTMLInputElement>, FormFieldProps {
  onChange?: (e: any) => void;
}

export const CheckBox = (props: ICheckboxProps) => {
  const { field, id, label, form: { touched, errors, setFieldValue }, tooltip, required, disabled } = props;

  const { t } = useTranslation();

  const labelName = required ? label + " *" : label;

  return (
    <Form.Field>
      {tooltip && (
        <Popup
          {...tooltip}
          trigger={
            <Button style={{ border: "none", boxShadow: "none", paddingRight: 0, paddingBottom: "0.25em" }}
              compact
              size="small"
              basic
              type="button"
              icon={<Icon style={{ marginBottom: "1em" }} name="info circle" fitted />} />
          }
          position="bottom left"
        />
      )}
      <Checkbox
        checked={field.value}
        id={id}
        label={labelName}
        name={field.name}
        disabled={disabled}
        onChange={(e, data) => setFieldValue(field.name, data.checked)}
      />

      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label
          pointing="above"
          style={{ width: "100%", marginLeft: 0, display: "block", position: "relative" }}
          size="large"
          color="red"
          icon="times circle"
          content={t(getIn(errors, field.name))}
        />
      )}

    </Form.Field>
  )
}