import { Tab } from 'semantic-ui-react'
import { FlowSettingsOcr } from './FlowSettingsOcr'
import { OcrValidationFields } from './OcrValidationFields';

interface IProps {
  flowId: number;
}

export const OcrSettingTabs = ({ flowId }: IProps) => {

  const panes = [
    {
      menuItem: 'Settings',
      pane: { key: "Settings", content: (<FlowSettingsOcr flowId={flowId} />) }
    },
    {
      menuItem: 'Validation fields',
      pane: { key: "Validation fields", content: (<OcrValidationFields flowId={flowId} />) }
    }
  ]

  return (
    <Tab panes={panes} renderActiveOnly={false} />
  )
}