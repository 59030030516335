import ImageCapture from "./UtilityBillImageCapture";
import ImagePreview from "./UtilityBillImagePreview";
import { AnimationCheck } from "../../AnimationViews/AnimationCheck";
import { DevLog } from "../../../utils/DevLog";
import { ELogType } from "../../../Enums/ELogType";
import { EService } from "../../../Enums/EService";
import { StateDispatch, StateValue } from "../../Context";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { postTransactionProgressLog, postUtilityBillCheck } from "../../../Services/Backend";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UtilityBillCheckDto } from "../../../Types/UtilityBill/UtilityBillDto";
import UtilityBillInformation from "./UtilityBillInformation";

const UtilityBillComponent = () => {
  const dispatch = useContext(StateDispatch);
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const [currentPage, setCurrentPage] = useState<"INFORMATION" | "CAPTURE" | "PREVIEW" | "LOADING" | "SUCCESS">("INFORMATION");
  const [imageBase64, setImageBase64] = useState("");
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.UtilityBillCheckEntered });
  }, [])

  const handleImageCallback = (data: UtilityBillCheckDto) => {
    setImageBase64(data.utilityBillImageBase64);
    setCurrentPage("PREVIEW");
  }

  const handlePageChange = (e: any) => {
    setCurrentPage(e);
  }

  const handleBack = () => {
    setCurrentPage("CAPTURE");
    setImageBase64("");
  }

  const handleSubmit = () => {
    setCurrentPage("LOADING");
    postUtilityBillCheck({ ctxId: state.ctxId, utilityBillImageBase64: imageBase64, service: EService.ADDRESSCHECK }).then(response => {
      DevLog(response.data);
      if (response.data.statusCode === 1) {
        postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.UtilityBillCheckCompleted });
        setIsError(false);
        dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] });
      }
    }).catch(error => {
      setIsError(true);
      setCurrentPage("CAPTURE");
    });
  }

  const renderView = () => {
    switch (currentPage) {
      case "INFORMATION":
        return <UtilityBillInformation  imageCallBack={handleImageCallback} setPageCallback={handlePageChange} />
      case "CAPTURE":
        return <ImageCapture imageCallBack={handleImageCallback} translationKey={state.org.theme.config.translationKey} />
      case "PREVIEW":
        return <ImagePreview handleBack={handleBack} handleSubmit={handleSubmit} isError={isError} imageBase64={imageBase64} translationKey={state.org.theme.config.translationKey} />
      case "LOADING":
        return <AnimationCheck header="General_UI.Verifying" text="General_UI.One_Moment" key={`${state.view}-check`} svgComponent={state.org.theme.config.checkSvg && <state.org.theme.config.checkSvg fill={state.org.theme.themeColor} />} />
    }
  }

  return (<>
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={`${currentPage}-trans`}
        addEndListener={(node, done) => {
          node.addEventListener("transitionend", done, false);
        }}
        classNames="fade"
      >
        <div className="transition-container utility-bill-check" style={{ flex: 1 }}>
          {renderView()}
        </div>
      </CSSTransition>
    </SwitchTransition>
  </>
  )
}

export default UtilityBillComponent