import { OcrLabsValidationField } from "../../../Types";

export interface IOcrLabsSettings {
  id?: number;
  performFaceAndLiveness: boolean;
  extractedDataOnDesktop: boolean;
  appendMissingProperties: boolean;
  allowExpiredDocuments: boolean;
  allowUnderAge: boolean;
  allowInvalidData: boolean;
  ocrLabsValidationFields: OcrLabsValidationField[];
}

export class OcrLabsSettingsFormValues {
  performFaceAndLiveness: boolean = false;
  extractedDataOnDesktop: boolean = false;
  appendMissingProperties: boolean = false;
  allowExpiredDocuments: boolean = false;
  allowUnderAge: boolean = false;
  allowInvalidData: boolean = false;

  constructor(init?: IOcrLabsSettings) {
    Object.assign(this, init);
  }
}