import { Button, SemanticFLOATS } from "semantic-ui-react";
import { history } from "../../../ApplicationRouter";
import { useTranslation } from "react-i18next";

interface IProps {
  floated?: SemanticFLOATS;
}

export const CancelButton = (props: IProps) => {
  const { t } = useTranslation();
  const handleBack = () => {
    history.goBack();
  }

  return (
    <Button type="button" floated={props.floated} secondary onClick={() => handleBack()}>
      {t("Button.Cancel")}
    </Button>
  );
}