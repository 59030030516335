export interface IMitekSettings {
  id?: number;
  hasPassport: boolean;
  hasId: boolean;
  hasDriversLicence: boolean;
  hasResidencePermit: boolean;
  appendMissingProperties: boolean;
  appendMissingPropertiesAddition: boolean;
  appendMissingPropertiesUpdate: boolean;
  appendMissingPropertiesDesktop: boolean;
  extractedDataOnDesktop: boolean;
  enableSelfie: boolean;
  enablePassportSignature: boolean;
  allowExpiredDocuments: boolean;
  allowAgentHandoff: boolean;
  agentHandoffOnFrontend: boolean;
  documentTypeMustMatch: boolean;
  isCountryInclusive: boolean;
  isAgentCall: boolean;
  allowInvalidData: boolean;
  freeCruksEdit: boolean;
}

export class MitekSettingsFormValues {
  hasPassport: boolean = false;
  hasId: boolean = false;
  hasDriversLicence: boolean = false;
  hasResidencePermit: boolean = false;
  appendMissingProperties: boolean = false;
  appendMissingPropertiesAddition: boolean = false;
  appendMissingPropertiesUpdate: boolean = false;
  appendMissingPropertiesDesktop: boolean = false;
  extractedDataOnDesktop: boolean = false;
  enableSelfie: boolean = false;
  enablePassportSignature: boolean = false;
  allowExpiredDocuments: boolean = false;
  allowAgentHandoff: boolean = false;
  agentHandoffOnFrontend: boolean = false;
  documentTypeMustMatch: boolean = false;
  isCountryInclusive: boolean = false;
  allowInvalidData: boolean = false;
  freeCruksEdit: boolean = false;

  constructor(init?: IMitekSettings) {
    Object.assign(this, init);
  }
}