import { Segment } from 'semantic-ui-react'
import { StateValue } from '../../Context';
import { useContext } from 'react'
import { useTranslation } from 'react-i18next';

type MitekCheckFinishedProps = {
  themeColor: string
}

export default function MitekCheckFinished(props: MitekCheckFinishedProps) {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  return (
    <Segment id="error-screen" className="mitek" basic style={{ height: "90%", width: "100%", margin: 0 }}>
      <h1 className="service-item-header">{t("Mitek.Finished.Error_Header")}</h1>
      <h2 className="service-item-subheader">{t("Mitek.Finished.Error_SubHeader")}</h2>
      <p className="service-item-explanation">{`${t("Mitek.Finished.Error_Explanation")} ${state.org.name}${t("Mitek.Finished.Error_Explanation_2")}`}</p>
    </Segment>
  )
}
