import { makeAutoObservable, runInAction } from "mobx";
import { ISimpleClient, ISimpleFlowId } from "../models/client/IClient";
import { ISimpleCustomer } from "../models/customer/ICustomer";
import { GetStatisticsReportFormValues, IStatisticReportResponse } from "../models/statistics/IStatisticsReport";
import { IgetHomeScreenStatisticsByCustomerId } from "../Types";
import { RootStore } from "./RootStore";

export default class StatisticsStore {
  rootStore: RootStore;
  loading: boolean = false;
  loadingCustomers: boolean = false;
  loadingClients: boolean = false;
  loadingFlowIds: boolean = false;

  statisticReportResponse: IStatisticReportResponse | undefined = undefined

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getStatisticsReport = async (values: GetStatisticsReportFormValues) => {
    runInAction(() => {
      this.loading = true;
    });

    const search = `start=${values.start}&end=${values.end}&envType=${values.envType ?? ""}&customerId=${values.customerId ?? ""}&clientId=${values.clientId && values.clientId > 0 ? values.clientId : ""}&flowId=${values.flowId && values.flowId > 0 ? values.flowId : ""}`
    const { success, data }: { success: boolean; data: IStatisticReportResponse } = await this.rootStore.baseApiService.get(`statistics/report?${search}`);
    if (success) {
      runInAction(() => {
        this.statisticReportResponse = data;
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  getAllowedCustomers = async () => {
    runInAction(() => {
      this.loadingCustomers = true;
    });

    const { success, data }: { success: boolean; data: ISimpleCustomer[] } = await this.rootStore.baseApiService.get(`statistics/report-customers`);
    if (success) {
      runInAction(() => {
        this.loadingCustomers = false;
      })
      return data;
    } else {
      runInAction(() => {
        this.loadingCustomers = false;
      })
    }
  }

  getAllowedClientsForCustomer = async (value: string) => {
    runInAction(() => {
      this.loadingClients = true;
    });

    const { success, data }: { success: boolean; data: ISimpleClient[] } = await this.rootStore.baseApiService.get(`statistics/report-clients?customerId=${value}`);
    if (success) {
      runInAction(() => {
        this.loadingClients = false;
      })
      return data;
    } else {
      runInAction(() => {
        this.loadingClients = false;
      })
    }
  }

  GetAllowedFlowIdsForCustomerAndClient = async (customerId: number, clientId: number | undefined) => {
    runInAction(() => {
      this.loadingFlowIds = true;
    });

    const { success, data }: { success: boolean; data: ISimpleFlowId[] } = await this.rootStore.baseApiService.get(`statistics/report-flowids?customerId=${customerId}&clientId=${clientId ?? ""}`);
    if (success) {
      runInAction(() => {
        this.loadingFlowIds = false;
      })
      return data;
    } else {
      runInAction(() => {
        this.loadingFlowIds = false;
      })
    }
  }

  getStatisticsByCustomer = async () => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IgetHomeScreenStatisticsByCustomerId[] } = await this.rootStore.baseApiService.get(`adminexcelexport/excel/recent/1`); // TODO: integer
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}