import * as yup from 'yup'
import { AnimationCheck } from '../../AnimationViews/AnimationCheck'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { Button, Form, Header, Segment } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { ELogType } from '../../../Enums/ELogType'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { getFieldValue, setFieldValue } from '../../../utils/LocalStoragePersonalInformation'
import { isDev } from '../../../utils/IsDev'
import { postNategisStartService, postTransactionProgressLog } from '../../../Services/Backend'
import { StateDispatch, StateValue } from '../../Context'
import { TimerButton } from '../../Common/Button/TimerButton'
import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'

export const NategisCheckComponent: FunctionComponent = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  const validationSchema = [
    yup.object().shape({
      TITLE: yup.string(),
      INITIALS: yup.string().min(1, ""),
      MIDDLENAME: yup.string(),
      LASTNAME: yup.string().required(t("Nategis.Last_Name")),
      GENDER: yup.string(),
      BIRTHDATE: yup.string(),
    }),
    yup.object().shape({
      STREET: yup.string(),
      HOUSENUMBER: yup.number().not([0], t("Nategis.House_Number")).required(t("Nategis.House_Number_Required")),
      HOUSENUMBERADDITION: yup.string(),
      POSTALCODE: yup.string().required(t("Nategis.Postal_Code_Required")),
      CITY: yup.string(),
    }),
    yup.object().shape({
      PHONENUMBER: yup.string(),
      MOBILENUMBER: yup.string(),
      EMAIL: yup.string().email(),
    }),
  ];

  const currentValidationSchema = validationSchema[currentPage];

  const handleNext = () => {
    currentPage < 2 ? setCurrentPage(currentPage + 1) : state.view === "success" ? onSuccess() : postBackend()
  }

  const formik = useFormik({
    initialValues: {
      TITLE: "",
      INITIALS: "",
      MIDDLENAME: "",
      LASTNAME: "",
      GENDER: "",
      BIRTHDATE: "",
      STREET: "",
      HOUSENUMBER: "",
      HOUSENUMBERADDITION: "",
      POSTALCODE: "",
      CITY: "",
      PHONENUMBER: "",
      MOBILENUMBER: "",
      EMAIL: "",
    },
    validationSchema: currentValidationSchema,
    validateOnMount: true,
    onSubmit: handleNext,
  });

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.NategisCheckEntered });
    formik.setFieldValue("INITIALS", getFieldValue("INITIALS") ?? "");
    formik.setFieldValue("FIRSTNAME", getFieldValue("FIRSTNAME") ?? "");
    formik.setFieldValue("MIDDLENAME", getFieldValue("MIDDLENAME") ?? "");
    formik.setFieldValue("LASTNAME", getFieldValue("LASTNAME") ?? "");
    formik.setFieldValue("BIRTHDATE", getFieldValue("BIRTHDATE") ?? "");
    formik.setFieldValue("GENDER", getFieldValue("GENDER") ?? "");
  }, [])

  const dispatch = useContext(StateDispatch);

  const postBackend = () => {
    dispatch({ type: "setView", data: "checking" });
    setFieldValue("INITIALS", formik.values.INITIALS);
    setFieldValue("MIDDLENAME", formik.values.MIDDLENAME);
    setFieldValue("LASTNAME", formik.values.LASTNAME);
    setFieldValue("BIRTHDATE", formik.values.BIRTHDATE);
    setFieldValue("GENDER", formik.values.GENDER);
    postNategisStartService({
      title: formik.values.TITLE,
      initials: formik.values.INITIALS,
      middleName: formik.values.MIDDLENAME,
      lastName: formik.values.LASTNAME,
      gender: formik.values.GENDER,
      birthDate: formik.values.BIRTHDATE.replace(/-/g, ''),
      street: formik.values.STREET,
      houseNumber: formik.values.HOUSENUMBER,
      houseNumberAddition: formik.values.HOUSENUMBERADDITION,
      postalCode: formik.values.POSTALCODE,
      city: formik.values.CITY,
      phoneNumber: formik.values.PHONENUMBER,
      mobileNumber: formik.values.MOBILENUMBER,
      email: formik.values.EMAIL,
      ctxId: state.ctxId
    }).then(res => {
      postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.NategisCheckCompleted });
      dispatch({ type: "setView", data: "success" });
    }).catch((e) => {
      if (isDev) {
        onSuccess();
      } else {
        window.location.reload();
      }
    });
  }

  const onSuccess = () => {
    dispatch({ type: "setView", data: "form" });
    setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
  }

  const optionsGender = [
    { key: 'm', text: 'Man', value: 'M' },
    { key: 'f', text: 'Vrouw', value: 'F' },
  ]
  const [optionsTitle, setOptionsTitle] = useState([
    { key: 'dhr', text: 'Dhr.', value: 'dhr' },
    { key: 'mevr', text: 'Mevr.', value: 'mevr' },
    { key: 'ot', text: 'Anders (typ in)', value: 'other', disabled: true },
  ]);


  const renderPage = () => {
    switch (currentPage) {
      case 0:
        return (
          <>
            <Header as='h3'> {t("Nategis.Personal_Details")} </Header>
            <Form widths="equal" className="w100 h100">
              <Form.Group unstackable>
                <Form.Dropdown
                  labeled
                  options={optionsTitle} label={t("Nategis.Title_Form")} placeholder={t("Nategis.Title_Form")} name="TITLE" id="TITLE" onFocus={(e) => formik.setFieldTouched("TITLE", true)} onChange={(e, d) => formik.setFieldValue("TITLE", d.value)} width={2}
                  allowAdditions
                  search
                  fluid
                  compact
                  value={formik.values.TITLE}
                  onAddItem={(e, d) => { setOptionsTitle([...optionsTitle, { key: d.value?.toString() ?? "", text: d.value?.toString() ?? "", value: d.value?.toString() ?? "" }]); formik.setFieldValue("TITLE", d.value) }}
                />
                <Form.Input label={t("Nategis.Initials")} value={formik.values.INITIALS} error={formik.touched.INITIALS && formik.errors.INITIALS} placeholder={t("Nategis.Initials")} name="INITIALS" id="INITIALS" onFocus={() => formik.setFieldTouched("INITIALS", true)} onChange={(e) => formik.handleChange(e)} width={4} />
                <Form.Input label={t("Nategis.Middle_Name")} value={formik.values.MIDDLENAME} placeholder={t("Nategis.Middle_Name")} name="MIDDLENAME" id="MIDDLENAME" onFocus={(e: any) => formik.setFieldTouched("MIDDLENAME", true)} onChange={formik.handleChange} error={formik.touched.MIDDLENAME && formik.errors.MIDDLENAME} width={3} />
                <Form.Input label={t("Nategis.Last_Name")} value={formik.values.LASTNAME} placeholder={t("Nategis.Last_Name")} name="LASTNAME" id="LASTNAME" onFocus={(e: any) => formik.setFieldTouched("LASTNAME", true)} onChange={formik.handleChange} error={formik.touched.LASTNAME && formik.errors.LASTNAME} width={3} required />
              </Form.Group>
              <Form.Group unstackable>
                <Form.Select options={optionsGender} label={t("Nategis.Gender")} value={formik.values.GENDER} placeholder={t("Nategis.Gender")} name="GENDER" id="GENDER" onFocus={(e) => formik.setFieldTouched("GENDER", true)} onChange={(e, d) => formik.setFieldValue("GENDER", d.value)} error={formik.touched.GENDER && formik.errors.GENDER} width={6} />
                <Form.Field width="6" error={formik.touched.BIRTHDATE && formik.errors.BIRTHDATE}>
                  <label>{t("Nategis.Birthdate")}</label>
                  <input type="date" id="BIRTHDATE" name="BIRTHDATE" value={formik.values.BIRTHDATE} onChange={formik.handleChange} />
                </Form.Field>
              </Form.Group>
            </Form>
          </>
        )
      case 1:
        return (
          <>
            <Header as='h3'> {t("Nategis.Address_Information")} </Header>
            <Form widths="equal" className="w100 h100">
              <Form.Group unstackable>
                <Form.Input label={t("Nategis.Street")} value={formik.values.STREET} placeholder={t("Nategis.Street")} name="STREET" id="STREET" onFocus={(e: any) => formik.setFieldTouched("STREET", true)} onChange={formik.handleChange} error={formik.touched.STREET && formik.errors.STREET} width={7} />
                <Form.Input type="number" value={formik.values.HOUSENUMBER} min="0" label={t("Nategis.House_Number")} placeholder={t("Nategis.House_Number")} name="HOUSENUMBER" id="HOUSENUMBER" onFocus={(e: any) => formik.setFieldTouched("HOUSENUMBER", true)} onChange={formik.handleChange} error={formik.touched.HOUSENUMBER && formik.errors.HOUSENUMBER} width={2} required />
                <Form.Input label={t("Nategis.House_Number_Addition")} value={formik.values.HOUSENUMBERADDITION} placeholder='A03' name="HOUSENUMBERADDITION" id="HOUSENUMBERADDITION" onFocus={(e: any) => formik.setFieldTouched("HOUSENUMBERADDITION", true)} onChange={formik.handleChange} error={formik.touched.HOUSENUMBERADDITION && formik.errors.HOUSENUMBERADDITION} width={1} />
              </Form.Group>
              <Form.Group unstackable>
                <Form.Input label={t("Nategis.Postal_Code")} value={formik.values.POSTALCODE} placeholder={t("Nategis.Postal_Code")} name="POSTALCODE" id="POSTALCODE" onFocus={(e: any) => formik.setFieldTouched("POSTALCODE", true)} onChange={formik.handleChange} error={formik.touched.POSTALCODE && formik.errors.POSTALCODE} width={4} required />
                <Form.Input label={t("Nategis.City")} value={formik.values.CITY} placeholder={t("Nategis.City")} name="CITY" id="CITY" onFocus={(e: any) => formik.setFieldTouched("CITY", true)} onChange={formik.handleChange} error={formik.touched.CITY && formik.errors.CITY} width={4} />
              </Form.Group>
            </Form>
          </>
        )
      case 2:
        return (
          <>
            <Form widths="equal" className="w100 h100">
              <Header as='h3'> {t("Nategis.Contact_Details")} </Header>
              <Form.Group unstackable>
                <Form.Input label={t("Nategis.Phone_Number")} value={formik.values.PHONENUMBER} placeholder={t("Nategis.Phone_Number")} name="PHONENUMBER" id="PHONENUMBER" onFocus={(e: any) => formik.setFieldTouched("PHONENUMBER", true)} onChange={formik.handleChange} error={formik.touched.PHONENUMBER && formik.errors.PHONENUMBER} width={4} />
                <Form.Input label={t("Nategis.Mobile_Phone_Number")} value={formik.values.MOBILENUMBER} placeholder={t("Nategis.Mobile_Phone_Number")} name="MOBILENUMBER" id="MOBILENUMBER" onFocus={(e: any) => formik.setFieldTouched("MOBILENUMBER", true)} onChange={formik.handleChange} error={formik.touched.MOBILENUMBER && formik.errors.MOBILENUMBER} width={4} />
              </Form.Group>
              <Form.Group unstackable>
                <Form.Input label={t("Nategis.Email")} value={formik.values.EMAIL} placeholder={t("Nategis.Email")} name="EMAIL" id="EMAIL" onFocus={(e: any) => formik.setFieldTouched("EMAIL", true)} onChange={formik.handleChange} error={formik.touched.EMAIL && formik.errors.EMAIL} width={4} />
              </Form.Group>
            </Form>
          </>
        )
    }
  }

  const renderView = () => {
    switch (state.view) {
      case "form":
        return (<>
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={`${currentPage}-trans`}
              addEndListener={(node, done) => {
                node.addEventListener("transitionend", done, false);
              }}
              classNames="fade"
            >
              <div className="transition-container nategis">
                {renderPage()}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </>
        )
      case "checking":
        return <AnimationCheck header="General_UI.Verifying" text="General_UI.One_Moment" key={`${state.view}-check`} svgComponent={state.org.theme.config.checkSvg && <state.org.theme.config.checkSvg fill={state.org.theme.themeColor} />} />
      case "success":
        return <AnimationSuccess header="General_UI.Great" text="General_UI.Correct" key={`${state.view}-success`} />
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${state.view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container nategis">
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
      <Segment basic className="service-item-button-container mt-0">
        <Button floated="left" type="submit" onClick={() => { setCurrentPage(currentPage - 1) }} disabled={state.view !== "form" || currentPage === 0}>
          {t("General_UI.Back")}
        </Button>
        {state.view === "success" ?
          <TimerButton countDownStart={state.org.theme.config.countDownStart ?? 5} callback={formik.submitForm} /> :
          <Button floated="right" type="submit" onClick={() => formik.submitForm()} loading={state.view === "checking"} >
            {t("General_UI.Next")}
          </Button>
        }
      </Segment>
    </>
  );
};

