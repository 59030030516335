import MitekHeader from '../../MitekHeader'
import { AnimationCheck } from '../../../../AnimationViews/AnimationCheck'
import { Segment, Button } from 'semantic-ui-react'
import { state } from '../../../../Context'
import { useTranslation } from 'react-i18next'

export type IProps = {
  state: state | undefined
  onSuccess: () => void;
}

const MitekCheckLoadingComponentDesktop = (props: IProps) => {
  const { t } = useTranslation(props.state?.org.theme.config.translationKey);

  return (
    <>
      <MitekHeader />
      <Segment id="loading-screen" className="mitek" basic style={{ height: "90%", width: "100%", margin: 0, paddingBottom: 0 }}>
        <AnimationCheck
          header={t("Mitek.Loading.Automatic_Refresh_Header_Desktop")}
          text={t("Mitek.Loading.Automatic_Refresh_Text")}
          svgComponent={props.state?.org.theme.config.checkSvg && <props.state.org.theme.config.checkSvg fill={props.state.org.theme.themeColor} />}
        />
      </Segment>
      <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
        <Button className="full-width" loading color="green" floated="right" disabled>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
}

export default MitekCheckLoadingComponentDesktop