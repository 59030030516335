import { EMitekPageType } from '../Enums/EMitekPageType'
import { EService } from '../Enums/EService'
import { StateValue } from '../Components/Context'
import { useContext, useEffect, useState } from 'react'

export function useView<T>(initialView: T) {
  const [view, setView] = useState<T>(initialView);
  const state = useContext(StateValue);

  useEffect(() => {
    if (state.org.theme.config.isIframe) {
      if ((view as any) in EMitekPageType) {
        // console.log(JSON.stringify({ current_step: state.current.order, current_section: EMitekPageType[view as any] }));
        window.parent.postMessage({ current_step: state.current.order, current_section: EMitekPageType[view as any] }, "*");
        return;
      }

      // console.log(JSON.stringify({ current_step: state.current.order, current_section: formatViewString(view) }));
      window.parent.postMessage({ current_step: state.current.order, current_section: formatViewString(view) }, "*");
    }
  }, [view]);

  return [view, setView] as const;
}


export function formatViewString(str: any): string {
  return str.toUpperCase().replace(/-/g, "_");
}