import useInterval from '../../../Hooks/useInterval'
import { AnimationCheck } from '../../AnimationViews/AnimationCheck'
import { AnimationSuccess } from '../../AnimationViews/AnimationSuccess'
import { Button, Container, Dropdown, Header, Icon, Modal, Popup, Segment } from 'semantic-ui-react'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { DevLog } from '../../../utils/DevLog'
import { ELogType } from '../../../Enums/ELogType'
import { getIdinCheckStatus, postIdinStartService, postTransactionProgressLog } from '../../../Services/Backend'
import { isDesktop } from 'react-device-detect'
import { renderToStaticMarkup } from 'react-dom/server'
import { StateDispatch, StateValue } from '../../Context'
import { TimerButton } from '../../Common/Button/TimerButton'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useView } from '../../../Hooks/useView'

type IdinCheckProps = {
  idinNeeded?: boolean;
}

const bankoptions = [
  {
    key: 'ABN-amro',
    text: 'ABN AMRO',
    value: 'ABNANL2A',
  },
  {
    key: 'ASN-Bank',
    text: 'ASN Bank',
    value: 'ASNBNL21',
  },
  {
    key: 'Bunq',
    text: 'Bunq',
    value: 'BUNQNL2A',
  },
  {
    key: 'ING',
    text: 'ING',
    value: 'INGBNL2A',
  },
  {
    key: 'Rabobank',
    text: 'Rabobank',
    value: 'RABONL2U',
  },
  {
    key: 'Regiobank',
    text: 'Regiobank',
    value: 'RBRBNL21',
  },
  {
    key: 'SNS',
    text: 'SNS',
    value: 'SNSBNL2A',
  },
]

export const IdinCheckComponent = (props: IdinCheckProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const [open, setOpen] = useState(false)
  const [finishedIframe, setFinishedIframe] = useState(false);
  const [view, setView] = useView<"success" | "form" | "checking">("form");
  const background = state.org.theme.config.idinSvg ? encodeURIComponent(renderToStaticMarkup(<state.org.theme.config.idinSvg fill={state.org.theme.themeColor} />)) : null;

  const dispatch = useContext(StateDispatch);
  const [currentBank, setCurrentBank] = useState<string | undefined>(undefined);
  const [url, setUrl] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    postTransactionProgressLog({ ctxId: state.ctxId, logType: ELogType.IdinCheckEntered });
    if (localStorage.getItem("idinRedirect") === "true" && state.lastFinishedAccordingToBackend! >= state.current.order && state.lastFinishedAccordingToBackend !== null) {
      setView("success");
    }
  }, [])

  const onSuccess = () => {
    setView("form");
    setTimeout(() => dispatch({ type: "setCurrent", data: state.flow[state.current.order + 1] }), 700);
  }

  useEffect(() => {
    return () => {
      localStorage.setItem("idinRedirect", "true");
    }
  }, []);

  useInterval(() => {
    if (state.org.theme.config.isIframe && url !== "" && finishedIframe == false) {
      getIdinCheckStatus(state.ctxId).then(res => {
        if (res.data.completed) {
          setView("success");
          setFinishedIframe(true);
        }
      })
    }
  }, 5000);


  useEffect(() => {
    if (currentBank !== undefined) {
      DevLog("Current bank has changed, updating URL");
      postIdinStartService(state.ctxId, currentBank!).then(res => {
        if (res.status === 200) {
          setUrl(res.data.transactionUrl);
        }
      }).catch(error => {
        setIsSubmitting(false);
        dispatch({ type: "setErrors", data: [t("General_UI.Incorrect")] })
        setView("form");
      })
    }
  }, [currentBank])

  const renderView = () => {
    switch (view) {
      case "form":
        return (
          <>
            <span id="idin-container">
              {
                state.org.theme.config.showTitle && (
                  <h1 className="service-item-header"> {t("Idin.Title")} </h1>
                )
              }
              {
                <Header as={"h2"} className="service-item-subheader">
                  {t("Idin.Header")}
                </Header>
              }

              <h3 className="service-item-explanation">
                {t("Idin.SubHeader")}
              </h3>
              {
                !state.org.theme.config.showTitle && (<br />)
              }
              <div className="bank-dropdown-container">
                <b className="mb-2">{t("Idin.Choose_Bank")}*</b>
                <Dropdown
                  placeholder={t("Idin.Choose_Bank")}
                  fluid
                  // search
                  onChange={(e, d) => setCurrentBank(d.value?.toString() ?? undefined)}
                  selection
                  scrolling
                  options={bankoptions}
                  className="bank-dropdown mb-2"
                />
                <i>{t("Idin.Info")}</i>
              </div>
            </span>
            <span className="explanation-text mb-4">
              {t("Idin.What_Idin_Check")}
              {
                isDesktop && !state.org.theme.config.isIframe ? (
                  <Popup
                    trigger={<Icon name='info circle' />}
                    content={<span>{t("Idin.What_Idin_Check_Explanation")}</span>}
                    size='large'
                    position='top right'
                  />
                ) : (<>
                  <Modal
                    basic
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    open={open}
                    size='small'
                    trigger={<Icon name='info circle' />}
                  >
                    <Modal.Content>
                      <Container text>{t("Idin.What_Idin_Check_Explanation")}</Container>
                    </Modal.Content>
                    <Modal.Actions>
                      <Button basic color='red' inverted onClick={() => setOpen(false)}>
                        <Icon name='remove' />{t("General_UI.Close")}
                      </Button>
                    </Modal.Actions>
                  </Modal>
                </>
                )
              }
            </span>
          </>
        )
      case "checking":
        return <AnimationCheck svgComponent={state.org.theme.config.checkSvg && <state.org.theme.config.checkSvg fill={state.org.theme.themeColor} />} header={t("General_UI.Verifying")} text={t("General_UI.One_Moment")} key={`${view}-check`} />
      case "success":
        return <AnimationSuccess header={t("General_UI.Great")} text={t("General_UI.Correct")} key={`${view}-success`} />
    }
  }

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`${view}-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container" style={{ background: view !== "success" ? `url("data:image/svg+xml,${background}")` : "" }}>
            {renderView()}
          </div>
        </CSSTransition>
      </SwitchTransition>
      {/* TODO: Refactor button logic */}
      <Segment basic className="service-item-button-container mt-0">
        {
          view == "success" || finishedIframe ? (
            <TimerButton countDownStart={state.org.theme.config.countDownStart ?? 5} callback={() => onSuccess()} />
          ) : (
            <>
              {(state.lastFinishedAccordingToBackend ?? -1) < state.current.order && localStorage.getItem("idinRedirect") !== "true" ? <Button href={url} target={state.org.theme.config.isIframe ? "_blank" : ""} loading={isSubmitting} disabled={isSubmitting || url === ""} color="green" floated="right" type="submit">
                {t("Idin.Open")}
              </Button> : null}
              {(state.lastFinishedAccordingToBackend ?? -1) < state.current.order && localStorage.getItem("idinRedirect") === "true" ? <Button href={url} target={state.org.theme.config.isIframe ? "_blank" : ""} disabled={isSubmitting || url === ""} loading={isSubmitting} color="red" floated="right" type="submit">
                {t("General_UI.Retry")}
              </Button> : null}
            </>
          )
        }
      </Segment>
    </>
  );
};
