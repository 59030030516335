import { ChangePasswordProfileFormvalues } from "../models/user/IPassword";
import { EToastType } from "../Enums/EToastType";
import { IProfile, ProfileFormValues } from "../models/user/IProfile";
import { RootStore } from "./RootStore";
import { history, routePrefixDashboard } from "../ApplicationRouter";
import { makeAutoObservable, runInAction } from "mobx";

export default class ProfileStore {
  rootStore: RootStore;
  profile: IProfile | undefined = undefined;
  loadingProfile: boolean = false;
  submitting: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getProfile = async () => {
    runInAction(() => {
      this.loadingProfile = true;
    });

    const { success, data }: { success: boolean; data: IProfile } = await this.rootStore.baseApiService.get("profile");
    if (success) {
      runInAction(() => {
        this.profile = data;
        this.loadingProfile = false;
      });
    } else {
      runInAction(() => {
        this.loadingProfile = false;
      });
    }
  }

  editProfile = async (values: ProfileFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.put("profile", values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Edit_Success");
      });
      await this.getProfile(); // Reload profile to update the navBar
      history.push(routePrefixDashboard);
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      })
    }
  }

  changePassword = async (values: ChangePasswordProfileFormvalues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success } = await this.rootStore.baseApiService.post("profile/change-password", values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Edit_Success");
      });
      history.push(routePrefixDashboard);
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }
}