import SuccessSvg from "./SuccessSvg"
import { Button, Container, Grid, Header, Image, Segment } from "semantic-ui-react"
import { Fragment } from "react"
import { observer } from "mobx-react-lite"

interface IProps {
  logo: string,
  name: string,
  themeColor: string
}

export const OrganizationThemePreview: React.FC<IProps> = observer(({ logo, name, themeColor }) => {
  return (
    <Container style={{ width: "35%", marginTop: "1em" }}>
      <Segment.Group className="containersegment">
        <Segment>
          <Header as="h3" style={{ marginBottom: 0 }}>
            <Image
              src={logo}
              size="massive"
              alt="logo"
            />
            {name}
          </Header>
        </Segment>
        <Fragment>
          <Segment>
            <Segment basic>
              <Grid columns={2} stackable textAlign="left">
                <Grid.Row>
                  <Grid.Column verticalAlign="top">
                    <Header as="h2">Gelukt!</Header>
                    <Header as="h4">Uw gegevens zijn met success gevalideerd!</Header>
                  </Grid.Column>
                  <Grid.Column verticalAlign="bottom">
                    <SuccessSvg color={themeColor} />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </Segment>
        </Fragment>
        <Segment style={{ minHeight: "5em" }}>
          <Button floated="right" type="submit" disabled>
            Voorbeeld
          </Button>
        </Segment>
      </Segment.Group>
    </Container>
  )
})