export const countries = [
  { name: 'AFGHANISTAN', countryCode: 'AF' },
  { name: 'ALAND ISLANDS', countryCode: 'AX' },
  { name: 'ALBANIA', countryCode: 'AL' },
  { name: 'ALGERIA', countryCode: 'DZ' },
  { name: 'AMERICAN SAMOA', countryCode: 'AS' },
  { name: 'ANDORRA', countryCode: 'AD' },
  { name: 'ANGOLA', countryCode: 'AO' },
  { name: 'ANGUILLA', countryCode: 'AI' },
  { name: 'ANTIGUA', countryCode: 'AG' },
  { name: 'ARGENTINA', countryCode: 'AR' },
  { name: 'ARMENIA', countryCode: 'AM' },
  { name: 'ARUBA', countryCode: 'AW' },
  { name: 'AUSTRALIA', countryCode: 'AU' },
  { name: 'AUSTRIA', countryCode: 'AT' },
  { name: 'AZERBAIJAN', countryCode: 'AZ' },
  { name: 'BAHAMAS', countryCode: 'BS' },
  { name: 'BAHRAIN', countryCode: 'BH' },
  { name: 'BANGLADESH', countryCode: 'BD' },
  { name: 'BARBADOS', countryCode: 'BB' },
  { name: 'BELARUS', countryCode: 'BY' },
  { name: 'BELGIUM', countryCode: 'BE' },
  { name: 'BELIZE', countryCode: 'BZ' },
  { name: 'BENIN', countryCode: 'BJ' },
  { name: 'BERMUDA', countryCode: 'BM' },
  { name: 'BHUTAN', countryCode: 'BT' },
  { name: 'BOLIVIA', countryCode: 'BO' },
  { name: 'BOSNIA', countryCode: 'BA' },
  { name: 'BOTSWANA', countryCode: 'BW' },
  { name: 'BOUVET ISLAND', countryCode: 'BV' },
  { name: 'BRAZIL', countryCode: 'BR' },
  { name: 'BRITISH VIRGIN ISLANDS', countryCode: 'VG' },
  { name: 'BRUNEI', countryCode: 'BN' },
  { name: 'BULGARIA', countryCode: 'BG' },
  { name: 'BURKINA FASO', countryCode: 'BF' },
  { name: 'BURMA', countryCode: 'MM', ALIAS: 'MYANMAR' },
  { name: 'BURUNDI', countryCode: 'BI' },
  { name: 'CAICOS ISLANDS', countryCode: 'TC' },
  { name: 'CAMBODIA', countryCode: 'KH' },
  { name: 'CAMEROON', countryCode: 'CM' },
  { name: 'CANADA', countryCode: 'CA' },
  { name: 'CAPE VERDE', countryCode: 'CV' },
  { name: 'CAYMAN ISLANDS', countryCode: 'KY' },
  { name: 'CENTRAL AFRICAN REPUBLIC', countryCode: 'CF' },
  { name: 'CHAD', countryCode: 'TD' },
  { name: 'CHILE', countryCode: 'CL' },
  { name: 'CHINA', countryCode: 'CN' },
  { name: 'CHRISTMAS ISLAND', countryCode: 'CX' },
  { name: 'COCOS ISLANDS', countryCode: 'CC' },
  { name: 'COLOMBIA', countryCode: 'CO' },
  { name: 'COMOROS', countryCode: 'KM' },
  { name: 'CONGO', countryCode: 'CD' },
  { name: 'CONGO BRAZZAVILLE', countryCode: 'CG' },
  { name: 'COOK ISLANDS', countryCode: 'CK' },
  { name: 'COSTA RICA', countryCode: 'CR' },
  { name: 'COTE DIVOIRE', countryCode: 'CI' },
  { name: 'CROATIA', countryCode: 'HR' },
  { name: 'CUBA', countryCode: 'CU' },
  { name: 'CYPRUS', countryCode: 'CY' },
  { name: 'CZECH REPUBLIC', countryCode: 'CZ' },
  { name: 'DENMARK', countryCode: 'DK' },
  { name: 'DJIBOUTI', countryCode: 'DJ' },
  { name: 'DOMINICA', countryCode: 'DM' },
  { name: 'DOMINICAN REPUBLIC', countryCode: 'DO' },
  { name: 'ECUADOR', countryCode: 'EC' },
  { name: 'EGYPT', countryCode: 'EG' },
  { name: 'EL SALVADOR', countryCode: 'SV' },
  { name: 'ENGLAND', countryCode: 'GB ENG' },
  { name: 'EQUATORIAL GUINEA', countryCode: 'GQ' },
  { name: 'ERITREA', countryCode: 'ER' },
  { name: 'ESTONIA', countryCode: 'EE' },
  { name: 'ETHIOPIA', countryCode: 'ET' },
  { name: 'EUROPEANUNION', countryCode: 'EU' },
  { name: 'FALKLAND ISLANDS', countryCode: 'FK' },
  { name: 'FAROE ISLANDS', countryCode: 'FO' },
  { name: 'FIJI', countryCode: 'FJ' },
  { name: 'FINLAND', countryCode: 'FI' },
  { name: 'FRANCE', countryCode: 'FR' },
  { name: 'FRENCH GUIANA', countryCode: 'GF' },
  { name: 'FRENCH POLYNESIA', countryCode: 'PF' },
  { name: 'FRENCH TERRITORIES', countryCode: 'TF' },
  { name: 'GABON', countryCode: 'GA' },
  { name: 'GAMBIA', countryCode: 'GM' },
  { name: 'GEORGIA', countryCode: 'GE' },
  { name: 'GERMANY', countryCode: 'DE' },
  { name: 'GHANA', countryCode: 'GH' },
  { name: 'GIBRALTAR', countryCode: 'GI' },
  { name: 'GREECE', countryCode: 'GR' },
  { name: 'GREENLAND', countryCode: 'GL' },
  { name: 'GRENADA', countryCode: 'GD' },
  { name: 'GUADELOUPE', countryCode: 'GP' },
  { name: 'GUAM', countryCode: 'GU' },
  { name: 'GUATEMALA', countryCode: 'GT' },
  { name: 'GUINEA', countryCode: 'GN' },
  { name: 'GUINEA-BISSAU', countryCode: 'GW' },
  { name: 'GUYANA', countryCode: 'GY' },
  { name: 'HAITI', countryCode: 'HT' },
  { name: 'HEARD ISLAND', countryCode: 'HM' },
  { name: 'HONDURAS', countryCode: 'HN' },
  { name: 'HONG KONG', countryCode: 'HK' },
  { name: 'HUNGARY', countryCode: 'HU' },
  { name: 'ICELAND', countryCode: 'IS' },
  { name: 'INDIA', countryCode: 'IN' },
  { name: 'INDIAN OCEAN TERRITORY', countryCode: 'IO' },
  { name: 'INDONESIA', countryCode: 'ID' },
  { name: 'IRAN', countryCode: 'IR' },
  { name: 'IRAQ', countryCode: 'IQ' },
  { name: 'IRELAND', countryCode: 'IE' },
  { name: 'ISRAEL', countryCode: 'IL' },
  { name: 'ITALY', countryCode: 'IT' },
  { name: 'JAMAICA', countryCode: 'JM' },
  { name: 'JAN MAYEN', countryCode: 'SJ', ALIAS: 'SVALBARD' },
  { name: 'JAPAN', countryCode: 'JP' },
  { name: 'JORDAN', countryCode: 'JO' },
  { name: 'KAZAKHSTAN', countryCode: 'KZ' },
  { name: 'KENYA', countryCode: 'KE' },
  { name: 'KIRIBATI', countryCode: 'KI' },
  { name: 'KUWAIT', countryCode: 'KW' },
  { name: 'KYRGYZSTAN', countryCode: 'KG' },
  { name: 'LAOS', countryCode: 'LA' },
  { name: 'LATVIA', countryCode: 'LV' },
  { name: 'LEBANON', countryCode: 'LB' },
  { name: 'LESOTHO', countryCode: 'LS' },
  { name: 'LIBERIA', countryCode: 'LR' },
  { name: 'LIBYA', countryCode: 'LY' },
  { name: 'LIECHTENSTEIN', countryCode: 'LI' },
  { name: 'LITHUANIA', countryCode: 'LT' },
  { name: 'LUXEMBOURG', countryCode: 'LU' },
  { name: 'MACAU', countryCode: 'MO' },
  { name: 'MACEDONIA', countryCode: 'MK' },
  { name: 'MADAGASCAR', countryCode: 'MG' },
  { name: 'MALAWI', countryCode: 'MW' },
  { name: 'MALAYSIA', countryCode: 'MY' },
  { name: 'MALDIVES', countryCode: 'MV' },
  { name: 'MALI', countryCode: 'ML' },
  { name: 'MALTA', countryCode: 'MT' },
  { name: 'MARSHALL ISLANDS', countryCode: 'MH' },
  { name: 'MARTINIQUE', countryCode: 'MQ' },
  { name: 'MAURITANIA', countryCode: 'MR' },
  { name: 'MAURITIUS', countryCode: 'MU' },
  { name: 'MAYOTTE', countryCode: 'YT' },
  { name: 'MEXICO', countryCode: 'MX' },
  { name: 'MICRONESIA', countryCode: 'FM' },
  { name: 'MOLDOVA', countryCode: 'MD' },
  { name: 'MONACO', countryCode: 'MC' },
  { name: 'MONGOLIA', countryCode: 'MN' },
  { name: 'MONTENEGRO', countryCode: 'ME' },
  { name: 'MONTSERRAT', countryCode: 'MS' },
  { name: 'MOROCCO', countryCode: 'MA' },
  { name: 'MOZAMBIQUE', countryCode: 'MZ' },
  { name: 'NAMIBIA', countryCode: 'NA' },
  { name: 'NAURU', countryCode: 'NR' },
  { name: 'NEPAL', countryCode: 'NP' },
  { name: 'NETHERLANDS', countryCode: 'NL' },
  { name: 'NETHERLANDSANTILLES', countryCode: 'AN' },
  { name: 'NEW CALEDONIA', countryCode: 'NC' },
  { name: 'NEW GUINEA', countryCode: 'PG' },
  { name: 'NEW ZEALAND', countryCode: 'NZ' },
  { name: 'NICARAGUA', countryCode: 'NI' },
  { name: 'NIGER', countryCode: 'NE' },
  { name: 'NIGERIA', countryCode: 'NG' },
  { name: 'NIUE', countryCode: 'NU' },
  { name: 'NORFOLK ISLAND', countryCode: 'NF' },
  { name: 'NORTH KOREA', countryCode: 'KP' },
  { name: 'NORTHERN MARIANA ISLANDS', countryCode: 'MP' },
  { name: 'NORWAY', countryCode: 'NO' },
  { name: 'OMAN', countryCode: 'OM' },
  { name: 'PAKISTAN', countryCode: 'PK' },
  { name: 'PALAU', countryCode: 'PW' },
  { name: 'PALESTINE', countryCode: 'PS' },
  { name: 'PANAMA', countryCode: 'PA' },
  { name: 'PARAGUAY', countryCode: 'PY' },
  { name: 'PERU', countryCode: 'PE' },
  { name: 'PHILIPPINES', countryCode: 'PH' },
  { name: 'PITCAIRN ISLANDS', countryCode: 'PN' },
  { name: 'POLAND', countryCode: 'PL' },
  { name: 'PORTUGAL', countryCode: 'PT' },
  { name: 'PUERTO RICO', countryCode: 'PR' },
  { name: 'QATAR', countryCode: 'QA' },
  { name: 'REUNION', countryCode: 'RE' },
  { name: 'ROMANIA', countryCode: 'RO' },
  { name: 'RUSSIA', countryCode: 'RU' },
  { name: 'RWANDA', countryCode: 'RW' },
  { name: 'SAINT HELENA', countryCode: 'SH' },
  { name: 'SAINT KITTS AND NEVIS', countryCode: 'KN' },
  { name: 'SAINT LUCIA', countryCode: 'LC' },
  { name: 'SAINT PIERRE', countryCode: 'PM' },
  { name: 'SAINT VINCENT', countryCode: 'VC' },
  { name: 'SAMOA', countryCode: 'WS' },
  { name: 'SAN MARINO', countryCode: 'SM' },
  { name: 'SANDWICH ISLANDS', countryCode: 'GS' },
  { name: 'SAO TOME', countryCode: 'ST' },
  { name: 'SAUDI ARABIA', countryCode: 'SA' },
  { name: 'SCOTLAND', countryCode: 'GB SCT' },
  { name: 'SENEGAL', countryCode: 'SN' },
  { name: 'SERBIA', countryCode: 'CS' },
  { name: 'SERBIA', countryCode: 'RS' },
  { name: 'SEYCHELLES', countryCode: 'SC' },
  { name: 'SIERRA LEONE', countryCode: 'SL' },
  { name: 'SINGAPORE', countryCode: 'SG' },
  { name: 'SLOVAKIA', countryCode: 'SK' },
  { name: 'SLOVENIA', countryCode: 'SI' },
  { name: 'SOLOMON ISLANDS', countryCode: 'SB' },
  { name: 'SOMALIA', countryCode: 'SO' },
  { name: 'SOUTH AFRICA', countryCode: 'ZA' },
  { name: 'SOUTH KOREA', countryCode: 'KR' },
  { name: 'SPAIN', countryCode: 'ES' },
  { name: 'SRI LANKA', countryCode: 'LK' },
  { name: 'SUDAN', countryCode: 'SD' },
  { name: 'SURIname', countryCode: 'SR' },
  { name: 'SWAZILAND', countryCode: 'SZ' },
  { name: 'SWEDEN', countryCode: 'SE' },
  { name: 'SWITZERLAND', countryCode: 'CH' },
  { name: 'SYRIA', countryCode: 'SY' },
  { name: 'TAIWAN', countryCode: 'TW' },
  { name: 'TAJIKISTAN', countryCode: 'TJ' },
  { name: 'TANZANIA', countryCode: 'TZ' },
  { name: 'THAILAND', countryCode: 'TH' },
  { name: 'TIMORLESTE', countryCode: 'TL' },
  { name: 'TOGO', countryCode: 'TG' },
  { name: 'TOKELAU', countryCode: 'TK' },
  { name: 'TONGA', countryCode: 'TO' },
  { name: 'TRINIDAD', countryCode: 'TT' },
  { name: 'TUNISIA', countryCode: 'TN' },
  { name: 'TURKEY', countryCode: 'TR' },
  { name: 'TURKMENISTAN', countryCode: 'TM' },
  { name: 'TUVALU', countryCode: 'TV' },
  { name: 'U.A.E.', countryCode: 'AE', ALIAS: 'UNITED ARAB EMIRATES' },
  { name: 'UGANDA', countryCode: 'UG' },
  { name: 'UKRAINE', countryCode: 'UA' },
  { name: 'UNITED KINGDOM', countryCode: 'GB', ALIAS: 'UK' },
  { name: 'UNITED STATES', countryCode: 'US', ALIAS: 'AMERICA' },
  { name: 'URUGUAY', countryCode: 'UY' },
  { name: 'US MINOR ISLANDS', countryCode: 'UM' },
  { name: 'US VIRGIN ISLANDS', countryCode: 'VI' },
  { name: 'UZBEKISTAN', countryCode: 'UZ' },
  { name: 'VANUATU', countryCode: 'VU' },
  { name: 'VATICAN CITY', countryCode: 'VA' },
  { name: 'VENEZUELA', countryCode: 'VE' },
  { name: 'VIETNAM', countryCode: 'VN' },
  { name: 'WALES', countryCode: 'GB WLS' },
  { name: 'WALLIS AND FUTUNA', countryCode: 'WF' },
  { name: 'WESTERN SAHARA', countryCode: 'EH' },
  { name: 'YEMEN', countryCode: 'YE' },
  { name: 'ZAMBIA', countryCode: 'ZM' },
  { name: 'ZIMBABWE', countryCode: 'ZW' },
]