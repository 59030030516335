import { Button, Segment, Message, Icon } from "semantic-ui-react";
import { isDesktop } from "react-device-detect";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { UtilityBillCheckDto } from "../../../Types/UtilityBill/UtilityBillDto";
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import ReactMarkdown from "react-markdown";

type IProps = {
  imageCallBack: (data: UtilityBillCheckDto) => void
  translationKey: string,
}

const ImageCapture = (props: IProps) => {
  const { t } = useTranslation(props.translationKey);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleImageCapture = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (reader.result) {
            props.imageCallBack({ utilityBillImageBase64: reader.result.toString() });
          }
        };
      }
    }
  };

  return (
    <>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={`$-trans`}
          addEndListener={(node, done) => {
            node.addEventListener("transitionend", done, false);
          }}
          classNames="fade"
        >
          <div className="transition-container">
            <span>
              <ReactMarkdown className='service-item-header' skipHtml>{t("UtilityBill.Information.Title")!}</ReactMarkdown>
              <ReactMarkdown className='explanation-text' skipHtml>{t("UtilityBill.Information.Text")!}</ReactMarkdown>
              <Message warning className={`image-warning`}>
                <Icon name='warning' />
                <ReactMarkdown skipHtml>{t("UtilityBill.ImageCapture.Warning")!}</ReactMarkdown>
              </Message>
            </span>
          </div>

        </CSSTransition>
      </SwitchTransition>

      <Segment basic className="service-item-button-container mt-0">
        <input ref={inputRef} style={{ display: "none" }} type="file" name="imageCapture" accept="image/*" onChange={handleImageCapture} />
        <Button fluid onClick={() => inputRef.current?.click()}>{isDesktop ? t("UtilityBill.ImageCapture.Select_Picture") : t("UtilityBill.ImageCapture.Take_Picture")}</Button>
      </Segment>
    </>
  )
}

export default ImageCapture;