import { Container, Divider, Header } from "semantic-ui-react";
import { OrganizationParent } from "../../../Components/secure/organization/OrganizationParent";

export const CreateOrganization = () => {
  return (
    <>
      <Container>
        <Header as="h1">New</Header>
        <Divider />
        <OrganizationParent />
      </Container>
    </>
  )
};