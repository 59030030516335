import { ClientManagers } from '../../pages/Secure/users/clientManager/ClientManagers';
import { CreateClientManager } from '../../pages/Secure/users/clientManager/CreateClientManager';
import { EditClientManager } from '../../pages/Secure/users/clientManager/EditClientManager';
import { IRoute } from './../../models/routes/IRoute';
import { Roles } from '../../Enums/Roles';

export const ClientManagerRoutes: IRoute[] = [
  {
    component: ClientManagers,
    path: "/client-managers",
    title: "Navbar.ClientManagers",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN, Roles.DTSMANAGER, Roles.CUSTOMERMANAGER, Roles.CLIENTMANAGER],
    dropdown: false,
    childs: []
  },
  {
    component: EditClientManager,
    path: "/client-manager/edit/:id",
    title: "Navbar.ClientManagers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER, Roles.CUSTOMERMANAGER, Roles.CLIENTMANAGER],
    dropdown: false,
    childs: []
  },
  {
    component: CreateClientManager,
    path: "/client-manager/new",
    title: "Navbar.ClientManagers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER, Roles.CUSTOMERMANAGER, Roles.CLIENTMANAGER],
    dropdown: false,
    childs: []
  }
]