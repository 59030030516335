import "./DashboardLayout.scss"
import { ConfirmModalContainer } from "../Components/Common/modal/ConfirmModalContainer";
import { DashboardNavbar } from "../Components/layout/navbar/DashboardNavbar";
import { DashboardNavbarSecondary } from "../Components/layout/navbar/DashboardNavbarSecondary";
import { ModalContainer } from "../Components/Common/modal/ModalContainer";
import { Redirect } from "react-router-dom";
import { RolesAdmins } from "../Enums/Roles";
import { RootStoreContext } from "../stores/RootStore"
import { SessionGuard } from "../Components/Common/guards/SessionGuard";
import { ToastContainer } from "react-toastify";
import { useContext } from "react"

interface IProps {
  children: any;
}

export const DashboardLayout: React.FC<IProps> = ({ children }) => {
  const rootStore = useContext(RootStoreContext);

  if (!rootStore.authorizationStore.isAuthenticated()) {
    return (
      <Redirect to="/" />
    )
  }
  if (rootStore.authorizationStore.isInRoles(RolesAdmins)) {
    return (
      <>
        <SessionGuard />
        <ToastContainer position="top-right" theme="colored" limit={4} pauseOnFocusLoss={false} draggable={false} hideProgressBar={true} />
        <ModalContainer />
        <ConfirmModalContainer />
        <>
          <DashboardNavbar />
          <DashboardNavbarSecondary />
          <main>
            {children}
          </main>
        </>
      </>
    )
  }

  return (
    <Redirect to="/" />
  )
}