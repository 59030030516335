import { EService } from '../Enums/EService'
import { Header, Image, Segment } from 'semantic-ui-react'
import { StateValue } from '../Components/Context'
import { StyledProgressBar } from '../Components/StyledComponents/StyledProgressbar'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import './CDDN/CDDN.mobile.scss'
import './HPG/HPG.mobile.scss'
import './Jacks/Jacks.mobile.scss'
import './ComeOn/ComeOn.mobile.scss'
import './MobileLayout.scss'
import './NovaMedia/NovaMedia.Mobile.scss'
import './Wildmania/Wildmania.mobile.scss'
import './Sonnenspiele/Sonnenspiele.mobile.scss'
import './Fairplay/Fairplay.mobile.scss'
import './Betway/Betway.mobile.scss'
import './Lucky7/Lucky7.mobile.scss'
import './Hommerson/Hommerson.mobile.scss'

interface IProps {
  children: any,
  useLayout?: boolean,
  title: string,
  clientClass?: string,
}

const MobileLayout = (props: IProps) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey)

  const getProgressBarPercent = () => {
    if (state.current.identityService.combinationCheckTotal !== undefined) {
      return (state.current.identityService.combinationCheckCompleted! / state.current.identityService.combinationCheckTotal * 100);
    } else {
      return (state.current.order / (state.flow.length) * 100);
    }
  }

  if (props.useLayout || props.useLayout === undefined) {
    return (
      <div className={state.org.theme.config.classNameMobile}>
        <Segment className={`mobile-content-header-container${props.clientClass ? "-" + props.clientClass : ""}`}>
          <StyledProgressBar percent={getProgressBarPercent()} attached="top" color={state.org.theme.themeColor} className="" />
          {
            state.org.theme.config.mobileHeaderImageOnly ? (
              <Image src={state.org.theme.logo} style={{ height: "4.5em", width: "auto" }} />
            ) : (
              <Header as="h3" className="mt-0">
                <Image src={state.org.theme.logo} style={{ height: "2.5em", width: "auto" }} /> {t(props.title)}
              </Header>
            )
          }
        </Segment>
        <div style={{ flex: 1 }} className={`mobile-service-item-container ${state.current.identityService.service ? `${EService[state.current.identityService.service]}-container` : ""}`}>
          {props.children}
        </div>
      </div>

    )
  } else {
    return (
      <div style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap", justifyContent: "space-between", alignItems: "stretch", height: "100%", width: "100vw" }} className={state.org.theme.config.mitekClassNameMobile}>
        {props.children}
      </div>
    )
  }

}

export default MobileLayout
