import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Header, Message, Segment } from "semantic-ui-react"
import { EOCRLabsPageType } from "../../../../Types/OCRLabs/Enums/EOCRLabsPageType";
import { StateValue } from "../../../Context";

export interface IOCRWarningErrorProps {
  /** Content to be displayed on the page */
  messageKeys: string[],
  /** Header translation key, displayed at the top of the page, defaults to OCR.Error.Header */
  headerTranslationKey?: string
  /** Sub header translation key, displayed at the top of the page, defaults to OCR.Error.MessageHeader */
  messageHeaderTranslationKey?: string
  onCallback?: () => void
}

// Reuse the same code for both errors and warnings, just pass in a boolean to determine if it's an error or not
interface IPropsGeneric extends IOCRWarningErrorProps {
  isError: boolean
}

const OCRGenericWarningError = (props: IPropsGeneric) => {
  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);
  const { messageKeys, headerTranslationKey, messageHeaderTranslationKey, isError } = props;
  return (
    <div className="service-item-container ocr-padding">
      <span>
        <Header as="h1" className="service-item-header">
          {t(headerTranslationKey ?? "OCR.GenericError.Header")}
        </Header>
        <Message header={t(messageHeaderTranslationKey ?? "OCR.GenericError.MessageHeader")} error={isError} warning={!isError} list={messageKeys.map(key => t(key))} />
      </span>
      <Segment basic className="service-item-button-container mt-0">
        <ButtonGroup>
          <Button
            color="green"
            floated="right"
            type="submit"
            onClick={() => { props.onCallback?.() }}
          >
            {t("General_UI.Next")}
          </Button>
        </ButtonGroup>
      </Segment>
    </div>
  )
}

export const OCRGenericError = (props: IOCRWarningErrorProps) => {
  const { messageKeys, headerTranslationKey, messageHeaderTranslationKey, onCallback } = props;
  return (
    <OCRGenericWarningError messageKeys={messageKeys} isError={true} headerTranslationKey={headerTranslationKey} messageHeaderTranslationKey={messageHeaderTranslationKey} onCallback={onCallback} />
  )
}

export const OCRGenericWarning = (props: IOCRWarningErrorProps) => {
  const { messageKeys, headerTranslationKey, messageHeaderTranslationKey, onCallback } = props;
  return (
    <OCRGenericWarningError headerTranslationKey={headerTranslationKey} isError={false} messageHeaderTranslationKey={messageHeaderTranslationKey} messageKeys={messageKeys} onCallback={onCallback} />
  )
}