export enum EMitekErrorType {
  NONE = 0,
  IMG = 1,
  MISSING_DATA = 2,
  AGENT_ASSIST = 3,
  EXPIRED = 4,
  COUNTRY = 5,
  IMG_SELFIE = 6,
  HARD_FAILURE = 7,
  ID = 8,
  AGENT_RESPONSE_INVALID = 9,
  UNDERAGE = 10
}