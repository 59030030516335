// import { DiscreteColorLegend, FlexibleWidthXYPlot, HorizontalGridLines, VerticalBarSeries, VerticalGridLines, XAxis, YAxis } from "react-vis";
import { Grid } from "semantic-ui-react";
import { IgetHomeScreenStatisticsByCustomerId } from "../../../../Types";
import { RootStoreContext } from "../../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";

interface IData {
  key: string;
  name: string;
  themeColor: string;
  transactions: IDataCall[]
}

interface IDataCall {
  y: number;
  x: string;
}

export const StatisticsHome = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { getStatisticsByCustomer } = rootStore.statisticsStore;

  // const BarSeries = VerticalBarSeries;

  const [data, setData] = useState<IgetHomeScreenStatisticsByCustomerId[]>([]);
  const [dataParsed, setDataParsed] = useState<IData[]>();

  useEffect(() => {
    getStatistics();
  }, [])

  const getStatistics = async () => {
    var res = await getStatisticsByCustomer();
    if (res) {
      setData(res);
      var parsed = res.map((x) => ({
        key: x.name + "st", name: x.name, themeColor: x.themeColor, transactions: x.transactionCallByDay.map((t) => ({
          y: t.count, x: t.date.toString().slice(5, 10)
        }))
      }));
      setDataParsed(parsed);
    }
  }

  return (
    <div>
      <Grid>
        <Grid.Row>
          {/* <Grid.Column width={13}>
            <div className="datavis">
              <FlexibleWidthXYPlot xType="ordinal" height={300} stackBy="y">
                <VerticalGridLines tickTotal={11} />
                <HorizontalGridLines tickTotal={11} />
                <XAxis tickSize={3} tickTotal={data?.length ?? 0} title="Datum" />
                <YAxis tickSize={3} title="API-calls per dag" />
                {dataParsed?.map((x, index) => (
                  <BarSeries
                    key={`${x.name}-${index}-ccc`}
                    width={1}
                    barWidth={1}
                    data={x.transactions}
                    color={x.themeColor}
                  />
                ))}
              </FlexibleWidthXYPlot>
            </div>
          </Grid.Column>
          <Grid.Column width={3} style={{ textAlign: "left", display: "flex", justifyContent: "center", alignItems: 'center', flexFlow: "column" }}>
            <DiscreteColorLegend
              height={300}
              width={150}
              items={dataParsed?.filter((x, i, a) => a.indexOf(x) == i).map((x, index) => ({ title: x.name, color: x.themeColor, key: `${x.name}-${x.themeColor}-${index}` })) ?? ([] as any)} />
          </Grid.Column> */}
        </Grid.Row>
      </Grid>
    </div>
  )
})