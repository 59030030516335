import axios from 'axios'
import coverArt_Sonio from '../../Assets/Sonio_Cover.png'
import { Button, Container, Grid, Header, Icon, Message } from 'semantic-ui-react'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

interface RouteParams {
  ctxId: string;
}

export const ResultDevSession = () => {
  const { ctxId } = useParams<RouteParams>();
  const [result, setResult] = useState<any>();

  useEffect(() => {
    axios.get(`/api/Development/resultcallback/transaction/${ctxId}`).then(res => {
      if (res.status === 200) {
        setResult(res.data.paspoortOcrCheck.output.evidence[0].extractedData);
      }
    })
  }, [])

  const handleClick = () => {
    window.location.href = "https://www.sonioid.com/";
  }

  return (
    <Container style={{ width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", backgroundImage: `url(${coverArt_Sonio})`, backgroundSize: "cover" }}>
      <div style={{ textAlign: "center", background: "white", padding: "3em", borderRadius: "14px", boxShadow: "0px 16px 40px 0px rgba(0,0,0,0.3)" }}>
        <Header as="h1">
          Welcome back!
        </Header>
        <Header as="h2" style={{ marginBottom: "2em" }}>
          Once integrated, we would now pass all the results back to the operator.
        </Header>
        <Header as="h3" style={{ marginBottom: "4em" }}>
          If successful, the user would be able to continue <br />
        </Header>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Button color="green" onClick={handleClick} >
                Successful <Icon name='angle right' />
              </Button>
            </Grid.Column>
            <Grid.Column>
              <Button color="red" onClick={handleClick} >
                Failed - Please validate manually <Icon name='angle right' />
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {result &&
          <>
            <pre>{JSON.stringify(result, null, 1)}</pre>
          </>}
        <Message warning>
          Saved pictures are stored on server, not shown here.
        </Message>
      </div>
    </Container>
  );
};
