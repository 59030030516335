import { EToastType } from "../Enums/EToastType";
import { IJsonExport } from "../models/export/IJsonExport";
import { RootStore } from "./RootStore";
import { makeAutoObservable, runInAction } from "mobx";

export default class ExportStore {
  rootStore: RootStore;
  submitting: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  createJsonExport = async (values: IJsonExport) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success, data }: { success: boolean, data: any } = await this.rootStore.baseApiService.get(`export/json?ctxId=${values.ctxId}`);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }
}