import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { CheckBox } from "../../../Common/form/CheckBox";
import { Field, Formik } from "formik";
import { FlowSettingsMitekFormValues, IFlowSettingsMitek } from "../../../../models/flow/settings/IFlowOrder";
import { FlowSettingsMitekValidation } from "./FlowSettingsMitek.Validation";
import { RootStoreContext } from "../../../../stores/RootStore"
import { Spinner } from "../../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

interface IProps {
  flowId: number;
}

export const FlowSettingsMitek = observer(({ flowId }: IProps) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { loading, getMitekSettingsForFlow, submitting, updateMitekSettingsForFlow } = rootStore.flowSettingsStore;

  const [flowSettings, setFlowSettings] = useState<IFlowSettingsMitek>();
  const [formValues, setFormValues] = useState(new FlowSettingsMitekFormValues());

  useEffect(() => {
    const loadData = async () => {
      var flowSettings = await getMitekSettingsForFlow(flowId);
      setFlowSettings(flowSettings);
      setFormValues(new FlowSettingsMitekFormValues(flowSettings));
    }
    loadData();
  }, [flowId])

  const handleSubmit = async (values: FlowSettingsMitekFormValues) => {
    await updateMitekSettingsForFlow(flowId, values);
  }

  return (
    <>
      <Header as="h1" attached="top">{t("Flow_Settings.Mitek.Title")}</Header>
      <Segment loading={loading}>
        {!flowSettings ? <Spinner /> : (
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
            validationSchema={FlowSettingsMitekValidation}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">{t("Flow_Settings.General.Heading")}</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="isVisible"
                          label={t("Flow_Settings.General.Visible")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {/* Flow settings */}
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">{t("Flow_Settings.Mitek.Scan_Requirements")}</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.hasPassport"
                          label={t("Flow_Settings.Mitek.Scan_Requirements_Passport")}
                        />
                      </Grid.Column>
                      {formik.values.mitekSettings.hasPassport && (
                        <Grid.Column>
                          <Field
                            component={CheckBox}
                            name="mitekSettings.enablePassportSignature"
                            label={t("Flow_Settings.Mitek.Scan_Requirements_Passport_Signature")}
                          />
                        </Grid.Column>
                      )}
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.hasId"
                          label={t("Flow_Settings.Mitek.Scan_Requirements_Idcard")}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.hasDriversLicence"
                          label={t("Flow_Settings.Mitek.Scan_Requirements_DriversLicense")}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.hasResidencePermit"
                          label={t("Flow_Settings.Mitek.Scan_Requirements_ResidencePermit")}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.enableSelfie"
                          label={t("Flow_Settings.Mitek.Scan_Requirements_Selfie")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">{t("Flow_Settings.Mitek.Logic")}</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.documentTypeMustMatch"
                          label={t("Flow_Settings.Mitek.Document_Type_Should_Match")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.allowExpiredDocuments"
                          label={t("Flow_Settings.Mitek.Expired_Documents_Allowed")}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.isCountryInclusive"
                          label={t("Flow_Settings.Mitek.Country_Inclusive")}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.extractedDataOnDesktop"
                          label={t("Flow_Settings.Mitek.Extracted_Data_To_Desktop")}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.allowInvalidData"
                          label={t("Flow_Settings.Mitek.Allow_Invalid_Data")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">{t("Flow_Settings.Mitek.Missing_Properties")}</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.appendMissingProperties"
                          label={t("Flow_Settings.Mitek.Append_Missing_Properties_Show")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    {formik.values.mitekSettings.appendMissingProperties && (
                      <>
                        <Grid.Row columns={3}>
                          <Grid.Column>
                            <Field
                              component={CheckBox}
                              name="mitekSettings.appendMissingPropertiesAddition"
                              label={t("Flow_Settings.Mitek.Append_Missing_Properties_Add")}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Field
                              component={CheckBox}
                              name="mitekSettings.appendMissingPropertiesUpdate"
                              label={t("Flow_Settings.Mitek.Append_Missing_Properties_Update")}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Field
                              component={CheckBox}
                              name="mitekSettings.appendMissingPropertiesDesktop"
                              label={t("Flow_Settings.Mitek.Append_Missing_Properties_Desktop")}
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Field
                              component={CheckBox}
                              name="mitekSettings.freeCruksEdit"
                              label={t("Flow_Settings.Mitek.FreeCruksEdit")}
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </>
                    )}
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">{t("Flow_Settings.Mitek.Agent_Handoff")}</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={3}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="mitekSettings.allowAgentHandoff"
                          label={t("Flow_Settings.Mitek.Agent_Handoff_Enabled")}
                        />
                      </Grid.Column>
                      {formik.values.mitekSettings.allowAgentHandoff && (
                        <Grid.Column>
                          <Field
                            component={CheckBox}
                            name="mitekSettings.agentHandoffOnFrontend"
                            label={t("Flow_Settings.Mitek.Agent_Handoff_Frontend")}
                          />
                        </Grid.Column>
                      )}
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column textAlign="right">
                        <Button type="submit" primary disabled={submitting}>
                          {t("Button.Submit")}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Form>
            )}
          </Formik>
        )}
      </Segment>
    </>
  )
})