import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { CheckBox } from "../../../Common/form/CheckBox";
import { EService } from "../../../../Enums/EService";
import { Field, Formik } from "formik";
import { FlowSettingsGeneralFormValues, IFlowOrder } from "../../../../models/flow/settings/IFlowOrder";
import { RootStoreContext } from "../../../../stores/RootStore";
import { Spinner } from "../../../Common/spinner/Spinner";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  flowId: number;
  service: EService;
}

export const FlowSettingsGeneral = observer(({ flowId, service }: IProps) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { loading, submitting, getGeneralSettingsForFlow, updateGeneralSettingsForFlow } = rootStore.flowSettingsStore;

  const [flowSettings, setFlowSettings] = useState<IFlowOrder>();
  const [formValues, setFormValues] = useState(new FlowSettingsGeneralFormValues());

  useEffect(() => {
    const loadData = async () => {
      var generalSettings = await getGeneralSettingsForFlow(flowId, service);
      setFlowSettings(generalSettings);
      setFormValues(new FlowSettingsGeneralFormValues(generalSettings));
    }
    loadData();
  }, [flowId, service]);

  const handleSubmit = async (values: FlowSettingsGeneralFormValues) => {
    await updateGeneralSettingsForFlow(flowId, values);
  }

  return (
    <>
      <Header as="h1" attached="top">{t("Flow_Settings.General.Title")}</Header>
      <Segment loading={loading}>
        {!flowSettings ? <Spinner /> : (
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Segment>
                  <Grid>
                    <Grid.Row>
                      <Grid.Column>
                        <Header as="h3">{t("Flow_Settings.General.Heading")}</Header>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                      <Grid.Column>
                        <Field
                          component={CheckBox}
                          name="isVisible"
                          label={t("Flow_Settings.General.Visible")}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column textAlign="right">
                        <Button type="submit" primary disabled={submitting}>
                          {t("Button.Submit")}
                        </Button>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Segment>
              </Form>
            )}
          </Formik>
        )}
      </Segment>
    </>
  )
})