import { isDesktop, isIPad13 } from "react-device-detect";
import { EMitekPageType } from "../../../../Enums/EMitekPageType";

export const getStartingPage = () => {
  if (sessionStorage.getItem("MITEK_FINISHED") === "true") {
    return EMitekPageType.GENERAL_COMPLETED;
  }

  if (isDesktop && !isIPad13) {
    return EMitekPageType.DESKTOP_GENERAL;
  }

  return EMitekPageType.DOCUMENT_SELECTION;
}