import { AdminFormValues, IAdmin } from "../models/user/IAdmin";
import { EToastType } from "../Enums/EToastType";
import { RootStore } from "./RootStore";
import { history, routePrefixDashboard } from "../ApplicationRouter";
import { makeAutoObservable, runInAction } from "mobx";

export default class AdminStore {
  rootStore: RootStore;
  loadingAdmins: boolean = false;
  submitting: boolean = false;
  admins: IAdmin[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getAdmins = async () => {
    runInAction(() => {
      this.loadingAdmins = true;
    });

    const { success, data }: { success: boolean, data: IAdmin[] } = await this.rootStore.baseApiService.get('admin2');
    if (success) {
      runInAction(() => {
        this.admins = data;
        this.loadingAdmins = false;
      });
    } else {
      runInAction(() => {
        this.loadingAdmins = false;
      });
    }
  }

  getAdmin = async (id: string) => {
    runInAction(() => {
      this.loadingAdmins = true;
    });

    const { success, data }: { success: boolean, data: IAdmin } = await this.rootStore.baseApiService.get(`admin2/${id}`);
    if (success) {
      runInAction(() => {
        this.loadingAdmins = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingAdmins = false;
      });
    }
  }

  addAdmin = async (values: AdminFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.post('admin2', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      history.push(`${routePrefixDashboard}/admins`);
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  editAdmin = async (values: AdminFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.put(`admin2/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      });
      history.push(`${routePrefixDashboard}/admins`);
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  deleteAdmin = async (id: string) => {
    this.submitting = true;

    const success = await this.rootStore.baseApiService.delete(`admin2/${id}`);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.confirmModalStore.closeConfirmModal();
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Delete_Success");
        this.getAdmins();
      });
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }
}