import { useState, useEffect, useRef, useCallback } from 'react';

type CountdownHookProps = {
  count: number;
  onComplete: () => void;
};

type CountdownHookState = {
  currentCount: number;
  startCountdown: () => void;
};

const useCountdown = ({ count, onComplete }: CountdownHookProps): CountdownHookState => {
  const [currentCount, setCurrentCount] = useState(count);
  const intervalRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setCurrentCount(count);
  }, [count])

  const startCountdown = useCallback(() => {
    if (intervalRef.current) return;

    intervalRef.current = setInterval(() => {
      setCurrentCount((prevCount) => {
        if (prevCount === 0) {
          clearInterval(intervalRef.current!);
          intervalRef.current = undefined;
          onComplete();
          setCurrentCount(count);
          return count;
        }

        return prevCount - 1;
      });
    }, 1000);
  }, [count, onComplete]);

  useEffect(() => {
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, []);

  return { currentCount, startCountdown };
};

export default useCountdown;