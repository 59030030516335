import { Container, Divider, Header } from "semantic-ui-react";
import { CustomerParent } from "../../../Components/secure/customer/CustomerParent";
import { RouteComponentProps, useParams } from "react-router-dom";

interface RouteParams {
  id: string;
}

export const EditCustomer: React.FC<RouteComponentProps<RouteParams>> = () => {
  const { id } = useParams<RouteParams>();
  return (
    <>
      <Container>
        <Header as="h1">Edit</Header>
        <Divider />
        <CustomerParent id={id} />
      </Container>
    </>
  )
}