import AdminStore from "./AdminStore";
import ApplicationStore from "./ApplicationStore";
import AuthenticationStore from "./AuthenticationStore";
import AuthorizationStore from "./AuthorizationStore";
import ClientManagerStore from "./ClientManagerStore";
import ConfirmModalStore from "./ConfirmModalStore";
import CustomerClientStore from "./CustomerClientStore";
import CustomerKeyStore from "./CustomerKeyStore";
import CustomerManagerStore from "./CustomerManagerStore";
import DtsManagerStore from "./DtsManagerStore";
import FlowSettingsStore from "./FlowSettingsStore";
import LinkStore from "./LinkStore";
import ModalStore from "./ModalStore";
import NotificationStore from "./NotificationStore";
import ProfileStore from "./ProfileStore";
import StatisticsStore from "./StatisticsStore";
import { BaseApiService } from "../Services/BaseApiService";
import { createContext } from "react";
import ExportStore from "./ExportStore";

export class RootStore {
  adminStore: AdminStore;
  applicationStore: ApplicationStore;
  authenticationStore: AuthenticationStore;
  authorizationStore: AuthorizationStore;
  baseApiService: BaseApiService;
  clientManagerStore: ClientManagerStore;
  confirmModalStore: ConfirmModalStore;
  customerClientStore: CustomerClientStore;
  customerKeyStore: CustomerKeyStore;
  customerManagerStore: CustomerManagerStore;
  dtsManagerStore: DtsManagerStore;
  exportStore: ExportStore;
  flowSettingsStore: FlowSettingsStore;
  linkStore: LinkStore;
  modalStore: ModalStore;
  notificationStore: NotificationStore;
  profileStore: ProfileStore;
  statisticsStore: StatisticsStore;

  constructor() {
    this.adminStore = new AdminStore(this);
    this.applicationStore = new ApplicationStore(this);
    this.authenticationStore = new AuthenticationStore(this);
    this.authorizationStore = new AuthorizationStore(this);
    this.baseApiService = new BaseApiService(this);
    this.clientManagerStore = new ClientManagerStore(this);
    this.confirmModalStore = new ConfirmModalStore(this);
    this.customerClientStore = new CustomerClientStore(this);
    this.customerKeyStore = new CustomerKeyStore(this);
    this.customerManagerStore = new CustomerManagerStore(this);
    this.dtsManagerStore = new DtsManagerStore(this);
    this.exportStore = new ExportStore(this);
    this.flowSettingsStore = new FlowSettingsStore(this);
    this.linkStore = new LinkStore(this);
    this.modalStore = new ModalStore(this);
    this.notificationStore = new NotificationStore(this);
    this.profileStore = new ProfileStore(this);
    this.statisticsStore = new StatisticsStore(this);
  }
}

export const RootStoreContext = createContext(new RootStore());