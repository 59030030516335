import { EToastType } from "../Enums/EToastType";
import { IManager, ManagerFormValues } from "../models/user/IManager";
import { RootStore } from "./RootStore";
import { history, routePrefixDashboard } from "../ApplicationRouter";
import { makeAutoObservable, runInAction } from "mobx";

export default class ClientManagerStore {
  rootStore: RootStore;
  loadingClientManagers: boolean = false;
  submitting: boolean = false;
  clientManagers: IManager[] = [];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getClientManagers = async () => {
    runInAction(() => {
      this.loadingClientManagers = true;
    });

    const { success, data }: { success: boolean; data: IManager[] } = await this.rootStore.baseApiService.get("clientManager");
    if (success) {
      runInAction(() => {
        this.clientManagers = data;
        this.loadingClientManagers = false;
      });
    } else {
      runInAction(() => {
        this.loadingClientManagers = false;
      });
    }
  }

  getClientManager = async (id: string) => {
    runInAction(() => {
      this.loadingClientManagers = true;
    });

    const { success, data }: { success: boolean; data: IManager } = await this.rootStore.baseApiService.get(`clientManager/${id}`);
    if (success) {
      runInAction(() => {
        this.loadingClientManagers = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loadingClientManagers = false;
      });
    }
  }

  addClientManager = async (values: ManagerFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.post('clientManager', values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      history.push(`${routePrefixDashboard}/client-managers`);
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  editClientManager = async (values: ManagerFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });

    const { success } = await this.rootStore.baseApiService.put(`clientManager/${values.id}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success");
      });
      history.push(`${routePrefixDashboard}/client-managers`);
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }

  deleteClientManager = async (id: string) => {
    runInAction(() => {
      this.submitting = true;
    });

    const success = await this.rootStore.baseApiService.delete(`clientManager/${id}`);
    if (success) {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.confirmModalStore.closeConfirmModal();
        this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Delete_Success");
      });
      this.getClientManagers();
    } else {
      runInAction(() => {
        this.submitting = false;
        this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
      });
    }
  }
}