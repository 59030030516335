import { Button, Form, FormFieldProps, FormInputProps, Icon, Label, LabelProps, Popup, PopupProps, StrictFormFieldProps } from "semantic-ui-react"
import { Field, FieldProps, getIn } from "formik"
import { StateValue } from '../../Context'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'

interface TextInputProps {
  warning?: LabelProps,
  tooltip?: PopupProps,
  formInput?: FormInputProps,
  formField?: FormFieldProps,
}

export interface IFormikFieldConfig extends Partial<FieldProps<string, HTMLInputElement>>, TextInputProps, Pick<StrictFormFieldProps, "error" | "disabled" | "required"> {
  component: any
  [key: string]: any
}

export const CustomField = (props: IFormikFieldConfig) => {
  return (
    <Field {...props} />
  )
}


{/*

  <CustomField
    component={TextInputNew}

    warning={{any semantic LabelProps or anything you'd want to apply to the Error/Warning Label}}
    formInput={{any semantic FormInputProps or anything you'd want to apply to the Form.Input}}
    tooltip={{any semantic PopupProps or anything you'd want to apply to the input field}}
    formField={{any semantic FormFieldProps or anything you'd want to apply to the Form.Field}}

    id="password"
    label={t("Login_Password")} // Shorthand to change label text
    name="password"
    type="password"
  />

*/}

export const TextInput = (props: Omit<IFormikFieldConfig, "component">) => {

  const state = useContext(StateValue);
  const { t } = useTranslation(state.org.theme.config.translationKey);

  return (
    <Form.Field error={props.error} required={props.required} {...props.formField}>
      <label>
        {props.label}
        {props.tooltip && (
          <Popup
            {...props.tooltip}
            trigger={
              <Button style={{ border: "none", boxShadow: "none", paddingRight: 0, paddingBottom: "0.25em" }} compact size="small" basic type="button" icon={<Icon style={{ marginBottom: "1em" }} name="info circle" fitted />} />
            }
            position='bottom left'
          />
        )}
      </label>
      <Form.Input disabled={props.disabled} className="mt-1" id={props.id} {...props.formInput} {...props.inputProps} type={props.type} {...props.field} />
      {getIn(props.form!.touched, props.field!.name) && getIn(props.form!.errors, props.field!.name) && (
        <Label
          pointing="above"
          style={{ width: "100%", marginLeft: 0 }}
          size="large"
          color="red"
          icon="times circle"
          content={t(getIn(props.form!.errors, props.field!.name))}
          {...props.warning}
        />
      )}
    </Form.Field>
  )
}

