import privateRoutesConfig from "../../../routes/PrivateRoutesConfig";
import { Container, Dropdown, Menu } from "semantic-ui-react";
import { GetAllowedRoutes } from "../../../utils/RouteUtils";
import { NavLink, useRouteMatch } from "react-router-dom";
import { RootStoreContext } from "../../../stores/RootStore";
import { StyledDashboardNavbarSecondary } from "./DashboardNavbar.styles";
import { history, routePrefixDashboard } from "../../../ApplicationRouter";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useTranslation } from "react-i18next";

export const DashboardNavbarSecondary = observer(() => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { role } = rootStore.authorizationStore;

  const match = useRouteMatch(routePrefixDashboard);

  let allowedRoutes: any = [];

  if (rootStore.authorizationStore.isAuthenticated()) {
    allowedRoutes = GetAllowedRoutes(privateRoutesConfig);
  } else {
    history.push("/login");
  }

  return (
    <StyledDashboardNavbarSecondary>
      <Container className="sec-navbar">
        {role ? (
          <>
            <Menu.Item className="nav-dashboard" as={NavLink} exact to={routePrefixDashboard}>
              {t("Navbar.Dashboard")}
            </Menu.Item>

            {allowedRoutes.some((route: { path: string; title: string; showInMenu: boolean }) => route.showInMenu) &&
              <StyledDashboardNavbarSecondary.Menu className="nav-desktop">
                {allowedRoutes.filter((route: { showInMenu: boolean }) => route.showInMenu).map(
                  (
                    route: {
                      path: string;
                      title: string;
                      showInMenu: boolean;
                      dropDown: boolean;
                      childs: [];
                    },
                    index: number
                  ) =>
                    !route.dropDown ? (
                      <StyledDashboardNavbarSecondary.Item
                        as={NavLink}
                        className="border"
                        key={index}
                        name={route.title}
                        to={`${match?.path}${route.path}`}
                      >
                        {t(route.title)}
                      </StyledDashboardNavbarSecondary.Item>
                    ) : (
                      <Dropdown
                        className="border nav-dropdown"
                        item
                        key={index}
                        simple
                        trigger={(
                          <StyledDashboardNavbarSecondary.Item>
                            {route.title}</StyledDashboardNavbarSecondary.Item>
                        )}>
                        <Dropdown.Menu>
                          {route.childs.map(
                            (
                              childRoute: {
                                path: string;
                                showInMenu: boolean;
                                title: string;
                              },
                              childIndex: number
                            ) => childRoute.showInMenu && (
                              <Dropdown.Item
                                as={NavLink}
                                key={`${index}-${childIndex}`}
                                text={t(childRoute.title)}
                                to={`${match?.path}${childRoute.path}`}
                              />
                            )
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                    )
                )}
              </StyledDashboardNavbarSecondary.Menu>}
          </>
        ) : <></>}
      </Container>
    </StyledDashboardNavbarSecondary>
  )
})