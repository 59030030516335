import { Container, Divider, Header } from "semantic-ui-react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { OrganizationParent } from "../../../Components/secure/organization/OrganizationParent";

interface RouteParams {
  id: string;
}

export const EditOrganization: React.FC<RouteComponentProps<RouteParams>> = () => {
  const { id } = useParams<RouteParams>();
  return (
    <>
      <Container>
        <Header as="h1">Edit</Header>
        <Divider />
        <OrganizationParent id={id} />
      </Container>
    </>
  )
};