import MitekHeader from '../../MitekHeader';
import { Segment, Header, Icon, Button } from 'semantic-ui-react';
import { state } from '../../../../Context';
import { useTranslation } from 'react-i18next';

type IProps = {
  hint: string | undefined;
  loadingCamera: boolean;
  startManualCapture: () => void;
  openFileBrowser: () => void;
  handleFileSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
  inputFile: React.RefObject<HTMLInputElement>;
  state: state;
}

const MitekCheckManualCaptureBackComponent = (props: IProps) => {
  const { t } = useTranslation(props.state?.org.theme.config.translationKey);

  return (
    <>
      <MitekHeader />
      <Segment id="manual-select" className="mitek manual-screen" basic style={{ height: "90%", width: "100%", margin: 0, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "stretch", flex: 1 }}>
        <h2>{t("Mitek.Back")}</h2>
        <div className="manual-screen-items">
          <div>
            {
              props.hint !== null && props.hint !== undefined && props.hint.length > 2 && (
                <h2>
                  {props.hint}, {t("General_UI.Could_You_Try_Again").toLowerCase()}
                </h2>
              )
            }
          </div>
          <div className="manual-screen-button-container">
            <Segment placeholder loading={props.loadingCamera} className="manual-screen-button">
              <Header icon>
                <Icon style={{ display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center" }} fitted name='camera' />
              </Header>
              <Button primary color="green" floated="right" onClick={() => props.startManualCapture()}>{t("Mitek.Open_Camera")}</Button>
            </Segment>
            <Segment placeholder className="manual-screen-button">
              <Header icon>
                <Icon style={{ display: "flex", alignContent: "center", justifyContent: "center", alignItems: "center" }} fitted name='file image outline' />
                {/* {t("Mitek.Manually_Choose_Document")} */}
              </Header>
              <Button primary color="green" floated="right" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} onClick={() => props.openFileBrowser()}>{t("Mitek.Select_File")}</Button>
              <input onChange={(e) => props.handleFileSelect(e)} accept="image/*" type='file' id='file' ref={props.inputFile} style={{ display: 'none' }} />
            </Segment>
          </div>
        </div>
      </Segment>
    </>
  )
}

export default MitekCheckManualCaptureBackComponent