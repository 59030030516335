import MitekHeader from "../../MitekHeader"
import useInterval from "../../../../../Hooks/useInterval";
import { Button, Segment } from "semantic-ui-react";
import { EMitekCheckStatus } from "../../../../../Enums/EMitekCheckStatus";
import { EMitekErrorType } from "../../../../../Enums/EMitekErrorType";
import { EMitekPageType } from "../../../../../Enums/EMitekPageType";
import { ETransactionStatusCode } from "../../../../../Enums/ETransactionStatusCode";
import { IMitekResponse } from "../../../../../Types";
import { getMitekAgentHandoffStatus } from "../../../../../Services/Backend";
import { state } from "../../../../Context"
import { useState } from "react";
import { useTranslation } from "react-i18next";

type IProps = {
  state: state;
  ctxId: string;
  setCurrentPage: (page: number) => void;
  setResponseCallback: (response: IMitekResponse) => void;
}

export const MitekCheckAgentHandoff = (props: IProps) => {
  const { t } = useTranslation(props.state?.org.theme.config.translationKey);

  const [count, setCount] = useState(0);

  useInterval(() => {
    setCount(count + 1);
    if (count < 120) {
      getMitekAgentHandoffStatus(props.ctxId).then(res => {
        if (res.data.status === EMitekCheckStatus.COMPLETED) {
          const didFinishDto = res.data.didFinishDto;
          var mitekResponse: IMitekResponse = {
            ctxId: props.ctxId,
            isAgentReviewRequest: true,
            requiredFields: didFinishDto.requiredFields,
            extractedData: didFinishDto.extractedData ?? undefined,
            success: didFinishDto.status == ETransactionStatusCode.Completed,
            reasons: []
          };
          props.setResponseCallback(mitekResponse);
          props.setCurrentPage(EMitekPageType.ERROR_MISSING_DATA);
        } else if (res.data.status === EMitekCheckStatus.PROCESSING) {
          // No action, still pending
        } else {
          if (res.data.mitekError === EMitekErrorType.COUNTRY) {
            props.setCurrentPage(EMitekPageType.ERROR_COUNTRY);
          } else if (res.data.mitekError === EMitekErrorType.EXPIRED) {
            props.setCurrentPage(EMitekPageType.ERROR_EXPIRED);
          } else {
            props.setCurrentPage(EMitekPageType.ERROR_FRADULENT);
          }
        }
      })
    }
  }, 15000);

  return (
    <>
      <MitekHeader />
      <Segment className="mitek" basic style={{ height: "90%", width: "100%", margin: 0 }}>
        <h1 className="service-item-header mb-1">{t("Mitek.Agent_Assist.Header")}</h1>
        <h3 className="service-item-header mb-1">{t("Mitek.Agent_Assist.SubHeader")}</h3>
        {/* <h3 className="service-item-header mb-1">{t("Mitek.Agent_Assist.Explanation")}</h3> */}
      </Segment>
      <Segment className="service-item-button-container mitek" style={{ height: "10%", margin: 0, width: "100vw", display: "flex", justifyContent: "flex-end", alignItems: "center", }}>
        <Button className="full-width" loading color="green" floated="right" disabled>
          {t("General_UI.Next")}
        </Button>
      </Segment>
    </>
  )
}