import DropFile from "../../Common/DropFile/DropFile";
import { BlockPicker } from "react-color";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import { OrganizationPreviewFormValues } from "../../../models/organization/IOrganization";
import { OrganizationThemePreview } from "./OrganizationThemePreview";
import { RootStoreContext } from "../../../stores/RootStore";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";

interface IProps {
  id?: number;
  color: string | undefined;
  name: string;
}

export const OrganizationPreview: React.FC<IProps> = observer(({ id, name, color }) => {
  const rootStore = useContext(RootStoreContext);
  const { editThemeForCustomer } = rootStore.customerClientStore;

  const themeColors = ["#D9E3F0", "#F47373", "#697689", "#37D67A", "#2CCCE4", "#555555", "#dce775", "#ff8a65"];
  const [themeColor, setThemeColor] = useState<string>(themeColors[2])
  const [logo, setLogo] = useState<any>();

  useEffect(() => {
    if (id && id !== 0) {
      setLogo(`/${id}/${id}_logo.png`);
    }
  }, [])

  useEffect(() => {
    if (color !== undefined) {
      setThemeColor(color);
    }
  }, [color])

  const callbackImage = (files: any[]) => {
    if (id === undefined) return;

    if (files.length > 0) {
      const file = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        setLogo(reader.result as string);
      }
    }
  }

  const saveLogoAndTheme = async () => {
    if (id) {
      const values = new OrganizationPreviewFormValues({ id: id, logo: logo, themeColor: themeColor });

      await editThemeForCustomer(values);
    }
  }

  return (
    <>
      <Header as="h4" attached>
        Logo en uiterlijk
      </Header>
      <Segment attached>
        <Grid>
          <Grid.Row>
            <Grid.Column width={5} floated="right">
              <Button floated="right" disabled={!id || id === 0} primary onClick={saveLogoAndTheme}>
                Save logo en uterlijk
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <OrganizationThemePreview logo={logo} name={name} themeColor={themeColor} />
        <Segment basic className="colorpicker">
          <BlockPicker
            width="140px"
            colors={themeColors}
            color={themeColor}
            onChangeComplete={(color) => setThemeColor(color.hex)}
          />
          <DropFile
            disabled={id === undefined}
            callBack={(e) => callbackImage(e)}
            text={id === undefined ? "Sla eerst de organisatie op, voor u een logo toevoegt" : "Upload een logo"}
            type="image/png"
            error="Dit is geen afbeelding"
            icon="file image outline"
            color={themeColor ?? "#22194d"}
          />
        </Segment>
      </Segment>
    </>
  )
})