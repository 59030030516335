import "./DropFile.css";
import { Segment, Icon, Button } from "semantic-ui-react";
import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const DropFile: React.FC<DropFileProps> = (props: DropFileProps) => {
  const onDrop = useCallback(
    (acceptedFiles, rejected: any[]) => {
      if (rejected.length === 1) {
        alert(props.error);
      } else {
        props.callBack(acceptedFiles);
      }
    },
    [props],
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: props.type });

  return (
    <>
      <div {...getRootProps()}>
        <Segment style={{ backgroundColor: props.color, borderBottomColor: props.color }} basic className="imgupload">
          <Segment basic textAlign='center' style={{ height: "65px" }} className="vertcenter">
            <Icon aria-hidden="false" name={props.icon} size="big" style={{ color: "white", mixBlendMode: 'color-dodge' }} />
          </Segment>
          <Segment basic style={{ backgroundColor: "white", height: "105.97px" }} className="vertcenter">
            <input disabled={props.disabled} {...getInputProps()} />
            <Button basic disabled={props.disabled}>{isDragActive ? "Drop file here" : props.text}</Button>
          </Segment>
        </Segment>
      </div>
    </>
  );
};

type DropFileProps = {
  callBack: (file: any) => void;
  type: string;
  error: string;
  text: string;
  icon: any;
  color: string;
  disabled: boolean
};

export default DropFile;