import { Field, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Grid, Header, Segment } from "semantic-ui-react";
import { FlowSettingsOcrLabsFormValues, IFlowSettingsOcrLabs } from "../../../../models/flow/settings/IFlowOrder";
import { RootStoreContext } from "../../../../stores/RootStore";
import { CheckBox } from "../../../Common/form/CheckBox";
import { Spinner } from "../../../Common/spinner/Spinner";

interface IProps {
  flowId: number;
}

export const FlowSettingsOcr = observer(({ flowId }: IProps) => {
  const rootStore = useContext(RootStoreContext);
  const { t } = useTranslation();
  const { loading, getOcrLabsSettingsForFlow, submitting, updateOcrLabsSettingsForFlow } = rootStore.flowSettingsStore;

  const [flowSettings, setFlowSettings] = useState<IFlowSettingsOcrLabs>();
  const [formValues, setFormValues] = useState(new FlowSettingsOcrLabsFormValues());

  useEffect(() => {
    const loadData = async () => {
      var flowSettings = await getOcrLabsSettingsForFlow(flowId);
      setFlowSettings(flowSettings);
      setFormValues(new FlowSettingsOcrLabsFormValues(flowSettings));
    }
    loadData();
  }, [flowId])

  const handleSubmit = async (values: FlowSettingsOcrLabsFormValues) => {
    await updateOcrLabsSettingsForFlow(flowId, values);
  }

  return (
    <>
      <Header as="h1">{t("Flow_Settings.OCR_Labs.Title")}</Header>
      <Segment loading={loading}>
        {!flowSettings ? <Spinner /> : (
          <Formik
            enableReinitialize
            initialValues={formValues}
            onSubmit={handleSubmit}
          >
            {(formik) => (
              <Form onSubmit={formik.handleSubmit}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3">{t("Flow_Settings.General.Heading")}</Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={1}>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="isVisible"
                        label={t("Flow_Settings.General.Visible")}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  {/* Flow settings */}
                  <Grid.Row>
                    <Grid.Column>
                      <Header as="h3">{t("Flow_Settings.OCR_Labs.Logic")}</Header>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row columns={2} >
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="ocrLabsSettings.performFaceAndLiveness"
                        label={t("Flow_Settings.OCR_Labs.PerformFaceAndLiveness")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="ocrLabsSettings.extractedDataOnDesktop"
                        label={t("Flow_Settings.OCR_Labs.ExtractedDataOnDesktop")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="ocrLabsSettings.appendMissingProperties"
                        label={t("Flow_Settings.OCR_Labs.AppendMissingProperties")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="ocrLabsSettings.allowExpiredDocuments"
                        label={t("Flow_Settings.OCR_Labs.AllowExpiredDocuments")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="ocrLabsSettings.allowUnderAge"
                        label={t("Flow_Settings.OCR_Labs.AllowUnderAge")}
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Field
                        component={CheckBox}
                        name="ocrLabsSettings.allowInvalidData"
                        label={t("Flow_Settings.OCR_Labs.AllowInvalidData")}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column textAlign="right">
                      <Button type="submit" primary disabled={submitting}>
                        {t("Button.Submit")}
                      </Button>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Segment>
    </>
  )
});