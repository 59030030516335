export enum EMitekPageType {
  DOCUMENT_SELECTION = 0,
  SCAN_FRONT = 1,
  SCAN_BACK = 2,
  GENERAL_SUBMIT = 3,
  EXPLANATION_FRONT = 4,
  EXPLANATION_BACK = 5,
  GENERAL_LOADING = 6,
  GENERAL_LOADING_DESKTOP = 7,
  ERROR_FRADULENT = 8,
  ERROR_MISSING_DATA = 9,
  MANUAL_CAPTURE_FRONT = 10,
  MANUAL_CAPTURE_BACK = 11,
  GENERAL_AGENT = 12,
  GENERAL_SUCCESS = 13,
  ERROR_EXPIRED = 15,
  ERROR_COUNTRY = 16,
  EXPLANATION_SELFIE = 17,
  MANUAL_CAPTURE_SELFIE = 18,
  SCAN_SELFIE = 19,
  GENERAL_COMPLETED = 20,
  SCAN_SIGNATURE = 21,
  EXPLANATION_SIGNATURE = 22,
  MANUAL_CAPTURE_SIGNATURE = 23,
  ERROR = 24,
  CHECK_DESKTOP = 25,
  DESKTOP_APPEND_ERROR_MISSING_DATA = 26,
  DESKTOP_GENERAL = 27, // TODO: Split this up into multiple components.
  SCAN_QR = 28,
  MANUAL_CAPTURE_QR = 29,
  EXPLANATION_QR = 30,
}