import * as Yup from "yup";

export const FlowSettingsMitekValidation = () => Yup.object().shape({
  isVisible: Yup.boolean(),
  mitekSettings: Yup.object().shape({
    agentHandoffOnFrontend: Yup.boolean(),
    allowAgentHandoff: Yup.boolean(),
    allowExpiredDocuments: Yup.boolean(),
    appendMissingProperties: Yup.boolean().test(
      'validate-missingProps', 'Flow_Settings.Mitek.Error.Should_MissingProperties_Be_Shown',
      function () {
        if (this.parent.appendMissingPropertiesAddition || this.parent.appendMissingPropertiesUpdate || !this.parent.appendMissingProperties) {
          return true;
        }
        this.createError();
        return false;
      }
    ),
    appendMissingPropertiesAddition: Yup.boolean(),
    appendMissingPropertiesDesktop: Yup.boolean(),
    appendMissingPropertiesUpdate: Yup.boolean(),
    documentTypeMustMatch: Yup.boolean(),
    enablePassportSignature: Yup.boolean(),
    enableSelfie: Yup.boolean(),
    extractedDataOnDesktop: Yup.boolean(),
    hasDriversLicence: Yup.boolean().test(
      'validate-hasOneDocument-d', 'Flow_Settings.Mitek.Error.One_Document_Type_Required',
      function () {
        if (this.parent.hasId || this.parent.hasDriversLicence || this.parent.hasPassport) {
          return true;
        }
        this.createError();
        return false;
      }
    ),
    hasId: Yup.boolean().test(
      'validate-hasOneDocument-i', 'Flow_Settings.Mitek.Error.One_Document_Type_Required',
      function () {
        if (this.parent.hasId || this.parent.hasDriversLicence || this.parent.hasPassport) {
          return true;
        }
        this.createError();
        return false;
      }
    ),
    hasPassport: Yup.boolean().test(
      'validate-hasOneDocument-p', 'Flow_Settings.Mitek.Error.One_Document_Type_Required',
      function () {
        if (this.parent.hasId || this.parent.hasDriversLicence || this.parent.hasPassport) {
          return true;
        }
        this.createError();
        return false;
      }
    ).test(
      "validate-passportSignature", "Flow_Settings.Mitek.Error.Passport_Signature_Enabled",
      function (value) {
        if (!value && this.parent.enablePassportSignature) {
          this.createError();
          return false;
        }
        return true;
      }),
    isCountryInclusive: Yup.boolean()
  })
})