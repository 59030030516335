import { CreateCustomerManager } from "../../pages/Secure/users/customerManager/CreateCustomerManager";
import { CustomerManagers } from "../../pages/Secure/users/customerManager/CustomerManagers";
import { EditCustomerManager } from "../../pages/Secure/users/customerManager/EditCustomerManager";
import { IRoute } from "../../models/routes/IRoute";
import { Roles } from "../../Enums/Roles";

export const CustomerManagerRoutes: IRoute[] = [
  {
    component: CustomerManagers,
    path: "/customer-managers",
    title: "Navbar.CustomerManagers",
    exact: true,
    showInMenu: true,
    permission: [Roles.ADMIN, Roles.DTSMANAGER, Roles.CUSTOMERMANAGER],
    dropdown: false,
    childs: []
  },
  {
    component: EditCustomerManager,
    path: "/customer-manager/edit/:id",
    title: "Navbar.CustomerManagers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER, Roles.CUSTOMERMANAGER],
    dropdown: false,
    childs: [],
  },
  {
    component: CreateCustomerManager,
    path: "/customer-manager/new",
    title: "Navbar.CustomerManagers",
    exact: true,
    showInMenu: false,
    permission: [Roles.ADMIN, Roles.DTSMANAGER, Roles.CUSTOMERMANAGER],
    dropdown: false,
    childs: [],
  },
]