import MitekHeader from '../../MitekHeader'
import { AnimationSuccess } from '../../../../AnimationViews/AnimationSuccess'
import { Button, Segment } from 'semantic-ui-react'
import { state } from '../../../../Context'
import { useTranslation } from 'react-i18next'

type IProps = {
  onSuccess: () => void,
  state: state,
  props: any
}

const MitekCheckSuccessComponent = (props: IProps) => {
  const { t } = useTranslation(props.state?.org.theme.config.translationKey);

  return (<>
    <MitekHeader />
    <Segment id="success-screen" className={"mitek" + props.props.match.params.handoff !== undefined ? "handoff" : "no-handoff"} basic style={{ height: "90%", width: "100%", margin: 0 }}>
      <AnimationSuccess showSvg={true} header={t("General_UI.Great")} text={`${t("Mitek.Loading.Success")}${props.props.match.params.handoff !== undefined ? t("Mitek.Loading.Check_Computer") : "."}`} key={`${props.state.view}-success`} />
    </Segment>
    {
      props.props.match.params.handoff == undefined ? (
        <Segment className="service-item-button-container mitek mt-0" style={{ height: "10%", margin: 0, width: "100%", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
          <Button className="full-width" color="green" floated="right" onClick={() => props.onSuccess()} disabled={props.props.match.params.handoff !== undefined}>
            {t("General_UI.Next")}
          </Button>
        </Segment>
      ) : null
    }
  </>)
}

export default MitekCheckSuccessComponent