import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { AG_GRID_LOCALE_EN } from './AgGrid.Locale.en';
import { Icon, Input } from 'semantic-ui-react';
import { formatDateTime } from './../../../utils/General';
import { toJS } from "mobx";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export type DataViewRenderer =
  | "actions"
  | "checkmark"
  | "datetime"
  | "date"
  | "enum"
  | "locked";

export type DataViewSort =
  | "desc"
  | "asc"

interface IEnumRenderInput {
  value: string | number;
  type: any;
  translationKey: string;
}

export interface IDataViewColumn {
  headerName: string;
  field?: string;
  sortable?: boolean;
  filter?: boolean;
  cellRenderer?: DataViewRenderer;
  cellRendererParams?: any;
  flex?: number;
  minWidth?: number;
  maxWidth?: number;
  valueGetter?: any;
  rowDrag?: boolean;
  lockPosition?: boolean;
  tooltipField?: string;
  sort?: DataViewSort;
  valueFormatter?: (params: any) => any;
}

export const DataView = ({ columns, data, actionsRenderer, quickFilter, onDrop: onPassData }: { columns: IDataViewColumn[]; data: any[]; actionsRenderer: (data: any) => JSX.Element, quickFilter?: any, onDrop?: any }) => {
  const [gridApi, setGridApi] = useState<any>(undefined);
  const [gridColumnApi, setGridColumnApi] = useState(null)
  const { t } = useTranslation();

  const onGridReady = (params: any) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.setRowData(data);
  }

  gridApi && gridApi.setQuickFilter(quickFilter);

  // Renderers
  const checkmarkRenderer = (input: any): JSX.Element => {
    const isChecked: boolean = input.getValue();
    return isChecked ? <Icon color="green" name="check" /> : <></>
  }

  const datetimeRenderer = (input: any): string => {
    return formatDateTime(input.getValue(), "I18n.Formatted.DateTime");
  }

  const enumRenderer = (input: IEnumRenderInput): string => {
    const enumValue = Object.keys(input.type)[Object.values(input.type).indexOf(input.value)];

    return t(`${input.translationKey}.${enumValue}`)
  }

  const dateRenderer = (input: any): string => {
    return formatDateTime(input.getValue(), "I18n.Formatted.Date");
  }

  const lockRenderer = (input: any) => {
    const isEncrypted: any = toJS(input.data);
    return isEncrypted ? <Icon name="lock" /> : input.getValue()
  }
  // End renderers

  // Row dragging
  const onRowDragMove = (event: any) => {
    var movingNode = event.node;
    var overNode = event.overNode;
    var rowNeedsToMove = movingNode !== overNode;

    const moveInArray = (arr: any, fromIndex: any, toIndex: any) => {
      var element = arr[fromIndex]
      arr.splice(fromIndex, 1)
      arr.splice(toIndex, 0, element)
    }

    if (rowNeedsToMove && overNode !== null && overNode.data !== null) {
      var movingData = movingNode.data;
      var overData = overNode.data;
      var fromIndex = data.indexOf(movingData);
      var toIndex = data.indexOf(overData);
      var newData = data.slice();
      moveInArray(newData, fromIndex, toIndex);
      data = newData;
      gridApi.setRowData(newData);
      gridApi.clearFocusedCell();
    }
  }

  const getRowNodeId = (data: any) => {
    return data.id;
  }

  const onDrop = (event: any) => {
    const movingNode = event.node;
    const previousNode = data[movingNode.rowIndex - 1];
    const nextNode = data[movingNode.rowIndex + 1];

    onPassData(movingNode.id, previousNode?.id, nextNode?.id);
  }
  // End row dragging

  return (
    <div className="ag-theme-alpine" style={{ height: "80vh", width: "100%", lineHeight: 1 }}>
      <AgGridReact
        frameworkComponents={{
          actions: actionsRenderer,
          checkmark: checkmarkRenderer,
          date: dateRenderer,
          datetime: datetimeRenderer,
          enum: enumRenderer,
          locked: lockRenderer,
        }}
        columnDefs={columns}
        defaultColDef={{ filter: false }}
        enableBrowserTooltips={true}
        immutableData={true}
        localeText={AG_GRID_LOCALE_EN}
        onGridReady={onGridReady}
        pagination={true}
        paginationAutoPageSize={true}
        rowData={data}
        suppressCellFocus={true}
        suppressMovableColumns={true}
        suppressRowClickSelection={true}

        // Row dragging
        animateRows={true}
        getRowNodeId={getRowNodeId}
        onRowDragEnd={onDrop}
        onRowDragLeave={onDrop}
        onRowDragMove={onRowDragMove}
        suppressMoveWhenRowDragging={true}
      />
    </div>
  )
}

export const DataViewSearch = (props: any) => {
  const { t } = useTranslation();

  return (
    <Input
      icon="search"
      onInput={props.onFilterChange}
      placeholder={t("Dataview.Search")}
      type="text"
    />
  )
}
