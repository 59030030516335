import { EService } from "../Enums/EService";
import { EToastType } from "../Enums/EToastType";
import { FlowSettingsBigFormValues, FlowSettingsGeneralFormValues, FlowSettingsMitekFormValues, FlowSettingsOcrLabsFormValues, IFlowOrder, IFlowSettingsBig, IFlowSettingsMitek, IFlowSettingsOcrLabs } from "../models/flow/settings/IFlowOrder";
import { RootStore } from "./RootStore";
import { makeAutoObservable, runInAction } from "mobx";
import { IMitekRequiredFields, IMitekRequiredFieldsFormValues } from "../models/flow/requiredFields/IMitekRequiredFields";
import { OcrLabsValidationField, OcrLabsValidationFieldFormValues } from "../Types";

export default class FlowSettingsStore {
  rootStore: RootStore;
  loading: boolean = false;
  submitting: boolean = false;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  getGeneralSettingsForFlow = async (flowId: number, service: EService) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IFlowOrder } = await this.rootStore.baseApiService.get(`flowsettings/general/${flowId}?service=${service}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  updateGeneralSettingsForFlow = async (flowId: number, values: FlowSettingsGeneralFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`flowSettings/general/${flowId}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success")
    } else {
      this.submitting = false;
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  getBigSettingsForFlow = async (flowId: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IFlowSettingsBig } = await this.rootStore.baseApiService.get(`flowSettings/big/${flowId}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  updateBigSettingsForFlow = async (flowId: number, values: FlowSettingsBigFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`flowSettings/big/${flowId}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success")
    } else {
      this.submitting = false;
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  getMitekSettingsForFlow = async (flowId: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IFlowSettingsMitek } = await this.rootStore.baseApiService.get(`flowSettings/mitek/${flowId}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  getOcrLabsSettingsForFlow = async (flowId: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IFlowSettingsOcrLabs } = await this.rootStore.baseApiService.get(`flowSettings/ocr/${flowId}`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  updateMitekSettingsForFlow = async (flowId: number, values: FlowSettingsMitekFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`flowSettings/mitek/${flowId}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success")
    } else {
      this.submitting = false;
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  updateOcrLabsSettingsForFlow = async (flowId: number, values: FlowSettingsOcrLabsFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`flowSettings/ocr/${flowId}`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success")
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  getMitekRequiredFieldsForFlow = async (flowId: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: IMitekRequiredFields } = await this.rootStore.baseApiService.get(`flowSettings/mitek/${flowId}/required-fields`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  getOcrLabsValidationFieldsForFlow = async (flowId: number) => {
    runInAction(() => {
      this.loading = true;
    });
    const { success, data }: { success: boolean; data: OcrLabsValidationField } = await this.rootStore.baseApiService.get(`flowSettings/ocr/${flowId}/validation-fields`);
    if (success) {
      runInAction(() => {
        this.loading = false;
      });
      return data;
    } else {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  updateMitekRequiredFieldsForFlow = async (flowId: number, values: IMitekRequiredFieldsFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`flowSettings/mitek/${flowId}/required-fields`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success")
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }

  updateOcrLabsValidationFieldsForFlow = async (flowId: number, values: OcrLabsValidationFieldFormValues) => {
    runInAction(() => {
      this.submitting = true;
    });
    const { success }: { success: boolean } = await this.rootStore.baseApiService.put(`flowSettings/ocr/${flowId}/validation-fields`, values);
    if (success) {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Success, "Notification.Saved_Success")
    } else {
      runInAction(() => {
        this.submitting = false;
      });
      this.rootStore.notificationStore.showNotification(EToastType.Error, "Notification.Error");
    }
  }
}