import React, { useEffect, useState } from 'react'
import { Button, ButtonProps } from 'semantic-ui-react'

interface IProps {
  callback: () => void,
  text: string,
  buttonProps: ButtonProps,
  isError: boolean,
  errorText: string,
  countDownProp?: number,
}

export const TimerButtonManual: React.FC<IProps> = ({ callback, text, buttonProps, isError, errorText, countDownProp }) => {
  const [countDown, setCountDown] = useState(countDownProp ?? 5);

  useEffect(() => {
    if (countDown > 0) {
      setTimeout(() => setCountDown(countDown - 1), 1000);
    }
  }, [countDown])

  return (
    <Button floated="right" type="submit" disabled={countDown !== 0} onClick={callback} {...buttonProps}>
      {!isError ? (countDown !== 0 ? `${text} (${countDown})` : text) : errorText}
    </Button>
  )
}
